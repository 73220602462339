import { Angle } from 'interfaces/api/lab'
import { getExperimentFlexions } from 'modules/lab/services'
import { forwardRef, memo, useState } from 'react'
import { useQuery } from 'react-query'
import { useParams } from 'react-router-dom'

import { FlexionAngleLoading } from './FlexionPanelLoading'
import { FlexionPanelAngle } from './FlexionPanelAngle'

const FlexionPanel = memo(
  forwardRef<HTMLDivElement>((_, ref) => {
    const { experimentId } = useParams()
    if (!experimentId) {
      return null
    }
    const [panelStatus, setPanelStatus] = useState<Record<Angle, boolean>>({
      [Angle.ZERO]: false,
      [Angle.NINETY]: false
    })

    const get = useQuery(
      ['lab.experiment.flexion', experimentId],
      async () => await getExperimentFlexions(experimentId)
    )

    const handleOnPanelChange = (angle: Angle, open: boolean) => {
      setPanelStatus((status) => ({ ...status, [angle]: open }))
    }

    return get.isFetched ? (
      <div ref={ref}>
        <FlexionPanelAngle
          angle={Angle.ZERO}
          onPanelChange={(open) => handleOnPanelChange(Angle.ZERO, open)}
          open={panelStatus[Angle.ZERO]}
        />
        <div className='h-5'></div>
        <FlexionPanelAngle
          angle={Angle.NINETY}
          onPanelChange={(open) => handleOnPanelChange(Angle.NINETY, open)}
          open={panelStatus[Angle.NINETY]}
        />
      </div>
    ) : (
      <div ref={ref}>
        <FlexionAngleLoading angle={Angle.ZERO} />
        <div className='h-5'></div>
        <FlexionAngleLoading angle={Angle.NINETY} />
      </div>
    )
  })
)

export { FlexionPanel }
