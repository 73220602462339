import { forwardRef, memo, useContext, useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { useParams } from 'react-router-dom'
import { format } from 'date-fns'

import { SvgCreate } from 'assets/svg'
import { UiContext } from 'contexts/ui.context'
import { DATE_FORMAT, ToastType } from 'core/constants'
import { ErrorObj, Nullable } from 'interfaces'
import { ExperimentWaterUptake, ExperimentWaterUptakeDto, WaterUptakeLine } from 'interfaces/api/lab'
import { getExperimentWaterUptakes, postExperimentWaterUptakes } from 'modules/lab/services'

import { ButtonPrimary, ButtonSecondary, Panel } from 'ui/atoms'

import { WaterUptakeFormValues } from '../ProductionDataTab.type'
import { WaterUptake } from './WaterUptake'

import { i18n } from 'i18n'
import { i18n as productionDatai18n } from 'modules/lab/i18n'
import { WATER_UPTAKE_PREFIX } from 'modules/lab/constants'
import { SpinnerLoaderMask } from 'ui/molecules'

const panelI18n = productionDatai18n.productionDataTab.waterUptakePanel

const WaterUptakePanel = memo(
  forwardRef<HTMLFormElement>((_, ref) => {
    const { experimentId } = useParams()

    if (!experimentId) {
      return null
    }

    const [canSubmit, setCanSubmit] = useState<boolean>(false)
    const [waterUptakeError, setWaterUptakeError] = useState<Nullable<ErrorObj<ExperimentWaterUptakeDto>>>(null)
    const { updateToast } = useContext(UiContext)
    const queryClient = useQueryClient()

    const { control, setValue, handleSubmit, watch, reset } = useForm<{ water_uptakes: WaterUptakeFormValues }>({
      defaultValues: { water_uptakes: [] }
    })

    const get = useQuery(
      ['lab.experiment.waterUptake', experimentId],
      async () => await getExperimentWaterUptakes(experimentId),
      {
        onSuccess: ({ data }) => {
          setCanSubmit(true)
          resetForm(data)
        }
      }
    )
    const resetForm = (data?: Nullable<ExperimentWaterUptake<WaterUptakeLine>[]>) => {
      data ? reset({ water_uptakes: data }) : reset({ water_uptakes: [] })
    }

    const post = useMutation((data: ExperimentWaterUptakeDto) => postExperimentWaterUptakes(experimentId, data), {
      onMutate: () => {
        setWaterUptakeError(null)
      },
      onSuccess: (data) => {
        queryClient.setQueryData(['lab.experiment.waterUptake', experimentId], data)
        updateToast({
          content: get.data?.data?.length ? panelI18n.toasts.updateSuccess : panelI18n.toasts.createSuccess,
          displayed: true,
          type: ToastType.SUCCESS
        })
      },
      onError: (e: Error & { errors?: ErrorObj<ExperimentWaterUptakeDto> }) => {
        setWaterUptakeError(e.errors ?? null)
        updateToast({
          content: e.message ?? 'Une erreur est survenue',
          displayed: true,
          type: ToastType.ERROR
        })
      }
    })
    const handleOnSubmit: SubmitHandler<{ water_uptakes: WaterUptakeFormValues }> = ({ water_uptakes }) => {
      post.mutate({ water_uptakes })
    }

    const handleCreateNewWaterUptake = () => {
      const lastIndex = watch('water_uptakes').length
      setValue(`water_uptakes.${lastIndex}.is_active`, false)
      setValue(`water_uptakes.${lastIndex}.identifier`, `${WATER_UPTAKE_PREFIX}${lastIndex + 1}`)
      setValue(`water_uptakes.${lastIndex}.lines`, [])
      setValue(`water_uptakes.${lastIndex}.lines.0.analyzed_at`, format(new Date(), DATE_FORMAT))
      setValue(`water_uptakes.${lastIndex}.lines.0.days_count`, 0)
      setValue(`water_uptakes.${lastIndex}.lines.0.sample_weight`, 0)
      setValue(`water_uptakes.${lastIndex}.lines.0.weight_gain`, 0)
      updateIdentifier()
    }
    const handleCreateNewWaterUptakeLine = (id: number) => {
      const lastIndex = watch(`water_uptakes.${id}.lines`).length
      setValue(`water_uptakes.${id}.lines.${lastIndex}.analyzed_at`, format(new Date(), DATE_FORMAT))
      setValue(`water_uptakes.${id}.lines.${lastIndex}.days_count`, 0)
      setValue(`water_uptakes.${id}.lines.${lastIndex}.sample_weight`, 0)
      setValue(`water_uptakes.${id}.lines.${lastIndex}.weight_gain`, 0)
      updateIdentifier()
    }
    const handleDeleteWaterUptakeLine = (waterUptakeIndex: number, lineIndex: number) => {
      const lines = watch(`water_uptakes.${waterUptakeIndex}.lines`)
      if (lines.length <= 1) {
        const waterUptakes = watch(`water_uptakes`)
        const updatedWaterUptakes = waterUptakes.filter((_, i) => i !== waterUptakeIndex)
        setValue(`water_uptakes`, updatedWaterUptakes)
      } else {
        const updatedLines = lines.filter((_, i) => i !== lineIndex)
        setValue(`water_uptakes.${waterUptakeIndex}.lines`, updatedLines)
      }
      updateIdentifier()
    }
    const updateIdentifier = () => {
      const waterUptakes = watch(`water_uptakes`)
      for (let i = 0; i < waterUptakes.length; i++) {
        setValue(`water_uptakes.${i}.identifier`, `${WATER_UPTAKE_PREFIX}${i}`)
      }
    }

    return (
      <form onSubmit={handleSubmit(handleOnSubmit)} ref={ref}>
        <Panel noPadding={true} openable={true} title={panelI18n.title}>
          <SpinnerLoaderMask
            message={get.isFetching ? i18n.isLoading : i18n.isSaving}
            spinning={get.isFetching || post.isLoading}
          >
            <div className='flex flex-col items-start'>
              {watch('water_uptakes').map(({ lines }, i) => (
                <WaterUptake
                  control={control}
                  key={i}
                  lines={lines}
                  onCreateNewWaterUptakeLine={handleCreateNewWaterUptakeLine}
                  onDeleteWaterUptakeLine={handleDeleteWaterUptakeLine}
                  setValue={setValue}
                  waterUptakeCount={watch('water_uptakes').length}
                  waterUptakeError={waterUptakeError}
                  waterUptakeIndex={i}
                />
              ))}
              <button className='flex items-center mt-4' onClick={handleCreateNewWaterUptake} type='button'>
                <img className='mr-4 w-7 h-7' src={SvgCreate} />
                <span className='font-medium whitespace-nowrap'>{i18n.addNewId}</span>
              </button>
              <div className='flex flex-row justify-around mx-auto mt-10 w-full  max-w-2xl'>
                <ButtonSecondary className='px-14 mr-4' onClick={() => resetForm(get.data?.data)} type='button'>
                  {i18n.cancel}
                </ButtonSecondary>
                <ButtonPrimary className='px-14' disabled={!canSubmit} type='submit'>
                  {i18n.submit}
                </ButtonPrimary>
              </div>
            </div>
          </SpinnerLoaderMask>
        </Panel>
      </form>
    )
  })
)

export { WaterUptakePanel }
