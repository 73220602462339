import { ExperimentWaterUptake, ExperimentWaterUptakeDto } from 'interfaces/api/lab'
import { fetchJson } from 'utils/fetch'

/**
 *
 * @param experimentId
 * @param payload
 * @returns
 */
const postExperimentWaterUptakes = (experimentId: string, payload: ExperimentWaterUptakeDto) => {
  return fetchJson<ExperimentWaterUptake[], ExperimentWaterUptakeDto>(
    'POST',
    `/lab/experiments/${experimentId}/water-uptakes`,
    payload
  )
}

/**
 *
 * @param experimentId
 * @returns
 */
const getExperimentWaterUptakes = (experimentId: string) => {
  return fetchJson<ExperimentWaterUptake[]>('GET', `/lab/experiments/${experimentId}/water-uptakes`)
}

export { postExperimentWaterUptakes, getExperimentWaterUptakes }
