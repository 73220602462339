import { SvgCreate, SvgDisk } from 'assets/svg'
import { FIBERS } from 'core/constants'
import { Nullable } from 'interfaces'
import { Fiber, Roving } from 'interfaces/api/management'
import { getManagementRovings, postManagementRoving } from 'modules/list-parameters/services/rovings'
import { FC, FormEvent, useState } from 'react'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { useSearchParams } from 'react-router-dom'
import { UnderlinedInput } from 'ui/atoms/UnderLinedInput'
import { UnderLinedSelect } from 'ui/atoms/UnderLinedSelect'
import { PaginationMenu } from 'ui/molecules/PaginationMenu'
import { RovingItem } from './RovingItem'

export const Rovings: FC = () => {
  const [searchParams] = useSearchParams()

  const [rovingCreationError, setRovingCreationError] = useState<Nullable<Record<string, string[]>>>(null)
  const [isInputVisible, setIsInputVisible] = useState(false)
  const [newRovingLabel, setNewRovingLabel] = useState<Nullable<string>>(null)
  const [newRovingFiber, setNewRovingFiber] = useState<Nullable<Fiber>>(null)
  const [page, setPage] = useState<string>(searchParams.get('page') ?? '1')
  const [perPage, setPerPage] = useState<string>(searchParams.get('perPage') ?? '10')

  const fetchRovings = (page: string, perPage: string) => getManagementRovings(page, perPage)

  const queryClient = useQueryClient()
  const query = useQuery(['rovings', page, perPage], async () => await fetchRovings(page, perPage), {
    keepPreviousData: true
  })

  const rovingCreation = useMutation(postManagementRoving, {
    onMutate: () => setRovingCreationError(null),
    onSuccess: () => {
      queryClient.invalidateQueries('rovings')
      setIsInputVisible(false)
      setNewRovingLabel(null)
      setNewRovingFiber(null)
    },
    onError: (e: { errors?: Record<string, string[]> }) => setRovingCreationError(e.errors ?? null)
  })

  const onCreate = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    rovingCreation.mutate({ label: newRovingLabel, fiber: newRovingFiber })
  }

  const onPaginationChange = (newPage: string, newPerPage: string) => {
    setPage(newPage)
    setPerPage(newPerPage)
  }

  return (
    <div className='flex flex-col w-full h-full'>
      <div className='flex-initial py-10 px-16 bg-white rounded shadow-card'>
        <div className='flex flex-row'>
          <h2 className='mb-2.5 text-sm font-medium'>Roving</h2>
          <span className='flex w-36'></span>
          <h2 className='mb-2.5 text-sm font-medium'>Fibre</h2>
        </div>
        {query.data?.data &&
          query.data.data
            .sort((a, b) => (a.label > b.label ? 1 : -1))
            .map((roving: Roving) => (
              <div className='mt-5' key={roving.id}>
                <RovingItem roving={roving} />
              </div>
            ))}
        <div className='flex justify-between items-center mt-5'>
          {!isInputVisible && (
            <button className='flex items-center' onClick={() => setIsInputVisible(true)}>
              <img className='mr-4 w-7 h-7' src={SvgCreate} />
              <span className='font-medium whitespace-nowrap'>Ajouter un roving</span>
            </button>
          )}
          {isInputVisible && (
            <form className='flex flex-row flex-auto justify-between' name='form' onSubmit={onCreate}>
              <div className='overflow-hidden flex-initial w-28'>
                <UnderlinedInput
                  errors={rovingCreationError?.label ?? null}
                  onChange={setNewRovingLabel}
                  value={newRovingLabel ?? ''}
                />
              </div>
              <div className='overflow-hidden flex-initial ml-10 w-28'>
                <UnderLinedSelect
                  errors={rovingCreationError?.fiber ?? null}
                  onInput={(e) => setNewRovingFiber((e.target as HTMLSelectElement).value as Fiber)}
                  options={FIBERS}
                  value={newRovingFiber ?? ''}
                />
              </div>
              <button className='flex-initial pl-1 ml-10' data-testid='submit-button' type='submit'>
                <img className='w-4 h-4' src={SvgDisk} />
              </button>
            </form>
          )}
        </div>
      </div>
      <div className='mt-4 ml-auto'>
        <PaginationMenu meta={query.data?.meta ?? null} onChange={onPaginationChange} />
      </div>
    </div>
  )
}
