import { FC } from 'react'

import { MainTemplate } from 'ui/templates'

import { Link } from 'react-router-dom'
import { DescriptionCreationTab } from 'modules/lab/components/description-tab/DescriptionCreationTab'

const Title: FC = () => <h1 className='text-xl font-bold text-dark-blue'>Créer un nouvel essai</h1>
const BackButton: FC = () => <Link className='text-dusty-teal' to='/experiments'>{`< Retour`}</Link>

const ExperimentCreation: FC = () => {
  return (
    <MainTemplate backButton={<BackButton />} className='overflow-y-scroll' title={<Title />}>
      <div className='mt-10' />
      <DescriptionCreationTab />
    </MainTemplate>
  )
}

export { ExperimentCreation }
