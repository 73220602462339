import { PultrusionDie } from 'interfaces/api/management'
import { PultrusionDieUpdateDto } from './list-parameters.dto'
import { fetchJson } from 'utils/fetch'

/**
 *
 * @param page
 * @param perPage
 * @returns
 */
const getManagementPultrusionDies = (page: string, perPage: string) => {
  return fetchJson<PultrusionDie[], undefined>('GET', `/management/pultrusion-dies?page=${page}&per_page=${perPage}`)
}

/**
 *
 * @param label
 * @returns
 */
const postManagementPultrusionDie = (label: string) => {
  return fetchJson<PultrusionDie, PultrusionDieUpdateDto>('POST', '/management/pultrusion-dies', { label })
}

/**
 *
 * @param index
 * @param label
 * @returns
 */
const putManagementPultrusionDie = (index: number, label: string) => {
  return fetchJson<PultrusionDie, PultrusionDieUpdateDto>('PUT', `/management/pultrusion-dies/${index}`, { label })
}

/**
 *
 * @param index
 * @returns
 */
const deleteManagementPultrusionDie = (index: number) => {
  return fetchJson('DELETE', `/management/pultrusion-dies/${index}`)
}

export {
  deleteManagementPultrusionDie,
  getManagementPultrusionDies,
  postManagementPultrusionDie,
  putManagementPultrusionDie
}
