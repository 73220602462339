import { SvgCheckboxChecked, SvgCreate } from 'assets/svg'
import { Nullable } from 'interfaces'
import { User, UserDTO } from 'interfaces/api/management'
import { deleteManagementUser, getManagementUsers, postManagementUser } from 'modules/list-parameters/services/users'
import { FC, useState } from 'react'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { useSearchParams } from 'react-router-dom'
import { ToastNotification } from 'ui/atoms/ToastNotification'
import { PaginationMenu } from 'ui/molecules/PaginationMenu'
import { UserTableForm } from './UserTableForm'
import { UserTableItem } from './UserTableItem'

const emptyUser = () => ({
  first_name: '',
  last_name: '',
  email: ''
})

export const Users: FC = () => {
  const [searchParams] = useSearchParams()
  const [page, setPage] = useState<string>(searchParams.get('page') ?? '1')
  const [isInputVisible, setIsInputVisible] = useState(false)
  const [isSuccessToastVisible, setIsSuccessToastVisible] = useState(false)
  const [perPage, setPerPage] = useState<string>(searchParams.get('perPage') ?? '10')
  const [userCreationError, setUserCreationError] = useState<Nullable<Record<string, string>>>(null)
  const [newUser, setNewUser] = useState<UserDTO>(emptyUser())
  const fetchThemes = (page: string, perPage: string) => getManagementUsers(page, perPage)

  const queryClient = useQueryClient()
  const query = useQuery(['users', page, perPage], async () => await fetchThemes(page, perPage), {
    keepPreviousData: true
  })

  const themeCreation = useMutation(postManagementUser, {
    onMutate: () => setUserCreationError(null),
    onSuccess: () => {
      queryClient.invalidateQueries('users')
      setNewUser(emptyUser())
      setIsInputVisible(false)
      setIsSuccessToastVisible(true)
    },
    onError: (e: { errors?: Record<string, string> }) => setUserCreationError(e.errors ?? null)
  })

  const themeDelete = useMutation(deleteManagementUser, {
    onSuccess: () => queryClient.invalidateQueries('users')
  })
  const onUserDelete = (id: number) => {
    themeDelete.mutate(id)
  }

  const onCreateUser = (user: UserDTO) => {
    themeCreation.mutate(user)
  }

  const onPaginationChange = (newPage: string, newPerPage: string) => {
    setPage(newPage)
    setPerPage(newPerPage)
  }

  return (
    <div className='flex flex-col w-full h-full'>
      {isSuccessToastVisible && (
        <ToastNotification
          onClose={() => setIsSuccessToastVisible(false)}
          svg={SvgCheckboxChecked}
          text={'Le compte à été créé'}
        />
      )}
      <div className='py-10 px-16 bg-white rounded shadow-card'>
        <table className='table-fixed'>
          <thead>
            <tr>
              <td className='w-56 h-12 font-medium'>Nom</td>
              <td className='w-56 h-12 font-medium'>Prénom</td>
              <td className='w-72 h-12 font-medium'>Email</td>
              <td></td>
            </tr>
          </thead>
          <tbody>
            {isInputVisible && (
              <UserTableForm
                errors={userCreationError ?? {}}
                onChange={setNewUser}
                onSave={onCreateUser}
                user={newUser}
              />
            )}
            {query.data?.data &&
              query.data.data
                .sort((a: User, b: User) => (a.last_name > b.last_name ? 1 : -1))
                .map((user: User) => (
                  <UserTableItem className='h-12' key={user.id} onUserDelete={onUserDelete} user={user} />
                ))}
          </tbody>
        </table>

        {!isInputVisible && (
          <button className='flex items-center mt-4' onClick={() => setIsInputVisible(true)}>
            <img className='mr-4 w-7 h-7' src={SvgCreate} />
            <span className='font-medium whitespace-nowrap'>Ajouter un nouvel utilisateur</span>
          </button>
        )}
      </div>
      <div className='mt-4 ml-auto'>
        <PaginationMenu meta={query.data?.meta ?? null} onChange={onPaginationChange} />
      </div>
    </div>
  )
}
