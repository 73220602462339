import { SvgCreate, SvgDisk } from 'assets/svg'
import { Nullable } from 'interfaces'
import { Reinforcement } from 'interfaces/api/management'
import {
  getManagementReinforcements,
  postManagementReinforcement
} from 'modules/list-parameters/services/reinforcements'
import { FC, FormEvent, useState } from 'react'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { useSearchParams } from 'react-router-dom'
import { UnderlinedInput } from 'ui/atoms/UnderLinedInput'
import { PaginationMenu } from 'ui/molecules/PaginationMenu'
import { ReinforcementItem } from './ReinforcementItem'

export const Reinforcements: FC = () => {
  const [searchParams] = useSearchParams()

  const [themeCreationError, setReinforcementCreationError] = useState<Nullable<string[]>>(null)
  const [isInputVisible, setIsInputVisible] = useState(false)
  const [newReinforcementLabel, setNewthemeLabel] = useState<Nullable<string>>(null)
  const [page, setPage] = useState<string>(searchParams.get('page') ?? '1')
  const [perPage, setPerPage] = useState<string>(searchParams.get('perPage') ?? '10')

  const fetchReingorcement = (page: string, perPage: string) => getManagementReinforcements(page, perPage)

  const queryClient = useQueryClient()
  const query = useQuery(['reinforcements', page, perPage], async () => await fetchReingorcement(page, perPage), {
    keepPreviousData: true
  })

  const themeCreation = useMutation(postManagementReinforcement, {
    onMutate: () => setReinforcementCreationError(null),
    onSuccess: () => {
      queryClient.invalidateQueries('reinforcements')
      setIsInputVisible(false)
      setNewthemeLabel(null)
    },
    onError: (e: { message: string }) => setReinforcementCreationError([e.message])
  })

  const onCreate = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    if (!newReinforcementLabel) {
      return
    }
    themeCreation.mutate(newReinforcementLabel)
  }

  const onChange = (value: string) => {
    setNewthemeLabel(value)
  }

  const onPaginationChange = (newPage: string, newPerPage: string) => {
    setPage(newPage)
    setPerPage(newPerPage)
  }

  return (
    <div className='flex flex-col w-full h-full'>
      <div className='py-10 px-16 w-[310px] bg-white rounded shadow-card'>
        <h2 className='mb-2.5 text-sm font-medium'>Renforts transverses</h2>
        {query.data?.data &&
          query.data.data
            .sort((a, b) => (a.label > b.label ? 1 : -1))
            .map((reinforcement: Reinforcement) => (
              <div className='mt-5' key={reinforcement.id}>
                <ReinforcementItem reinforcement={reinforcement} />
              </div>
            ))}
        <div className='flex justify-between items-center mt-5'>
          {!isInputVisible && (
            <button className='flex items-center' onClick={() => setIsInputVisible(true)}>
              <img className='mr-4 w-7 h-7' src={SvgCreate} />
              <span className='font-medium whitespace-nowrap'>Ajouter un renfort</span>
            </button>
          )}
          {isInputVisible && (
            <>
              <form className='flex overflow-hidden justify-between w-full' name='form' onSubmit={onCreate}>
                <UnderlinedInput
                  className='w-28'
                  errors={themeCreationError}
                  onChange={onChange}
                  value={newReinforcementLabel ?? ''}
                />
                <button className='w-5 h-5' data-testid='submit-button' type='submit'>
                  <img className='w-4 h-4' src={SvgDisk} />
                </button>
              </form>
            </>
          )}
        </div>
      </div>
      <div className='mt-4 ml-auto'>
        <PaginationMenu meta={query.data?.meta ?? null} onChange={onPaginationChange} />
      </div>
    </div>
  )
}
