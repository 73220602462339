import { FC } from 'react'
import { Control, Controller } from 'react-hook-form'
import DatePicker, { registerLocale } from 'react-datepicker'
import { format, parse } from 'date-fns'
import { fr } from 'date-fns/locale'

import { SvgTrash } from 'assets/svg'
import { DATE_FORMAT } from 'core/constants'
import { ErrorObj, Nullable } from 'interfaces'
import { ExperimentWaterUptakeDto } from 'interfaces/api/lab'

import { InputError } from 'ui/atoms'
import { SvgInjector } from 'ui/atoms/SvgInjector'
import { UnderlinedNumberInput } from 'ui/atoms/UnderLinedInput'

import { WaterUptakeFormValues } from '../ProductionDataTab.type'

registerLocale('fr', fr)

const WaterUptakeLine: FC<{
  waterUptakeIndex: number
  waterUptakeLineIndex: number
  waterUptakeError: Nullable<ErrorObj<ExperimentWaterUptakeDto>>
  onLineChange: () => void
  control: Control<{
    water_uptakes: WaterUptakeFormValues
  }>
  onDeleteWaterUptakeLine: (i: number, k: number) => void
}> = ({ control, waterUptakeError, waterUptakeIndex, waterUptakeLineIndex, onDeleteWaterUptakeLine, onLineChange }) => {
  return (
    <div className='grid grid-cols-13 col-span-5 col-start-3 gap-4 mt-5' key={`wul_${waterUptakeLineIndex}`}>
      <Controller
        control={control}
        defaultValue={format(new Date(), DATE_FORMAT)}
        name={`water_uptakes.${waterUptakeIndex}.lines.${waterUptakeLineIndex}.analyzed_at`}
        render={({ field }) => (
          <div className='flex flex-col col-span-3 w-24'>
            <DatePicker
              calendarStartDay={1}
              customInput={
                <input
                  className={`${
                    waterUptakeError &&
                    waterUptakeError[`water_uptakes.${waterUptakeIndex}.lines.${waterUptakeLineIndex}.analyzed_at`]
                      ? 'border-b-[red]'
                      : 'border-b-pinkish-grey'
                  } pb-2 text-sm text-warm-grey border-b outline-none h-8 w-inherit bg-white`}
                />
              }
              dateFormat={DATE_FORMAT}
              locale='fr'
              onChange={(date) => {
                field.onChange(date ? format(date, DATE_FORMAT) : '')
                onLineChange()
              }}
              selected={parse(field.value, DATE_FORMAT, new Date())}
              showPopperArrow={false}
              wrapperClassName='w-24'
            />
            <InputError
              errors={
                waterUptakeError
                  ? waterUptakeError[`water_uptakes.${waterUptakeIndex}.lines.${waterUptakeLineIndex}.analyzed_at`]
                  : null
              }
            />
          </div>
        )}
      />
      <Controller
        control={control}
        name={`water_uptakes.${waterUptakeIndex}.lines.${waterUptakeLineIndex}.days_count`}
        render={({ field }) => (
          <div className='col-span-3'>
            <UnderlinedNumberInput
              className='w-14'
              errors={
                waterUptakeError
                  ? waterUptakeError[`water_uptakes.${waterUptakeIndex}.lines.${waterUptakeLineIndex}.days_count`]
                  : null
              }
              onChange={field.onChange}
              placeholder=''
              readOnly={true}
              value={field.value}
            />
          </div>
        )}
      />
      <Controller
        control={control}
        name={`water_uptakes.${waterUptakeIndex}.lines.${waterUptakeLineIndex}.sample_weight`}
        render={({ field }) => (
          <div className='col-span-3'>
            <UnderlinedNumberInput
              className='w-24'
              errors={
                waterUptakeError
                  ? waterUptakeError[`water_uptakes.${waterUptakeIndex}.lines.${waterUptakeLineIndex}.sample_weight`]
                  : null
              }
              onChange={(d) => {
                field.onChange(d)
                onLineChange()
              }}
              placeholder=''
              value={field.value}
            />
          </div>
        )}
      />
      <Controller
        control={control}
        name={`water_uptakes.${waterUptakeIndex}.lines.${waterUptakeLineIndex}.weight_gain`}
        render={({ field }) => (
          <div className='col-span-3'>
            <UnderlinedNumberInput
              className='w-24'
              errors={
                waterUptakeError
                  ? waterUptakeError[`water_uptakes.${waterUptakeIndex}.lines.${waterUptakeLineIndex}.weight_gain`]
                  : null
              }
              onChange={field.onChange}
              placeholder=''
              readOnly={true}
              value={field.value}
            />
          </div>
        )}
      />
      <div className='flex justify-center items-start mt-2'>
        <button onClick={() => onDeleteWaterUptakeLine(waterUptakeIndex, waterUptakeLineIndex)} type='button'>
          <SvgInjector className='inline-block w-3.5 h-3.5 text-warm-grey' src={SvgTrash} />
        </button>
      </div>
    </div>
  )
}

export { WaterUptakeLine }
