import { SvgTrash } from 'assets/svg'
import { FIBERS } from 'core/constants'
import { Nullable } from 'interfaces'
import { Fiber, Roving } from 'interfaces/api/management'
import { deleteManagementRoving, putManagementRoving } from 'modules/list-parameters/services/rovings'
import { FC, useState } from 'react'
import { useMutation, useQueryClient } from 'react-query'
import { UnderlinedInput } from 'ui/atoms/UnderLinedInput'
import { UnderLinedSelect } from 'ui/atoms/UnderLinedSelect'
import { DebouncedTextInput } from 'ui/molecules'

export const RovingItem: FC<{
  roving: Roving
}> = ({ roving }) => {
  const [rovingUpdateError, setRovingUpdateError] = useState<Nullable<Record<string, string[]>>>(null)

  const queryClient = useQueryClient()
  const rovingUpdate = useMutation(
    (params: { id: number; roving: Roving }) => putManagementRoving(params.id, params.roving),
    {
      onMutate: () => setRovingUpdateError(null),
      onSuccess: () => queryClient.invalidateQueries('rovings'),
      onError: (e: { errors?: Record<string, string[]> }) => setRovingUpdateError(e.errors ?? null)
    }
  )

  const onLabelInput = (label: string) => {
    rovingUpdate.mutate({ id: roving.id, roving: { ...roving, label } })
  }

  const onFiberInput = (fiber: Fiber) => {
    rovingUpdate.mutate({ id: roving.id, roving: { ...roving, fiber } })
  }

  const rovingDelete = useMutation(deleteManagementRoving, {
    onSuccess: () => queryClient.invalidateQueries('rovings')
  })

  return (
    <div className='flex justify-between items-center'>
      <div className='overflow-hidden w-28'>
        <DebouncedTextInput
          Component={UnderlinedInput}
          disabled={rovingUpdate.isLoading}
          errors={rovingUpdateError?.label ?? null}
          onChange={onLabelInput}
          value={roving.label}
        />
      </div>
      <div className='overflow-hidden ml-10 w-28'>
        <UnderLinedSelect
          errors={rovingUpdateError?.fiber ?? null}
          onInput={(e) => onFiberInput((e.target as HTMLSelectElement).value as Fiber)}
          options={FIBERS}
          value={roving.fiber}
        />
      </div>
      <div className='w-5'>
        {roving.deletable && (
          <button className='ml-10 w-5' data-testid='delete-button' onClick={() => rovingDelete.mutate(roving.id)}>
            <img className='w-5 h-5' src={SvgTrash} />
          </button>
        )}
      </div>
    </div>
  )
}
