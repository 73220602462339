import { Extensions, LiteMedia } from 'interfaces'
import { FC, FormEvent, ReactNode, forwardRef, useEffect, useRef, useState } from 'react'
import { InputError, Panel } from 'ui/atoms'
import { Field } from 'ui/atoms/Field'
import { InputRadio } from 'ui/atoms/InputRadio'
import { UnderlinedInput, UnderlinedNumberInput } from 'ui/atoms/UnderLinedInput'
import DatePicker from 'react-datepicker'
import {
  ConfigurationChange,
  ConfigurationDetail,
  ConfigurationGeneralInformation,
  ConfigurationImpregnation,
  ConfigurationUser,
  ImpregnationMode
} from '../services/configuration.dto'
import { SvgCalendar } from 'assets/svg'
import { getUsers } from '../services'
import { useQuery } from 'react-query'
import { UnderLinedSelect } from 'ui/atoms/UnderLinedSelect'
import { SwitchInput } from 'ui/atoms/SwitchInput'
import { FileManagement } from 'modules/files'

import { AsideNavigation, NavigationRef } from 'ui/atoms/AsideNavigation'
import { format, parse } from 'date-fns'
import { DATE_FORMAT } from 'core/constants'

const FileImport = forwardRef<
  HTMLDivElement,
  {
    onChange: (medias: LiteMedia[]) => void
    onReadyToSubmitChange: (bool: boolean) => void
    files: LiteMedia[]
    errors?: string[]
  }
>(({ files = [], errors = [], onChange, onReadyToSubmitChange }, ref) => {
  return (
    <div ref={ref}>
      <Panel title='Import de fichiers'>
        <div className='flex justify-center w-full'>
          <Field label='Importer un ou plusieurs fichiers'>
            <FileManagement
              accept={[Extensions.ALL]}
              multiple={true}
              onChange={onChange}
              onReadyToSubmitChange={onReadyToSubmitChange}
              value={files}
            />
          </Field>
        </div>
        <InputError errors={errors} />
      </Panel>
    </div>
  )
})

const Impregnation = forwardRef<
  HTMLDivElement,
  {
    onChange: (change: Partial<ConfigurationDetail>) => void
    impregnation: ConfigurationImpregnation
    errors: string[]
    fileErrors: string[]
    onReadyToSubmitChange: (bool: boolean) => void
  }
>(({ impregnation, errors, onChange, onReadyToSubmitChange, fileErrors }, ref) => {
  return (
    <div ref={ref}>
      <Panel title='Impregnation'>
        <Field label="Mode d'impregnation">
          <div className='flex justify-between w-64'>
            <InputRadio
              id='bath'
              label='Bain'
              name='bain'
              onChange={() => onChange({ impregnation: { ...impregnation, mode: ImpregnationMode.BATH } })}
              value={impregnation?.mode as string}
            />
            <InputRadio
              id='injection'
              label='Injection'
              name='injection'
              onChange={() => onChange({ impregnation: { ...impregnation, mode: ImpregnationMode.INJECTION } })}
              value={impregnation?.mode as string}
            />
          </div>
        </Field>
        <div>
          <InputError errors={errors} />
        </div>
        <div className='flex justify-center w-full'>
          <Field hasError={fileErrors && fileErrors.length > 0} label='Importer un fichier'>
            <FileUploadWrapper
              accept={[Extensions.ALL]}
              multiple={true}
              onChange={(value) => onChange({ impregnation: { ...impregnation, ...{ files: value } } })}
              onReadyToSubmitChange={onReadyToSubmitChange}
              value={impregnation.files ? impregnation.files : undefined}
            />
          </Field>
        </div>
        <div>
          <InputError errors={fileErrors} />
        </div>
      </Panel>
    </div>
  )
})

const Oven = forwardRef<
  HTMLDivElement,
  {
    count: number
    onChange: (change: Partial<ConfigurationDetail>) => void
    errors: string[]
  }
>(({ count, onChange, errors = [] }, ref) => {
  const [ovenUsed, setOvenUsed] = useState(count > 0)
  const handleSwitchChange = (value: boolean) => {
    setOvenUsed(value)
    onChange({ oven_count: value ? count : 0 })
  }
  useEffect(() => {
    setOvenUsed(count > 0)
  }, [count])
  return (
    <div ref={ref}>
      <Panel title='Fours'>
        <Field label='Utilisation de fours ?'>
          <div className='flex justify-between mb-4 w-64'>
            <SwitchInput onChange={handleSwitchChange} value={ovenUsed} />
          </div>
        </Field>
        {ovenUsed && (
          <Field label='Nombre de fours'>
            <UnderlinedNumberInput
              errors={errors}
              onChange={(value) => onChange({ oven_count: value ?? 1 })}
              value={count}
            />
          </Field>
        )}
      </Panel>
    </div>
  )
})

const FileUploadWrapper: FC<{
  accept?: Extensions[]
  acceptText?: string
  multiple?: boolean
  value?: LiteMedia[]
  onChange: (value: LiteMedia[]) => void
  onReadyToSubmitChange: (bool: boolean) => void
}> = ({ accept = [Extensions.ALL], value = [], onChange, onReadyToSubmitChange, multiple = true }) => {
  const onInputChange = (files: LiteMedia[]) => {
    onChange(files)
  }

  return (
    <FileManagement
      accept={accept}
      multiple={multiple}
      onChange={onInputChange}
      onReadyToSubmitChange={onReadyToSubmitChange}
      value={value}
    />
  )
}

const Conformation = forwardRef<
  HTMLDivElement,
  {
    conformations: LiteMedia[]
    onChange: (value: LiteMedia[]) => void
    onReadyToSubmitChange: (bool: boolean) => void
    errors: string[]
  }
>(({ conformations = [], onChange, onReadyToSubmitChange, errors }, ref) => {
  return (
    <div ref={ref}>
      <Panel title='Conformation'>
        <div className='flex justify-center w-full'>
          <Field label='Importer un ou plusieurs fichiers'>
            <FileUploadWrapper
              onChange={onChange}
              onReadyToSubmitChange={onReadyToSubmitChange}
              value={conformations}
            />
          </Field>
        </div>
        <InputError errors={errors} />
      </Panel>
    </div>
  )
})

const FieldWrapper: FC<{ className?: string; children: ReactNode }> = ({ children, className = '' }) => {
  return <div className={`w-[210px] h-16 ${className}`}>{children}</div>
}

const GeneralInformation = forwardRef<
  HTMLDivElement,
  {
    errors: Record<string, string[]>
    generalInformation: ConfigurationGeneralInformation
    onChange: (change: Partial<ConfigurationDetail>) => void
  }
>(({ generalInformation, errors, onChange }, ref) => {
  const onValueChange = (data: Partial<ConfigurationGeneralInformation>) => {
    onChange({ general_information: { ...generalInformation, ...data } })
  }

  const userQuery = useQuery(['configuration-users'], async () => await getUsers(), {
    keepPreviousData: true
  })

  const userById = (id: string) => userQuery.data?.data?.find((user: ConfigurationUser) => user.id.toString() === id)

  return (
    <div className='w-full min-w-[900px]' ref={ref}>
      <Panel title='Information générale'>
        <div className='grid grid-cols-3 gap-10 mb-10 w-full'>
          <FieldWrapper>
            <Field label='Nom de la machine'>
              <div className='flex flex-1 max-w-full'>
                <InputRadio
                  className='mr-auto'
                  id='PX'
                  label='Px'
                  name='PX'
                  onChange={() => onValueChange({ machine: 'PX' })}
                  value={generalInformation.machine ?? null}
                />
                <InputRadio
                  id='RO'
                  label='Ro'
                  name='RO'
                  onChange={() => onValueChange({ machine: 'RO' })}
                  value={generalInformation.machine ?? null}
                />
              </div>
            </Field>
            <InputError errors={errors['machine']} />
          </FieldWrapper>
          <FieldWrapper className='col-span-2'>
            <Field label='Type de dévidage'>
              <div className='flex flex-1 justify-between'>
                <InputRadio
                  id='simple'
                  label='Simple'
                  name='simple'
                  onChange={() => onValueChange({ unwinding_type: 'simple' })}
                  value={generalInformation.unwinding_type ?? null}
                />
                <InputRadio
                  id='double'
                  label='Double'
                  name='double'
                  onChange={() => onValueChange({ unwinding_type: 'double' })}
                  value={generalInformation.unwinding_type ?? null}
                />
              </div>
            </Field>
            <InputError errors={errors['unwinding_type']} />
          </FieldWrapper>
          <FieldWrapper>
            <Field label='Nom de la configuration'>
              <UnderlinedInput
                errors={errors['name']}
                onChange={(value) => onValueChange({ name: value })}
                value={generalInformation.name ?? 'Configuration_'}
              />
            </Field>
          </FieldWrapper>
          <FieldWrapper>
            <Field label='Date de création'>
              <div className='flex relative flex-col'>
                <DatePicker
                  calendarStartDay={1}
                  className='flex w-full'
                  customInput={
                    <input
                      className={`${
                        errors['configured_at']?.length ? 'border-b-[red]' : 'border-b-pinkish-grey'
                      } pb-2 text-sm text-warm-grey border-b outline-none h-8 w-inherit bg-white`}
                    />
                  }
                  dateFormat={'yy-MM-dd'}
                  locale='fr'
                  onChange={(date) => onValueChange({ configured_at: format(date ?? new Date(), DATE_FORMAT) })}
                  placeholderText='--:--'
                  selected={
                    generalInformation.configured_at
                      ? parse(generalInformation.configured_at, DATE_FORMAT, new Date())
                      : null
                  }
                  showPopperArrow={false}
                  wrapperClassName='w-full'
                />
                <img className='absolute top-1 right-0 w-5 h-5' src={SvgCalendar} />
                <InputError className='flex w-full' errors={errors['configured_at']} />
              </div>
            </Field>
          </FieldWrapper>
          <FieldWrapper>
            <Field label='Créateur'>
              <UnderLinedSelect
                errors={errors['user_id']}
                onChange={(evt: FormEvent<HTMLSelectElement>) =>
                  onValueChange({ user: userById(evt.currentTarget.value) })
                }
                options={(userQuery.data?.data ?? []).map((user: ConfigurationUser) => ({
                  value: user?.id,
                  label: user.name
                }))}
                value={generalInformation?.user?.id ?? null}
              />
            </Field>
          </FieldWrapper>
          <FieldWrapper>
            <Field label='Longueur Filières (m)'>
              <UnderlinedInput
                className='mt-6'
                errors={errors['die_length']}
                onChange={(value) => onValueChange({ die_length: parseInt(value) })}
                value={generalInformation.die_length}
              />
            </Field>
          </FieldWrapper>
          <FieldWrapper>
            <Field label='Pression de fermeture des tracteurs (bar)'>
              <UnderlinedInput
                errors={errors['closing_pressure']}
                onChange={(value) => onValueChange({ closing_pressure: parseInt(value) })}
                value={generalInformation.closing_pressure}
              />
            </Field>
          </FieldWrapper>
        </div>
      </Panel>
    </div>
  )
})

export const ConfigurationForm: FC<{
  editable: boolean
  data: ConfigurationDetail
  onChange: (change: ConfigurationChange) => void
  onReadyToSubmitChange: (bool: boolean) => void
  errors?: Record<string, string[]>
  scrollTo: (px: number) => void
}> = ({ editable = true, data, onReadyToSubmitChange, errors = {}, onChange, scrollTo }) => {
  const generalInformationPanelRef = useRef<HTMLDivElement>(null)
  const conformationPanelRef = useRef<HTMLDivElement>(null)
  const ovenPanelRef = useRef<HTMLDivElement>(null)
  const impregnationPanelRef = useRef<HTMLDivElement>(null)
  const fileImportPanelRef = useRef<HTMLDivElement>(null)
  const [refs, setRefs] = useState<NavigationRef[]>([])

  useEffect(() => {
    setRefs([
      {
        title: 'Information générale',
        ref: generalInformationPanelRef.current,
        id: 0
      },
      {
        title: 'Conformation',
        ref: conformationPanelRef.current,
        id: 1
      },
      {
        title: 'Fours',
        ref: ovenPanelRef.current,
        id: 2
      },
      {
        title: 'Impregnation',
        ref: impregnationPanelRef.current,
        id: 3
      },
      {
        title: 'Import de fichiers',
        ref: fileImportPanelRef.current,
        id: 4
      }
    ])
  }, [generalInformationPanelRef, conformationPanelRef, ovenPanelRef, impregnationPanelRef, fileImportPanelRef])

  return (
    <div className='flex relative flex-row flex-1 items-start w-full'>
      <div className='relative flex-1 2xl:mr-10'>
        <div className='relative mt-12'>
          <GeneralInformation
            errors={errors}
            generalInformation={data.general_information}
            onChange={(change) => onChange({ ...data.general_information, ...change })}
            ref={generalInformationPanelRef}
          />
        </div>
        <div className='mt-10'>
          <Conformation
            conformations={data.conformations}
            errors={errors['conformations']}
            onChange={(files: LiteMedia[]) => {
              onChange({ conformations: files })
            }}
            onReadyToSubmitChange={onReadyToSubmitChange}
            ref={conformationPanelRef}
          />
        </div>
        <div className='mt-10'>
          <Oven count={data.oven_count ?? 0} errors={errors['oven_count']} onChange={onChange} ref={ovenPanelRef} />
        </div>
        <div className='mt-10'>
          <Impregnation
            errors={errors['impregnation_mode']}
            fileErrors={errors['impregnations']}
            impregnation={data.impregnation}
            onChange={onChange}
            onReadyToSubmitChange={onReadyToSubmitChange}
            ref={impregnationPanelRef}
          />
        </div>
        <div className='mt-10'>
          <FileImport
            errors={errors['documents']}
            files={data.documents ?? []}
            onChange={(medias: LiteMedia[]) => onChange({ documents: medias } as ConfigurationChange)}
            onReadyToSubmitChange={onReadyToSubmitChange}
            ref={fileImportPanelRef}
          />
        </div>
        <div className='h-[20vh]' />
        {!editable && <div className='absolute inset-0' />}
      </div>
      {refs.length > 0 && (
        <AsideNavigation className='hidden top-10 flex-none 2xl:flex' margin={20} refs={refs} scrollTo={scrollTo} />
      )}
    </div>
  )
}
