import { FC, ReactNode } from 'react'

export const Modal: FC<{ isOpen: boolean; onClose: () => void; children: ReactNode }> = ({
  children,
  isOpen,
  onClose
}) => {
  return (
    <>
      {isOpen && (
        <div className='flex fixed inset-0 z-50 justify-center items-center'>
          <div className='fixed inset-0 bg-warm-grey opacity-20' onClick={onClose}></div>
          <div className='relative p-10 bg-white shadow-card opacity-100'>{children}</div>
        </div>
      )}
    </>
  )
}
