import { ExperimentIndexDto } from 'interfaces/api/lab'
import { getConfigurations, getPultrusionDies, getThemes, getUsers } from 'modules/lab/services'
import { Controller, useFormContext } from 'react-hook-form'
import { useQuery } from 'react-query'
import { DualDateInput, DualNumberInput, MultiSelectDropdown } from 'ui/molecules'

const GeneralInformationFilters = () => {
  const { control } = useFormContext<ExperimentIndexDto>()
  const usersQuery = useQuery('filters.users', async () => await getUsers())
  const themesQuery = useQuery('filters.themes', async () => await getThemes())
  const pultrusionsDiesQuery = useQuery('filters.pultrusions', async () => await getPultrusionDies())
  const configurationsQuery = useQuery('filters.configurations', async () => await getConfigurations())

  return (
    <div className='flex flex-col mt-5'>
      <details className='' open={true}>
        <summary className='py-1 px-2 text-xl font-semibold list-none text-black bg-ice-blue cursor-pointer'>
          Général
        </summary>
        <div className='grid grid-cols-3 gap-8 py-5'>
          <Controller
            control={control}
            defaultValue={{ min: null, max: null }}
            name='experiment.realized_at'
            render={({ field }) => (
              <DualDateInput
                label={'Date de réalisation'}
                onChange={(dualDate) => field.onChange(dualDate)}
                value={field.value}
              />
            )}
          />
          <Controller
            control={control}
            name='experiment.themes'
            render={({ field }) => (
              <MultiSelectDropdown
                label='Thème'
                onChange={field.onChange}
                options={(themesQuery?.data?.data ?? []).map(({ id, label }) => ({ value: id, label }))}
                value={field.value ?? []}
              />
            )}
          />
          <Controller
            control={control}
            name='experiment.users'
            render={({ field }) => (
              <MultiSelectDropdown
                label='Responsable'
                onChange={field.onChange}
                options={(usersQuery?.data?.data ?? []).map(({ id, name }) => ({ value: id, label: name }))}
                value={field.value ?? []}
              />
            )}
          />
          <Controller
            control={control}
            name='experiment.configurations'
            render={({ field }) => (
              <MultiSelectDropdown
                label='Configuration machine'
                onChange={field.onChange}
                options={(configurationsQuery?.data?.data ?? []).map(({ id, name }) => ({ value: id, label: name }))}
                value={field.value ?? []}
              />
            )}
          />
          <Controller
            control={control}
            name='experiment.pultrusion_dies'
            render={({ field }) => (
              <MultiSelectDropdown
                label='Filière'
                onChange={field.onChange}
                options={(pultrusionsDiesQuery?.data?.data ?? []).map(({ id, label }) => ({ value: id, label }))}
                value={field.value ?? []}
              />
            )}
          />
          <Controller
            control={control}
            name='experiment.speed'
            render={({ field }) => (
              <DualNumberInput
                label={'Vitesse (cm/min)'}
                onChange={field.onChange}
                value={field.value ?? { min: null, max: null }}
              />
            )}
          />
        </div>
      </details>
    </div>
  )
}

export { GeneralInformationFilters }
