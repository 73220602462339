import { ApiError, Nullable } from 'interfaces'
import { FC, FormEvent, useState } from 'react'
import { useParams, useSearchParams } from 'react-router-dom'
import { ArkemaLogo, ButtonPrimary } from 'ui/atoms'
import { PasswordInput, TextInput } from 'ui/molecules'
import { postResetPassword } from '../services/auth'
import { InputType } from 'ui/molecules/TextInput'

export const ResetPassword: FC = () => {
  const [searchParams] = useSearchParams()
  const { token } = useParams()
  const [isSuccess, setIsSuccess] = useState(false)
  const [email, setEmail] = useState<Nullable<string>>(searchParams.get('email'))
  const [password, setPassword] = useState<Nullable<string>>('')
  const [passwordConfirm, setPasswordConfirm] = useState<Nullable<string>>('')
  const [errors, setErrors] = useState<Nullable<ApiError>>(null)

  const [isFetching, setisFetching] = useState(false)

  const onSubmitForm = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    setisFetching(true)
    setErrors(null)
    setIsSuccess(false)
    if (email && password && passwordConfirm && token) {
      try {
        await postResetPassword(email, password, passwordConfirm, token)
        setIsSuccess(true)
      } catch (e) {
        setErrors(e as ApiError)
      }
      setisFetching(false)
    }
  }
  return (
    <div className='flex flex-col'>
      <div className='my-16 mx-20 w-[350px]'>
        <div className='flex justify-center mb-16'>
          <ArkemaLogo />
        </div>
        <form className='flex relative flex-col justify-center mx-auto w-[350px]' onSubmit={(e) => onSubmitForm(e)}>
          <TextInput
            className='mb-4'
            errors={errors?.errors?.email ?? null}
            label='Addresse Email'
            name='username'
            onChange={(value) => setEmail(value)}
            placeholder='Saisir ici votre adresse email'
            required={true}
            type={InputType.EMAIL}
            value={email ?? ''}
          />
          <PasswordInput
            errors={errors?.errors?.password ?? null}
            label='Créer un nouveau mot de passe'
            min={8}
            name='password'
            onChange={(value) => setPassword(value)}
            required={true}
            value={password}
          />
          <PasswordInput
            errors={errors?.errors?.password_confirmation ?? null}
            label='Confirmer votre mot de passe'
            min={8}
            name='passwordConfirm'
            onChange={(value) => setPasswordConfirm(value)}
            required={true}
            value={passwordConfirm}
          />
          {isSuccess && (
            <div className='mb-4 text-sm font-semibold text-dusty-teal'>
              La modification de votre mot de passe a bien été prise en compte.
            </div>
          )}
          <ButtonPrimary
            className='mx-auto mb-3'
            disabled={!(email && password && passwordConfirm && token)}
            isFetching={isFetching}
            type='submit'
          >
            Confirmer
          </ButtonPrimary>
        </form>
      </div>
    </div>
  )
}
