import { Roving } from 'interfaces/api/management'
import { fetchJson } from 'utils/fetch'

/**
 *
 * @returns
 */
const getExperimentRovings = () => {
  return fetchJson<Roving[]>('GET', '/lab/rovings')
}

export { getExperimentRovings }
