import { dma } from 'modules/lab/schemas'
import { FC } from 'react'
import { z } from 'zod'
import { TableTd } from './TableTd'

const DMATd: FC<{ matrix: Record<string, boolean>; data: z.infer<typeof dma> }> = ({ matrix, data }) => {
  return (
    <>
      {matrix.full_width_at_half_maximum && <TableTd className='text-left'>{data?.full_width_at_half_maximum}</TableTd>}
      {matrix.tan_delta_max_temperature && <TableTd className='text-left'>{data?.tan_delta_max_temperature}</TableTd>}
      {matrix.g_second_max_temperature && <TableTd className='text-left'>{data?.g_second_max_temperature}</TableTd>}
    </>
  )
}

export { DMATd }
