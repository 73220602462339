import { DATE_FORMAT } from 'core/constants'
import { format } from 'date-fns'
import { ConfigurationDetail, ConfigurationDto } from '../services/configuration.dto'

export const configurationDataToDto = ({
  conformations = [],
  documents = [],
  general_information,
  impregnation,
  oven_count
}: ConfigurationDetail): ConfigurationDto => ({
  user_id: general_information.user?.id,
  name: general_information.name,
  machine: general_information.machine,
  die_length: general_information.die_length,
  unwinding_type: general_information.unwinding_type,
  configured_at: format(new Date(general_information.configured_at ?? Date.now()), DATE_FORMAT),
  closing_pressure: general_information.closing_pressure,
  impregnation_mode: impregnation.mode,
  impregnations: impregnation.files ?? [],
  documents: documents,
  conformations: conformations,
  oven_count
})
