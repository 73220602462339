import { Nullable } from 'interfaces'
import { FC, ReactNode } from 'react'

export const WhiteButton: FC<{
  icon: Nullable<string>
  className?: string
  onClick?: () => void
  children: ReactNode
}> = ({ children, icon, className, onClick }) => (
  <button className={'flex p-4 w-full bg-white rounded shadow-card ' + className} onClick={onClick}>
    {icon && <img className='mr-2 w-6 h-6' src={icon} />}
    <span className=''>{children}</span>
  </button>
)
