import { Nullable } from 'interfaces'
import { FC } from 'react'

interface InputErrorProps {
  errors: Nullable<string[]>
  className?: string
}

export const InputError: FC<InputErrorProps> = ({ className, errors = [] }) => {
  return (
    <div className={className}>
      {errors && (
        <span className='relative h-5'>
          {errors.map((error: string, index: number) => (
            <div className='mt-2 text-xs text-[red]' key={index}>
              {error}
            </div>
          ))}
        </span>
      )}
    </div>
  )
}
