import { Theme } from 'interfaces/api/management'
import { fetchJson } from 'utils/fetch'
import { ThemeUpdateDto } from './list-parameters.dto'

/**
 *
 * @param page
 * @param perPage
 * @returns
 */
const getManagementThemes = (page: string, perPage: string) => {
  return fetchJson<Theme[], undefined>('GET', `/management/themes?page=${page}&per_page=${perPage}`)
}

/**
 *
 * @param label
 * @returns
 */
const postManagementTheme = (label: string) => {
  return fetchJson<Theme, ThemeUpdateDto>('POST', '/management/themes', { label })
}

/**
 *
 * @param index
 * @param label
 * @returns
 */
const putManagementTheme = (index: number, label: string) => {
  return fetchJson<Theme, ThemeUpdateDto>('PUT', `/management/themes/${index}`, { label })
}

/**
 *
 * @param index
 * @returns
 */
const deleteManagementTheme = (index: number) => {
  return fetchJson('DELETE', `/management/themes/${index}`)
}

export { getManagementThemes, putManagementTheme, postManagementTheme, deleteManagementTheme }
