import { FileStatus } from 'core/constants/file'
import { Extensions, LiteMedia, SpatieFile } from 'interfaces'
import { LiteMediaWithStatus } from '../types'

/**
 * Utilitaire pour déduire l'extension d'un fichier LiteMedia
 * @param file
 * @returns
 */
const getExtension = (file: LiteMedia) => file.file_name.split('.').pop() ?? ''

/**
 * Utilitaire pour transformer un fichier File en LiteMedia
 * @param param0
 * @param uuid
 * @param status
 * @returns
 */
const fileToLiteMedia = ({ name }: File, uuid: string, status: FileStatus): LiteMediaWithStatus => ({
  created_at: '',
  file_name: name ?? '',
  file_url: '',
  uuid,
  status
})

/**
 * Utilitaire pour transformer un fichier SpatieFile en LiteMedia
 * @param param0
 * @param status
 * @returns
 */
const spatieToLiteMedia = ({ name, preview_url, uuid }: SpatieFile, status: FileStatus): LiteMediaWithStatus => ({
  created_at: '',
  file_name: name ?? '',
  file_url: preview_url ?? '',
  uuid,
  status
})

/**
 * Affichage des extensions autorisées
 * @param acceptedExtensions
 * @returns
 */
const getAcceptedExtensions = (acceptedExtensions: Extensions[]): string =>
  Object.entries(Extensions)
    .filter(([, mimeType]) => acceptedExtensions.includes(mimeType))
    .map(([key]) => `.${key === 'ALL' ? '*' : key.toLocaleLowerCase()}`)
    .join(', ')

export { fileToLiteMedia, getAcceptedExtensions, getExtension, spatieToLiteMedia }
