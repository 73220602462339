import { FC, useContext, useEffect, useRef, useState } from 'react'

import i18n from 'modules/lab/i18n'

import { FormulationPanel } from './FormulationPanel'
import { InformationsPanel } from './InformationsPanel'
import { ProcessParametersPanel } from './ProcessParametersPanel'
import { ReinforcementsPanel } from './ReinforcementsPanel'
import { ViscosityPanel } from './ViscosityPanel'
import { AsideNavigation, NavigationRef } from 'ui/atoms/AsideNavigation'
import { LabContext } from 'modules/lab/contexts'

const DescriptionTab: FC<{ scrollTo: (px: number) => void }> = ({ scrollTo }) => {
  const informationsPanelRef = useRef<HTMLFormElement>(null)
  const formulationPanelRef = useRef<HTMLFormElement>(null)
  const viscosityPanelRef = useRef<HTMLFormElement>(null)
  const reinforcementsPanelRef = useRef<HTMLFormElement>(null)
  const processParametersPanelRef = useRef<HTMLFormElement>(null)
  const [refs, setRefs] = useState<NavigationRef[]>([])
  const { reference } = useContext(LabContext)

  useEffect(() => {
    setRefs([
      {
        title: i18n.descriptionTab.informationsPanel.title,
        ref: informationsPanelRef.current,
        id: 0
      },
      {
        title: i18n.descriptionTab.formulationPanel.title,
        ref: formulationPanelRef.current,
        id: 1
      },
      {
        title: i18n.descriptionTab.viscosityPanel.title,
        ref: viscosityPanelRef.current,
        id: 2
      },
      {
        title: i18n.descriptionTab.reinforcementsPanel.title,
        ref: reinforcementsPanelRef.current,
        id: 3
      },
      {
        title: i18n.descriptionTab.processParametersPanel.title,
        ref: processParametersPanelRef.current,
        id: 4
      }
    ])
  }, [informationsPanelRef, formulationPanelRef, viscosityPanelRef, reinforcementsPanelRef, processParametersPanelRef])

  return (
    <div className='flex flex-row flex-1 items-start'>
      <div className='flex-1 w-full min-w-[900px] 2xl:mr-10'>
        <InformationsPanel ref={informationsPanelRef} />
        <div className='h-10'></div>
        <FormulationPanel ref={formulationPanelRef} />
        <div className='h-10'></div>
        <ViscosityPanel ref={viscosityPanelRef} />
        <div className='h-10'></div>
        <ReinforcementsPanel ref={reinforcementsPanelRef} />
        <div className='h-10'></div>
        <ProcessParametersPanel ref={processParametersPanelRef} />
        <div className='h-10'></div>
      </div>
      {refs.length > 0 && (
        <AsideNavigation
          className='hidden top-10 flex-none 2xl:flex'
          margin={20}
          reference={reference}
          refs={refs}
          scrollTo={scrollTo}
        />
      )}
    </div>
  )
}

export { DescriptionTab }
