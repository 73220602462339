const i18n = {
  experiments: {
    customDisplay: {
      title: 'Affichage personnalisé',
      reset: "Réinitialiser l'affichage",
      table: {
        titles: {
          general: 'Général',
          formula: 'Formulation',
          viscosity: 'Viscosité',
          reinforcement: 'Renforts',
          process_parameter: 'Paramètres de process',
          traction_0: 'Traction 0°',
          traction_90: 'Traction 90°',
          flexion_0: 'Flexion 0°',
          flexion_90: 'Flexion 90°',
          compression_0: 'Compression 0°',
          compression_90: 'Compression 90°',
          thermocouple: 'Thermocouple',
          dynamic_mechanical_analyses_ramp_1: 'DMA Rampe 1',
          dynamic_mechanical_analyses_ramp_2: 'DMA Rampe 2',
          eosin: 'Éosine',
          fiber_rate: 'TVF/TP',
          fire_test: 'Test au feu',
          residual_monomer: 'Monomètre résiduel'
        },
        general: {
          reference: 'Référence',
          realized_at: 'Date de réalisation',
          theme: 'Thème',
          user: 'Responsable',
          configuration: 'Configuration',
          pultrusion_die: 'Filière',
          speed: 'Vitesse (cm/min)'
        },
        formula: {
          ingredients: 'Résine'
        },
        viscosity: {
          starting_drain_viscosity: 'Viscosité début',
          drain_viscosity: 'Viscosité égouttures',
          ending_drain_viscosity: 'Viscosité fin'
        },
        reinforcement: {
          roving_label: 'Roving',
          roving_fiber: 'Type de roving',
          transverse_reinforcements: 'Renforts transverses'
        },
        process_parameter: {
          wire_count: 'Nombre de fils',
          has_conformation: 'Bloc de conformation',
          oven_temperatures: 'Fours',
          impregnation_mode: "Mode d'impregnation",
          is_stable: 'Stabilité du procédé'
        },
        tcf: {
          max_force_average: 'Force maximale (kN)',
          max_stress_average: 'Contrainte maximale (MPa)',
          max_stress_movement_average: 'Déplacement contrainte max (mm)',
          max_stress_deformation_average: 'Déformation contrainte max (%)',
          chord_modulus_deformation_average: 'Module corde déformation (GPa)',
          chord_modulus_force_average: 'Module corde force (GPa)',
          fracture_stress_average: 'Contrainte à rupture (MPa)',
          fracture_deformation_average: 'Déformation à rupture (%)'
        },
        thermocouple: {
          peak_temperature: 'Température au pic (°C)',
          peak_time: 'Temps au pic (s)',
          peak_distance: 'Distance au pic (cm)'
        },
        dma: {
          full_width_at_half_maximum: 'FWHM',
          tan_delta_max_temperature: 'Température pour tan δ max (°C)',
          g_second_max_temperature: 'Température pour G" max (°C)'
        },
        eosin: {
          classification: 'Classement'
        },
        fiber_rate: {
          fiber_mass_rate: 'Taux massique de fibre',
          fiber_volume_rate: 'Taux volumique de fibre',
          porosity_rate: 'Taux de porosité'
        },
        fire_test: {
          classification: 'Classement'
        },
        residual_monomer: {
          average: 'Teneur MAM (%) Moyenne'
        }
      }
    }
  },
  descriptionTab: {
    informationsPanel: {
      title: 'Informations générales',
      fieldLabels: {
        configuration: 'Configuration de machine',
        pultrusionDie: 'Filière',
        speed: 'Vitesse (cm/min)',
        reference: "Référence de l'essai",
        user: "Responsable de l'essai",
        theme: "Thème de l'essai",
        realizedAt: 'Date de réalisation',
        startTime: 'Heure de début',
        finishTime: 'Heure de fin',
        details: "Détail de l'essai"
      }
    },
    formulationPanel: {
      title: 'Formulation'
    },
    viscosityPanel: {
      title: 'Viscosité'
    },
    reinforcementsPanel: {
      title: 'Renforts'
    },
    processParametersPanel: {
      title: 'Paramètres de process'
    }
  },
  productionDataTab: {
    tractionPanel: {
      title: 'Traction',
      dataEntry: 'Saisie de données',
      importTitle: 'Import de fichier',
      visualization: 'Visualisation',
      test_tubes: "Nombre d'éprouvettes",
      average: 'Moyenne',
      std: 'Ecart type',
      variation: 'Variance (%)',
      max_force: 'Force maximale (kN)',
      max_stress: 'Contrainte maximale (MPa)',
      max_stress_movement: 'Déplacement contrainte maximale (mm)',
      max_stress_deformation: 'Déformation contrainte maximale (%)',
      chord_modulus_deformation: 'Module corde déformation (GPa)',
      chord_modulus_force: 'Module corde force (GPa)',
      fracture_stress: 'Contrainte à rupture (MPa)',
      fracture_deformation: 'Déformation à rupture (%)',
      toasts: {
        create_success: 'Traction crée avec succès',
        update_success: 'Traction mise à jour avec succès'
      }
    },
    compressionPanel: {
      title: 'Compression',
      dataEntry: 'Saisie de données',
      importTitle: 'Import de fichier',
      visualization: 'Visualisation',
      test_tubes: "Nombre d'éprouvettes",
      average: 'Moyenne',
      std: 'Ecart type',
      variation: 'Variance (%)',
      max_force: 'Force maximale (kN)',
      max_stress: 'Contrainte maximale (MPa)',
      max_stress_movement: 'Déplacement contrainte maximale (mm)',
      max_stress_deformation: 'Déformation contrainte maximale (%)',
      chord_modulus_deformation: 'Module corde déformation (GPa)',
      chord_modulus_force: 'Module corde force (GPa)',
      fracture_stress: 'Contrainte à rupture (MPa)',
      fracture_deformation: 'Déformation à rupture (%)',
      toasts: {
        create_success: 'Compression crée avec succès',
        update_success: 'Compression mise à jour avec succès'
      }
    },
    flexionPanel: {
      title: 'Flexion',
      dataEntry: 'Saisie de données',
      importTitle: 'Import de fichier',
      visualization: 'Visualisation',
      test_tubes: "Nombre d'éprouvettes",
      average: 'Moyenne',
      std: 'Ecart type',
      variation: 'Variance (%)',
      max_force: 'Force maximale (kN)',
      max_stress: 'Contrainte maximale (MPa)',
      max_stress_movement: 'Déplacement contrainte maximale (mm)',
      max_stress_deformation: 'Déformation contrainte maximale (%)',
      chord_modulus_deformation: 'Module corde déformation (GPa)',
      chord_modulus_force: 'Module corde force (GPa)',
      fracture_stress: 'Contrainte à rupture (MPa)',
      fracture_deformation: 'Déformation à rupture (%)',
      toasts: {
        create_success: 'Flexion crée avec succès',
        update_success: 'Flexion mise à jour avec succès'
      }
    },
    DMAPanel: {
      title: 'DMA',
      toasts: {
        create_success: 'DMA crée avec succès',
        update_success: 'DMA mis à jour avec succès'
      }
    },
    linearDensityPanel: {
      title: 'Masse linéique (g/m)'
    },
    microscopyPanel: {
      title: 'Microscopie'
    },
    eosinPanel: {
      title: 'Éosine'
    },
    alkalineResistancePanel: {
      title: 'Résistance alcaline',
      linesTitle: (length: number) => (length > 1 ? 'dates enregistrées' : 'date enregistrée'),
      actions: {
        addDate: 'Ajouter une date...'
      },
      tableColumns: {
        analyzedAt: 'Date',
        daysCount: 'Nombre jours',
        constraint: 'Contrainte (Mpa)',
        std: 'Ecart type (Mpa)',
        propertyLoss: 'Perte propriété en flexion (%)'
      },
      toasts: {
        updateSuccess: 'Résistance alcaline mise à jour avec succès',
        createSuccess: 'Résistance alcaline créée avec succès'
      }
    },
    thermocouplePanel: {
      title: 'Thermocouple'
    },
    fiberRatePanel: {
      title: 'TVF/TP'
    },
    fireTestPanel: {
      title: 'Test au feu'
    },
    waterUptakePanel: {
      title: "Reprise d'eau",
      linesTitle: (length: number) => (length > 1 ? 'dates enregistrées' : 'date enregistrée'),
      actions: {
        addDate: 'Ajouter une date...'
      },
      tableColumns: {
        analyzedAt: 'Date',
        daysCount: 'Nombre jours',
        sampleWeight: 'Masse echantillion (g)',
        weightGain: 'Prise de masse (%)'
      },
      toasts: {
        updateSuccess: "Reprise d'eau mise à jour avec succès",
        createSuccess: "Reprise d'eau créée avec succès"
      }
    },
    residualMonomerPanel: {
      title: 'Monomère résiduels'
    }
  }
}

export { i18n }
export default i18n
