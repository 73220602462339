import { ExperimentIndexDto } from 'interfaces/api/lab'

interface State {
  reference: string
  displayMatrix: DisplayMatrix
  updateDisplayMatrix: (matrix: DisplayMatrix) => void
  resetDisplayMatrix: () => void
  filters: Omit<ExperimentIndexDto, '_method'>
  updateFilters: (filters: Omit<ExperimentIndexDto, '_method'>) => void
  updateReference: (reference?: string) => void
  resetFilters: () => void
}

enum Types {
  UPDATE_DISPLAY_MATRIX = 'UPDATE_DISPLAY_MATRIX',
  RESET_DISPLAY_MATRIX = 'RESET_DISPLAY_MATRIX',
  UPDATE_REFERENCE = 'UPDATE_REFERENCE',
  UPDATE_FILTERS = 'UPDATE_FILTERS',
  RESET_FILTERS = 'RESET_FILTERS'
}

type Actions =
  | { type: Types.UPDATE_DISPLAY_MATRIX; payload: DisplayMatrix }
  | { type: Types.RESET_DISPLAY_MATRIX; payload: DisplayMatrix }
  | { type: Types.UPDATE_REFERENCE; payload?: string }
  | { type: Types.UPDATE_FILTERS; payload: Omit<ExperimentIndexDto, '_method'> }
  | { type: Types.RESET_FILTERS; payload: Omit<ExperimentIndexDto, '_method'> }

type DisplayMatrix = Record<string, boolean | Record<string, boolean>>

export { Types }
export type { Actions, State, DisplayMatrix }
