import { createRoot } from 'react-dom/client'
import App from './App'
import p from '../package.json'
import 'sentry'
import { BrowserRouter } from 'react-router-dom'
import { AuthProvider } from 'stores/auth'
import { QueryClient, QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'

import { getCsrf } from 'modules/auth/services/auth'
import { UiProvider } from 'contexts'
import { FetchError } from 'interfaces'

getCsrf()
const client = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      keepPreviousData: true,
      retry: false,
      onError: (err: unknown) => {
        if ((err as FetchError).status === 401) {
          window.location.href = '/'
        } else {
          throw err
        }
      }
    }
  }
})
const container = document.getElementById('root') as HTMLElement
const root = createRoot(container)
root.render(
  <AuthProvider>
    <UiProvider>
      <QueryClientProvider client={client}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </UiProvider>
  </AuthProvider>
)

console.info(`🐆 v${p.version}`) // eslint-disable-line no-console
