import { SvgCheckboxChecked, SvgCheckboxUnchecked } from 'assets/svg'
import { FC, memo } from 'react'

const InputCheckbox: FC<{
  className?: string
  checked: boolean
  onChange: (value: boolean) => void
  label?: string
  small?: boolean
}> = memo(({ className = '', label, onChange, checked, small = false }) =>
  label ? (
    <label className={`${className} cursor-pointer flex flex-row`} tabIndex={0}>
      <span className={`inline-block relative ${small ? 'w-4 h-4' : 'w-6 h-6'}`}>
        <input
          checked={checked}
          className='absolute inset-0 opacity-0 cursor-pointer'
          onChange={() => onChange(!checked)}
          type='checkbox'
        />
        <span className={`inline-block ${small ? 'w-4 h-4' : 'w-6 h-6'}`}>
          {checked ? (
            <img className='w-full' src={SvgCheckboxChecked} />
          ) : (
            <img className='w-full' src={SvgCheckboxUnchecked} />
          )}
        </span>
      </span>
      <span className={`${checked ? 'text-greyish-brown' : 'text-pinkish-grey'} pl-3 inline-block`}>{label}</span>
    </label>
  ) : (
    <span className={`inline-block relative ${small ? 'w-4 h-4' : 'w-6 h-6'}`}>
      <input
        checked={checked}
        className='absolute inset-0 opacity-0 cursor-pointer'
        onChange={() => onChange(!checked)}
        type='checkbox'
      />
      <span className={`inline-block ${small ? 'w-4 h-4' : 'w-6 h-6'}`}>
        {checked ? (
          <img className='w-full' src={SvgCheckboxChecked} />
        ) : (
          <img className='w-full' src={SvgCheckboxUnchecked} />
        )}
      </span>
    </span>
  )
)
export { InputCheckbox }
