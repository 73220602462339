import { PultrusionDie } from 'interfaces/api/lab'
import { fetchJson } from 'utils/fetch'

/**
 *
 * @returns
 */
const getPultrusionDies = () => {
  return fetchJson<PultrusionDie[]>('GET', `/lab/pultrusion-dies`)
}

export { getPultrusionDies }
