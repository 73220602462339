import { FC } from 'react'
import { ButtonPrimary } from 'ui/atoms'
import { SvgInjector } from 'ui/atoms/SvgInjector'
import { MainTemplate } from 'ui/templates'
import { SvgAdd } from 'assets/svg'
import { ConfigurationList } from './components/ConfigurationList'
import { Link } from 'react-router-dom'

export const Configuration: FC = () => {
  return (
    <MainTemplate
      actionButton={
        <Link to='/configuration/create'>
          <ButtonPrimary type={'button'}>
            <div className='flex items-center text-white'>
              <SvgInjector className='w-4 h-4 text-white stroke-white' src={SvgAdd} />
              <span className='ml-4'>Créer une nouvelle configuration</span>
            </div>
          </ButtonPrimary>
        </Link>
      }
      className='overflow-y-scroll'
      title={<span className='text-2xl font-bold text-dark-blue'>Liste de configurations machines</span>}
    >
      <section className='flex flex-auto'>
        <ConfigurationList />
      </section>
    </MainTemplate>
  )
}
