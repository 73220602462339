import { FC, ReactNode } from 'react'

const Badge: FC<{
  children: ReactNode
  className?: string
  displayed?: boolean
  count?: number
}> = ({ children, className = '', count, displayed = true }) => {
  return (
    <div className={`flex relative ${className}`}>
      {children}
      {displayed && (
        <span className='absolute -top-1.5 -right-1.5 w-3 h-3 bg-greeny-blue rounded-full'>{count ?? ''}</span>
      )}
    </div>
  )
}

export { Badge }
