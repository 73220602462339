import { createContext, FC, ReactNode, useCallback, useReducer } from 'react'
import { AnyZodObject, ZodNullable } from 'zod'
import { EMPTY_FILTERS } from '../constants'
import { getExperiment } from '../schemas'
import { Actions, DisplayMatrix, State, Types } from './lab.context.type'

const reducers = (state: State, action: Actions): State => {
  switch (action.type) {
    case Types.UPDATE_REFERENCE:
      return {
        ...state,
        reference: action.payload ?? ''
      }
    case Types.UPDATE_DISPLAY_MATRIX:
      return {
        ...state,
        displayMatrix: action.payload
      }
    case Types.RESET_DISPLAY_MATRIX:
      return {
        ...state,
        displayMatrix: action.payload
      }
    case Types.UPDATE_FILTERS:
      return {
        ...state,
        filters: action.payload
      }
    case Types.RESET_FILTERS:
      return {
        ...state,
        filters: action.payload
      }
    default:
      return state
  }
}

const getMatrix = (schema: AnyZodObject | ZodNullable<AnyZodObject>): DisplayMatrix => {
  let obj = {}
  if (schema === null) {
    return obj
  }
  const shape = 'shape' in schema ? schema.shape : schema._def.innerType.shape
  const parentKeys = Object.keys(shape).filter((k) => k !== 'id')
  for (const parentKey of parentKeys) {
    const hasShape = 'shape' in shape[parentKey]
    const child: AnyZodObject | ZodNullable<AnyZodObject> = hasShape
      ? shape[parentKey]
      : shape[parentKey]._def.innerType
    const isObject = hasShape || child?._def?.typeName === 'ZodObject'
    if (isObject) {
      obj = { ...obj, [parentKey]: getMatrix(child) }
    } else {
      obj = { ...obj, [parentKey]: true }
    }
  }
  return obj
}
const initialState: State = {
  reference: '',
  displayMatrix: getMatrix(getExperiment),
  updateDisplayMatrix: () => null,
  resetDisplayMatrix: () => null,
  filters: EMPTY_FILTERS,
  updateReference: () => null,
  updateFilters: () => null,
  resetFilters: () => null
}

const LabContext = createContext(initialState)
const LabProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const [state, dispatch] = useReducer(reducers, initialState)
  const value: State = {
    ...state,
    updateDisplayMatrix: useCallback((payload) => {
      dispatch({
        type: Types.UPDATE_DISPLAY_MATRIX,
        payload
      })
    }, []),
    resetDisplayMatrix: useCallback(() => {
      dispatch({
        type: Types.UPDATE_DISPLAY_MATRIX,
        payload: getMatrix(getExperiment)
      })
    }, []),
    updateFilters: useCallback((payload) => {
      dispatch({
        type: Types.UPDATE_FILTERS,
        payload
      })
    }, []),
    resetFilters: useCallback(() => {
      dispatch({
        type: Types.RESET_FILTERS,
        payload: EMPTY_FILTERS
      })
    }, []),
    updateReference: useCallback((payload) => {
      dispatch({
        type: Types.UPDATE_REFERENCE,
        payload
      })
    }, [])
  }

  return <LabContext.Provider value={value}>{children}</LabContext.Provider>
}

export { LabProvider, LabContext, initialState }
