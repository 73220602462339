import { GenericConstituent } from 'interfaces/api/management'
import { getManagementConstituents } from 'modules/list-parameters/services/constituents'
import { FC, ReactNode } from 'react'
import { useQuery } from 'react-query'
import { FetchResult } from 'utils/fetch'
import { GenericList } from './components/GenericList'

const DetailSummary: FC<{ children: ReactNode }> = ({ children }) => (
  <summary className='py-5 px-6 cursor-pointer'>{children}</summary>
)

const DetailBody: FC<{ children: ReactNode }> = ({ children }) => (
  <div className='bg-white rounded shadow-card select-none'>
    <details className='mb-0.5 w-[310px]'>{children}</details>
  </div>
)

export const Constituents: FC = () => {
  const fetchThemes = () => getManagementConstituents()

  const query = useQuery<FetchResult<GenericConstituent[]>>(['constituents'], async () => await fetchThemes(), {
    keepPreviousData: true
  })

  return (
    <div>
      <DetailBody>
        <DetailSummary>Résine</DetailSummary>
        <GenericList itemName='une résine' items={query.data?.data ?? []} type='resin' />
      </DetailBody>
      <DetailBody>
        <DetailSummary>Charge</DetailSummary>
        <GenericList itemName='une charge' items={query.data?.data ?? []} type='filler' />
      </DetailBody>
      <DetailBody>
        <DetailSummary>Peroxyde</DetailSummary>
        <GenericList itemName='un peroxyde' items={query.data?.data ?? []} type='peroxyde' />
      </DetailBody>
      <DetailBody>
        <DetailSummary>Accélérateur</DetailSummary>
        <GenericList itemName='un accélérateur' items={query.data?.data ?? []} type='accelerator' />
      </DetailBody>
      <DetailBody>
        <DetailSummary>Additifs</DetailSummary>
        <GenericList itemName='un additif' items={query.data?.data ?? []} type='additive' />
      </DetailBody>
      <DetailBody>
        <DetailSummary>Démoulant</DetailSummary>
        <GenericList itemName='un démoulant' items={query.data?.data ?? []} type='release-agent' />
      </DetailBody>
    </div>
  )
}
