import {
  Angle,
  ExperimentCompression,
  ExperimentCompressionDto,
  ExperimentCompressionTaskDto
} from 'interfaces/api/lab'
import { ExperimentCompressionTask } from 'interfaces/api/task'
import { Dto } from 'modules/list-parameters/services/list-parameters.dto'
import { fetchJson } from 'utils/fetch'

/**
 *
 * @param experimentId
 * @param angle
 * @param payload
 * @returns
 */
const postExperimentCompression = (experimentId: string, angle: Angle, payload: Dto<ExperimentCompressionDto>) => {
  return fetchJson<ExperimentCompression, Dto<ExperimentCompressionDto>>(
    'POST',
    `/lab/experiments/${experimentId}/compressions/${angle}`,
    payload
  )
}

/**
 *
 * @param experimentId
 * @param angle
 * @param payload
 * @returns
 */
const putExperimentCompression = (experimentId: string, angle: Angle, payload: Dto<ExperimentCompressionDto>) => {
  return fetchJson<ExperimentCompression, Dto<ExperimentCompressionDto>>(
    'PUT',
    `/lab/experiments/${experimentId}/compressions/${angle}`,
    payload
  )
}

/**
 *
 * @param experimentId
 * @returns
 */
const getExperimentCompressions = (experimentId: string) => {
  return fetchJson<ExperimentCompression[]>('GET', `/lab/experiments/${experimentId}/compressions`)
}

/**
 *
 * @param url
 * @param angle
 * @param payload
 * @returns
 */
const postExperimentCompressionTask = (
  experimentId: string,
  angle: Angle,
  payload: Dto<ExperimentCompressionTaskDto>
) => {
  return fetchJson<ExperimentCompressionTask, Dto<ExperimentCompressionTaskDto>>(
    'POST',
    `/lab/experiments/${experimentId}/compressions/${angle}/task`,
    payload
  )
}

export { postExperimentCompression, putExperimentCompression, getExperimentCompressions, postExperimentCompressionTask }
