import { Nullable } from 'interfaces'
import { CompressionFlexionTractionFilter, ExperimentIndexDto } from 'interfaces/api/lab'
import { FC } from 'react'
import { Control, Controller } from 'react-hook-form'
import { DualNumberInput } from 'ui/molecules'

type MechanicalsFilters = 'traction_0' | 'traction_90' | 'flexion_0' | 'flexion_90' | 'compression_0' | 'compression_90'

const PANELS = [
  { name: 'traction_0', label: 'Traction 0°' },
  { name: 'traction_90', label: 'Traction 90°' },
  { name: 'flexion_0', label: 'Flexion 0°' },
  { name: 'flexion_90', label: 'Flexion 90°' },
  { name: 'compression_0', label: 'Compression 0°' },
  { name: 'compression_90', label: 'Compression 90°' }
] as {
  name: Extract<keyof ExperimentIndexDto, MechanicalsFilters>
  label: string
}[]
const FIELDS = [
  { propName: 'max_force', propLabel: 'Force maximale (kn)' },
  { propName: 'max_stress', propLabel: 'Contrainte maximale (MPa)' },
  { propName: 'max_stress_movement', propLabel: 'Déplacement contrainte max (mm)' },
  { propName: 'max_stress_deformation', propLabel: 'Déplacement contrainte max (%)' },
  { propName: 'chord_modulus_deformation', propLabel: 'Module corde déformation (GPa)' },
  { propName: 'chord_modulus_force', propLabel: 'Module corde force (GPa)' },
  { propName: 'fracture_stress', propLabel: 'Contraine à rupture (MPa)' },
  { propName: 'fracture_deformation', propLabel: 'Déformation à rupture (%)' }
] as { propName: keyof CompressionFlexionTractionFilter; propLabel: string }[]

const MechanicalsFilters: FC<{
  control: Control<ExperimentIndexDto>
}> = ({ control }) => {
  return (
    <>
      {PANELS.map(({ name, label }) => (
        <div className='flex flex-col mt-5' key={`section-${name}`}>
          <details className='' open={true}>
            <summary className='py-1 px-2 text-xl font-semibold list-none text-black bg-ice-blue cursor-pointer'>
              {label}
            </summary>
            <div className='grid grid-cols-3 gap-8 py-5'>
              {FIELDS.map(({ propName, propLabel }) => (
                <Controller
                  control={control}
                  key={`property-${propName}`}
                  name={`${name}.${propName}`}
                  render={({ field }) => (
                    <DualNumberInput
                      label={propLabel}
                      onChange={field.onChange}
                      value={(field.value ?? { min: null, max: null }) as Record<string, Nullable<number>>}
                    />
                  )}
                />
              ))}
            </div>
          </details>
        </div>
      ))}
    </>
  )
}

export { MechanicalsFilters }
