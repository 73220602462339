import { Nullable } from 'interfaces'
import { FC, memo } from 'react'

const InputRadio: FC<{
  className?: string
  value: Nullable<string>
  onChange?: (value: string) => void
  id: string
  label?: string
  name: string
}> = memo(
  ({
    className = '',
    name,
    label,
    id,
    onChange = () => {
      return
    },
    value
  }) => (
    <label className={` cursor-pointer flex flex-row items-center ${className}`}>
      <span className='inline-block relative'>
        <input
          checked={value === id}
          className='absolute inset-0 opacity-0 cursor-pointer'
          id={id}
          name={name}
          onChange={() => onChange(id)}
          type='radio'
        />
        <span
          className={`inline-block w-6 h-6 rounded-full shadow-radio border-[1px] ${
            value === id ? 'bg-greeny-blue border-warm-grey' : 'bg-white border-pinkish-grey'
          }`}
        ></span>
      </span>
      {label !== undefined && (
        <span
          className={`${
            value === id ? 'text-greyish-brown font-medium' : 'text-pinkish-grey font-light'
          } pl-3 inline-block mb-1.5`}
        >
          {label}
        </span>
      )}
    </label>
  )
)
export { InputRadio }
