import { useEffect, ReactNode, FC, useContext } from 'react'
import { createPortal } from 'react-dom'

import { ToastType, TOAST_TIMEOUT } from 'core/constants'
import { ToastProps } from 'interfaces'
import { SvgCheckboxChecked, SvgHint, SvgError } from 'assets/svg'
import { UiContext } from 'contexts/ui.context'

const TAILWIND_ANIMATION_DURATION = 300
const ToastContainer: FC<{
  children: ReactNode
  type: ToastType
  onClick: () => void
  displayed: boolean
}> = ({ children, displayed = false, type, onClick }) => {
  const Icons = {
    [ToastType.ERROR]: SvgError,
    [ToastType.SUCCESS]: SvgCheckboxChecked,
    [ToastType.WARNING]: SvgHint
  }

  return (
    <div
      className={`flex fixed left-1/2 bottom-10 flex-row items-center p-6 -ml-48 bg-white rounded-md shadow-toast cursor-pointer transition-toast duration-300 ${
        displayed ? 'translate-y-0 opacity-100' : 'translate-y-2 opacity-0'
      }`}
      onClick={onClick}
    >
      <img className='flex-none w-9 h-9 text-greeny-blue' src={Icons[type]} />
      <div className='flex flex-col ml-4 text-lg font-medium text-warm-grey'>{children}</div>
    </div>
  )
}

const Toast: FC<ToastProps> = ({ content = '', displayed, hideCallback = () => null, type = ToastType.SUCCESS }) => {
  const node = document.createElement('div')
  const $toast = document.querySelector('#toast')
  const { updateToast } = useContext(UiContext)

  const removeNode = () => {
    if (!$toast || $toast.children.length === 0) {
      return
    }
    $toast.childNodes[0].remove()
  }
  const handleHideCallback = () => {
    hideCallback()
  }

  useEffect(() => {
    if (!$toast || !displayed) {
      return removeNode()
    }
    $toast.appendChild(node)
    const destroyTimeout = setTimeout(() => {
      hideCallback()
      removeNode()
      updateToast({ displayed: false })
    }, TOAST_TIMEOUT + TAILWIND_ANIMATION_DURATION * 2)

    return () => {
      removeNode()
      clearInterval(destroyTimeout)
    }
  }, [node, displayed, hideCallback])

  return createPortal(
    <ToastContainer displayed={displayed} onClick={handleHideCallback} type={type}>
      {content}
    </ToastContainer>,
    node
  )
}
export { Toast }
