import { Theme, User, UserDTO } from 'interfaces/api/management'
import { fetchJson } from 'utils/fetch'

/**
 *
 * @param page
 * @param perPage
 * @returns
 */
const getManagementUsers = (page: string, perPage: string) => {
  return fetchJson<User[], undefined>('GET', `/management/users?page=${page}&per_page=${perPage}`)
}

/**
 *
 * @param user: UserDTO
 * @returns
 */
const postManagementUser = (user: UserDTO) => {
  return fetchJson<User, UserDTO>('POST', '/management/users', user)
}

const putManagementUser = (user: User) => {
  return fetchJson<Theme, UserDTO>('PUT', `/management/users/${user.id}`, user)
}

const deleteManagementUser = (index: number) => {
  return fetchJson('DELETE', `/management/users/${index}`)
}

export { putManagementUser, getManagementUsers, postManagementUser, deleteManagementUser }
