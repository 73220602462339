import { UiContext } from 'contexts/ui.context'
import { Nullable, ToastProps } from 'interfaces'
import { ExperimentViscosityDto } from 'interfaces/api/lab'
import { parseInt } from 'lodash'
import { getExperimentViscosity, postExperimentViscosity, putExperimentViscosity } from 'modules/lab/services'
import { FormEvent, forwardRef, useContext, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { useParams } from 'react-router-dom'
import { ButtonPrimary, ButtonSecondary, Panel } from 'ui/atoms'
import { UnderlinedNumberInput } from 'ui/atoms/UnderLinedInput'
import { UnderLinedSelect } from 'ui/atoms/UnderLinedSelect'
import { SpinnerLoaderMask } from 'ui/molecules'
import { ViscosityMobileList, ViscosityPanelFormValues } from './DescriptionTab.type'

const ViscosityPanel = forwardRef<HTMLFormElement>((_, ref) => {
  const [error, setError] = useState<Nullable<Record<string, string[]>>>(null)
  const { control, setValue, getValues } = useForm<ViscosityPanelFormValues>()
  const { experimentId: id = '' } = useParams()
  const { updateToast } = useContext(UiContext)

  const newViscosityRow = () => ({
    mobile: null,
    viscosity: undefined,
    temperature: undefined,
    torsion_torque: undefined,
    minute_per_tour: undefined
  })

  const fetchFormula = async (id?: string) => {
    if (!id) {
      throw new Error('😓 une erreur est survenue')
    }
    return await getExperimentViscosity(id)
  }

  const query = useQuery(['viscosity', id], async () => await fetchFormula(id), {
    onSuccess: (data) => {
      resetForm(data.data as ViscosityPanelFormValues)
    }
  })

  const resetForm = (data: ViscosityPanelFormValues) => {
    if (!data) {
      setValue('ending_drain', newViscosityRow())
      setValue('starting_drain', newViscosityRow())
      setValue('drain', newViscosityRow())
      return
    }
    data.starting_drain.mobile = data.starting_drain.mobile === null ? 'Non' : data.starting_drain.mobile
    data.drain.mobile = data.drain.mobile === null ? 'Non' : data.drain.mobile
    data.ending_drain.mobile = data.ending_drain.mobile === null ? 'Non' : data.ending_drain.mobile
    setValue('ending_drain', data.ending_drain)
    setValue('starting_drain', data.starting_drain)
    setValue('drain', data.drain)
  }

  const queryClient = useQueryClient()
  const viscosityUpdate = useMutation(
    (payload: ExperimentViscosityDto) =>
      query.data?.data ? putExperimentViscosity(id, payload) : postExperimentViscosity(id, payload),
    {
      onMutate: () => {
        setError(null)
      },
      onError: (e: { errors: Record<keyof ExperimentViscosityDto, string[]> }) => {
        updateToast({
          displayed: true,
          type: 'error',
          content: "Les modifications n'ont pas pu être enregistrées."
        } as ToastProps)
        setError(e.errors ?? null)
      },
      onSuccess: () => {
        queryClient.invalidateQueries('viscosity')
        updateToast({
          displayed: true,
          type: 'success',
          content: 'Les modifications ont bien été enregistrées.'
        } as ToastProps)
      }
    }
  )

  const isLoading = [query.isFetching, viscosityUpdate.isLoading].includes(true)

  const handleSubmitForm = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    setError(null)

    const payload = {
      id: parseInt(id),
      drain: getValues('drain'),
      starting_drain: getValues('starting_drain'),
      ending_drain: getValues('ending_drain')
    }
    payload.starting_drain.mobile = payload.starting_drain.mobile === 'Non' ? null : payload.starting_drain.mobile
    payload.drain.mobile = payload.drain.mobile === 'Non' ? null : payload.drain.mobile
    payload.ending_drain.mobile = payload.ending_drain.mobile === 'Non' ? null : payload.ending_drain.mobile
    viscosityUpdate.mutate({
      id: parseInt(id),
      drain: getValues('drain'),
      starting_drain: getValues('starting_drain'),
      ending_drain: getValues('ending_drain')
    })
  }

  const handleResetForm = () => {
    updateToast({
      displayed: true,
      type: 'error',
      content: 'Les modifications on été annulées.'
    } as ToastProps)
    resetForm(query.data?.data as ViscosityPanelFormValues)
  }

  return (
    <form onSubmit={handleSubmitForm} ref={ref}>
      <Panel title='Viscosité'>
        <SpinnerLoaderMask spinning={isLoading}>
          <div className='flex flex-col'>
            <div className='grid grid-cols-6 justify-between items-end mb-8'>
              <div></div>
              <span className='w-28 text-sm font-medium text-left text-black'>Mobile</span>
              <span className='w-28 text-sm font-medium text-left text-black'>Tour minute</span>
              <span className='w-28 text-sm font-medium text-left text-black'>Couple de Torsion (%)</span>
              <span className='w-28 text-sm font-medium text-left text-black'>Température (°C)</span>
              <span className='w-28 text-sm font-medium text-left text-black'>Viscosité (mPa s)</span>
            </div>
            <div className='grid grid-cols-6 justify-between mb-6'>
              <div>
                <span className='w-28 text-sm font-medium text-left text-black'>Début (cP)</span>
              </div>
              <Controller
                control={control}
                name={'starting_drain.mobile'}
                render={({ field }) => (
                  <UnderLinedSelect
                    {...field}
                    className='w-28'
                    errors={(error ?? {})['starting_drain.mobile'] ?? null}
                    options={ViscosityMobileList.map((v: string | null) => ({ label: v, value: v }))}
                  />
                )}
              />
              <Controller
                control={control}
                name={'starting_drain.minute_per_tour'}
                render={({ field }) => (
                  <UnderlinedNumberInput
                    className='w-28'
                    errors={(error ?? {})['starting_drain.minute_per_tour'] ?? null}
                    onChange={field.onChange}
                    placeholder='Chiffre'
                    value={field.value}
                  />
                )}
              />
              <Controller
                control={control}
                name={'starting_drain.torsion_torque'}
                render={({ field }) => (
                  <UnderlinedNumberInput
                    className='w-28'
                    errors={(error ?? {})['starting_drain.torsion_torque'] ?? null}
                    onChange={field.onChange}
                    placeholder='Chiffre'
                    value={field.value}
                  />
                )}
              />
              <Controller
                control={control}
                name={'starting_drain.temperature'}
                render={({ field }) => (
                  <UnderlinedNumberInput
                    className='w-28'
                    errors={(error ?? {})['starting_drain.temperature'] ?? null}
                    onChange={field.onChange}
                    placeholder='Chiffre'
                    value={field.value}
                  />
                )}
              />
              <Controller
                control={control}
                name={'starting_drain.viscosity'}
                render={({ field }) => (
                  <UnderlinedNumberInput
                    className='w-28'
                    errors={(error ?? {})['starting_drain.viscosity'] ?? null}
                    onChange={field.onChange}
                    placeholder='Chiffre'
                    value={field.value}
                  />
                )}
              />
            </div>
            <div className='grid grid-cols-6 justify-between mb-6'>
              <div>
                <span className='w-28 text-sm font-medium text-left text-black'>Egouttures (cP)</span>
              </div>
              <Controller
                control={control}
                name={'drain.mobile'}
                render={({ field }) => (
                  <UnderLinedSelect
                    {...field}
                    className='w-28'
                    errors={(error ?? {})['drain.mobile'] ?? null}
                    options={ViscosityMobileList.map((v: string | null) => ({ label: v ?? 'Non', value: v }))}
                  />
                )}
              />
              <Controller
                control={control}
                name={'drain.minute_per_tour'}
                render={({ field }) => (
                  <UnderlinedNumberInput
                    className='w-28'
                    errors={(error ?? {})['drain.minute_per_tour'] ?? null}
                    onChange={field.onChange}
                    placeholder='Chiffre'
                    value={field.value}
                  />
                )}
              />
              <Controller
                control={control}
                name={'drain.torsion_torque'}
                render={({ field }) => (
                  <UnderlinedNumberInput
                    className='w-28'
                    errors={(error ?? {})['drain.torsion_torque'] ?? null}
                    onChange={field.onChange}
                    placeholder='Chiffre'
                    value={field.value}
                  />
                )}
              />
              <Controller
                control={control}
                name={'drain.temperature'}
                render={({ field }) => (
                  <UnderlinedNumberInput
                    className='w-28'
                    errors={(error ?? {})['drain.temperature'] ?? null}
                    onChange={field.onChange}
                    placeholder='Chiffre'
                    value={field.value}
                  />
                )}
              />
              <Controller
                control={control}
                name={'drain.viscosity'}
                render={({ field }) => (
                  <UnderlinedNumberInput
                    className='w-28'
                    errors={(error ?? {})['drain.viscosity'] ?? null}
                    onChange={field.onChange}
                    placeholder='Chiffre'
                    value={field.value}
                  />
                )}
              />
            </div>
            <div className='grid grid-cols-6 justify-between mb-6'>
              <div>
                <span className='w-28 text-sm font-medium text-left text-black'>Fin (cP)</span>
              </div>
              <Controller
                control={control}
                name={'ending_drain.mobile'}
                render={({ field }) => (
                  <UnderLinedSelect
                    {...field}
                    className='w-28'
                    errors={null}
                    options={ViscosityMobileList.map((v: string | null) => ({ label: v ?? 'Non', value: v }))}
                  />
                )}
              />
              <Controller
                control={control}
                name={'ending_drain.minute_per_tour'}
                render={({ field }) => (
                  <UnderlinedNumberInput
                    className='w-28'
                    errors={(error ?? {})['ending_drain.minute_per_tour'] ?? null}
                    onChange={field.onChange}
                    placeholder='Chiffre'
                    value={field.value}
                  />
                )}
              />
              <Controller
                control={control}
                name={'ending_drain.torsion_torque'}
                render={({ field }) => (
                  <UnderlinedNumberInput
                    className='w-28'
                    errors={(error ?? {})['ending_drain.torsion_torque'] ?? null}
                    onChange={field.onChange}
                    placeholder='Chiffre'
                    value={field.value}
                  />
                )}
              />
              <Controller
                control={control}
                name={'ending_drain.temperature'}
                render={({ field }) => (
                  <UnderlinedNumberInput
                    className='w-28'
                    errors={(error ?? {})['ending_drain.temperature'] ?? null}
                    onChange={field.onChange}
                    placeholder='Chiffre'
                    value={field.value}
                  />
                )}
              />
              <Controller
                control={control}
                name={'ending_drain.viscosity'}
                render={({ field }) => (
                  <UnderlinedNumberInput
                    className='w-28'
                    errors={(error ?? {})['ending_drain.viscosity'] ?? null}
                    onChange={field.onChange}
                    placeholder='Chiffre'
                    value={field.value}
                  />
                )}
              />
            </div>
          </div>
          <div className='flex flex-row justify-center mt-10'>
            <ButtonSecondary className='px-14 mr-40' onClick={handleResetForm} type='button'>
              Annuler
            </ButtonSecondary>
            <ButtonPrimary className='px-14' type='submit'>
              Valider
            </ButtonPrimary>
          </div>
        </SpinnerLoaderMask>
      </Panel>
    </form>
  )
})

export { ViscosityPanel }
