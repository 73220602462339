import { ExperimentEosin, ExperimentEosinDto } from 'interfaces/api/lab'
import { Dto } from 'modules/list-parameters/services/list-parameters.dto'
import { fetchJson } from 'utils/fetch'

/**
 *
 * @param experimentId
 * @param payload
 * @returns
 */
const postExperimentEosin = (experimentId: string, payload: Dto<ExperimentEosinDto>) => {
  return fetchJson<ExperimentEosin, Dto<ExperimentEosinDto>>('POST', `/lab/experiments/${experimentId}/eosin`, payload)
}
/**
 *
 * @param experimentId
 * @param payload
 * @returns
 */
const putExperimentEosin = (experimentId: string, payload: Dto<ExperimentEosinDto>) => {
  return fetchJson<ExperimentEosin, Dto<ExperimentEosinDto>>('PUT', `/lab/experiments/${experimentId}/eosin`, payload)
}
/**
 *
 * @param experimentId
 * @returns
 */
const getExperimentEosin = (experimentId: string) => {
  return fetchJson<ExperimentEosin>('GET', `/lab/experiments/${experimentId}/eosin`)
}

export { postExperimentEosin, putExperimentEosin, getExperimentEosin }
