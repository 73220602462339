import { Nullable } from 'interfaces'
import { FC } from 'react'
import { InputError } from 'ui/atoms'
import { Field } from 'ui/atoms/Field'
import { InputCheckbox } from 'ui/atoms/InputCheckbox'

interface DualCheckBoxInputProps {
  label: string
  onChange: (value: Nullable<boolean>) => void
  value?: Nullable<boolean>
  errors?: string[]
}

const DualCheckboxInput: FC<DualCheckBoxInputProps> = ({ label, value = null, onChange, errors = [] }) => {
  return (
    <Field className='flex' label={label} labelClassName='font-normal'>
      <InputCheckbox checked={value === true} className='mr-4' label='Oui' onChange={() => onChange(true)} />
      <InputCheckbox checked={value === false} className='mr-4' label='Non' onChange={() => onChange(false)} />
      <InputCheckbox checked={value === null} label='N/A' onChange={() => onChange(null)} />
      <InputError errors={errors} />
    </Field>
  )
}

export type { DualCheckBoxInputProps }
export { DualCheckboxInput }
