import { ExperimentLinearDensity, ExperimentLinearDensityDto } from 'interfaces/api/lab'
import { Dto } from 'modules/list-parameters/services/list-parameters.dto'
import { fetchJson } from 'utils/fetch'

/**
 *
 * @param experimentId
 * @param payload
 * @returns
 */
const postExperimentLinearDensity = (experimentId: string, payload: Dto<ExperimentLinearDensityDto>) => {
  return fetchJson<ExperimentLinearDensity, Dto<ExperimentLinearDensityDto>>(
    'POST',
    `/lab/experiments/${experimentId}/linear-density`,
    payload
  )
}
/**
 *
 * @param experimentId
 * @param payload
 * @returns
 */
const putExperimentLinearDensity = (experimentId: string, payload: Dto<ExperimentLinearDensityDto>) => {
  return fetchJson<ExperimentLinearDensity, Dto<ExperimentLinearDensityDto>>(
    'PUT',
    `/lab/experiments/${experimentId}/linear-density`,
    payload
  )
}
/**
 *
 * @param experimentId
 * @returns
 */
const getExperimentLinearDensity = (experimentId: string) => {
  return fetchJson<ExperimentLinearDensity>('GET', `/lab/experiments/${experimentId}/linear-density`)
}

export { getExperimentLinearDensity, postExperimentLinearDensity, putExperimentLinearDensity }
