import { FileStatus } from 'core/constants/file'
import { Extensions, Nullable, State } from 'interfaces'
import { FC, memo, useContext, useEffect, useState } from 'react'
import { getTask } from '../services'
import { useQuery, useQueryClient } from 'react-query'
import { Task } from 'interfaces/api/task'
import { FetchResult } from 'utils/fetch'
import { FileManagementWithTaskProps } from '../types'
import { FileManagementChild } from './FileManagement'
import { FilesProvider } from '../context'
import { FilesContext } from '../context/files.context'
import { UiContext } from 'contexts/ui.context'
import { ToastType } from 'core/constants'

const FileAndTaskManagementChild: FC<FileManagementWithTaskProps> = memo(
  ({
    readonly = false,
    className = '',
    value = [],
    accept = [Extensions.ALL],
    multiple = false,
    onChange,
    onReadyToSubmitChange,
    taskId,
    onCreateTask,
    onTaskPollingEnd
  }) => {
    const { files, updateFiles } = useContext(FilesContext)
    const { updateToast } = useContext(UiContext)
    const [errors, setErrors] = useState<Map<string, string>>(new Map())
    const queryClient = useQueryClient()

    /**
     * Récupération de la task active
     */
    const activeTask = queryClient.getQueryData<FetchResult<Task>>(['tasks', taskId])?.data

    /**
     * Polling de la task active
     */
    useQuery(['tasks', taskId], async () => await getTask(`${taskId}`), {
      refetchInterval: 1000,
      enabled: taskId !== null && activeTask?.state === State.RUNNING,
      onError: (e: { message: string }) => {
        updateToast({
          content: e.message ?? 'Une erreur est survenue',
          displayed: true,
          type: ToastType.ERROR
        })
        if (!activeTask) {
          return
        }
        activeTask.state = State.FAILED
        updateProcessedFilesAfterTask(activeTask)
      }
    })

    /**
     * Mise à jour des fichiers avec un statut PROCESSED en fonction de l'état d'une tache
     * @param task: Task
     */
    const updateProcessedFilesAfterTask = (task: Nullable<Task>) => {
      switch (task?.state) {
        case State.DATA_REQUIRED:
          updateFiles(files.map((f) => (f.status === FileStatus.PROCESSING ? { ...f, status: FileStatus.WARNING } : f)))
          break
        case State.FAILED:
          updateFiles(files.map((f) => (f.status === FileStatus.PROCESSING ? { ...f, status: FileStatus.ERROR } : f)))
          if (task.error) {
            setErrors(errors.set(task.media_uuid, task.error))
          }
          break
        case State.FINISHED:
          updateFiles(
            files.map((f) => (f.status === FileStatus.PROCESSING ? { ...f, status: FileStatus.AVAILABLE } : f))
          )
          break
        default:
          break
      }
    }

    /**
     * Mise à jour des fichiers après création de tache
     */
    useEffect(() => {
      updateProcessedFilesAfterTask(activeTask ?? null)
      if (activeTask && onTaskPollingEnd) {
        onTaskPollingEnd(activeTask)
      }
    }, [activeTask])

    /**
     * Callback si les fichiers sont mis à jour
     */
    useEffect(() => {
      const hasUploadInProgress = files.some((f) => f.status === FileStatus.UPLOADING)
      const hasUploadInProcess = files.some((f) => f.status === FileStatus.PROCESSING)
      const hasIdleUploads = files.some((f) => f.status === FileStatus.IDLE)
      if (!hasUploadInProgress && hasUploadInProcess && !hasIdleUploads && files.length > 0 && onCreateTask) {
        onCreateTask(files)
      }
    }, [files])

    const fileManagementProps = {
      readonly,
      className,
      value,
      accept,
      multiple,
      onChange,
      onReadyToSubmitChange,
      error: activeTask ? errors.get(activeTask.media_uuid) : null
    }
    return (
      <>
        <FileManagementChild {...fileManagementProps} hasTask={true} />
      </>
    )
  }
)

const FileAndTaskManagement: FC<FileManagementWithTaskProps> = (props) => (
  <FilesProvider>
    <FileAndTaskManagementChild {...props} />
  </FilesProvider>
)
export { FileAndTaskManagement }
