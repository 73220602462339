import { NumberRange, RangeValueError } from 'interfaces/api/lab'
import { FC } from 'react'
import { Nullable } from 'interfaces'
import { Field } from 'ui/atoms/Field'
import { UnderlinedNumberInput } from 'ui/atoms/UnderLinedInput'

interface DualNumberInputProps {
  label: string
  onChange: (dateRange: NumberRange) => void
  value?: Nullable<NumberRange>
  errors?: RangeValueError
}

const DualNumberInput: FC<DualNumberInputProps> = ({ label, value, onChange, errors }) => {
  return (
    <Field className='flex font-normal' label={label} labelClassName='font-normal'>
      <div>
        <UnderlinedNumberInput
          errors={errors?.min ?? null}
          onChange={(v) => onChange({ min: v, max: value?.max })}
          placeholder='Min'
          value={value?.min}
        />
      </div>
      <div className='ml-4'>
        <UnderlinedNumberInput
          errors={errors?.max ?? null}
          onChange={(v) => onChange({ min: value?.min, max: v })}
          placeholder='Max'
          value={value?.max}
        />
      </div>
    </Field>
  )
}

export type { DualNumberInputProps }
export { DualNumberInput }
