import { Fiber } from 'interfaces/api/management'

const FIBERS = [
  {
    value: Fiber.CARBON,
    label: 'Carbone'
  },
  {
    value: Fiber.GLASS,
    label: 'Verre'
  },
  {
    value: Fiber.MIX,
    label: 'Mixte'
  },
  {
    value: Fiber.NATURAL_FIBER,
    label: 'Naturelle'
  }
]
export { FIBERS }
