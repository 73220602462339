import { SvgTrash } from 'assets/svg'
import { Nullable } from 'interfaces'
import { Reinforcement } from 'interfaces/api/management'
import {
  deleteManagementReinforcement,
  putManagementReinforcement
} from 'modules/list-parameters/services/reinforcements'
import { FC, useState } from 'react'
import { useMutation, useQueryClient } from 'react-query'
import { UnderlinedInput } from 'ui/atoms/UnderLinedInput'
import { DebouncedTextInput } from 'ui/molecules'

export const ReinforcementItem: FC<{
  reinforcement: Reinforcement
}> = ({ reinforcement }) => {
  const [reinforcementUpdateError, setReinforcementUpdateError] = useState<Nullable<string[]>>(null)

  const queryClient = useQueryClient()
  const reinforcementUpdate = useMutation(
    (params: { id: number; label: string }) => putManagementReinforcement(params.id, params.label),
    {
      onMutate: () => setReinforcementUpdateError(null),
      onSuccess: () => queryClient.invalidateQueries('reinforcements'),
      onError: (e: { message: string }) => setReinforcementUpdateError([e.message])
    }
  )
  const onChange = (label: string) => {
    reinforcementUpdate.mutate({ id: reinforcement.id, label })
  }

  const reinforcementDelete = useMutation(deleteManagementReinforcement, {
    onSuccess: () => queryClient.invalidateQueries('reinforcements')
  })

  return (
    <div className='flex justify-between items-center'>
      <div className='overflow-hidden w-28'>
        <DebouncedTextInput
          Component={UnderlinedInput}
          disabled={reinforcementUpdate.isLoading}
          errors={reinforcementUpdateError}
          onChange={onChange}
          value={reinforcement.label}
        />
      </div>
      {reinforcement.deletable && (
        <button
          className='w-5'
          data-testid='delete-button'
          onClick={() => reinforcementDelete.mutate(reinforcement.id)}
        >
          <img className='w-5 h-5' src={SvgTrash} />
        </button>
      )}
    </div>
  )
}
