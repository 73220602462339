import { Configuration } from 'interfaces/api/lab'
import { fetchJson } from 'utils/fetch'

/**
 *
 * @returns
 */
const getConfigurations = () => {
  return fetchJson<Configuration[]>('GET', `/lab/configurations`)
}

export { getConfigurations }
