import { FC, ReactNode } from 'react'
import { Link, useMatch, useResolvedPath } from 'react-router-dom'

export const WhiteButtonLink: FC<{ href: string; className?: string; children: ReactNode }> = ({
  children,
  href,
  className
}) => {
  const resolved = useResolvedPath(href)
  const match = useMatch({ path: resolved.pathname, end: true })
  return (
    <Link className={`relative flex h-[60px] items-center bg-white h-15 rounded shadow-card ${className}`} to={href}>
      {match && <span className='flex absolute inset-y-0 w-1 h-full bg-dusk-blue' />}
      <span className='ml-5'>{children}</span>
    </Link>
  )
}
