import { fiber_rate } from 'modules/lab/schemas'
import { FC } from 'react'
import { z } from 'zod'
import { TableTd } from './TableTd'

const FiberRateTd: FC<{ matrix: Record<string, boolean>; data: z.infer<typeof fiber_rate> }> = ({ matrix, data }) => {
  return (
    <>
      {matrix.fiber_mass_rate && <TableTd className='text-left'>{data?.fiber_mass_rate}</TableTd>}
      {matrix.fiber_volume_rate && <TableTd className='text-left'>{data?.fiber_volume_rate}</TableTd>}
      {matrix.porosity_rate && <TableTd className='text-left'>{data?.porosity_rate}</TableTd>}
    </>
  )
}

export { FiberRateTd }
