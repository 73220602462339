import { thermocouple } from 'modules/lab/schemas'
import { FC } from 'react'
import { z } from 'zod'
import { TableTd } from './TableTd'

const ThermocoupleTd: FC<{ matrix: Record<string, boolean>; data: z.infer<typeof thermocouple> }> = ({
  matrix,
  data
}) => {
  return (
    <>
      {matrix.peak_temperature && <TableTd className='text-left'>{data?.peak_temperature}</TableTd>}
      {matrix.peak_time && <TableTd className='text-left'>{data?.peak_time}</TableTd>}
      {matrix.peak_distance && <TableTd className='text-left'>{data?.peak_distance}</TableTd>}
    </>
  )
}

export { ThermocoupleTd }
