import { FC, useEffect, useRef } from 'react'
import { Link, useParams } from 'react-router-dom'

import { MainTemplate } from 'ui/templates'
import { Tabs } from 'ui/molecules'

import { DescriptionTab, ProductionDataTab } from '../../components'
import { useQuery, useQueryClient } from 'react-query'
import { getExperimentFormula } from 'modules/lab/services'

const Title: FC = () => <h1 className='text-xl font-bold text-dark-blue'>Détails de l'essai</h1>
const BackButton: FC = () => <Link className='text-dusty-teal' to='/experiments'>{`< Retour`}</Link>

const ExperimentEdition: FC = () => {
  const templateRef = useRef<HTMLDivElement>(null)
  const { experimentId: id = '' } = useParams()

  const handleScrollTo = (px: number) => {
    if (templateRef.current) {
      templateRef.current.scrollTo({
        top: px,
        behavior: 'smooth'
      })
    }
  }
  const descriptionTab = {
    title: `Description de l'essai`,
    component: <DescriptionTab scrollTo={handleScrollTo} />
  }
  const productionDataTab = {
    title: `Données de production et d'analyse`,
    component: <ProductionDataTab scrollTo={handleScrollTo} />
  }

  const fetchFormula = async (id?: string) => {
    if (!id) {
      throw new Error('😓 une erreur est survenue')
    }
    return await getExperimentFormula(id)
  }
  const query = useQuery(['formulation', id], async () => await fetchFormula(id))

  const tabs = query.data?.data ? [descriptionTab, productionDataTab] : [descriptionTab]

  const queryClient = useQueryClient()
  useEffect(() => {
    return () => {
      queryClient.removeQueries('lab.experiment')
    }
  }, [])
  return (
    <MainTemplate backButton={<BackButton />} className='overflow-y-auto' ref={templateRef} title={<Title />}>
      <Tabs className='flex flex-1 mx-auto mt-12' items={tabs} />
    </MainTemplate>
  )
}

export { ExperimentEdition }
