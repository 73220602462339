import { createContext, FC, ReactNode, useCallback, useReducer } from 'react'
import { reducers, Types } from 'stores/auth'
import { Nullable } from 'interfaces'
import { AuthenticatedUser } from 'interfaces/api/auth'

interface State {
  userProfile: Nullable<AuthenticatedUser>
  updateUserProfile: (payload: Nullable<AuthenticatedUser>) => void
}

const initialState: State = {
  userProfile: null,
  updateUserProfile: () => null
}

const AuthContext = createContext(initialState)

const AuthProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const [state, dispatch] = useReducer(reducers, initialState)

  const value = {
    ...state,
    updateUserProfile: useCallback((payload: Nullable<AuthenticatedUser>) => {
      dispatch({
        type: Types.UPDATE_USER_PROFILE,
        payload
      })
    }, [])
  }

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}

export { AuthProvider, AuthContext, initialState }
export type { State }
