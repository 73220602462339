import { Nullable } from 'interfaces'
import { ExperimentIndexDto } from 'interfaces/api/lab'
import { FC } from 'react'
import { Control, Controller, FieldPath } from 'react-hook-form'
import { DualNumberInput } from 'ui/molecules'

const DMAFilters: FC<{
  control: Control<ExperimentIndexDto>
}> = ({ control }) => {
  return (
    <>
      {[
        { name: 'dynamic_mechanical_analysis_ramp_1', label: 'DMA rampe 1' },
        { name: 'dynamic_mechanical_analysis_ramp_2', label: 'DMA rampe 2' }
      ].map(({ name, label }) => (
        <div className='flex flex-col mt-5' key={`section-${name}`}>
          <details className='' open={true}>
            <summary className='py-1 px-2 text-xl font-semibold list-none text-black bg-ice-blue cursor-pointer'>
              {label}
            </summary>
            <div className='grid grid-cols-3 gap-8 py-5'>
              {[
                { propName: 'full_width_at_half_maximum', propLabel: 'FWHM' },
                { propName: 'tan_delta_max_temperature', propLabel: 'Temperature pour tan max (°C)' },
                { propName: 'g_second_max_temperature', propLabel: 'Temperature pour G" max (°C)' }
              ].map(({ propName, propLabel }) => (
                <Controller
                  control={control}
                  key={`property-${propName}`}
                  name={`${name}.${propName}` as FieldPath<ExperimentIndexDto>}
                  render={({ field }) => (
                    <DualNumberInput
                      label={propLabel}
                      onChange={field.onChange}
                      value={(field.value ?? { min: null, max: null }) as Record<string, Nullable<number>>}
                    />
                  )}
                />
              ))}
            </div>
          </details>
        </div>
      ))}
    </>
  )
}

export { DMAFilters }
