import { FormulaIngredientsDto } from 'interfaces/api/lab'
import { Dto } from 'modules/list-parameters/services/list-parameters.dto'
import { fetchJson } from 'utils/fetch'

/**
 *
 * @param experimentId
 * @returns void
 */
const getExperimentFormula = (experimentId: string) => {
  return fetchJson<FormulaIngredientsDto, Dto<FormulaIngredientsDto>>('GET', `/lab/experiments/${experimentId}/formula`)
}
/**
 *
 * @param experimentId
 * @param payload: Dto<FormulaIngredientsDto>
 * @returns void
 */
const postExperimentFormula = (experimentId: string, payload: Dto<FormulaIngredientsDto>) => {
  return fetchJson<FormulaIngredientsDto, Dto<FormulaIngredientsDto>>(
    'POST',
    `/lab/experiments/${experimentId}/formula`,
    payload
  )
}

/**
 *
 * @param experimentId
 * @param payload: Dto<FormulaIngredientsDto>
 * @returns void
 */
const putExperimentFormula = (experimentId: string, payload: Dto<FormulaIngredientsDto>) => {
  return fetchJson<FormulaIngredientsDto, Dto<FormulaIngredientsDto>>(
    'PUT',
    `/lab/experiments/${experimentId}/formula`,
    payload
  )
}

export { getExperimentFormula, putExperimentFormula, postExperimentFormula }
