import { Nullable } from 'interfaces'
import { ExperimentIndexDto } from 'interfaces/api/lab'
import { FC } from 'react'
import { Control, Controller, FieldPath } from 'react-hook-form'
import { DualNumberInput } from 'ui/molecules'

const ThermocoupleFilters: FC<{
  control: Control<ExperimentIndexDto>
}> = ({ control }) => {
  return (
    <div className='flex flex-col mt-5'>
      <details className='' open={true}>
        <summary className='py-1 px-2 text-xl font-semibold list-none text-black bg-ice-blue cursor-pointer'>
          Thermocouple
        </summary>
        <div className='grid grid-cols-3 gap-8 py-5'>
          {[
            { propName: 'peak_temperature', propLabel: 'Temperature au pic (°C)' },
            { propName: 'peak_time', propLabel: 'Temps au pic (s)' },
            { propName: 'peak_distance', propLabel: 'Distance au pic (s)' }
          ].map(({ propName, propLabel }) => (
            <Controller
              control={control}
              key={`property-${propName}`}
              name={`thermocouple.${propName}` as FieldPath<ExperimentIndexDto>}
              render={({ field }) => (
                <DualNumberInput
                  label={propLabel}
                  onChange={field.onChange}
                  value={(field.value ?? { min: null, max: null }) as Record<string, Nullable<number>>}
                />
              )}
            />
          ))}
        </div>
      </details>
    </div>
  )
}

export { ThermocoupleFilters }
