import { fetchJson } from 'utils/fetch'

/**
 *
 * @returns
 */
const getExperimentReinforcements = () => {
  return fetchJson<{ id: number; label: string }[]>('GET', '/lab/transverse-reinforcements')
}

export { getExperimentReinforcements }
