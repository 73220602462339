import { ExperimentProcessParameterDto } from 'interfaces/api/lab'
import { Dto } from 'modules/list-parameters/services/list-parameters.dto'
import { fetchJson } from 'utils/fetch'

/**
 *
 * @param experimentId
 * @returns Promise<FetchResult<ExperimentProcessParameters>>
 */
const getExperimentProcessParameters = (experimentId: string) => {
  return fetchJson<ExperimentProcessParameterDto, Dto<ExperimentProcessParameterDto>>(
    'GET',
    `/lab/experiments/${experimentId}/process-parameters`
  )
}

/**
 *
 * @param experimentId
 * @param payload: Dto<ExperimentProcessParameterDto>
 * @returns Promise<FetchResult<ExperimentProcessParameters>>
 */
const postExperimentProcessParameters = (experimentId: string, payload: Dto<ExperimentProcessParameterDto>) => {
  return fetchJson<ExperimentProcessParameterDto, Dto<ExperimentProcessParameterDto>>(
    'POST',
    `/lab/experiments/${experimentId}/process-parameters`,
    payload
  )
}

/**
 *
 * @param experimentId
 * @param payload: Dto<ExperimentProcessParameterDto>
 * @returns void
 */
const putExperimentProcessParameters = (experimentId: string, payload: Dto<ExperimentProcessParameterDto>) => {
  return fetchJson<ExperimentProcessParameterDto, Dto<ExperimentProcessParameterDto>>(
    'PUT',
    `/lab/experiments/${experimentId}/process-parameters`,
    payload
  )
}

export { getExperimentProcessParameters, postExperimentProcessParameters, putExperimentProcessParameters }
