import { FC, useState } from 'react'
import { Control, Controller, ControllerRenderProps, UseFormSetValue } from 'react-hook-form'

import { SvgCreate, SvgTriangleDown } from 'assets/svg'
import { ErrorObj, Nullable } from 'interfaces'
import { ExperimentWaterUptakeDto } from 'interfaces/api/lab'

import { InputError } from 'ui/atoms'
import { InputCheckbox } from 'ui/atoms/InputCheckbox'
import { UnderlinedInput } from 'ui/atoms/UnderLinedInput'

import { WaterUptakeFormValues, WaterUptakeLineWithoutId } from '../ProductionDataTab.type'
import { WaterUptakeLine } from './WaterUptakeLine'

import { i18n as productionDatai18n } from 'modules/lab/i18n'
import { SvgInjector } from 'ui/atoms/SvgInjector'
import { parse, differenceInDays } from 'date-fns'
import { DATE_FORMAT } from 'core/constants'

const panelI18n = productionDatai18n.productionDataTab.waterUptakePanel

const WaterUptake: FC<{
  lines: WaterUptakeLineWithoutId[]
  waterUptakeIndex: number
  waterUptakeError: Nullable<ErrorObj<ExperimentWaterUptakeDto>>
  control: Control<{
    water_uptakes: WaterUptakeFormValues
  }>
  setValue: UseFormSetValue<{
    water_uptakes: WaterUptakeFormValues
  }>
  onCreateNewWaterUptakeLine: (i: number) => void
  onDeleteWaterUptakeLine: (i: number, k: number) => void
  waterUptakeCount: number
}> = ({
  control,
  lines,
  waterUptakeError,
  waterUptakeIndex,
  onCreateNewWaterUptakeLine,
  onDeleteWaterUptakeLine,
  setValue,
  waterUptakeCount
}) => {
  const [isTableOpen, setIsTableOpen] = useState(false)

  const handleOnLineChange = () => {
    if (lines.length === 0) {
      return
    }
    for (let i = 0; i < lines.length; i++) {
      const firstDay = parse(lines[0].analyzed_at, DATE_FORMAT, new Date())
      if (i === 0) {
        const updatedFirstLine: WaterUptakeLineWithoutId = {
          analyzed_at: lines[0].analyzed_at,
          days_count: 0,
          sample_weight: lines[0].sample_weight,
          weight_gain: 0
        }
        setValue(`water_uptakes.${waterUptakeIndex}.lines.0`, updatedFirstLine)
      } else {
        const day = parse(lines[i].analyzed_at, DATE_FORMAT, new Date())
        const daysCount = differenceInDays(day, firstDay)
        const weightGain =
          lines[0].sample_weight === 0 || lines[i].sample_weight === 0
            ? 0
            : (lines[i].sample_weight / lines[0].sample_weight) * 100 - 100
        const updatedLine: WaterUptakeLineWithoutId = {
          analyzed_at: lines[i].analyzed_at,
          days_count: daysCount,
          sample_weight: lines[i].sample_weight,
          weight_gain: weightGain
        }
        setValue(`water_uptakes.${waterUptakeIndex}.lines.${i}`, updatedLine)
      }
    }
  }
  const handleIsActiveChange = (
    field: ControllerRenderProps<{ water_uptakes: WaterUptakeFormValues }, `water_uptakes.${number}.is_active`>,
    value: boolean
  ) => {
    for (let i = 0; i < waterUptakeCount; i++) {
      setValue(`water_uptakes.${i}.is_active`, false)
    }
    field.onChange(value)
  }

  return (
    <div className='grid grid-cols-7 mb-8 w-full' key={`wu_${waterUptakeIndex}`}>
      <div className='flex flex-col col-span-2 row-span-3'>
        <span className='mb-4 text-sm font-medium'>ID</span>
        <div className='flex flex-row items-center mt-5'>
          <Controller
            control={control}
            name={`water_uptakes.${waterUptakeIndex}.is_active`}
            render={({ field }) => (
              <InputCheckbox
                checked={field.value}
                className='mr-4'
                onChange={(value) => handleIsActiveChange(field, value)}
              />
            )}
          />
          <button className='mx-3' onClick={() => setIsTableOpen(!isTableOpen)} type='button'>
            <SvgInjector
              className={`${isTableOpen ? 'rotate-0' : '-rotate-90'} w-4 text-warm-grey`}
              src={SvgTriangleDown}
            />
          </button>
          <Controller
            control={control}
            name={`water_uptakes.${waterUptakeIndex}.identifier`}
            render={({ field }) => (
              <UnderlinedInput
                className='w-24'
                errors={waterUptakeError ? waterUptakeError[`water_uptakes.${waterUptakeIndex}.identifier`] : null}
                onChange={field.onChange}
                placeholder=''
                readOnly={true}
                value={field.value}
              />
            )}
          />
        </div>
        <InputError
          errors={waterUptakeError ? waterUptakeError[`water_uptakes.${waterUptakeIndex}.is_active`] : null}
        />
      </div>
      {lines.length > 0 && (
        <div
          className={`${
            isTableOpen ? 'opacity-100' : 'opacity-25 italic'
          } grid grid-cols-13 col-span-5 col-start-3 mb-4`}
        >
          <span className='col-span-3 text-sm font-medium'>{panelI18n.tableColumns.analyzedAt}</span>
          <span className='col-span-3 text-sm font-medium'>{panelI18n.tableColumns.daysCount}</span>
          <span className='col-span-3 text-sm font-medium'>{panelI18n.tableColumns.sampleWeight}</span>
          <span className='col-span-3 text-sm font-medium'>{panelI18n.tableColumns.weightGain}</span>
        </div>
      )}
      {isTableOpen ? (
        <>
          {lines.map((_, i) => (
            <WaterUptakeLine
              control={control}
              key={i}
              onDeleteWaterUptakeLine={onDeleteWaterUptakeLine}
              onLineChange={handleOnLineChange}
              waterUptakeError={waterUptakeError}
              waterUptakeIndex={waterUptakeIndex}
              waterUptakeLineIndex={i}
            />
          ))}
          <div className='col-span-5 col-start-3 mt-5'>
            <button
              className='flex items-center'
              onClick={() => onCreateNewWaterUptakeLine(waterUptakeIndex)}
              type='button'
            >
              <img className='mr-2 w-4.5 h-4.5' src={SvgCreate} />
              <span className='text-sm font-normal whitespace-nowrap'>{panelI18n.actions.addDate}</span>
            </button>
          </div>
        </>
      ) : (
        <span className='flex items-center mt-5 h-6 text-sm font-normal text-black'>
          {lines.length} {panelI18n.linesTitle(lines.length)}
        </span>
      )}
    </div>
  )
}

export { WaterUptake }
