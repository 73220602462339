import { ExperimentFireTest, ExperimentFireTestDto, ExperimentFireTestTaskDto } from 'interfaces/api/lab'
import { ExperimentFireTestTask } from 'interfaces/api/task'
import { Dto } from 'modules/list-parameters/services/list-parameters.dto'
import { fetchJson } from 'utils/fetch'

/**
 *
 * @param experimentId
 * @param payload
 * @returns
 */
const postExperimentFireTest = (experimentId: string, payload: Dto<ExperimentFireTestDto>) => {
  return fetchJson<ExperimentFireTest, Dto<ExperimentFireTestDto>>(
    'POST',
    `/lab/experiments/${experimentId}/fire-test`,
    payload
  )
}
/**
 *
 * @param experimentId
 * @param payload
 * @returns
 */
const putExperimentFireTest = (experimentId: string, payload: Dto<ExperimentFireTestDto>) => {
  return fetchJson<ExperimentFireTest, Dto<ExperimentFireTestDto>>(
    'PUT',
    `/lab/experiments/${experimentId}/fire-test`,
    payload
  )
}
/**
 *
 * @param experimentId
 * @returns
 */
const getExperimentFireTest = (experimentId: string) => {
  return fetchJson<ExperimentFireTest>('GET', `/lab/experiments/${experimentId}/fire-test`)
}

/**
 *
 * @param url
 * @param payload
 * @returns
 */
const postExperimentFireTestTask = (experimentId: string, payload: Dto<ExperimentFireTestTaskDto>) => {
  return fetchJson<ExperimentFireTestTask, Dto<ExperimentFireTestTaskDto>>(
    'POST',
    `/lab/experiments/${experimentId}/fire-test/task`,
    payload
  )
}

export { postExperimentFireTest, putExperimentFireTest, getExperimentFireTest, postExperimentFireTestTask }
