import { FC } from 'react'
import { Route, Routes } from 'react-router-dom'

import { TestCampaign } from './pages'

const TestCampaignRoutes: FC = () => {
  return (
    <Routes>
      <Route element={<TestCampaign />} path='' />
    </Routes>
  )
}

export { TestCampaignRoutes }
