import { ModalProps, ToastProps } from 'interfaces'
import { createContext, FC, ReactNode, useCallback, useReducer } from 'react'

import { Types, State, Actions } from './ui.context.type'

const reducers = (state: State, action: Actions): State => {
  switch (action.type) {
    case Types.UPDATE_TOAST:
      return {
        ...state,
        toast: action.payload
      }
    case Types.UPDATE_MODAL:
      return {
        ...state,
        modal: {
          ...action.payload
        }
      }
    default:
      return state
  }
}

const initialState: State = {
  toast: {
    content: '',
    displayed: false,
    hideCallback: () => null
  },
  modal: {
    content: null,
    displayed: false,
    hideCallback: () => null
  },
  updateToast: () => null,
  updateModal: () => null
}

const UiContext = createContext(initialState)
const UiProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const [state, dispatch] = useReducer(reducers, initialState)
  const value: State = {
    ...state,
    updateToast: useCallback((payload: ToastProps) => {
      dispatch({
        type: Types.UPDATE_TOAST,
        payload
      })
    }, []),
    updateModal: useCallback((payload: ModalProps) => {
      dispatch({
        type: Types.UPDATE_MODAL,
        payload
      })
    }, [])
  }

  return <UiContext.Provider value={value}>{children}</UiContext.Provider>
}

export { UiProvider, UiContext, initialState }
