import { SvgArrowDown } from 'assets/svg'
import { UiContext } from 'contexts/ui.context'
import { ToastType } from 'core/constants'
import { ErrorObj, Extensions, LiteMedia, Nullable } from 'interfaces'
import { ExperimentFlexionDto, Angle, ExperimentFlexion, ExperimentFlexionTaskDto } from 'interfaces/api/lab'
import { FileAndTaskManagement } from 'modules/files'
import { i18n } from 'i18n'
import { i18n as productionDatai18n } from 'modules/lab/i18n'
import { postExperimentFlexion, postExperimentFlexionTask, putExperimentFlexion } from 'modules/lab/services'
import { Dto } from 'modules/list-parameters/services/list-parameters.dto'
import { FC, useContext, useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useMutation, useQueryClient } from 'react-query'
import { useParams } from 'react-router-dom'

import { ButtonPrimary, ButtonSecondary, InputError } from 'ui/atoms'
import { Field } from 'ui/atoms/Field'
import { Spinner } from 'ui/atoms/Spinner'
import { SvgInjector } from 'ui/atoms/SvgInjector'
import { UnderlinedNumberInput } from 'ui/atoms/UnderLinedInput'
import { FetchResult } from 'utils/fetch'
import { FlexionFormValues } from '../ProductionDataTab.type'
import { Task } from 'interfaces/api/task'
import { TaskOverwriteModal } from '../../shared'

const localeI18n = productionDatai18n.productionDataTab.flexionPanel

const visualisationRows = [
  'max_force',
  'max_stress',
  'max_stress_movement',
  'max_stress_deformation',
  'chord_modulus_deformation',
  'chord_modulus_force',
  'fracture_stress',
  'fracture_deformation'
] as Exclude<keyof FlexionFormValues[Angle], 'test_tubes' | 'files'>[]

const FlexionAngleForm: FC<{
  angle: Angle
  onClose: () => void
}> = ({ angle, onClose }) => {
  const { experimentId } = useParams()
  const [taskId, setTaskId] = useState<Nullable<number>>(null)
  const [canSubmit, setCanSubmit] = useState<boolean>(false)
  const [taskModal, setTaskModal] = useState<boolean>(false)
  const [taskFiles, setTaskFiles] = useState<LiteMedia[]>([])
  if (!experimentId) {
    return null
  }
  const [flexionError, setFlexionError] = useState<Nullable<ErrorObj<ExperimentFlexionDto>>>(null)
  const { updateToast } = useContext(UiContext)
  const queryClient = useQueryClient()

  const form = useForm<FlexionFormValues[Angle]>()
  const { control, handleSubmit, reset, getValues } = form

  const flexion =
    queryClient.getQueryData<FetchResult<ExperimentFlexion[]>>(['lab.experiment.flexion', experimentId])?.data ?? null
  const angleExist = flexion?.map((t) => t.angle).includes(angle) ?? false

  const handleOnSubmit = (payload: FlexionFormValues[Angle]) => {
    angleExist ? put.mutate(payload) : post.mutate(payload)
  }

  const post = useMutation((data: Dto<ExperimentFlexionDto>) => postExperimentFlexion(experimentId, angle, data), {
    onMutate: () => {
      setFlexionError(null)
    },
    onSuccess: ({ data }) => {
      const otherFlexion = flexion?.find((t) => t.angle !== angle)
      const updatedData = otherFlexion ? [otherFlexion, data] : [data]
      queryClient.setQueryData(['lab.experiment.flexion', experimentId], {
        ...flexion,
        data: updatedData
      })
      updateToast({
        content: localeI18n.toasts.create_success,
        displayed: true,
        type: ToastType.SUCCESS
      })
    },
    onError: (e: Error & { errors?: ErrorObj<ExperimentFlexionDto> }) => {
      setFlexionError(e.errors ?? null)
      updateToast({
        content: e.message ?? 'Une erreur est survenue',
        displayed: true,
        type: ToastType.ERROR
      })
    }
  })

  const put = useMutation((data: Dto<ExperimentFlexionDto>) => putExperimentFlexion(experimentId, angle, data), {
    onMutate: () => {
      setFlexionError(null)
    },
    onSuccess: ({ data }) => {
      queryClient.setQueryData(['lab.experiment.flexion', experimentId], {
        ...flexion,
        data: flexion?.map((t) => (t.angle == angle ? data : t))
      })
      updateToast({
        content: localeI18n.toasts.update_success,
        displayed: true,
        type: ToastType.SUCCESS
      })
    },
    onError: (e: Error & { errors?: ErrorObj<ExperimentFlexionDto> }) => {
      setFlexionError(e.errors ?? null)
      updateToast({
        content: e.message ?? 'Une erreur est survenue',
        displayed: true,
        type: ToastType.ERROR
      })
    }
  })

  const resetForm = () => {
    const flexionAngle = flexion?.find((t) => t.angle === angle)
    reset(flexionAngle)
  }

  const handleCreateTask = (files: LiteMedia[]) => {
    setTaskModal(true)
    setTaskFiles(files)
  }

  const handleTaskCreationDone = (data: Task) => {
    if (!data.results) {
      return
    }
    reset({ ...getValues(), ...data.results })
  }

  useEffect(() => {
    resetForm()
  }, [flexion])

  return (
    <>
      <form className='relative' onSubmit={handleSubmit(handleOnSubmit)}>
        {post.isLoading && (
          <div className='flex absolute z-10 flex-row flex-1 justify-center items-center w-full h-full bg-white75 backdrop-blur-xs'>
            <Spinner className='w-6 text-dusty-teal' stroke='#eee' />
            <p className='ml-4 italic text-pinkish-grey'>{i18n.isSaving}</p>
          </div>
        )}
        <div className={`flex flex-col py-6 px-9 bg-white rounded-sm shadow-card`}>
          <div
            className='flex flex-row justify-between items-center border-b border-cool-grey cursor-pointer'
            onClick={onClose}
          >
            <h3 className='pb-2 text-xl text-black'>{`${localeI18n.title} ${angle}° - ${localeI18n.importTitle}`}</h3>
            <SvgInjector className='w-4 rotate-180' src={SvgArrowDown} />
          </div>
          <div className='flex flex-col items-center py-6'>
            <div className='flex flex-col px-9 my-4'>
              <Controller
                control={control}
                defaultValue={[]}
                name={`files`}
                render={({ field }) => (
                  <div className='flex flex-col items-center'>
                    <Field className='' label='Importer un fichier'>
                      <FileAndTaskManagement
                        accept={[Extensions.CSV, Extensions.XLSX, Extensions.ZIP]}
                        onChange={field.onChange}
                        onCreateTask={handleCreateTask}
                        onReadyToSubmitChange={setCanSubmit}
                        onTaskPollingEnd={handleTaskCreationDone}
                        taskId={taskId}
                        value={field.value}
                      />
                      <InputError errors={null} />
                    </Field>
                  </div>
                )}
              />
            </div>
          </div>
          <div className='flex flex-row justify-between items-center border-b border-cool-grey cursor-pointer'>
            <h3 className='pb-2 text-xl text-black'>{`${localeI18n.title} ${angle}° - ${localeI18n.dataEntry}`}</h3>
          </div>
          <div className='flex flex-col items-center py-6'>
            <div className='flex flex-col px-9 my-4'>
              <Field className='flex flex-row mb-3 w-[360px]' label={localeI18n.test_tubes}>
                <Controller
                  control={control}
                  defaultValue={null}
                  name={`test_tubes`}
                  render={({ field }) => (
                    <UnderlinedNumberInput
                      className='w-48'
                      errors={flexionError?.test_tubes ?? null}
                      onChange={field.onChange}
                      placeholder=''
                      value={field.value}
                    />
                  )}
                />
              </Field>
              <p className='my-8 font-semibold text-black'>{localeI18n.visualization}</p>
              <div className='grid grid-cols-4 mb-6'>
                <div></div>
                <span className='justify-self-start w-28 text-sm font-medium text-left text-black'>
                  {localeI18n.average}*
                </span>
                <span className='justify-self-center w-28 text-sm font-medium text-left text-black'>
                  {localeI18n.std}
                </span>
                <span className='justify-self-end w-28 text-sm font-medium text-left text-black'>
                  {localeI18n.variation}
                </span>
              </div>
              {visualisationRows.map((r) => (
                <div className='grid grid-cols-4 mb-6' key={`${angle}.${r}`}>
                  <div className='mr-4'>
                    <span className='text-sm font-medium text-left text-black'>{localeI18n[r]}</span>
                    <InputError errors={flexionError ? flexionError[r] : null} />
                  </div>
                  <div className='justify-self-start'>
                    <Controller
                      control={control}
                      defaultValue={null}
                      name={`${r}.average`}
                      render={({ field }) => (
                        <UnderlinedNumberInput
                          className='w-24'
                          errors={flexionError ? flexionError[`${r}.average`] : null}
                          onChange={field.onChange}
                          placeholder=''
                          value={field.value}
                        />
                      )}
                    />
                  </div>
                  <div className='justify-self-center'>
                    <Controller
                      control={control}
                      defaultValue={null}
                      name={`${r}.std`}
                      render={({ field }) => (
                        <UnderlinedNumberInput
                          className='w-24'
                          errors={flexionError ? flexionError[`${r}.std`] : null}
                          onChange={field.onChange}
                          placeholder=''
                          value={field.value}
                        />
                      )}
                    />
                  </div>
                  <div className='justify-self-end'>
                    <Controller
                      control={control}
                      defaultValue={null}
                      name={`${r}.variation`}
                      render={({ field }) => (
                        <UnderlinedNumberInput
                          className='w-24'
                          errors={flexionError ? flexionError[`${r}.variation`] : null}
                          onChange={field.onChange}
                          placeholder=''
                          value={field.value}
                        />
                      )}
                    />
                  </div>
                </div>
              ))}
            </div>
            <div className='flex flex-row justify-center mt-10'>
              <ButtonSecondary className='px-14 mr-40' onClick={() => resetForm()} type='button'>
                Annuler
              </ButtonSecondary>
              <ButtonPrimary className='px-14' disabled={!canSubmit} type='submit'>
                Valider
              </ButtonPrimary>
            </div>
          </div>
        </div>
      </form>
      <TaskOverwriteModal
        experimentId={experimentId}
        files={taskFiles}
        mutationFn={(payload: Dto<ExperimentFlexionTaskDto>) => postExperimentFlexionTask(experimentId, angle, payload)}
        onClose={() => setTaskModal(false)}
        onTaskIdChange={setTaskId}
        open={taskModal}
      />
    </>
  )
}

export { FlexionAngleForm, visualisationRows }
