import { SvgClose } from 'assets/svg'
import { FormEvent, forwardRef } from 'react'
import { InputType, TextInputProps } from 'ui/molecules/TextInput'
import { InputError } from './InputError'
import { SvgInjector } from './SvgInjector'
import { Nullable } from 'interfaces'

interface NumberInputProps {
  scientist?: boolean
  disabled?: boolean
  className?: string
  placeholder?: string
  hint?: string
  name?: string
  readOnly?: boolean
  label?: string
  errors: Nullable<string[]>
  required?: boolean
  onChange?: (value: Nullable<number>) => void
  type?: InputType
  value?: Nullable<number>
}

const UnderlinedInput = forwardRef<HTMLInputElement, TextInputProps>(
  (
    {
      name = '',
      className = 'w-full',
      type = InputType.TEXT,
      value,
      onChange = () => null,
      errors,
      placeholder = '',
      disabled = false,
      readOnly = false,
      tabIndex = 0
    },
    ref
  ) => (
    <div className={`flex flex-col`}>
      <input
        className={`${className} disabled:opacity-50 disabled:cursor-not-allowed bg-transparent pb-2 text-sm text-warm-grey border-b outline-none h-8 ${
          errors && errors[0] ? 'border-b-[red] ' : 'border-b-pinkish-grey '
        }`}
        data-testid='debounced-input'
        disabled={disabled}
        name={name}
        onChange={(e: FormEvent<HTMLInputElement>) => onChange(e.currentTarget.value)}
        placeholder={placeholder}
        readOnly={readOnly}
        ref={ref}
        tabIndex={tabIndex}
        type={type as string}
        value={value ?? ''}
      />
      {errors && <InputError errors={errors} />}
    </div>
  )
)
const UnderlinedTextarea = forwardRef<HTMLTextAreaElement, TextInputProps>(
  ({ name = '', className = '', value, onChange = () => null, errors, placeholder = '', disabled = false }, ref) => (
    <div className='flex flex-col flex-1'>
      <textarea
        className={`${className} disabled:opacity-50 disabled:cursor-not-allowed ${
          errors && errors[0] ? 'border-[red] ' : 'border-pinkish-grey '
        }pb-2 text-sm text-warm-grey border outline-none h-32 w-inherit`}
        data-testid='debounced-input'
        disabled={disabled}
        name={name}
        onChange={(e: FormEvent<HTMLTextAreaElement>) => onChange(e.currentTarget.value)}
        placeholder={placeholder}
        ref={ref}
        value={value ?? ''}
      />
      {errors && <InputError errors={errors} />}
    </div>
  )
)

const UnderlinedNumberInput = forwardRef<HTMLInputElement, NumberInputProps>(
  (
    {
      name = '',
      className = 'w-full',
      value,
      onChange = () => null,
      errors,
      placeholder = '',
      disabled,
      readOnly = false
    },
    ref
  ) => {
    const handleDeleteValue = () => {
      onChange(null)
    }

    return (
      <div className='flex relative flex-col'>
        <div className='flex'>
          <input
            className={` ${className} text-left disabled:bg-pale-grey pb-2 bg-transparent text-sm text-warm-grey border-b outline-none h-8
             ${errors && errors[0] ? 'border-b-[red] ' : 'border-b-pinkish-grey '}`}
            data-testid='debounced-input'
            disabled={disabled}
            name={name}
            onChange={(e: FormEvent<HTMLInputElement>) =>
              onChange(e.currentTarget.value ? Number(e.currentTarget.value) : null)
            }
            placeholder={placeholder}
            readOnly={readOnly}
            ref={ref}
            type='number'
            value={value ?? ''}
          />
          {!readOnly && !disabled && !!value && (
            <button className='absolute right-4 mt-1 w-4 h-4' onClick={handleDeleteValue} type='button'>
              <SvgInjector className='m-auto w-2.5 h-2.5 text-warm-grey' src={SvgClose} />
            </button>
          )}
        </div>
        {errors && <InputError errors={errors} />}
      </div>
    )
  }
)

export { UnderlinedInput, UnderlinedNumberInput, UnderlinedTextarea }
