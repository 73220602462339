import { SvgCopy, SvgEdit, SvgRecherche, SvgTrash } from 'assets/svg'
import { LabContext } from 'modules/lab/contexts'
import { getExperiment as getExperimentSchema, getExperiments as getExperimentsSchema } from 'modules/lab/schemas'
import { FC, ReactNode, useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import { SvgInjector } from 'ui/atoms/SvgInjector'
import { z } from 'zod'
import { DMATd } from './DMATd'
import { EosinTd } from './EosinTd'
import { FireTestTd } from './FireTestTd'
import { FormulaTd } from './FormulaTd'
import { GeneralTd } from './GeneralTd'
import { ProcessParameterTd } from './ProcessParameterTd'
import { ReinforcementTd } from './ReinforcementTd'
import { ResidualMonomerTd } from './ResidualMonomerTd'
import { ThermocoupleTd } from './ThermocoupleTd'
import { TractionFlexionCompressionTd } from './TractionFlexionCompressionTd'
import { ViscosityTd } from './ViscosityTd'
import { i18n as localeI18n } from '../../../i18n'
import { Experiment } from 'modules/lab/types'
import { useMutation, useQueryClient } from 'react-query'
import { deleteExperiment, getConfigurations, getExperiment } from 'modules/lab/services'
import { UiContext } from 'contexts/ui.context'
import { ToastType } from 'core/constants'
import { ButtonPrimary, ButtonSecondary } from 'ui/atoms'
import { DuplicateModal } from './DuplicateModal'
import { Configuration } from 'interfaces/api/lab'
import { powerBiUrl } from 'config'
import { FiberRateTd } from './FiberRateTd'

type Obj = Omit<z.infer<typeof getExperimentSchema>, 'id'>
type PropType<T, K extends keyof T> = T[K]

const TableTopTh: FC<{ className?: string; children: ReactNode; colSpan: number }> = ({
  className = '',
  children,
  colSpan
}) => (
  <th
    className={`sticky top-0 left-0 z-30 px-2 h-5 text-[0.625em] font-normal text-left text-dusk-blue bg-[#F4F9FC] rounded-t-md ${className}`}
    colSpan={colSpan}
  >
    {children}
  </th>
)
const TableTh: FC<{ className?: string; children: ReactNode }> = ({ className = '', children }) => (
  <th className={`sticky top-5 h-7 text-sm font-normal text-warm-grey bg-[#CADEF1] px-5 ${className}`}>{children}</th>
)
const TableTd: FC<{ className?: string; children: ReactNode }> = ({ className = '', children }) => (
  <td className={`bg-white h-14 z-10 px-5 text-black font-normal text-sm ${className}`}>{children}</td>
)

const ExperimentsTable: FC<{ className?: string; experiments: z.infer<typeof getExperimentsSchema> }> = ({
  className = '',
  experiments
}) => {
  const { updateToast, updateModal } = useContext(UiContext)
  const { displayMatrix } = useContext(LabContext)
  const navigate = useNavigate()
  const queryClient = useQueryClient()

  const tableTopTitles: { colSpan: number; title: string; key: string }[] = Object.keys(displayMatrix)
    .filter((k) => k !== 'id')
    .map((k) => {
      const child = displayMatrix[k]
      return {
        colSpan: Object.keys(child).filter((ck) => (child instanceof Object ? child[ck] : child)).length,
        title: localeI18n.experiments.customDisplay.table.titles[k as keyof Obj],
        key: k
      }
    })

  const isDisplayed = ({ key }: { key: string }): boolean => {
    if (key === 'general') {
      return true
    }
    const child = displayMatrix[key]
    if (!(child instanceof Object)) {
      return child
    }
    for (const k in child) {
      if (child[k]) {
        return true
      }
    }
    return false
  }

  const tableTitles = tableTopTitles.flatMap(({ key }) => {
    const child = displayMatrix[key]
    if (!(child instanceof Object)) {
      return []
    }
    const parentKey = key as keyof Obj
    return Object.keys(child)
      .filter((k) => (k !== 'reference' ? child[k] : true))
      .map((ck) => {
        const childKey = ck as keyof PropType<Obj, typeof parentKey>
        switch (parentKey) {
          case 'traction_0':
          case 'traction_90':
          case 'compression_0':
          case 'compression_90':
          case 'flexion_0':
          case 'flexion_90':
            return localeI18n.experiments.customDisplay.table.tcf[childKey]
          case 'dynamic_mechanical_analyses_ramp_1':
          case 'dynamic_mechanical_analyses_ramp_2':
            return localeI18n.experiments.customDisplay.table.dma[childKey]
          default:
            return localeI18n.experiments.customDisplay.table[parentKey][childKey]
        }
      })
  })

  const handleEditExperiment = (e: Experiment) => {
    navigate(`/experiments/${e.id}`)
  }
  const getExperimentConfiguration = async (id: number): Promise<Configuration | undefined> => {
    const [experiment, configurations] = await Promise.all([getExperiment(`${id}`), getConfigurations()])
    if (!experiment.data || !configurations.data) {
      throw new Error('getExperimentConfiguration fetch failed')
    }
    return configurations.data.find((c) => c.id === experiment.data?.configuration_id)
  }
  const experimentConfiguration = useMutation(getExperimentConfiguration, {
    onSuccess: (data, id) => {
      if (!data) {
        updateToast({
          content: 'Aucune configuration disponible',
          displayed: true,
          type: ToastType.WARNING
        })
        return
      }
      updateModal({
        content: <DuplicateModal configuration={data} id={id} />,
        displayed: true
      })
    },
    onError: () => {
      updateToast({
        content: 'Erreur lors du chargement de la configuration',
        displayed: true,
        type: ToastType.ERROR
      })
    }
  })

  const handleCopyExperiment = (e: Experiment) => {
    experimentConfiguration.mutate(e.id)
  }

  const experimentDeletion = useMutation(deleteExperiment, {
    onSuccess: () => {
      updateToast({
        content: 'Essai supprimé avec succès',
        displayed: true,
        type: ToastType.SUCCESS
      })
      queryClient.invalidateQueries('lab.experiments')
    },
    onError: () => {
      updateToast({
        content: "Erreur lors de la suppression de l'essai",
        displayed: true,
        type: ToastType.ERROR
      })
    }
  })
  const DeleteModal: FC<{ id: number }> = ({ id }) => {
    const handleModalDeletion = () => {
      updateModal({
        content: null,
        displayed: false
      })
      experimentDeletion.mutate(`${id}`)
    }
    const handleModalCancellation = () => {
      updateModal({
        content: null,
        displayed: false
      })
    }
    return (
      <div className='px-8 pt-6 pb-8'>
        <p className='mr-12 text-xl font-medium text-black'>Êtes-vous sûr de vouloir supprimer cet essai ?</p>
        <div className='flex flex-row justify-between m-auto w-80'>
          <ButtonSecondary className='mt-8' onClick={handleModalCancellation} type='button'>
            Annuler
          </ButtonSecondary>
          <ButtonPrimary className='mt-8' onClick={handleModalDeletion} type='button'>
            Supprimer
          </ButtonPrimary>
        </div>
      </div>
    )
  }
  const handleDeleteExperiment = (e: Experiment) => {
    updateModal({
      content: <DeleteModal id={e.id} />,
      displayed: true
    })
  }
  const handleSearchExperiment = (e: Experiment) => {
    const filter_simple = `v_datas_experiment_name/experiment_name eq '${e.general.reference}'`
    const filters = `${filter_simple}`
    const url = `${powerBiUrl}&filter=${filters}`
    window.open(url, '_blank')?.focus()
  }

  return (
    <div className={`flex overflow-scroll w-full max-h-full ${className}`}>
      <table className={`${className} whitespace-nowrap table-fixed`}>
        <thead>
          <tr>
            {tableTopTitles.filter(isDisplayed).map((t) => (
              <TableTopTh colSpan={t.colSpan} key={t.key}>
                {t.title}
              </TableTopTh>
            ))}
          </tr>
          <tr className='bg-[#CADEF1]'>
            {tableTitles.map((ck, k) =>
              k === 0 ? (
                <TableTh
                  className="after:absolute after:top-0 after:-right-5 left-0 z-30 after:w-5 after:h-7 text-left after:content-[''] after:bg-gradient-to-l after:from-transparent after:to-[#CADEF1]"
                  key={k}
                >
                  {ck}
                </TableTh>
              ) : (
                <TableTh className='z-20 text-left' key={k}>
                  {ck}
                </TableTh>
              )
            )}
            <TableTh className="before:absolute before:top-0 right-0 before:-left-5 z-30 before:w-5 before:h-7 before:content-[''] before:bg-gradient-to-r before:from-transparent before:to-[#CADEF1]">
              Actions
            </TableTh>
          </tr>
        </thead>
        <tbody>
          {experiments.map((v) => (
            <tr className='border-b-2 border-ice-blue' key={v.id}>
              {/* General */}
              <GeneralTd data={v.general} id={v.id} matrix={displayMatrix.general} />
              {/* Formula */}
              {displayMatrix.formula instanceof Object && (
                <FormulaTd data={v.formula} matrix={displayMatrix.formula} reference={v.general.reference} />
              )}
              {/* Viscosity */}
              {displayMatrix.viscosity instanceof Object && (
                <ViscosityTd data={v.viscosity} matrix={displayMatrix.viscosity} />
              )}
              {/* Reinforcement */}
              {displayMatrix.reinforcement instanceof Object && (
                <ReinforcementTd
                  data={v.reinforcement}
                  matrix={displayMatrix.reinforcement}
                  reference={v.general.reference}
                />
              )}
              {/* Process Parameter */}
              {displayMatrix.process_parameter instanceof Object && (
                <ProcessParameterTd
                  data={v.process_parameter}
                  matrix={displayMatrix.process_parameter}
                  reference={v.general.reference}
                />
              )}
              {/* Traction 0 */}
              {displayMatrix.traction_0 instanceof Object && (
                <TractionFlexionCompressionTd data={v.traction_0} matrix={displayMatrix.traction_0} />
              )}
              {/* Traction 90 */}
              {displayMatrix.traction_90 instanceof Object && (
                <TractionFlexionCompressionTd data={v.traction_90} matrix={displayMatrix.traction_90} />
              )}
              {/* Compression 0 */}
              {displayMatrix.compression_0 instanceof Object && (
                <TractionFlexionCompressionTd data={v.compression_0} matrix={displayMatrix.compression_0} />
              )}
              {/* Compression 90 */}
              {displayMatrix.compression_90 instanceof Object && (
                <TractionFlexionCompressionTd data={v.compression_90} matrix={displayMatrix.compression_90} />
              )}
              {/* Flexion 0 */}
              {displayMatrix.flexion_0 instanceof Object && (
                <TractionFlexionCompressionTd data={v.flexion_0} matrix={displayMatrix.flexion_0} />
              )}
              {/* Flexion 90 */}
              {displayMatrix.flexion_90 instanceof Object && (
                <TractionFlexionCompressionTd data={v.flexion_90} matrix={displayMatrix.flexion_90} />
              )}
              {/* Thermocouple */}
              {displayMatrix.thermocouple instanceof Object && (
                <ThermocoupleTd data={v.thermocouple} matrix={displayMatrix.thermocouple} />
              )}
              {/* DMA 1 */}
              {displayMatrix.dynamic_mechanical_analyses_ramp_1 instanceof Object && (
                <DMATd
                  data={v.dynamic_mechanical_analyses_ramp_1}
                  matrix={displayMatrix.dynamic_mechanical_analyses_ramp_1}
                />
              )}
              {/* DMA 2 */}
              {displayMatrix.dynamic_mechanical_analyses_ramp_2 instanceof Object && (
                <DMATd
                  data={v.dynamic_mechanical_analyses_ramp_2}
                  matrix={displayMatrix.dynamic_mechanical_analyses_ramp_2}
                />
              )}
              {/* Eosine */}
              {displayMatrix.eosin instanceof Object && <EosinTd data={v.eosin} matrix={displayMatrix.eosin} />}
              {/* TVF/TP */}
              {displayMatrix.fiber_rate instanceof Object && (
                <FiberRateTd data={v.fiber_rate} matrix={displayMatrix.fiber_rate} />
              )}
              {/* Test a feu */}
              {displayMatrix.fire_test instanceof Object && (
                <FireTestTd data={v.fire_test} matrix={displayMatrix.fire_test} />
              )}
              {/* Monomère résiduel */}
              {displayMatrix.residual_monomer instanceof Object && (
                <ResidualMonomerTd data={v.residual_monomer} matrix={displayMatrix.residual_monomer} />
              )}
              <TableTd className="sticky before:absolute before:top-0.5 right-0 before:-left-5 before:w-5 before:h-12 before:content-[''] before:bg-gradient-to-r before:from-transparent before:to-white">
                <button onClick={() => handleEditExperiment(v)} type='button'>
                  <SvgInjector className='inline-block mr-2 w-3.5 h-3.5 text-warm-grey' src={SvgEdit} />
                </button>
                <button onClick={() => handleCopyExperiment(v)} type='button'>
                  <SvgInjector className='inline-block mr-2 w-4 h-4  text-warm-grey' src={SvgCopy} />
                </button>
                <button onClick={() => handleDeleteExperiment(v)} type='button'>
                  <SvgInjector className='inline-block mr-2 w-3.5 h-3.5  text-warm-grey' src={SvgTrash} />
                </button>
                <button onClick={() => handleSearchExperiment(v)} type='button'>
                  <SvgInjector className='inline-block w-3.5 h-3.5 text-warm-grey' src={SvgRecherche} />
                </button>
              </TableTd>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}

export { ExperimentsTable }
