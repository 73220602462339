import { FC } from 'react'

export const Spinner: FC<{ fixed?: boolean; className?: string; stroke?: string }> = ({
  fixed = false,
  className = 'text-dusty-teal',
  stroke = '#000'
}) => (
  <svg className={className} fill='none' viewBox='0 0 66 66'>
    <circle cx='33' cy='33' fill='none' r='28' stroke={stroke} strokeWidth={8} />
    {!fixed && (
      <circle
        className='animate-[spinners-react-circular-fixed_linear_infinite_1s]'
        cx='33'
        cy='33'
        fill='none'
        r='28'
        stroke='currentColor'
        strokeDasharray='40, 134'
        strokeDashoffset='325'
        strokeLinecap='round'
        strokeWidth={9}
      />
    )}
  </svg>
)
