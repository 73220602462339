import { UiContext } from 'contexts/ui.context'
import { ToastType } from 'core/constants'
import { i18n } from 'i18n'
import { ErrorObj, Extensions, LiteMedia, Nullable } from 'interfaces'
import { ExperimentThermocouple, ExperimentThermocoupleDto, ExperimentThermocoupleTaskDto } from 'interfaces/api/lab'
import { Task } from 'interfaces/api/task'
import { FileAndTaskManagement } from 'modules/files'
import {
  getExperimentThermocouple,
  postExperimentThermocouple,
  postExperimentThermocoupleTask,
  putExperimentThermocouple
} from 'modules/lab/services'
import { Dto } from 'modules/list-parameters/services/list-parameters.dto'
import { forwardRef, memo, useContext, useState } from 'react'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { useParams } from 'react-router-dom'

import { ButtonPrimary, ButtonSecondary, InputError, Panel } from 'ui/atoms'
import { Field } from 'ui/atoms/Field'
import { UnderlinedNumberInput } from 'ui/atoms/UnderLinedInput'
import { SpinnerLoaderMask } from 'ui/molecules'
import { TaskOverwriteModal } from '../../shared/TaskOverwriteModal'
import { ThermocoupleFormValues } from '../ProductionDataTab.type'

const ThermocouplePanel = memo(
  forwardRef<HTMLFormElement>((_, ref) => {
    const { experimentId } = useParams()

    if (!experimentId) {
      return null
    }

    const [taskId, setTaskId] = useState<Nullable<number>>(null)
    const [canSubmit, setCanSubmit] = useState<boolean>(false)
    const [taskModal, setTaskModal] = useState<boolean>(false)
    const [taskFiles, setTaskFiles] = useState<LiteMedia[]>([])
    const [thermocoupleError, setThermocoupleError] = useState<Nullable<ErrorObj<ExperimentThermocoupleDto>>>(null)
    const { updateToast } = useContext(UiContext)
    const queryClient = useQueryClient()
    const { control, reset, handleSubmit, getValues } = useForm<ThermocoupleFormValues>()

    const get = useQuery(
      ['lab.experiment.thermocouple', experimentId],
      async () => await getExperimentThermocouple(experimentId),
      {
        onSuccess: ({ data }) => {
          resetForm(data)
        }
      }
    )

    const post = useMutation((data: Dto<ExperimentThermocoupleDto>) => postExperimentThermocouple(experimentId, data), {
      onMutate: () => {
        setThermocoupleError(null)
      },
      onSuccess: (data) => {
        queryClient.setQueryData(['lab.experiment.thermocouple', experimentId], data)
        updateToast({
          content: 'Essai crée avec succès',
          displayed: true,
          type: ToastType.SUCCESS
        })
      },
      onError: (e: Error & { errors?: ErrorObj<ExperimentThermocoupleDto> }) => {
        setThermocoupleError(e.errors ?? null)
        updateToast({
          content: e.message ?? 'Une erreur est survenue',
          displayed: true,
          type: ToastType.ERROR
        })
      }
    })
    const put = useMutation((data: Dto<ExperimentThermocoupleDto>) => putExperimentThermocouple(experimentId, data), {
      onMutate: () => {
        setThermocoupleError(null)
      },
      onSuccess: (data) => {
        queryClient.setQueryData(['lab.experiment.thermocouple', experimentId], data)
        updateToast({
          content: 'Essai crée avec succès',
          displayed: true,
          type: ToastType.SUCCESS
        })
      },
      onError: (e: Error & { errors?: ErrorObj<ExperimentThermocoupleDto> }) => {
        setThermocoupleError(e.errors ?? null)
        updateToast({
          content: e.message ?? 'Une erreur est survenue',
          displayed: true,
          type: ToastType.ERROR
        })
      }
    })

    const handleOnSubmit: SubmitHandler<ThermocoupleFormValues> = (payload) => {
      get.data?.data ? put.mutate(payload) : post.mutate(payload)
    }

    const resetForm = (data?: Nullable<ExperimentThermocouple>) => {
      data ? reset({ ...data, file: data.file ? [data.file] : [] }) : reset()
    }

    const handleCreateTask = (files: LiteMedia[]) => {
      setTaskModal(true)
      setTaskFiles(files)
    }

    const handleTaskCreationDone = (data: Task) => {
      setTaskModal(false)
      if (!data.results) {
        return
      }
      reset({ ...getValues(), ...data.results })
    }

    return (
      <>
        <form onSubmit={handleSubmit(handleOnSubmit)} ref={ref}>
          <Panel
            openable={true}
            title='Thermocouple'
            // topLink={
            //   <a href={void 0} className="inline-flex flex-row font-medium text-dusk-blue underline cursor-pointer">
            //     <SvgInjector className="mr-2 w-4.5 h-4.5 text-dusk-blue" src={SvgVisualisation} />
            //     Visualiser le graphique
            //   </a>
            // }
          >
            <SpinnerLoaderMask
              message={get.isFetching ? i18n.isLoading : i18n.isSaving}
              spinning={get.isFetching || post.isLoading || put.isLoading}
            >
              <div className='flex flex-col items-center'>
                <Controller
                  control={control}
                  defaultValue={[]}
                  name='file'
                  render={({ field }) => (
                    <div className='flex flex-col'>
                      <Field className='' label='Importer un fichier'>
                        <FileAndTaskManagement
                          accept={[Extensions.CSV]}
                          onChange={field.onChange}
                          onCreateTask={handleCreateTask}
                          onReadyToSubmitChange={setCanSubmit}
                          onTaskPollingEnd={handleTaskCreationDone}
                          taskId={taskId}
                          value={field.value}
                        />
                        <InputError errors={thermocoupleError?.file ?? null} />
                      </Field>
                    </div>
                  )}
                />
                <div className='flex flex-row justify-between my-4 w-full'>
                  <Field className='justify-self-start' label='Température au pic (°C)'>
                    <Controller
                      control={control}
                      name='peak_temperature'
                      render={({ field }) => (
                        <UnderlinedNumberInput
                          className='w-48'
                          errors={thermocoupleError?.peak_temperature ?? null}
                          onChange={field.onChange}
                          value={field.value}
                        />
                      )}
                    />
                  </Field>
                  <Field className='justify-self-start' label='Temps au pic (sec)'>
                    <Controller
                      control={control}
                      name='peak_time'
                      render={({ field }) => (
                        <UnderlinedNumberInput
                          className='w-48'
                          errors={thermocoupleError?.peak_time ?? null}
                          onChange={field.onChange}
                          value={field.value}
                        />
                      )}
                    />
                  </Field>
                  <Field className='justify-self-start' label='Distance au pic (cm)'>
                    <Controller
                      control={control}
                      name='peak_distance'
                      render={({ field }) => (
                        <UnderlinedNumberInput
                          className='w-48'
                          errors={thermocoupleError?.peak_distance ?? null}
                          onChange={field.onChange}
                          value={field.value}
                        />
                      )}
                    />
                  </Field>
                </div>
                <div className='flex flex-row justify-center mt-10'>
                  <ButtonSecondary className='px-14 mr-40' onClick={() => resetForm(get.data?.data)} type='button'>
                    Annuler
                  </ButtonSecondary>
                  <ButtonPrimary className='px-14' disabled={!canSubmit} type='submit'>
                    Valider
                  </ButtonPrimary>
                </div>
              </div>
            </SpinnerLoaderMask>
          </Panel>
        </form>
        <TaskOverwriteModal
          experimentId={experimentId}
          files={taskFiles}
          mutationFn={(payload: Dto<ExperimentThermocoupleTaskDto>) =>
            postExperimentThermocoupleTask(experimentId, payload)
          }
          onClose={() => setTaskModal(false)}
          onTaskIdChange={setTaskId}
          open={taskModal}
        />
      </>
    )
  })
)

export { ThermocouplePanel }
