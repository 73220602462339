import { Task, TaskPayload } from 'interfaces/api/task'
import { fetchJson } from 'utils/fetch'

/**
 *
 * @returns
 */
const postTasks = (task: TaskPayload) => {
  return fetchJson<Task, TaskPayload>('POST', `/tasks`, task)
}

/**
 *
 * @returns
 */
const getTask = (taskId: string) => {
  return fetchJson<Task>('GET', `/tasks/${taskId}`)
}

export { getTask, postTasks }
