import { FC, ReactNode } from 'react'
import Loader from 'assets/svg/loader.svg'

export const ButtonSecondary: FC<{
  className?: string
  type: 'button' | 'submit' | 'reset' | undefined
  onClick?: () => void
  isFetching?: boolean
  disabled?: boolean
  children: ReactNode
}> = ({ className = '', children, onClick, type, isFetching, disabled }) => {
  return (
    <button
      className={`${className} ${
        disabled || isFetching ? 'bg-cool-grey' : 'bg-white border-2 border-dusty-teal shadow-button'
      } py-1 px-6 font-semibold text-white rounded focus:shadow-transparent focus:translate-x-0.5 focus:translate-y-0.5 transition-all mb-1`}
      disabled={isFetching || disabled}
      onClick={onClick}
      type={type}
    >
      <div className='flex justify-center items-center'>
        <span className='text-warm-grey'>{children}</span>
        {isFetching && (
          <span>
            <img className='ml-2 h-[16px]' src={Loader} />
          </span>
        )}
      </div>
    </button>
  )
}
