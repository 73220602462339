import { UiContext } from 'contexts/ui.context'
import { ToastType } from 'core/constants'
import { i18n } from 'i18n'
import { ErrorObj, Extensions, Nullable } from 'interfaces'
import { ExperimentMicroscopy, ExperimentMicroscopyDto } from 'interfaces/api/lab'
import { FileManagement } from 'modules/files'
import { getExperimentMicroscopy, putExperimentMicroscopy, postExperimentMicroscopy } from 'modules/lab/services'
import { Dto } from 'modules/list-parameters/services/list-parameters.dto'
import { forwardRef, memo, useContext, useState } from 'react'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { useParams } from 'react-router-dom'

import { ButtonPrimary, ButtonSecondary, InputError, Panel } from 'ui/atoms'
import { Field } from 'ui/atoms/Field'

import { UnderlinedTextarea } from 'ui/atoms/UnderLinedInput'
import { SpinnerLoaderMask } from 'ui/molecules'
import { MicroscopyFormValues } from './ProductionDataTab.type'

const MicroscopyPanel = memo(
  forwardRef<HTMLFormElement>((_, ref) => {
    const { experimentId } = useParams()

    if (!experimentId) {
      return null
    }

    const [canSubmit, setCanSubmit] = useState<boolean>(false)
    const [thermocoupleError, setThermocoupleError] =
      useState<Nullable<Record<keyof ExperimentMicroscopyDto, string[]>>>(null)
    const { updateToast } = useContext(UiContext)
    const queryClient = useQueryClient()

    const { control, handleSubmit, reset } = useForm<MicroscopyFormValues>()

    const get = useQuery(
      ['lab.experiment.microscopy', experimentId],
      async () => await getExperimentMicroscopy(experimentId),
      {
        onSuccess: ({ data }) => {
          setCanSubmit(true)
          resetForm(data)
        }
      }
    )
    const resetForm = (data?: Nullable<ExperimentMicroscopy>) => {
      data ? reset(data) : reset()
    }

    const post = useMutation((data: Dto<ExperimentMicroscopyDto>) => postExperimentMicroscopy(experimentId, data), {
      onMutate: () => {
        setThermocoupleError(null)
      },
      onSuccess: (data) => {
        queryClient.setQueryData(['lab.experiment.microscopy', experimentId], data)
        updateToast({
          content: 'Microscopie créée avec succès',
          displayed: true,
          type: ToastType.SUCCESS
        })
      },
      onError: (e: Error & { errors?: ErrorObj<ExperimentMicroscopyDto> }) => {
        setThermocoupleError(e.errors ?? null)
        updateToast({
          content: e.message ?? 'Une erreur est survenue',
          displayed: true,
          type: ToastType.ERROR
        })
      }
    })
    const put = useMutation((data: Dto<ExperimentMicroscopyDto>) => putExperimentMicroscopy(experimentId, data), {
      onMutate: () => {
        setThermocoupleError(null)
      },
      onSuccess: (data) => {
        queryClient.setQueryData(['lab.experiment.microscopy', experimentId], data)
        updateToast({
          content: 'Microscopie mise à jour avec succès',
          displayed: true,
          type: ToastType.SUCCESS
        })
      },
      onError: (e: Error & { errors?: ErrorObj<ExperimentMicroscopyDto> }) => {
        setThermocoupleError(e.errors ?? null)
        updateToast({
          content: e.message ?? 'Une erreur est survenue',
          displayed: true,
          type: ToastType.ERROR
        })
      }
    })

    const handleOnSubmit: SubmitHandler<MicroscopyFormValues> = (payload) => {
      get.data?.data ? put.mutate(payload) : post.mutate(payload)
    }

    return (
      <form onSubmit={handleSubmit(handleOnSubmit)} ref={ref}>
        <Panel openable={true} title='Microscopie'>
          <SpinnerLoaderMask
            message={get.isFetching ? i18n.isLoading : i18n.isSaving}
            spinning={get.isFetching || post.isLoading || put.isLoading}
          >
            <div className='flex flex-col items-center'>
              <Controller
                control={control}
                defaultValue={[]}
                name='pictures'
                render={({ field }) => (
                  <div className='flex flex-col'>
                    <Field className='' label='Importer des fichiers'>
                      <FileManagement
                        accept={[Extensions.PNG, Extensions.JPEG]}
                        multiple={true}
                        onChange={field.onChange}
                        onReadyToSubmitChange={setCanSubmit}
                        value={field.value}
                      />
                      <InputError errors={thermocoupleError?.pictures ?? null} />
                    </Field>
                  </div>
                )}
              />
              <Field className='justify-self-start w-full' label='Commentaire'>
                <Controller
                  control={control}
                  name='comment'
                  render={({ field }) => (
                    <UnderlinedTextarea
                      errors={thermocoupleError?.comment ?? null}
                      onChange={field.onChange}
                      placeholder=''
                      value={field.value}
                    />
                  )}
                />
              </Field>
              <div className='flex flex-row justify-around mx-auto mt-10 w-full  max-w-2xl'>
                <ButtonSecondary className='px-14 mr-4' onClick={() => resetForm(get.data?.data)} type='button'>
                  Annuler
                </ButtonSecondary>
                <ButtonPrimary className='px-14' disabled={!canSubmit} type='submit'>
                  Valider
                </ButtonPrimary>
              </div>
            </div>
          </SpinnerLoaderMask>
        </Panel>
      </form>
    )
  })
)

export { MicroscopyPanel }
