import { FC } from 'react'
import { Tabs } from 'ui/molecules'
import { MainTemplate } from 'ui/templates'
import { BatchImport } from '../components'
import { i18n } from '../i18n'

const Title: FC = () => <h1 className='text-xl font-bold text-dark-blue'>{i18n.title}</h1>

const TestCampaign: FC = () => {
  const batchImportDataTab = {
    title: i18n.tabTitle,
    component: <BatchImport />
  }

  return (
    <MainTemplate className='overflow-y-auto' containerClassName='w-full' title={<Title />}>
      <Tabs className='flex flex-1 mx-auto mt-12' items={[batchImportDataTab]} />
    </MainTemplate>
  )
}

export { TestCampaign }
