import { Actions, State, Types } from 'stores/auth'

const reducers = (state: State, action: Actions): State => {
  switch (action.type) {
    case Types.UPDATE_USER_PROFILE:
      return {
        ...state,
        userProfile: action.payload
      }
    default:
      return state
  }
}
export { reducers }
