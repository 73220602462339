import { ExperimentIndexDto } from 'interfaces/api/lab'
import { FC } from 'react'
import { Control, Controller } from 'react-hook-form'
import { DualCheckboxInput, DualNumberInput, MultiSelectDropdown } from 'ui/molecules'

const ProcessParametersFilters: FC<{
  control: Control<ExperimentIndexDto>
}> = ({ control }) => {
  return (
    <div className='flex flex-col mt-5'>
      <details className='' open={true}>
        <summary className='py-1 px-2 text-xl font-semibold list-none text-black bg-ice-blue cursor-pointer'>
          Paramètres de process
        </summary>
        <div className='grid grid-cols-3 gap-8 py-5'>
          <Controller
            control={control}
            name='process_parameter.wire_count'
            render={({ field }) => (
              <DualNumberInput
                label='Nombre de fils'
                onChange={field.onChange}
                value={field.value ?? { min: null, max: null }}
              />
            )}
          />
          <Controller
            control={control}
            name='process_parameter.impregnation_modes'
            render={({ field }) => (
              <MultiSelectDropdown
                label="Mode d'impregnation"
                onChange={(values) => field.onChange(values.map((v) => ['injection', 'bath'][v]))}
                options={[
                  { value: 0, label: 'Injection' },
                  { value: 1, label: 'Bain' }
                ]}
                value={(field.value ?? []).map((v) => ({ injection: 0, bath: 1 }[v]))}
              />
            )}
          />
          <Controller
            control={control}
            name='process_parameter.has_ovens'
            render={({ field }) => <DualCheckboxInput label='Fours' onChange={field.onChange} value={field.value} />}
          />
          <Controller
            control={control}
            name='process_parameter.is_stable'
            render={({ field }) => (
              <DualCheckboxInput label='Stabilité du procédé' onChange={field.onChange} value={field.value} />
            )}
          />
        </div>
      </details>
    </div>
  )
}

export { ProcessParametersFilters }
