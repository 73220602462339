import { FC, useState } from 'react'
import { InputError, InputLabel } from 'ui/atoms'
import PasswordHidden from 'assets/svg/password-hidden.svg'
import PasswordVisible from 'assets/svg/password-visible.svg'
import { Nullable } from 'interfaces'

export const PasswordInput: FC<{
  min?: number
  className?: string
  placeholder?: string
  hint?: string
  name: string
  value: Nullable<string>
  onChange: (value: Nullable<string>) => void
  label: string
  errors: Nullable<string[]>
  required?: boolean
}> = ({ min, className, name, placeholder, value, onChange, label, hint, errors, required }) => {
  const [isVisible, setIsVisible] = useState(false)
  return (
    <div className={'relative flex flex-col flex-1 ' + className}>
      <InputLabel className='mb-3' hint={hint} htmlFor={name} label={label} />
      <input
        className={
          'flex w-full py-1 base-input border-b text-grey text-sm border-b-warm-grey outline-none' +
          (errors ? ' input-error' : '')
        }
        id={name}
        min={min}
        name={name}
        onChange={(e) => onChange(e.currentTarget.value)}
        placeholder={placeholder}
        required={required}
        type={isVisible ? 'text' : 'password'}
        value={value ?? ''}
      />
      <img
        className='absolute top-8 right-2 p-2 w-10 h-10 cursor-pointer'
        onClick={() => setIsVisible(!isVisible)}
        src={isVisible ? PasswordHidden : PasswordVisible}
      />
      <div className='mb-4'>
        <InputError errors={errors} />
      </div>
    </div>
  )
}
