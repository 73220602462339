import { SvgCreate, SvgTrash } from 'assets/svg'
import { UiContext } from 'contexts/ui.context'
import { DotNestedKeys, ErrorObj, Nullable, ToastProps } from 'interfaces'
import {
  ExperimentProcessParameters,
  ExperimentProcessParameterDto,
  ExperimentInjectionTemperature
} from 'interfaces/api/lab'
import { getManagementConfigurationDetail } from 'modules/configuration/services'
import { FileManagement } from 'modules/files'
import { getExperiment } from 'modules/lab/services'
import {
  getExperimentProcessParameters,
  postExperimentProcessParameters,
  putExperimentProcessParameters
} from 'modules/lab/services/experiment/processParameters'
import { FormEvent, forwardRef, useContext, useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { useParams } from 'react-router-dom'
import { ButtonPrimary, ButtonSecondary, InputError, Panel } from 'ui/atoms'
import { Field } from 'ui/atoms/Field'
import { InputRadio } from 'ui/atoms/InputRadio'
import { SwitchInput } from 'ui/atoms/SwitchInput'
import { UnderlinedNumberInput } from 'ui/atoms/UnderLinedInput'
import { SpinnerLoaderMask } from 'ui/molecules'

const ProcessParametersPanel = forwardRef<HTMLFormElement>((_, ref) => {
  const { control, reset, watch, setValue, getValues } = useForm<ExperimentProcessParameterDto>()
  const { experimentId = '' } = useParams()

  const [errors, setErrors] = useState<Nullable<Record<string, string[]>>>(null)
  const { updateToast } = useContext(UiContext)

  const resetForm = (data: Nullable<ExperimentProcessParameters>, oven_count: number) => {
    if (data === null) {
      reset({
        impregnation: {
          impregnation_temperature: null,
          injection_temperatures: []
        },
        oven_temperatures: Array(oven_count).fill(undefined),
        is_stable: false,
        area_temperatures: Array(4).fill(undefined),
        wire_count: null,
        conformation: {
          mode: 'conduction',
          is_active: false,
          temperature: null
        }
      })
      return
    }
    reset(data)
  }

  const experimentQuery = useQuery(['lab.experiment', experimentId], async () => await getExperiment(experimentId))
  const configurationId = experimentQuery.data?.data?.configuration_id

  const configurationQuery = useQuery(
    ['lab.configuration-detail', configurationId],
    async () => await getManagementConfigurationDetail(configurationId?.toString() ?? '')
  )
  const ovenCount = configurationQuery.data?.data?.oven_count

  const query = useQuery(
    ['lab.process-parameters', experimentId],
    async () => await getExperimentProcessParameters(experimentId)
  )

  const queryClient = useQueryClient()
  const reinforcementUpdate = useMutation(
    (payload: ExperimentProcessParameterDto) =>
      query.data?.data !== null
        ? putExperimentProcessParameters(experimentId, payload)
        : postExperimentProcessParameters(experimentId, payload),
    {
      onMutate: () => {
        setErrors(null)
      },
      onError: (e: { errors: Nullable<ErrorObj<ExperimentProcessParameterDto>> }) => {
        updateToast({
          displayed: true,
          type: 'error',
          content: "Les modifications n'ont pas pu être enregistrées."
        } as ToastProps)
        setErrors(e.errors ?? null)
      },
      onSuccess: () => {
        queryClient.invalidateQueries('lab.process-parameters')
        updateToast({
          displayed: true,
          type: 'success',
          content: 'Les modifications ont bien été enregistrées.'
        } as ToastProps)
      }
    }
  )

  const isLoading = [query.isFetching, configurationQuery.isFetching, reinforcementUpdate.isLoading].includes(true)

  const handleOnSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    reinforcementUpdate.mutate(getValues())
  }

  const handleResetForm = () => {
    resetForm(query.data?.data as ExperimentProcessParameters, configurationQuery.data?.data?.oven_count ?? 0)
    updateToast({
      displayed: true,
      type: 'error',
      content: 'Les modifications on été annulées.'
    } as ToastProps)
  }

  const getError = (
    name: DotNestedKeys<ExperimentProcessParameterDto> | 'impregnation.injection_temperatures'
  ): Nullable<string[]> => (errors ? errors[name] : null)

  const newInjectionTemperature = (order: number): ExperimentInjectionTemperature => ({
    order,
    debit: null,
    pressure: null
  })

  const handleAddRow = () => {
    setValue('impregnation.injection_temperatures', [
      ...getValues('impregnation.injection_temperatures'),
      newInjectionTemperature(getValues('impregnation.injection_temperatures').length)
    ])
  }

  const handleDeleteRow = (order: number) => {
    const filtered = getValues('impregnation.injection_temperatures')
      .filter((r: ExperimentInjectionTemperature) => order !== r.order)
      .map((r: ExperimentInjectionTemperature, index: number) => {
        r.order = index
        return r
      })
    setValue('impregnation.injection_temperatures', filtered)
  }

  useEffect(() => {
    resetForm(query.data?.data ?? null, ovenCount ?? 0)
  }, [query.data, configurationQuery.data, experimentQuery.data])

  return (
    <form onSubmit={handleOnSubmit} ref={ref}>
      <Panel title='Paramètres de process'>
        <SpinnerLoaderMask spinning={isLoading}>
          <div className='grid grid-cols-4 gap-10 mb-10 w-full'>
            <Field className='col-span-4' label='Nombre de fils'>
              <Controller
                control={control}
                name='wire_count'
                render={({ field }) => (
                  <UnderlinedNumberInput
                    className='w-36'
                    errors={getError('wire_count')}
                    onChange={field.onChange}
                    value={field.value}
                  />
                )}
              />
            </Field>
          </div>
          <Field className='flex flex-col col-span-4 mb-10' label='Température de zone (°c)'>
            <div className='grid grid-cols-4 gap-10 w-full'>
              {(watch('area_temperatures') ?? Array(4).fill(null)).map((v: Nullable<number>, index) => (
                <Controller
                  control={control}
                  key={`area-${index}`}
                  name={`area_temperatures.${index}`}
                  render={({ field }) => (
                    <div className='flex flex-col' key={'oven-' + index}>
                      <div className='mb-3 text-sm font-medium'>Zone {index + 1}</div>
                      <UnderlinedNumberInput
                        className='w-36'
                        errors={getError(`area_temperatures.${index}`)}
                        onChange={field.onChange}
                        value={v}
                      />
                    </div>
                  )}
                />
              ))}
            </div>
          </Field>
          <div className='grid grid-cols-4 gap-10 w-full'>
            <Field className='relative col-span-4' label='Bloc de conformation'>
              <div className='absolute top-0 left-48'>
                <Controller
                  control={control}
                  name='conformation.is_active'
                  render={({ field }) => <SwitchInput onChange={field.onChange} value={field.value} />}
                />
              </div>
              <div className='flex'>
                {watch('conformation.is_active') && (
                  <>
                    <div className='mr-5'>
                      <Controller
                        control={control}
                        name='conformation.mode'
                        render={({ field }) => (
                          <InputRadio
                            className='mr-4 cursor-default'
                            id='conduction'
                            label='Par conduction'
                            name={''}
                            onChange={field.onChange}
                            value={field.value}
                          />
                        )}
                      />
                    </div>
                    <div className='flex flex-col'>
                      <Controller
                        control={control}
                        name='conformation.mode'
                        render={({ field }) => (
                          <InputRadio
                            className='mr-4 cursor-default'
                            id='temperature'
                            label='Température (°c)'
                            name={''}
                            onChange={field.onChange}
                            value={field.value}
                          />
                        )}
                      />
                      {watch('conformation.mode') !== 'conduction' && (
                        <Controller
                          control={control}
                          name='conformation.temperature'
                          render={({ field }) => (
                            <UnderlinedNumberInput
                              className='w-36'
                              errors={getError('conformation.temperature')}
                              onChange={field.onChange}
                              value={field.value}
                            />
                          )}
                        />
                      )}
                    </div>
                  </>
                )}
              </div>
            </Field>
            {watch('oven_temperatures') && (
              <Field className='col-span-4 ' label='Utilisation de fours'>
                <div className='grid grid-cols-4 gap-10 w-full'>
                  {watch('oven_temperatures')?.map((v: number, index) => (
                    <Controller
                      control={control}
                      key={`oven_temperatures-${index}`}
                      name={`oven_temperatures.${index}`}
                      render={({ field }) => (
                        <div className='flex flex-col' key={'oven-' + index}>
                          <div className='mb-4 text-sm font-medium'>Température Four {index + 1}</div>
                          <UnderlinedNumberInput
                            className='w-36'
                            errors={getError(`oven_temperatures.${index}`)}
                            onChange={field.onChange}
                            value={v}
                          />
                        </div>
                      )}
                    />
                  ))}
                </div>
              </Field>
            )}
            <Field className='relative' label="Mode d'impregnation">
              <div className='flex'>
                {configurationQuery.data?.data && (
                  <div>
                    <div className='font-medium text-greyish-brown'>
                      {
                        { injection: 'Injection', bath: 'Bain' }[
                          configurationQuery?.data?.data?.impregnation?.mode ?? 'bath'
                        ]
                      }
                    </div>
                    {configurationQuery?.data?.data?.impregnation?.files && (
                      <div className='mt-14 w-40'>
                        <FileManagement
                          className='overflow-hidden w-40'
                          multiple={true}
                          onChange={() => null}
                          readonly={true}
                          value={configurationQuery?.data?.data?.impregnation?.files}
                        />
                      </div>
                    )}
                  </div>
                )}
              </div>
            </Field>
            <Field className='col-span-3' label={''}>
              <div className='flex flex-col mt-6'>
                <div className='mb-3 text-sm font-medium'>Température (°c)</div>
                <Controller
                  control={control}
                  name={`impregnation.impregnation_temperature`}
                  render={({ field }) => (
                    <UnderlinedNumberInput
                      errors={getError('impregnation.impregnation_temperature')}
                      onChange={field.onChange}
                      value={field.value}
                    />
                  )}
                />
              </div>
            </Field>
          </div>
          {configurationQuery.data?.data?.impregnation?.mode === 'injection' && (
            <>
              <div className='grid grid-cols-4 gap-x-10 gap-y-4 mt-10 w-full'>
                <div>Numéro</div>
                <div>Débit</div>
                <div className='col-span-2'>Pression (bar)</div>
              </div>
              {(watch('impregnation.injection_temperatures') ?? []).map((_, index: number) => (
                <div className='grid grid-cols-4 gap-x-10 mt-6 w-full' key={`injection-${index}`}>
                  <div className='h-8 text-sm font-medium text-warm-grey'>
                    {watch(`impregnation.injection_temperatures.${index}.order`) + 1}
                  </div>
                  <Controller
                    control={control}
                    name={`impregnation.injection_temperatures.${index}.debit`}
                    render={({ field }) => (
                      <UnderlinedNumberInput
                        className='w-36'
                        errors={getError(`impregnation.injection_temperatures.${index}.debit`)}
                        onChange={field.onChange}
                        value={field.value}
                      />
                    )}
                  />
                  <Controller
                    control={control}
                    name={`impregnation.injection_temperatures.${index}.pressure`}
                    render={({ field }) => (
                      <div className='flex relative'>
                        <UnderlinedNumberInput
                          className='w-36'
                          errors={getError(`impregnation.injection_temperatures.${index}.pressure`)}
                          onChange={field.onChange}
                          value={field.value}
                        />
                        <button className='ml-4' onClick={() => handleDeleteRow(index)} type='button'>
                          <img className='w-4 h-4' src={SvgTrash} />
                        </button>
                      </div>
                    )}
                  />
                  <span />
                </div>
              ))}
              <InputError errors={getError('impregnation.injection_temperatures')} />
              <div className='col-span-4'>
                <button className='flex items-center mt-6' onClick={handleAddRow} type='button'>
                  <img className='mr-4 w-7 h-7' src={SvgCreate} />
                  <span className='font-medium whitespace-nowrap'>Ajouter un point d'injection</span>
                </button>
              </div>
            </>
          )}
          <div className='grid grid-cols-4 gap-x-10 gap-y-4 mt-10 w-full'>
            <Controller
              control={control}
              name='is_stable'
              render={({ field }) => (
                <Field className='relative col-span-4 mt-10' label='Stabilité du procédé'>
                  <div className='absolute top-0 left-48'>
                    <SwitchInput onChange={field.onChange} value={field.value} />
                  </div>
                </Field>
              )}
            />
          </div>
          <div className='flex flex-row justify-center mt-10'>
            <ButtonSecondary className='px-14 mr-40' onClick={handleResetForm} type='reset'>
              Annuler
            </ButtonSecondary>
            <ButtonPrimary className='px-14' type='submit'>
              Valider
            </ButtonPrimary>
          </div>
        </SpinnerLoaderMask>
      </Panel>
    </form>
  )
})

export { ProcessParametersPanel }
