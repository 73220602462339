import { forwardRef, memo, useContext, useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { useParams } from 'react-router-dom'
import { format } from 'date-fns'

import { SvgCreate } from 'assets/svg'
import { UiContext } from 'contexts/ui.context'
import { DATE_FORMAT, ToastType } from 'core/constants'
import { ErrorObj, Nullable } from 'interfaces'
import {
  AlkalineResistanceLine,
  ExperimentAlkalineResistance,
  ExperimentAlkalineResistanceDto
} from 'interfaces/api/lab'
import { getExperimentAlkalineResistances, postExperimentAlkalineResistances } from 'modules/lab/services'
import { ALKALINE_RESISTANCE_PREFIX } from 'modules/lab/constants'

import { ButtonPrimary, ButtonSecondary, Panel } from 'ui/atoms'
import { i18n } from 'i18n'
import { i18n as productionDatai18n } from 'modules/lab/i18n'

import { AlkalineResistanceFormValues } from '../ProductionDataTab.type'
import { AlkalineResistance } from './AlkalineResistance'
import { SpinnerLoaderMask } from 'ui/molecules'

const panelI18n = productionDatai18n.productionDataTab.alkalineResistancePanel

const AlkalineResistancePanel = memo(
  forwardRef<HTMLFormElement>((_, ref) => {
    const { experimentId } = useParams()

    if (!experimentId) {
      return null
    }

    const [canSubmit, setCanSubmit] = useState<boolean>(false)
    const [alkalineResistanceError, setAlkalineResistanceError] =
      useState<Nullable<ErrorObj<ExperimentAlkalineResistanceDto>>>(null)
    const { updateToast } = useContext(UiContext)
    const queryClient = useQueryClient()

    const { control, setValue, handleSubmit, watch, reset } = useForm<{
      alkaline_resistances: AlkalineResistanceFormValues
    }>({
      defaultValues: { alkaline_resistances: [] }
    })

    const get = useQuery(
      ['lab.experiment.alkalineResistance', experimentId],
      async () => await getExperimentAlkalineResistances(experimentId),
      {
        onSuccess: ({ data }) => {
          setCanSubmit(true)
          resetForm(data)
        }
      }
    )

    const resetForm = (data?: Nullable<ExperimentAlkalineResistance<AlkalineResistanceLine>[]>) => {
      data ? reset({ alkaline_resistances: data }) : reset({ alkaline_resistances: [] })
    }

    const post = useMutation(
      (data: ExperimentAlkalineResistanceDto) => postExperimentAlkalineResistances(experimentId, data),
      {
        onMutate: () => {
          setAlkalineResistanceError(null)
        },
        onSuccess: (data) => {
          queryClient.setQueryData(['lab.experiment.alkalineResistance', experimentId], data)
          updateToast({
            content: get.data?.data?.length ? panelI18n.toasts.updateSuccess : panelI18n.toasts.createSuccess,
            displayed: true,
            type: ToastType.SUCCESS
          })
        },
        onError: (e: Error & { errors?: ErrorObj<ExperimentAlkalineResistanceDto> }) => {
          setAlkalineResistanceError(e.errors ?? null)
          updateToast({
            content: e.message ?? 'Une erreur est survenue',
            displayed: true,
            type: ToastType.ERROR
          })
        }
      }
    )
    const handleOnSubmit: SubmitHandler<{ alkaline_resistances: AlkalineResistanceFormValues }> = ({
      alkaline_resistances
    }) => {
      post.mutate({ alkaline_resistances })
    }

    const handleCreateNewAlkalineResistance = () => {
      const lastIndex = watch('alkaline_resistances').length
      setValue(`alkaline_resistances.${lastIndex}.is_active`, false)
      setValue(`alkaline_resistances.${lastIndex}.identifier`, `${ALKALINE_RESISTANCE_PREFIX}${lastIndex + 1}`)
      setValue(`alkaline_resistances.${lastIndex}.lines`, [])
      setValue(`alkaline_resistances.${lastIndex}.lines.0.analyzed_at`, format(new Date(), DATE_FORMAT))
      setValue(`alkaline_resistances.${lastIndex}.lines.0.constraint`, 0)
      setValue(`alkaline_resistances.${lastIndex}.lines.0.days_count`, 0)
      setValue(`alkaline_resistances.${lastIndex}.lines.0.property_loss`, 0)
      setValue(`alkaline_resistances.${lastIndex}.lines.0.std`, 0)
      updateIdentifier()
    }
    const handleCreateNewAlkalineResistanceLine = (id: number) => {
      const lastIndex = watch(`alkaline_resistances.${id}.lines`).length
      setValue(`alkaline_resistances.${id}.lines.${lastIndex}.analyzed_at`, format(new Date(), DATE_FORMAT))
      setValue(`alkaline_resistances.${id}.lines.${lastIndex}.constraint`, 0)
      setValue(`alkaline_resistances.${id}.lines.${lastIndex}.days_count`, 0)
      setValue(`alkaline_resistances.${id}.lines.${lastIndex}.property_loss`, 0)
      setValue(`alkaline_resistances.${id}.lines.${lastIndex}.std`, 0)
      updateIdentifier()
    }
    const handleDeleteAlkalineResistanceLine = (alkalineResistanceIndex: number, lineIndex: number) => {
      const lines = watch(`alkaline_resistances.${alkalineResistanceIndex}.lines`)
      if (lines.length <= 1) {
        const alkalineResistances = watch(`alkaline_resistances`)
        const updatedAlkalineResistances = alkalineResistances.filter((_, i) => i !== alkalineResistanceIndex)
        setValue(`alkaline_resistances`, updatedAlkalineResistances)
      } else {
        const updatedLines = lines.filter((_, i) => i !== lineIndex)
        setValue(`alkaline_resistances.${alkalineResistanceIndex}.lines`, updatedLines)
      }
      updateIdentifier()
    }
    const updateIdentifier = () => {
      const alkalineResistances = watch(`alkaline_resistances`)
      for (let i = 0; i < alkalineResistances.length; i++) {
        setValue(`alkaline_resistances.${i}.identifier`, `${ALKALINE_RESISTANCE_PREFIX}${i + 1}`)
      }
    }

    return (
      <form onSubmit={handleSubmit(handleOnSubmit)} ref={ref}>
        <Panel noPadding={true} openable={true} title={panelI18n.title}>
          <SpinnerLoaderMask
            message={get.isFetching ? i18n.isLoading : i18n.isSaving}
            spinning={get.isFetching || post.isLoading}
          >
            <div className='flex flex-col items-start'>
              {watch('alkaline_resistances').map(({ lines }, i) => (
                <AlkalineResistance
                  alkalineResistanceCount={watch('alkaline_resistances').length}
                  alkalineResistanceError={alkalineResistanceError}
                  alkalineResistanceIndex={i}
                  control={control}
                  key={i}
                  lines={lines}
                  onCreateNewAlkalineResistanceLine={handleCreateNewAlkalineResistanceLine}
                  onDeleteAlkalineResistanceLine={handleDeleteAlkalineResistanceLine}
                  setValue={setValue}
                />
              ))}
              <button className='flex items-center mt-4' onClick={handleCreateNewAlkalineResistance} type='button'>
                <img className='mr-4 w-7 h-7' src={SvgCreate} />
                <span className='font-medium whitespace-nowrap'>{i18n.addNewId}</span>
              </button>
              <div className='flex flex-row justify-around mx-auto mt-10 w-full  max-w-2xl'>
                <ButtonSecondary className='px-14 mr-4' onClick={() => resetForm(get.data?.data)} type='button'>
                  {i18n.cancel}
                </ButtonSecondary>
                <ButtonPrimary className='px-14' disabled={!canSubmit} type='submit'>
                  {i18n.submit}
                </ButtonPrimary>
              </div>
            </div>
          </SpinnerLoaderMask>
        </Panel>
      </form>
    )
  })
)

export { AlkalineResistancePanel }
