import { Task, TaskPayload } from 'interfaces/api/task'
import { fetchJson } from 'utils/fetch'

const getTasks = () => {
  return fetchJson<Task[]>('GET', '/tasks')
}

/**
 *
 * @returns
 */
const postTasks = (payload: TaskPayload) => {
  return fetchJson<Task, TaskPayload>('POST', `/tasks`, payload)
}

/**
 *
 * @returns
 */
const putTask = (uuid: string, data: Record<string, string>) => {
  return fetchJson<Task, Record<string, string>>('PUT', `/tasks/${uuid}/data`, data)
}

export { putTask, postTasks, getTasks }
