import { LiteMedia, Nullable } from 'interfaces'
import { Dto } from 'modules/list-parameters/services/list-parameters.dto'
import { FC, useContext, useEffect, useState } from 'react'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { useMutation, useQueryClient } from 'react-query'

import { ButtonPrimary, ButtonSecondary } from 'ui/atoms'

import { Modal } from 'ui/atoms/Modal'
import { Task, TaskDto } from 'interfaces/api/task'
import { FetchResult } from 'utils/fetch'
import { UiContext } from 'contexts/ui.context'
import { ToastType } from 'core/constants'

interface TaskFormValues {
  override: boolean
}
const TaskOverwriteModal: FC<{
  experimentId: string
  open: boolean
  onTaskIdChange: (taskId: Nullable<number>) => void
  files: LiteMedia[]
  onClose: () => void
  mutationFn: (payload: Dto<TaskDto>) => Promise<FetchResult<Task>>
}> = ({ onTaskIdChange, files, onClose, open, mutationFn }) => {
  if (!open) {
    return null
  }
  const { updateToast } = useContext(UiContext)
  const [taskConfirmModal, setTaskConfirmModal] = useState<boolean>(false)

  const queryClient = useQueryClient()

  const { control, setValue, handleSubmit } = useForm<TaskFormValues>()

  const taskMutation = useMutation(mutationFn, {
    onSuccess: (data) => {
      onTaskIdChange(data.data?.id ?? null)
      queryClient.setQueryData(['tasks', data.data?.id ?? null], data)
      onClose()
    },
    onError: (e: { status: number; message: string }) => {
      if (e.status === 409) {
        setValue('override', true)
        setTaskConfirmModal(true)
      } else {
        updateToast({
          content: e.message ?? 'Une erreur est survenue',
          displayed: true,
          type: ToastType.ERROR
        })
      }
    }
  })

  const handleOnSubmitTask: SubmitHandler<TaskFormValues> = ({ override }) => {
    setTaskConfirmModal(false)
    const file = files.map(({ uuid }) => ({ uuid }))
    taskMutation.mutate({ file, override })
  }

  useEffect(() => {
    setValue('override', false)
    const file = files.map(({ uuid }) => ({ uuid }))
    taskMutation.mutate({ file, override: false })
  }, [files])

  return (
    <form onSubmit={handleSubmit(handleOnSubmitTask)}>
      <Controller
        control={control}
        name='override'
        render={({ field }) => <input checked={field.value} hidden={true} onChange={field.onChange} type='checkbox' />}
      />
      <Modal isOpen={taskConfirmModal} onClose={() => setTaskConfirmModal(false)}>
        <p className='mb-8 text-center'>Ce fichier existe déjà, souhaitez-vous le remplacer ?</p>
        <ButtonSecondary className='px-14 mr-40' onClick={() => setTaskConfirmModal(false)} type='button'>
          Annuler
        </ButtonSecondary>
        <ButtonPrimary className='px-14' type='submit'>
          Valider
        </ButtonPrimary>
      </Modal>
    </form>
  )
}

export { TaskOverwriteModal }
