import { Nullable } from 'interfaces'
import { ExperimentIndexDto } from 'interfaces/api/lab'
import { FC } from 'react'
import { Control, Controller } from 'react-hook-form'
import { DualNumberInput } from 'ui/molecules'

const FiberRateFilters: FC<{
  control: Control<ExperimentIndexDto>
}> = ({ control }) => {
  return (
    <div className='flex flex-col mt-5'>
      <details className='' open={true}>
        <summary className='py-1 px-2 text-xl font-semibold list-none text-black bg-ice-blue cursor-pointer'>
          TVF/TP
        </summary>
        <div className='grid grid-cols-3 gap-8 py-5'>
          <Controller
            control={control}
            name='fiber_rate.fiber_mass_rate'
            render={({ field }) => (
              <DualNumberInput
                label='Taux massique de fibre (%)'
                onChange={field.onChange}
                value={(field.value ?? { min: null, max: null }) as Record<string, Nullable<number>>}
              />
            )}
          />
          <Controller
            control={control}
            name='fiber_rate.fiber_volume_rate'
            render={({ field }) => (
              <DualNumberInput
                label='Taux volumique de fibre (%)'
                onChange={field.onChange}
                value={(field.value ?? { min: null, max: null }) as Record<string, Nullable<number>>}
              />
            )}
          />
          <Controller
            control={control}
            name='fiber_rate.porosity_rate'
            render={({ field }) => (
              <DualNumberInput
                label='Taux de porisité (%)'
                onChange={field.onChange}
                value={(field.value ?? { min: null, max: null }) as Record<string, Nullable<number>>}
              />
            )}
          />
        </div>
      </details>
    </div>
  )
}

export { FiberRateFilters }
