import { createContext, FC, ReactNode, useCallback, useReducer } from 'react'
import { LiteMediaWithStatus } from '../types'

import { Types, State, Actions } from './files.context.type'

const reducers = (state: State, action: Actions): State => {
  switch (action.type) {
    case Types.REPLACE_FILE:
      return {
        ...state,
        files: [action.payload]
      }
    case Types.ADD_FILE:
      return {
        ...state,
        files: [...state.files, action.payload]
      }
    case Types.UPDATE_FILE:
      return {
        ...state,
        files: state.files.map((f) => (action.payload.uuid === f.uuid ? action.payload : f))
      }
    case Types.DELETE_FILE:
      return {
        ...state,
        files: state.files.filter((f) => action.payload !== f.uuid)
      }
    case Types.UPDATE_FILES:
      return {
        ...state,
        files: action.payload
      }
    default:
      return state
  }
}

const initialState: State = {
  files: [],
  replaceFile: () => null,
  addFile: () => null,
  updateFile: () => null,
  deleteFile: () => null,
  updateFiles: () => null
}

const FilesContext = createContext(initialState)
const FilesProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const [state, dispatch] = useReducer(reducers, initialState)
  const value: State = {
    ...state,
    replaceFile: useCallback((payload: LiteMediaWithStatus) => {
      dispatch({
        type: Types.REPLACE_FILE,
        payload
      })
    }, []),
    addFile: useCallback((payload: LiteMediaWithStatus) => {
      dispatch({
        type: Types.ADD_FILE,
        payload
      })
    }, []),
    updateFile: useCallback((payload: LiteMediaWithStatus) => {
      dispatch({
        type: Types.UPDATE_FILE,
        payload
      })
    }, []),
    deleteFile: useCallback((payload: string) => {
      dispatch({
        type: Types.DELETE_FILE,
        payload
      })
    }, []),
    updateFiles: useCallback((payload: LiteMediaWithStatus[]) => {
      dispatch({
        type: Types.UPDATE_FILES,
        payload
      })
    }, [])
  }

  return <FilesContext.Provider value={value}>{children}</FilesContext.Provider>
}

export { FilesProvider, FilesContext, initialState }
