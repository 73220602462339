import { FC, ReactNode } from 'react'
import { NavLink } from 'react-router-dom'
import { SvgInjector } from './SvgInjector'

export const NavigationLink: FC<{ href: string; icon: string; children: ReactNode }> = ({ children, href, icon }) => {
  return (
    <NavLink
      className={({ isActive }) =>
        `${isActive ? 'bg-ice-blue text-dusk-blue' : 'text-silver'} flex items-center rounded-tl-md p-3 mt-5`
      }
      to={href}
    >
      <SvgInjector className='mr-2 w-4 h-4' src={icon} />
      <span className='text-sm'>{children}</span>
    </NavLink>
  )
}
