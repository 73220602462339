import { forwardRef, ReactElement, ReactNode } from 'react'

const MainTemplate = forwardRef<
  HTMLDivElement,
  {
    className?: string
    containerClassName?: string
    children?: ReactNode
    actionButton?: ReactElement
    backButton?: ReactElement
    title: ReactElement
  }
>(
  (
    { className = '', containerClassName = '', children, title = null, backButton = null, actionButton = null },
    ref
  ) => {
    return (
      <div className={`relative flex flex-auto h-full ${className}`} id='scrollArea' ref={ref}>
        <div
          className={`flex flex-col flex-auto py-6 px-10 2xl:py-11 2xl:px-20 h-full min-h-full ${containerClassName}`}
          ref={ref}
        >
          <header className='flex flex-initial justify-between'>
            <div>
              <div className='pb-2'>{backButton}</div>
              <div>{title}</div>
            </div>
            <div>{actionButton}</div>
          </header>
          {children}
        </div>
      </div>
    )
  }
)

export { MainTemplate }
