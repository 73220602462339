import { ExperimentDMA, ExperimentDMADto, ExperimentDMATaskDto } from 'interfaces/api/lab'
import { ExperimentDMATask } from 'interfaces/api/task'
import { Dto } from 'modules/list-parameters/services/list-parameters.dto'
import { fetchJson } from 'utils/fetch'

/**
 *
 * @param experimentId
 * @param ramp
 * @param payload
 * @returns
 */
const postExperimentDMA = (experimentId: string, ramp: 1 | 2, payload: Dto<ExperimentDMADto>) => {
  return fetchJson<ExperimentDMA, Dto<ExperimentDMADto>>(
    'POST',
    `/lab/experiments/${experimentId}/dynamic-mechanical-analyses/${ramp}`,
    payload
  )
}
/**
 *
 * @param experimentId
 * @param ramp
 * @param payload
 * @returns
 */
const putExperimentDMA = (experimentId: string, ramp: 1 | 2, payload: Dto<ExperimentDMADto>) => {
  return fetchJson<ExperimentDMA, Dto<ExperimentDMADto>>(
    'PUT',
    `/lab/experiments/${experimentId}/dynamic-mechanical-analyses/${ramp}`,
    payload
  )
}
/**
 *
 * @param experimentId
 * @returns
 */
const getExperimentDMA = (experimentId: string) => {
  return fetchJson<ExperimentDMA[]>('GET', `/lab/experiments/${experimentId}/dynamic-mechanical-analyses`)
}

/**
 *
 * @param url
 * @param payload
 * @returns
 */
const postExperimentDMATask = (experimentId: string, ramp: 1 | 2, payload: Dto<ExperimentDMATaskDto>) => {
  return fetchJson<ExperimentDMATask, Dto<ExperimentDMATaskDto>>(
    'POST',
    `/lab/experiments/${experimentId}/dynamic-mechanical-analyses/${ramp}/task`,
    payload
  )
}

export { postExperimentDMA, putExperimentDMA, getExperimentDMA, postExperimentDMATask }
