import { SvgCreate, SvgTrash } from 'assets/svg'
import { UiContext } from 'contexts/ui.context'
import { ErrorObj, Extensions, Nullable, ToastProps } from 'interfaces'
import { ExperimentReinforcementDto, ExperimentTransverseReinforcement } from 'interfaces/api/lab'
import { Roving } from 'interfaces/api/management'
import { parseInt } from 'lodash'
import { FileManagement } from 'modules/files'
import {
  getExperimentReinforcement,
  postExperimentReinforcement,
  putExperimentReinforcement
} from 'modules/lab/services'
import { getExperimentReinforcements } from 'modules/lab/services/reinforcements'
import { getExperimentRovings } from 'modules/lab/services/rovings'
import { FormEvent, forwardRef, useContext, useEffect, useState } from 'react'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { useParams } from 'react-router-dom'
import { ButtonPrimary, ButtonSecondary, Panel } from 'ui/atoms'
import { Field } from 'ui/atoms/Field'
import { UnderLinedSelect } from 'ui/atoms/UnderLinedSelect'
import { SpinnerLoaderMask } from 'ui/molecules'
import { ReinforcementFormValues } from './DescriptionTab.type'

const ReinforcementsPanel = forwardRef<HTMLFormElement>((_, ref) => {
  const { control, setValue, handleSubmit, getValues, watch } = useForm<ReinforcementFormValues>()

  const [errors, setErrors] = useState<Nullable<ErrorObj<ReinforcementFormValues>>>(null)
  const { experimentId: id = '' } = useParams()
  const { updateToast } = useContext(UiContext)

  const reinforcementListQuery = useQuery(
    'lab.reinforcement.reinforcement-list',
    async () => await getExperimentReinforcements()
  )

  const rovingListQuery = useQuery('lab.reinforcement.roving-list', async () => await getExperimentRovings())

  const fetchReinforcements = async (id?: string) => {
    if (!id) {
      throw new Error('😓 une erreur est survenue')
    }
    return await getExperimentReinforcement(id)
  }

  const query = useQuery(['reinforcement', id], async () => await fetchReinforcements(id), {
    onSuccess: (data) => resetForm(data.data as ReinforcementFormValues)
  })

  const resetForm = (data: ReinforcementFormValues) => {
    if (!data) {
      setValue('roving_id', undefined)
      setValue('files', [])
      setValue('transverse_reinforcements', [])
      return
    }
    setValue('roving_id', data.roving_id)
    setValue(
      'fiber',
      (rovingListQuery.data?.data ?? []).find((r: Roving) => r.id === data?.roving_id ?? 0)?.fiber ?? ''
    )
    setValue(
      'transverse_reinforcements',
      (data.transverse_reinforcements ?? []).sort(
        (a: ExperimentTransverseReinforcement, b: ExperimentTransverseReinforcement) => (a.order > b.order ? 1 : -1)
      )
    )
    setValue('files', data?.files)
  }

  const handleAddRow = () => {
    setValue('transverse_reinforcements', [
      ...(getValues('transverse_reinforcements') as ExperimentTransverseReinforcement[]),
      newReinforcement(getValues('transverse_reinforcements').length)
    ])
  }

  const handleDeleteRow = (value: ExperimentTransverseReinforcement) => {
    const filtered = getValues('transverse_reinforcements')
      .filter((r: ExperimentTransverseReinforcement) => value.order !== r.order)
      .map((r: ExperimentTransverseReinforcement, index: number) => {
        r.order = index
        return r
      })
    setValue('transverse_reinforcements', filtered)
  }

  const newReinforcement = (id: number): ExperimentTransverseReinforcement => ({
    order: id,
    transverse_reinforcement_id: undefined
  })

  const queryClient = useQueryClient()
  const reinforcementUpdate = useMutation(
    (payload: ExperimentReinforcementDto) =>
      query.data?.data ? putExperimentReinforcement(id, payload) : postExperimentReinforcement(id, payload),
    {
      onMutate: () => {
        setErrors(null)
      },
      onError: (e: { errors: Nullable<ErrorObj<ReinforcementFormValues>> }) => {
        updateToast({
          displayed: true,
          type: 'error',
          content: "Les modifications n'ont pas pu être enregistrées."
        } as ToastProps)
        setErrors(e.errors ?? null)
      },
      onSuccess: () => {
        queryClient.invalidateQueries('reinforcement')
        updateToast({
          displayed: true,
          type: 'success',
          content: 'Les modifications ont bien été enregistrées.'
        } as ToastProps)
      }
    }
  )

  const isLoading = [query.isFetching, reinforcementUpdate.isLoading].includes(true)

  const handleOnSubmit: SubmitHandler<ReinforcementFormValues> = (payload: ExperimentReinforcementDto) => {
    reinforcementUpdate.mutate(payload as ExperimentReinforcementDto)
  }

  const handleResetForm = () => {
    updateToast({
      displayed: true,
      type: 'error',
      content: 'Les modifications on été annulées.'
    } as ToastProps)
    resetForm(query.data?.data as ReinforcementFormValues)
  }

  const handleChangeRoving = (e: FormEvent<HTMLSelectElement>) => {
    setValue('roving_id', parseInt(e.currentTarget.value))
    setValue(
      'fiber',
      (rovingListQuery.data?.data ?? []).find((r: Roving) => r.id === parseInt(e.currentTarget.value))?.fiber ?? ''
    )
  }

  useEffect(() => {
    resetForm(query.data?.data as ReinforcementFormValues)
  }, [rovingListQuery.data?.data, query.data?.data])
  return (
    <form onSubmit={handleSubmit(handleOnSubmit)} ref={ref}>
      <Panel title='Renforts'>
        <SpinnerLoaderMask spinning={isLoading}>
          <div className='flex flex-col'>
            <div className='flex flex-row'>
              <div className='flex w-1/3'></div>
              <div className='flex w-1/3'>
                <Controller
                  control={control}
                  name={`files`}
                  render={({ field }) => (
                    <Field label='Importer un ou plusieurs fichiers'>
                      <FileManagement
                        accept={[Extensions.PNG, Extensions.JPEG, Extensions.PDF]}
                        multiple={true}
                        onChange={field.onChange}
                        value={field.value}
                      />
                    </Field>
                  )}
                />
              </div>
            </div>
          </div>
          <div className='flex flex-row'>
            <div className='flex flex-col mt-4 w-1/3'>
              <Controller
                control={control}
                name='roving_id'
                render={({ field }) => (
                  <Field label={'Renforts'}>
                    <UnderLinedSelect
                      errors={errors?.roving_id ?? null}
                      onChange={handleChangeRoving}
                      options={(rovingListQuery.data?.data ?? []).map((r: Roving) => ({
                        value: r.id,
                        label: r.label
                      }))}
                      placeholder='Nom de fibre'
                      value={field.value}
                    />
                  </Field>
                )}
              />
              {watch('roving_id') && (
                <Controller
                  control={control}
                  name={`fiber`}
                  render={({ field }) => (
                    <Field className='mt-6' label='Nature'>
                      <span className='font-thin capitalize'>
                        {{ carbon: 'Carbone', mix: 'Mixte', glass: 'Verre', 'natural-fiber': 'Naturelle' }[field.value]}
                      </span>
                    </Field>
                  )}
                />
              )}
            </div>
            <div className='mt-4'>
              <Field label='Renforts transverses'>
                <div>
                  <table>
                    <thead>
                      <tr>
                        <th className='pr-6 w-8 text-sm font-medium text-left'>Numéro</th>
                        <th className='text-sm font-medium text-left'>Nom du renfort</th>
                      </tr>
                    </thead>
                    <tbody>
                      {watch('transverse_reinforcements')?.map(
                        (value: ExperimentTransverseReinforcement, index: number) => (
                          <tr key={`reinforcement-${index}`}>
                            <Controller
                              control={control}
                              name={`transverse_reinforcements.${index}.transverse_reinforcement_id`}
                              render={({ field }) => (
                                <>
                                  <td className='pt-6 pr-6 align-top'>
                                    <div className='h-8 text-sm font-medium text-warm-grey'>{value.order + 1}</div>
                                  </td>
                                  <td className='flex pt-6'>
                                    <UnderLinedSelect
                                      errors={
                                        errors
                                          ? errors[`transverse_reinforcements.${index}.transverse_reinforcement_id`]
                                          : null
                                      }
                                      onChange={field.onChange}
                                      options={(reinforcementListQuery.data?.data ?? []).map((r) => ({
                                        value: r.id,
                                        label: r.label
                                      }))}
                                      value={field.value}
                                    />
                                    <button className='ml-4' onClick={() => handleDeleteRow(value)} type='button'>
                                      <img className='w-4 h-4' src={SvgTrash} />
                                    </button>
                                  </td>
                                </>
                              )}
                            />
                          </tr>
                        )
                      )}
                    </tbody>
                  </table>
                  <button className='flex items-center mt-6' onClick={handleAddRow} type='button'>
                    <img className='mr-4 w-7 h-7' src={SvgCreate} />
                    <span className='font-medium whitespace-nowrap'>Ajouter un renfort transverse</span>
                  </button>
                </div>
              </Field>
            </div>
          </div>
          <div className='flex flex-row justify-center mt-10'>
            <ButtonSecondary className='px-14 mr-40' onClick={handleResetForm} type='button'>
              Annuler
            </ButtonSecondary>
            <ButtonPrimary className='px-14' type='submit'>
              Valider
            </ButtonPrimary>
          </div>
        </SpinnerLoaderMask>
      </Panel>
    </form>
  )
})

export { ReinforcementsPanel }
