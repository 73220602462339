import { UiContext } from 'contexts/ui.context'
import { ToastType } from 'core/constants'
import { ErrorObj, Nullable } from 'interfaces'
import { Configuration } from 'interfaces/api/lab'
import { duplicateExperiment } from 'modules/lab/services'
import { FC, FormEvent, useContext, useRef, useState } from 'react'
import { useMutation, useQueryClient } from 'react-query'
import { useNavigate } from 'react-router-dom'
import { ButtonPrimary, ButtonSecondary, InputError } from 'ui/atoms'
import { Field } from 'ui/atoms/Field'
import { UnderlinedInput } from 'ui/atoms/UnderLinedInput'

const DuplicateModal: FC<{ id: number; configuration: Configuration }> = ({ id, configuration }) => {
  const input = useRef<HTMLInputElement>(null)
  const [reference, setReference] = useState('')
  const [errors, setErrors] = useState<Nullable<ErrorObj<{ reference: string }>>>(null)

  const { updateModal, updateToast } = useContext(UiContext)

  const navigate = useNavigate()
  const queryClient = useQueryClient()

  const experimentDuplication = useMutation(
    (params: { id: string; reference: string }) => duplicateExperiment(params.id, params.reference),
    {
      onSuccess: (data) => {
        updateModal({
          content: null,
          displayed: false,
          hideCallback: () => {
            updateToast({
              content: `L'essai a été dupliqué avec succès`,
              displayed: true,
              type: ToastType.SUCCESS
            })
          }
        })
        queryClient.invalidateQueries('lab.experiment')
        navigate(`/experiments/${data.data?.id}`)
      },
      onError: (e: { errors: Nullable<ErrorObj<{ reference: string }>> }) => {
        setErrors(e.errors)
      }
    }
  )

  const handleModalSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    experimentDuplication.mutate({ id: `${id}`, reference: `${configuration.machine}${reference}` })
  }
  const handleModalCancellation = () => {
    updateModal({
      content: null,
      displayed: false
    })
  }

  return (
    <div className='px-8 pt-6 pb-8'>
      <form name='duplicate-form' onSubmit={handleModalSubmit}>
        <Field label='Référence du nouvel essai'>
          <div className='flex flex-col'>
            <div className='flex flex-row pt-4'>
              <span className='mr-2 w-6 text-sm'>{configuration.machine}</span>
              <UnderlinedInput
                className='pr-2'
                errors={null}
                onChange={setReference}
                placeholder='781117_33'
                ref={input}
                tabIndex={0}
                value={reference}
              />
            </div>
            <InputError errors={errors?.reference ?? null} />
          </div>
        </Field>
        <div className='flex flex-row justify-between m-auto w-80'>
          <ButtonSecondary className='mt-8' onClick={handleModalCancellation} type='button'>
            Annuler
          </ButtonSecondary>
          <ButtonPrimary className='mt-8' type='submit'>
            Valider
          </ButtonPrimary>
        </div>
      </form>
    </div>
  )
}

export { DuplicateModal }
