import { useEffect } from 'react'

const useErrorScroll = <T extends object>(errors: T) => {
  useEffect(() => {
    const element = document.querySelector('div.mt-2.text-xs.text-\\[red\\]')
    if (!element) {
      return
    }
    element.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
      inline: 'nearest'
    })
  }, [errors])
}

export { useErrorScroll }
