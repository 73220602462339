import { FC, useContext, useEffect, useState } from 'react'
import ArrowDown from 'assets/svg/arrow-down.svg'
import { SvgLogout, SvgUpdatePassword } from 'assets/svg'
import { useNavigate } from 'react-router-dom'
import { WhiteButton } from 'ui/atoms'
import { AuthContext } from 'stores/auth'
import { Nullable } from 'interfaces'
import { UpdatePasswordModal } from 'modules/auth/update-password/UpdatePasswordModal'
import { getAuthenticatedUser, postLogout } from 'modules/auth/services/auth'
import { UiContext } from 'contexts/ui.context'
import { ToastType } from 'core/constants'

export const UserDropdown: FC = () => {
  const { updateUserProfile } = useContext(AuthContext)
  const [lastName, setLastName] = useState<Nullable<string>>(null)
  const [firstName, setFirstName] = useState<Nullable<string>>(null)
  const [isOpen, setIsOpen] = useState(false)
  const { updateToast } = useContext(UiContext)
  const [isPasswordUpdateModalOpen, setIsPasswordUpdateModalOpen] = useState(false)
  const navigate = useNavigate()
  const toggleMenu = () => {
    setIsOpen(!isOpen)
  }
  const disconnectUser = async () => {
    try {
      await postLogout()
      navigate('/auth/login')
    } catch (e) {
      //
    }
  }

  const fetchUser = async () => {
    const userProfile = (await getAuthenticatedUser()).data
    updateUserProfile(userProfile)
    setFirstName(userProfile?.first_name ?? null)
    setLastName(userProfile?.last_name ?? null)
  }

  const handleToastUpdate = () => {
    updateToast({
      content: 'Votre mot de passe a été modifié',
      displayed: true,
      type: ToastType.SUCCESS,
      hideCallback: () =>
        updateToast({
          displayed: false
        })
    })
  }

  useEffect(() => {
    fetchUser().catch(() => {
      navigate('/auth/login')
    })
  }, [])

  return (
    <div>
      <div className='flex items-center cursor-pointer' onClick={toggleMenu}>
        <button
          className='flex justify-center items-center w-10 h-10 font-semibold text-center text-white bg-dusk-blue rounded-full'
          onClick={toggleMenu}
        >
          <span>{lastName?.charAt(0)}</span>
        </button>
        {isOpen && firstName && lastName && (
          <span className='ml-2 font-medium text-greyish-brown select-none'>{`${lastName} ${firstName}`}</span>
        )}
        <img className='ml-2 w-4 h-4' src={ArrowDown} />
      </div>
      {isOpen && (
        <div className='absolute inset-0 w-screen h-screen' onClick={toggleMenu}>
          <div className='absolute  inset-0 z-40 bg-[transparent]' />
          <div className='absolute top-[70px] right-8 z-50 w-[310px]'>
            <WhiteButton
              className='mb-[2px]'
              icon={SvgUpdatePassword}
              onClick={() => setIsPasswordUpdateModalOpen(true)}
            >
              Changer de mot de passe
            </WhiteButton>
            <WhiteButton icon={SvgLogout} onClick={disconnectUser}>
              Se déconnecter
            </WhiteButton>
          </div>
        </div>
      )}
      <UpdatePasswordModal
        isOpen={isPasswordUpdateModalOpen}
        onClose={() => setIsPasswordUpdateModalOpen(false)}
        onSuccess={() => {
          setIsPasswordUpdateModalOpen(false)
          handleToastUpdate()
        }}
      />
    </div>
  )
}
