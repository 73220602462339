import { LiteMedia } from 'interfaces'

type Machine = 'PX' | 'RO'

type UnwindingType = 'simple' | 'double'

enum ImpregnationMode {
  BATH = 'bath',
  INJECTION = 'injection'
}
interface ConfigurationGeneralInformation {
  name?: string
  machine?: Machine
  configured_at?: string
  user?: ConfigurationUser
  unwinding_type?: UnwindingType
  closing_pressure?: number
  die_length?: number
}

interface ConfigurationImpregnation {
  files?: LiteMedia[]
  mode?: ImpregnationMode
}

interface ConfigurationUser {
  id: number
  name: string
}

interface ConfigurationDetail {
  conformations: LiteMedia[]
  documents: LiteMedia[]
  editable: boolean
  general_information: ConfigurationGeneralInformation
  id?: number
  impregnation: ConfigurationImpregnation
  oven_count?: number
}

interface ConfigurationPreview {
  configured_at: string
  created_by: string
  deletable: boolean
  id: number
  is_active: boolean
  name: string
}

interface ConfigurationDto {
  user_id?: number
  name?: string
  machine?: Machine
  configured_at?: string
  created_by?: string
  unwinding_type?: UnwindingType
  closing_pressure?: number
  oven_count?: number
  die_length?: number
  conformations?: LiteMedia[]
  impregnation_mode?: ImpregnationMode
  impregnations?: LiteMedia[]
  documents?: LiteMedia[]
}

type ConfigurationChange = Record<
  string,
  | ConfigurationUser
  | string
  | ConfigurationGeneralInformation
  | string[]
  | number
  | Date
  | ConfigurationImpregnation
  | LiteMedia[]
  | LiteMedia
  | boolean
  | null
  | ImpregnationMode
>

export { ImpregnationMode }
export type {
  Machine,
  ConfigurationDto,
  ConfigurationChange,
  ConfigurationPreview,
  ConfigurationDetail,
  ConfigurationGeneralInformation,
  ConfigurationUser,
  UnwindingType,
  ConfigurationImpregnation
}
