import { ApiDate, ApiTime, LiteMedia, Nullable } from 'interfaces'
import { ViscosityRow } from 'modules/lab/components/description-tab/DescriptionTab.type'

interface ExperimentLite {
  id: number
  reference: string
}

interface ExperimentDto {
  reference: string
  realized_at?: Nullable<ApiDate>
  start_time?: Nullable<ApiTime>
  finish_time?: Nullable<ApiTime>
  configuration_id?: number
  user_id: number
  speed?: Nullable<number>
  details: Nullable<string>
  pultrusion_die_id: Nullable<number>
  theme_id: Nullable<number>
}

interface Experiment extends ExperimentLite {
  realized_at: ApiDate
  start_time: ApiTime
  finish_time: Nullable<ApiTime>
  configuration_id: number
  user_id: number
  speed: Nullable<number>
  details: Nullable<string>
  pultrusion_die_id: Nullable<number>
  theme_id: Nullable<number>
}

enum Machine {
  PX = 'PX',
  RO = 'RO'
}

const FormulaConstituentsTypesList = [
  'resin',
  'release-agent',
  'filler',
  'additive',
  'peroxyde',
  'accelerator'
] as const

type FormulaConstituentTypes = (typeof FormulaConstituentsTypesList)[number]

interface FormulaConstituent {
  id: number
  label: string
  type: FormulaConstituentTypes
}

type FormulaConstituents = Record<FormulaConstituentTypes, FormulaConstituent>

interface FormulaIngredient {
  id?: number
  order: number
  constituent_id?: number
  part_per_hundred_of_resin?: number
  quantity_to_introduce?: number
  introduced_quantity?: Nullable<number>
}

interface FormulaIngredientsDto {
  id?: number
  ingredients: FormulaIngredient[]
  degassing: boolean
  pressure: Nullable<number>
  pressure_time: Nullable<number>
}

interface ExperimentViscosityDto {
  id?: number
  starting_drain: ViscosityRow
  drain: ViscosityRow
  ending_drain: ViscosityRow
}

interface ExperimentTransverseReinforcement {
  order: number
  transverse_reinforcement_id?: number
}

interface ExperimentReinforcementDto {
  id?: number
  roving_id?: number
  transverse_reinforcements: ExperimentTransverseReinforcement[]
  files: LiteMedia[]
}

interface ExperimentInjectionTemperature {
  order: number
  debit: Nullable<number>
  pressure: Nullable<number>
}

interface ExperimentImpregnation {
  injection_temperatures: ExperimentInjectionTemperature[]
  impregnation_temperature: Nullable<number>
}

type ExperimentConformationMode = 'conduction' | 'temperature'

interface ExperimentConformation {
  is_active: boolean
  mode: ExperimentConformationMode
  temperature: Nullable<number>
}

interface ExperimentProcessParameters {
  wire_count: Nullable<number>
  area_temperatures: Nullable<number>[]
  conformation: ExperimentConformation
  oven_temperatures: Nullable<number[]>
  impregnation?: ExperimentImpregnation
  is_stable: boolean
}

interface ExperimentProcessParameterDto {
  wire_count: Nullable<number>
  area_temperatures: Nullable<number>[]
  conformation: ExperimentConformation
  oven_temperatures: Nullable<number[]>
  impregnation?: ExperimentImpregnation
  is_stable: boolean
}

interface Configuration {
  id: number
  name: string
  is_active: boolean
  machine: Machine
}

interface User {
  id: number
  name: string
}

interface Theme {
  id: number
  label: string
}

interface PultrusionDie {
  id: number
  label: string
}

export { FormulaConstituentsTypesList, Machine }
export type {
  User,
  Theme,
  PultrusionDie,
  ExperimentLite,
  Experiment,
  Configuration,
  ExperimentDto,
  ExperimentProcessParameters,
  ExperimentProcessParameterDto,
  ExperimentImpregnation,
  ExperimentConformation,
  ExperimentConformationMode,
  ExperimentInjectionTemperature,
  FormulaConstituent,
  FormulaConstituents,
  FormulaIngredient,
  FormulaIngredientsDto,
  FormulaConstituentTypes,
  ExperimentViscosityDto,
  ExperimentReinforcementDto,
  ExperimentTransverseReinforcement
}
