import { fetchJson } from 'utils/fetch'
import { ConfigurationDetail, ConfigurationDto, ConfigurationPreview } from './configuration.dto'

/**
 *
 * @param id: number
 * @returns
 */
const getManagementConfigurationDetail = (id: string) => {
  return fetchJson<ConfigurationDetail, number>('GET', `/management/configurations/${id}`)
}

/**
 *
 * @param page
 * @param perPage
 * @returns
 */
const getManagementConfigurations = (page: string, perPage: string) => {
  return fetchJson<ConfigurationPreview[], undefined>(
    'GET',
    `/management/configurations?page=${page}&per_page=${perPage}`
  )
}

/**
 *
 * @param index: number
 * @returns
 */
const deleteManagementConfiguration = (index: number) => {
  return fetchJson('DELETE', `/management/configurations/${index}`)
}

/**
 *
 * @param index: number
 * @returns
 */
const selectDefaultManagementConfiguration = (index: number) => {
  return fetchJson('PUT', `/management/configurations/${index}/toggle-activation`)
}

const createManagementConfiguration = (configurationDto: ConfigurationDto) => {
  return fetchJson('POST', '/management/configurations/', configurationDto)
}

const updateManagementConfiguration = (index: number, configurationDto: ConfigurationDto) => {
  return fetchJson('PUT', `/management/configurations/${index}`, configurationDto)
}

/**
 *
 * @returns
 */
const getUsers = () => {
  return fetchJson<{ id: number; name: string }[]>('GET', `/lab/users`)
}

export {
  getUsers,
  createManagementConfiguration,
  updateManagementConfiguration,
  deleteManagementConfiguration,
  getManagementConfigurations,
  selectDefaultManagementConfiguration,
  getManagementConfigurationDetail
}
