enum FileStatus {
  NONE = 'none',
  IDLE = 'idle',
  UPLOADING = 'uploading',
  PROCESSING = 'processing',
  AVAILABLE = 'available',
  ERROR = 'error',
  WARNING = 'warning'
}

export type FilesUploadStatus = Record<string, FileStatus>
export { FileStatus }
