import { FC } from 'react'
import { Outlet } from 'react-router-dom'

export const AuthLayout: FC = () => {
  return (
    <div className='flex justify-center items-center w-screen h-screen bg-ice-blue'>
      <div className='flex bg-white rounded shadow-card'>
        <Outlet />
      </div>
    </div>
  )
}
