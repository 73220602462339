import { Nullable } from 'interfaces'
import { FC, FormEvent } from 'react'
import { InputError, InputLabel } from 'ui/atoms'

export enum InputType {
  TEXT = 'text',
  EMAIL = 'email',
  TEL = 'tel',
  NUMBER = 'number'
}

export interface TextInputProps {
  disabled?: boolean
  className?: string
  placeholder?: string
  hint?: string
  name?: string
  readOnly?: boolean
  label?: string
  tabIndex?: number
  errors: Nullable<string[]>
  required?: boolean
  onChange?: (value: string) => void
  type?: InputType
  value?: Nullable<string | number>
}

export const TextInput: FC<TextInputProps> = (props) => {
  const onInput = (e: FormEvent<HTMLInputElement>) => {
    if (!onChange) {
      return
    }
    onChange(e.currentTarget.value)
  }
  const { className, disabled, name, type, placeholder, value, readOnly, onChange, label, hint, errors, required } =
    props

  return (
    <div className={'flex flex-col flex-1 ' + className}>
      <InputLabel className='mb-3' hint={hint} htmlFor={name} label={label} />
      <input
        className={`flex w-full py-1 base-input border-b text-warm-grey text-sm border-b-warm-grey outline-none${
          errors ? ' input-error' : ''
        }`}
        disabled={disabled}
        id={name}
        name={name}
        onChange={onInput}
        placeholder={placeholder}
        readOnly={readOnly}
        required={required}
        type={type as string}
        value={value ?? undefined}
      />
      <div className='h-8'>
        <InputError errors={errors} />
      </div>
    </div>
  )
}
