import { SvgRefresh } from 'assets/svg'
import { LabContext } from 'modules/lab/contexts'
import i18n from 'modules/lab/i18n'
import { getExperiment } from 'modules/lab/schemas'
import { FC, useContext } from 'react'
import { InputCheckbox } from 'ui/atoms/InputCheckbox'
import { SvgInjector } from 'ui/atoms/SvgInjector'
import { SwitchInput } from 'ui/atoms/SwitchInput'
import { z } from 'zod'
import { i18n as localeI18n } from '../../i18n'

type Obj = Omit<z.infer<typeof getExperiment>, 'id'>
type PropType<T, K extends keyof T> = T[K]

const Panel: FC<{
  onChange: (data: Record<string, boolean> | boolean) => void
  parentKey: keyof Obj
  data: Record<string, boolean> | boolean
}> = ({ parentKey, data, onChange }) => {
  const handleOnChange = (value: boolean, key?: string) => {
    // Update field individually
    if (data instanceof Object && key) {
      onChange({ ...data, [key]: value })
      // Update panel that include object
    } else if (data instanceof Object) {
      const d = data
      for (const k in data) {
        d[k] = value
      }
      onChange(d)
      // Update panel that has no field
    } else {
      onChange(value)
    }
  }
  const getPanelValue = () => {
    if (!(data instanceof Object)) {
      return data
    }
    for (const k in data) {
      if (data[k]) {
        return true
      }
    }
    return false
  }

  const tableTitles = (childKey: keyof PropType<Obj, typeof parentKey>): string => {
    switch (parentKey) {
      case 'traction_0':
      case 'traction_90':
      case 'compression_0':
      case 'compression_90':
      case 'flexion_0':
      case 'flexion_90':
        return localeI18n.experiments.customDisplay.table.tcf[childKey]
      case 'dynamic_mechanical_analyses_ramp_1':
      case 'dynamic_mechanical_analyses_ramp_2':
        return localeI18n.experiments.customDisplay.table.dma[childKey]
      default:
        return localeI18n.experiments.customDisplay.table[parentKey][childKey]
    }
  }
  return (
    <details className='relative z-0 mt-8' open={true}>
      <summary className='py-1 px-2 text-xl font-semibold list-none text-black bg-ice-blue cursor-pointer'>
        <div className='flex flex-row justify-between items-center'>
          {localeI18n.experiments.customDisplay.table.titles[parentKey]}
          <SwitchInput className='mr-4' onChange={() => handleOnChange(!getPanelValue())} value={getPanelValue()} />
        </div>
      </summary>
      {data instanceof Object && (
        <div className='overflow-hidden py-5'>
          <div className='grid grid-cols-customDisplay gap-4 justify-between'>
            {Object.entries(data)
              .filter(([key]) => key !== 'reference')
              .map(([key, value]) => (
                <label className='flex flex-row items-center text-black cursor-pointer' key={key}>
                  <InputCheckbox checked={value} onChange={() => handleOnChange(!value, key)} small={true} />
                  <span className='ml-2'>{tableTitles(key as keyof PropType<Obj, typeof parentKey>)}</span>
                </label>
              ))}
          </div>
        </div>
      )}
    </details>
  )
}

const ExperimentsCustomDisplayModal: FC = () => {
  const { displayMatrix, resetDisplayMatrix, updateDisplayMatrix } = useContext(LabContext)

  const handlePanelChange = (key: keyof Obj, panel: Record<string, boolean> | boolean) => {
    updateDisplayMatrix({ ...displayMatrix, [key]: panel })
  }
  return (
    <div className='overflow-y-auto px-16 w-modal max-h-hscreen'>
      <div className='flex sticky top-0 z-10 flex-col pt-6 bg-white'>
        <div className='flex flex-row justify-between items-center'>
          <h3 className='text-2xl font-semibold text-black'>{i18n.experiments.customDisplay.title}</h3>
          <button className='flex flex-row mr-16 font-medium text-dusty-teal' onClick={resetDisplayMatrix}>
            <SvgInjector className='mr-4 w-5' src={SvgRefresh} />
            {i18n.experiments.customDisplay.reset}
          </button>
        </div>
        <div className='mt-5 w-full h-0.5 bg-pinkish-grey'></div>
      </div>
      <div className='flex  flex-col pb-8'>
        {Object.entries(displayMatrix).map(([key, panel]) => (
          <Panel
            data={panel}
            key={key}
            onChange={(data) => handlePanelChange(key as keyof Obj, data)}
            parentKey={key as keyof Obj}
          />
        ))}
      </div>
    </div>
  )
}

export { ExperimentsCustomDisplayModal }
