import { apiToken, apiUrl } from 'config'
import { SpatieFile } from 'interfaces'
import { fetchJson, FetchResult, getXSRFValueInCookie } from 'utils/fetch'

const request = (body?: BodyInit) =>
  new Request(`${apiUrl}/files/uploads`, {
    credentials: 'include',
    method: 'POST',
    body,
    headers: {
      'X-Api-Key': apiToken,
      Accept: 'application/json, text/plain, */*',
      'X-XSRF-TOKEN': getXSRFValueInCookie(document.cookie) ?? ''
    }
  })

const uploadFile = async (uuid: string, file: File): Promise<SpatieFile> => {
  const formData = new FormData()
  formData.append('file', file)
  formData.append('name', file.name)
  formData.append('uuid', uuid)
  const response = await fetch(request(formData))
  if (response.ok) {
    try {
      const parsed = await response.json()
      return parsed as SpatieFile
    } catch (e) {
      throw new Error(`Failed parsing server response: ${e}`)
    }
  } else {
    throw await response.json()
  }
}

const getPreSigned = (uuid: string, fileName: string) => {
  return fetchJson<{ url: string }, { uuid: string; file_name: string }>(
    'GET',
    `/files/pre-signed-url?file_name=${fileName}&uuid=${uuid}`
  )
}

const putFileOnS3 = (url: string, file: Blob) => {
  const headers = new Headers()

  const body = file

  const payload = {
    method: 'PUT',
    headers,
    body
  }
  return fetch(url, payload)
}

const uploadFileV2 = async (uuid: string, fileName: string): Promise<FetchResult<SpatieFile>> => {
  const payload = {
    file_name: fileName,
    uuid
  }

  return fetchJson<SpatieFile, { file_name: string; uuid: string }>('POST', '/files/uploads', payload)
}

const uploadWithPreupload = async (file: File, uuid: string): Promise<SpatieFile> => {
  const s3response = await getPreSigned(uuid, file.name)

  await putFileOnS3(s3response.data?.url ?? '', file)

  const postUploadResponse = await uploadFileV2(uuid, file.name)
  return postUploadResponse.data ?? { uuid: uuid, name: file.name, preview_url: '' }
}

export { uploadFile, uploadWithPreupload }
