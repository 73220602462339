import { Nullable } from 'interfaces'

enum LinearDensity {
  ROD = 'rod',
  PLATE = 'plate',
  OTHER = 'other'
}

interface ExperimentLinearDensityDto {
  density: number
  type: LinearDensity
  diameter: Nullable<number>
  width: Nullable<number>
  thickness: Nullable<number>
  other: Nullable<string>
}

interface ExperimentLinearDensity {
  id: number
  density: number
  type: LinearDensity
  diameter: Nullable<number>
  width: Nullable<number>
  thickness: Nullable<number>
  other: Nullable<string>
}
export { LinearDensity }
export type { ExperimentLinearDensity, ExperimentLinearDensityDto }
