import { FC } from 'react'
import { WhiteButtonLink } from 'ui/atoms'

export const ListParametersNavigation: FC = () => (
  <>
    <section>
      <h2 className='my-5 text-lg text-greyish-brown'>Général</h2>
      <WhiteButtonLink className='mb-0.5' href='/list-parameters/users'>
        Utilisateurs
      </WhiteButtonLink>
      <WhiteButtonLink href='/list-parameters/themes'>Thèmatique</WhiteButtonLink>
    </section>
    <section className='py-5 mt-5'>
      <h2 className='my-5 text-lg text-greyish-brown'>Description d'essai</h2>
      <WhiteButtonLink className='mb-0.5' href='/list-parameters/pultrusion-dies'>
        Filière
      </WhiteButtonLink>
      <WhiteButtonLink className='mb-0.5' href='/list-parameters/constituents'>
        Formulation
      </WhiteButtonLink>
      <WhiteButtonLink className='mb-0.5' href='/list-parameters/rovings'>
        Roving
      </WhiteButtonLink>
      <WhiteButtonLink href='/list-parameters/reinforcement'>Renforts transverses</WhiteButtonLink>
    </section>
  </>
)
