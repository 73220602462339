import { ApiError, Nullable } from 'interfaces'
import { FC, FormEvent, useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { AuthContext } from 'stores/auth'
import { ArkemaLogo, ButtonPrimary, InputError } from 'ui/atoms'
import { PasswordInput, TextInput } from 'ui/molecules'
import { InputType } from 'ui/molecules/TextInput'
import { getAuthenticatedUser, postLogin } from '../services/auth'

export const Login: FC = () => {
  const [email, setEmail] = useState<Nullable<string>>('')
  const [password, setPassword] = useState<Nullable<string>>('')
  const [isFetching, setisFetching] = useState(false)
  const [errors, setErrors] = useState<Nullable<ApiError>>(null)
  const navigate = useNavigate()
  const { updateUserProfile } = useContext(AuthContext)

  const fetchUser = async () => {
    const userProfile = (await getAuthenticatedUser())?.data
    updateUserProfile(userProfile)
    navigate('/experiments')
  }

  useEffect(() => {
    fetchUser()
  }, [])

  const onSubmitForm = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    setisFetching(true)
    setErrors(null)
    if (email && password) {
      try {
        await postLogin(email, password)
        navigate('/experiments')
      } catch (e) {
        setErrors(e as ApiError)
        setisFetching(false)
      }
    }
  }

  return (
    <div className='my-16 mx-20'>
      <div className='flex justify-center mb-16'>
        <ArkemaLogo />
      </div>
      <form className='flex relative flex-col justify-center mx-auto w-[350px]' onSubmit={(e) => onSubmitForm(e)}>
        <TextInput
          className='mb-8'
          errors={errors ? [] : null}
          label='Addresse Email'
          name='username'
          onChange={(value) => setEmail(value)}
          placeholder='Saisir ici votre adresse email'
          required={true}
          type={InputType.EMAIL}
          value={email}
        />
        <PasswordInput
          errors={errors ? [] : null}
          label='Mot de passe'
          name='password'
          onChange={(value) => setPassword(value)}
          required={true}
          value={password}
        />
        <div className='relative'>
          <InputError className='absolute' errors={errors?.message ? [errors.message] : null} />
        </div>
        <ButtonPrimary
          className='mx-auto mt-12 mb-3'
          disabled={!email || !password}
          isFetching={isFetching}
          type='submit'
        >
          Se connecter
        </ButtonPrimary>
      </form>
      <div className='mx-auto text-sm text-center text-dusty-teal'>
        <a href='/auth/forgot-password'>Mot de passe oublié ?</a>
      </div>
    </div>
  )
}
