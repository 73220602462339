import { FC } from 'react'

export const InputLabel: FC<{ className?: string; label?: string; htmlFor?: string; hint?: string }> = ({
  className,
  htmlFor,
  label,
  hint
}) => {
  return (
    <div className={className}>
      <label className='text-base font-semibold tracking-wide text-warm-grey' htmlFor={htmlFor}>
        {label}
        <span className='ml-2 text-xs font-normal opacity-50'>{hint}</span>
      </label>
    </div>
  )
}
