import { ExperimentResidualMonomer, ExperimentResidualMonomerDto } from 'interfaces/api/lab'
import { Dto } from 'modules/list-parameters/services/list-parameters.dto'
import { fetchJson } from 'utils/fetch'

/**
 *
 * @param experimentId
 * @param payload
 * @returns
 */
const postExperimentResidualMonomer = (experimentId: string, payload: Dto<ExperimentResidualMonomerDto>) => {
  return fetchJson<ExperimentResidualMonomer, Dto<ExperimentResidualMonomerDto>>(
    'POST',
    `/lab/experiments/${experimentId}/residual-monomer`,
    payload
  )
}

/**
 *
 * @param experimentId
 * @param payload
 * @returns
 */
const putExperimentResidualMonomer = (experimentId: string, payload: Dto<ExperimentResidualMonomerDto>) => {
  return fetchJson<ExperimentResidualMonomer, Dto<ExperimentResidualMonomerDto>>(
    'PUT',
    `/lab/experiments/${experimentId}/residual-monomer`,
    payload
  )
}

/**
 *
 * @param experimentId
 * @returns
 */
const getExperimentResidualMonomer = (experimentId: string) => {
  return fetchJson<ExperimentResidualMonomer>('GET', `/lab/experiments/${experimentId}/residual-monomer`)
}

export { postExperimentResidualMonomer, putExperimentResidualMonomer, getExperimentResidualMonomer }
