import { FC } from 'react'

import { InformationsPanel } from './InformationsPanel'

const DescriptionCreationTab: FC = () => {
  return (
    <div className='flex flex-row flex-1 items-start w-full'>
      <div className='flex-1 mr-10'>
        <InformationsPanel />
      </div>
    </div>
  )
}

export { DescriptionCreationTab }
