import { SvgTrash } from 'assets/svg'
import { Nullable } from 'interfaces'
import { PultrusionDie } from 'interfaces/api/management'
import {
  deleteManagementPultrusionDie,
  putManagementPultrusionDie
} from 'modules/list-parameters/services/pultrusionDies'
import { FC, useState } from 'react'
import { useMutation, useQueryClient } from 'react-query'
import { UnderlinedInput } from 'ui/atoms/UnderLinedInput'
import { DebouncedTextInput } from 'ui/molecules'

export const PultrusionDieItem: FC<{
  pultrusionDie: PultrusionDie
}> = ({ pultrusionDie }) => {
  const [pultrusionDieUpdateError, setPultrusionDieUpdateError] = useState<Nullable<string[]>>(null)

  const queryClient = useQueryClient()
  const pultrusionDieUpdate = useMutation(
    (params: { id: number; label: string }) => putManagementPultrusionDie(params.id, params.label),
    {
      onMutate: () => setPultrusionDieUpdateError(null),
      onSuccess: () => queryClient.invalidateQueries('pultrusionDies'),
      onError: (e: { message: string }) => setPultrusionDieUpdateError([e.message])
    }
  )
  const onInput = (label: string) => {
    pultrusionDieUpdate.mutate({ id: pultrusionDie.id, label })
  }

  const pultrusionDieDelete = useMutation(deleteManagementPultrusionDie, {
    onSuccess: () => queryClient.invalidateQueries('pultrusionDies')
  })

  return (
    <div className='flex justify-between items-center'>
      <div className='overflow-hidden w-28'>
        <DebouncedTextInput
          Component={UnderlinedInput}
          disabled={pultrusionDieUpdate.isLoading}
          errors={pultrusionDieUpdateError}
          onChange={onInput}
          value={pultrusionDie.label}
        />
      </div>
      {pultrusionDie.deletable && (
        <button
          className='w-5'
          data-testid='delete-button'
          onClick={() => pultrusionDieDelete.mutate(pultrusionDie.id)}
        >
          <img className='w-5 h-5' src={SvgTrash} />
        </button>
      )}
    </div>
  )
}
