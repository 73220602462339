// import { SvgAvatar, SvgCalendar, SvgFilter, SvgPaint, SvgRecherche, SvgTheme } from 'assets/svg'
import { SvgFilter, SvgPaint, SvgRecherche } from 'assets/svg'
import { UiContext } from 'contexts/ui.context'
import { FC, FormEvent, useContext, useRef, useState } from 'react'
import { ButtonPrimary, ButtonSecondary } from 'ui/atoms'
import { SvgInjector } from 'ui/atoms/SvgInjector'
import { ExperimentTableFilterModal } from './ExperimnentTableFilterModal'
import { ExperimentsCustomDisplayModal } from './ExperimentsCustomDisplayModal'
import { Field } from 'ui/atoms/Field'
import { UnderlinedInput } from 'ui/atoms/UnderLinedInput'
import { LabContext } from 'modules/lab/contexts'
import { Badge } from 'ui/atoms/Badge'
import { Nullable } from 'interfaces'

const SearchModal: FC = () => {
  const input = useRef<HTMLInputElement>(null)
  const [search, setSearch] = useState<Nullable<string>>(null)

  const { updateModal } = useContext(UiContext)
  const { updateFilters, filters } = useContext(LabContext)
  const handleModalSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    updateModal({
      content: null,
      displayed: false
    })
    updateFilters({
      ...filters,
      experiment: {
        ...filters.experiment,
        search: search ? search : null
      }
    })
  }
  const handleModalCancellation = () => {
    updateModal({
      content: null,
      displayed: false
    })
  }

  return (
    <div className='px-8 pt-6 pb-8'>
      <form onSubmit={handleModalSubmit}>
        <Field label='Rechercher un essai'>
          <UnderlinedInput
            className='py-4 px-2'
            errors={null}
            onChange={setSearch}
            placeholder='RO781117_33'
            ref={input}
            tabIndex={0}
            value={search}
          />
        </Field>
        <div className='flex flex-row justify-between m-auto w-80'>
          <ButtonSecondary className='mt-8' onClick={handleModalCancellation} type='button'>
            Annuler
          </ButtonSecondary>
          <ButtonPrimary className='mt-8' type='submit'>
            Rechercher
          </ButtonPrimary>
        </div>
      </form>
    </div>
  )
}
const ExperimentsHeader: FC<{
  className?: string
}> = ({ className = '' }) => {
  const { updateModal } = useContext(UiContext)
  const { filters } = useContext(LabContext)

  const handleCustomDisplay = () => {
    updateModal({
      content: <ExperimentsCustomDisplayModal />,
      displayed: true
    })
  }

  const handleFilters = () => {
    updateModal({
      content: <ExperimentTableFilterModal />,
      displayed: true
    })
  }

  const handleSearch = () => {
    updateModal({
      content: <SearchModal />,
      displayed: true
    })
  }

  return (
    <div className={`${className} flex flex-wrap flex-row gap-7`}>
      <Badge displayed={filters.experiment?.search != null}>
        <ButtonSecondary className='py-2.5 px-4 border-0' onClick={handleSearch} type={'button'}>
          <div className='flex items-center font-normal text-gunmetal'>
            <SvgInjector className='w-4 h-4 text-cool-grey' src={SvgRecherche} />
            <span className='ml-2'>Rechercher un essai</span>
          </div>
        </ButtonSecondary>
      </Badge>
      {/* <ButtonPrimary type={'button'} className="px-4 bg-white">
        <div className="flex items-center font-normal text-gunmetal">
          <SvgInjector className="w-4 h-4 text-cool-grey" src={SvgCalendar} />
          <span className="ml-2 text-sm">Dates</span>
        </div>
      </ButtonPrimary>
      <ButtonPrimary type={'button'} className="px-4 bg-white">
        <div className="flex items-center font-normal text-gunmetal">
          <SvgInjector className="w-4 h-4 text-cool-grey" src={SvgAvatar} />
          <span className="ml-2 text-sm">Responsables</span>
        </div>
      </ButtonPrimary>
      <ButtonPrimary type={'button'} className="px-4 bg-white">
        <div className="flex items-center font-normal text-gunmetal">
          <SvgInjector className="w-4 h-4 text-cool-grey" src={SvgTheme} />
          <span className="ml-2 text-sm">Thèmes</span>
        </div>
      </ButtonPrimary> */}
      <ButtonSecondary className='py-2.5 px-4 border-0' onClick={handleFilters} type={'button'}>
        <div className='flex items-center font-normal text-gunmetal'>
          <SvgInjector className='w-4 h-4 text-cool-grey' src={SvgFilter} />
          <span className='ml-2'>Plus de filtres</span>
        </div>
      </ButtonSecondary>
      <ButtonSecondary className='py-2.5 px-4 border-0' onClick={handleCustomDisplay} type={'button'}>
        <div className='flex items-center font-normal text-gunmetal'>
          <SvgInjector className='w-4 h-4 text-cool-grey' src={SvgPaint} />
          <span className='ml-2'>Affichage personnalisé</span>
        </div>
      </ButtonSecondary>
      {/* <Modal isOpen={isFilterModalVisible} onClose={() => setIsFilterModalVisible(false)}>
        <ExperimentTableFilterModal
          filters={filters}
          onClose={() => setIsFilterModalVisible(false)}
          onSubmit={handleOnFilterSubmit}
        />
      </Modal> */}
    </div>
  )
}

export { ExperimentsHeader }
