import { Nullable } from 'interfaces'
import { Fiber } from 'interfaces/api/management'

enum ConstituentType {
  RESIN = 'resin',
  RELEASE_AGENT = 'release-agent',
  FILLER = 'filler',
  ADDITIVE = 'additive',
  PEROXYDE = 'peroxyde',
  ACCELERATOR = 'accelerator'
}

interface ThemeUpdateDto {
  label: string
}
interface ReinforcementUpdateDto {
  label: string
}

interface GenericConstituentDto {
  label: string
  type: 'accelerator' | 'resin' | 'filler' | 'release-agent' | 'peroxyde' | 'additive'
}

interface PultrusionDieUpdateDto {
  label: string
}

// Roving
interface RovingUpdateDto {
  fiber: Fiber
  label: string
}

interface RovingCreateDto {
  fiber: Fiber
  label: string
}

type Dto<T> = {
  [P in keyof T]?: Nullable<T[P]>
}
export { ConstituentType }
export type {
  Dto,
  ThemeUpdateDto,
  PultrusionDieUpdateDto,
  GenericConstituentDto,
  ReinforcementUpdateDto,
  RovingCreateDto,
  RovingUpdateDto
}
