import { UiContext } from 'contexts/ui.context'
import { Nullable, ToastProps } from 'interfaces'
import { FC, useContext, useState } from 'react'
import { useQuery, useQueryClient } from 'react-query'
import { useSearchParams } from 'react-router-dom'
import { ToastNotification } from 'ui/atoms/ToastNotification'
import { PaginationMenu } from 'ui/molecules/PaginationMenu'
import { FetchResult } from 'utils/fetch'
import { getManagementConfigurations, selectDefaultManagementConfiguration } from '../services'
import { ConfigurationPreview } from '../services/configuration.dto'
import { ConfigurationCard } from './ConfigurationCard'

export const ConfigurationList: FC = () => {
  const [searchParams] = useSearchParams()
  const [error, setError] = useState<Nullable<string>>(null)
  const [page, setPage] = useState<string>(searchParams.get('page') ?? '1')
  const [perPage, setPerPage] = useState<string>(searchParams.get('perPage') ?? '10')

  const fetchConfiguration = (page: string, perPage: string) => getManagementConfigurations(page, perPage)

  const { updateToast } = useContext(UiContext)
  const queryClient = useQueryClient()

  const query = useQuery(['lab.configurations', page, perPage], async () => await fetchConfiguration(page, perPage), {
    keepPreviousData: false
  })

  const onPaginationChange = (newPage: string, newPerPage: string) => {
    setPage(newPage)
    setPerPage(newPerPage)
  }

  const onSelectDefaultConfiguration = async (id: number) => {
    setError(null)
    const previousConfigurations: FetchResult<ConfigurationPreview[]> = queryClient.getQueryData([
      'lab.configurations',
      page,
      perPage
    ]) as FetchResult<ConfigurationPreview[]>
    queryClient.setQueryData(['lab.configurations', page, perPage], {
      ...previousConfigurations,
      data: previousConfigurations.data?.map((configuration: ConfigurationPreview) => {
        configuration.is_active = configuration.id === id ? !configuration.is_active : false
        return configuration
      })
    })
    try {
      await selectDefaultManagementConfiguration(id)
      updateToast({
        displayed: true,
        type: 'success',
        content: 'La configuration par défaut bien été enregistrées.'
      } as ToastProps)
    } catch (e) {
      updateToast({
        displayed: true,
        type: 'error',
        content: 'Une erreur est survenue.'
      } as ToastProps)
      setError("La nouvelle configuration par défaut n'a pas été prise en compte")
      queryClient.invalidateQueries('lab.configurations')
    }
  }

  return (
    <div>
      <div className='grid grid-cols-2 gap-16 pb-2 mt-10 mb-auto xl:grid-cols-3 2xl:grid-cols-4 3xl:grid-cols-5'>
        {query.data?.data &&
          query.data.data.map((config: ConfigurationPreview, index: number) => (
            <div className='h-auto' key={`config-${index}`}>
              <ConfigurationCard configuration={config} onSelectDefault={onSelectDefaultConfiguration} />
            </div>
          ))}
      </div>
      <div className='flex justify-end my-20'>
        <PaginationMenu meta={query.data?.meta ?? null} onChange={onPaginationChange} />
      </div>
      {error && <ToastNotification onClose={() => setError(null)} text={error} />}
    </div>
  )
}
