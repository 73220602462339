import { FC, ReactNode } from 'react'
import { Link } from 'react-router-dom'

const TableTdLink: FC<{ className?: string; children: ReactNode; id: number }> = ({
  className = '',
  children = '',
  id
}) => (
  <td className={`bg-white h-14 z-10 text-black font-normal text-sm ${className}`}>
    <Link className='flex flex-1 px-5' to={{ pathname: `/experiments/${id}` }}>
      {children}
    </Link>
  </td>
)
export { TableTdLink }
