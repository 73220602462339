import { UiContext } from 'contexts/ui.context'
import { ToastType } from 'core/constants'
import { i18n } from 'i18n'
import { ErrorObj, Extensions, Nullable } from 'interfaces'
import { EosinClassification, ExperimentEosin, ExperimentEosinDto } from 'interfaces/api/lab'
import { FileManagement } from 'modules/files'
import { getExperimentEosin, putExperimentEosin, postExperimentEosin } from 'modules/lab/services'
import { Dto } from 'modules/list-parameters/services/list-parameters.dto'
import { forwardRef, memo, useContext, useState } from 'react'
import { Controller, ControllerRenderProps, SubmitHandler, useForm } from 'react-hook-form'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { useParams } from 'react-router-dom'

import { ButtonPrimary, ButtonSecondary, InputError, Panel } from 'ui/atoms'
import { Field } from 'ui/atoms/Field'
import { InputRadio } from 'ui/atoms/InputRadio'

import { UnderlinedTextarea } from 'ui/atoms/UnderLinedInput'
import { SpinnerLoaderMask } from 'ui/molecules'
import { EosinFormValues } from './ProductionDataTab.type'

const EosinPanel = memo(
  forwardRef<HTMLFormElement>((_, ref) => {
    const { experimentId } = useParams()

    if (!experimentId) {
      return null
    }

    const [canSubmit, setCanSubmit] = useState<boolean>(false)
    const [thermocoupleError, setThermocoupleError] =
      useState<Nullable<Record<keyof ExperimentEosinDto, string[]>>>(null)
    const { updateToast } = useContext(UiContext)
    const queryClient = useQueryClient()
    const { control, handleSubmit, reset } = useForm<EosinFormValues>()

    const get = useQuery(['lab.experiment.eosin', experimentId], async () => await getExperimentEosin(experimentId), {
      onSuccess: ({ data }) => {
        setCanSubmit(true)
        resetForm(data)
      }
    })

    const resetForm = (data?: Nullable<ExperimentEosin>) => {
      data ? reset(data) : reset()
    }

    const post = useMutation((data: Dto<ExperimentEosinDto>) => postExperimentEosin(experimentId, data), {
      onMutate: () => {
        setThermocoupleError(null)
      },
      onSuccess: (data) => {
        queryClient.setQueryData(['lab.experiment.eosin', experimentId], data)
        updateToast({
          content: 'Microscopie créée avec succès',
          displayed: true,
          type: ToastType.SUCCESS
        })
      },
      onError: (e: Error & { errors?: ErrorObj<ExperimentEosinDto> }) => {
        setThermocoupleError(e.errors ?? null)
        updateToast({
          content: e.message ?? 'Une erreur est survenue',
          displayed: true,
          type: ToastType.ERROR
        })
      }
    })
    const put = useMutation((data: Dto<ExperimentEosinDto>) => putExperimentEosin(experimentId, data), {
      onMutate: () => {
        setThermocoupleError(null)
      },
      onSuccess: (data) => {
        queryClient.setQueryData(['lab.experiment.eosin', experimentId], data)
        updateToast({
          content: 'Microscopie mise à jour avec succès',
          displayed: true,
          type: ToastType.SUCCESS
        })
      },
      onError: (e: Error & { errors?: ErrorObj<ExperimentEosinDto> }) => {
        setThermocoupleError(e.errors ?? null)
        updateToast({
          content: e.message ?? 'Une erreur est survenue',
          displayed: true,
          type: ToastType.ERROR
        })
      }
    })

    const handleRatingChange = (
      field: ControllerRenderProps<EosinFormValues, 'classification'>,
      value: EosinClassification
    ) => {
      if (field.value === value) {
        field.onChange(undefined)
        return
      }
      field.onChange(value)
    }

    const handleOnSubmit: SubmitHandler<EosinFormValues> = (payload) => {
      get.data?.data ? put.mutate(payload) : post.mutate(payload)
    }

    return (
      <form onSubmit={handleSubmit(handleOnSubmit)} ref={ref}>
        <Panel openable={true} title='Éosine'>
          <SpinnerLoaderMask
            message={get.isFetching ? i18n.isLoading : i18n.isSaving}
            spinning={get.isFetching || post.isLoading || put.isLoading}
          >
            <div className='flex flex-col items-center'>
              <Controller
                control={control}
                defaultValue={[]}
                name='pictures'
                render={({ field }) => (
                  <div className='flex flex-col'>
                    <FileManagement
                      accept={[Extensions.PNG, Extensions.JPEG]}
                      multiple={true}
                      onChange={field.onChange}
                      onReadyToSubmitChange={setCanSubmit}
                      value={field.value}
                    />
                    <InputError errors={thermocoupleError?.pictures ?? null} />
                  </div>
                )}
              />
              <Field className='flex flex-row justify-self-start self-start mb-3' label='Classement'>
                <Controller
                  control={control}
                  name='classification'
                  render={({ field }) => (
                    <>
                      <button
                        className='cursor-pointer'
                        onClick={() => handleRatingChange(field, EosinClassification.OK)}
                        type='button'
                      >
                        <InputRadio
                          className='pointer-events-none'
                          id={EosinClassification.OK}
                          label='OK'
                          name='linear-type'
                          value={field.value}
                        />
                      </button>
                      <button
                        className='ml-10'
                        onClick={() => handleRatingChange(field, EosinClassification.NOK)}
                        type='button'
                      >
                        <InputRadio
                          className='pointer-events-none'
                          id={EosinClassification.NOK}
                          label='NOK'
                          name='linear-type'
                          value={field.value}
                        />
                      </button>
                    </>
                  )}
                />
              </Field>
              <Field className='justify-self-start w-full' label='Commentaire'>
                <Controller
                  control={control}
                  name='comment'
                  render={({ field }) => (
                    <UnderlinedTextarea
                      errors={thermocoupleError?.comment ?? null}
                      onChange={field.onChange}
                      placeholder=''
                      value={field.value}
                    />
                  )}
                />
              </Field>
            </div>
            <div className='flex flex-row justify-around mx-auto mt-10 w-full max-w-2xl'>
              <ButtonSecondary className='px-14 mr-4' onClick={() => resetForm(get.data?.data)} type='button'>
                Annuler
              </ButtonSecondary>
              <ButtonPrimary className='px-14' disabled={!canSubmit} type='submit'>
                Valider
              </ButtonPrimary>
            </div>
          </SpinnerLoaderMask>
        </Panel>
      </form>
    )
  })
)

export { EosinPanel }
