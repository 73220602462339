import { ExperimentViscosityDto } from 'interfaces/api/lab'
import { Dto } from 'modules/list-parameters/services/list-parameters.dto'
import { fetchJson } from 'utils/fetch'

/**
 *
 * @param experimentId
 * @param payload: Dto<ExperimentViscosityDto>
 * @returns void
 */
const getExperimentViscosity = (experimentId: string) => {
  return fetchJson<ExperimentViscosityDto, Dto<ExperimentViscosityDto>>(
    'GET',
    `/lab/experiments/${experimentId}/viscosity`
  )
}
/**
 *
 * @param experimentId
 * @param payload: Dto<ExperimentViscosityDto>
 * @returns void
 */
const postExperimentViscosity = (experimentId: string, payload: Dto<ExperimentViscosityDto>) => {
  return fetchJson<ExperimentViscosityDto, Dto<ExperimentViscosityDto>>(
    'POST',
    `/lab/experiments/${experimentId}/viscosity`,
    payload
  )
}

/**
 *
 * @param experimentId
 * @param payload: Dto<ExperimentViscosityDto>
 * @returns void
 */
const putExperimentViscosity = (experimentId: string, payload: Dto<ExperimentViscosityDto>) => {
  return fetchJson<ExperimentViscosityDto, Dto<ExperimentViscosityDto>>(
    'PUT',
    `/lab/experiments/${experimentId}/viscosity`,
    payload
  )
}

export { getExperimentViscosity, putExperimentViscosity, postExperimentViscosity }
