import { Reinforcement } from 'interfaces/api/management'
import { fetchJson } from 'utils/fetch'
import { ReinforcementUpdateDto } from './list-parameters.dto'

/**
 *
 * @param page
 * @param perPage
 * @returns
 */
const getManagementReinforcements = (page: string, perPage: string) => {
  return fetchJson<Reinforcement[], undefined>(
    'GET',
    `/management/transverse-reinforcements?page=${page}&per_page=${perPage}`
  )
}

/**
 *
 * @param label
 * @returns
 */
const postManagementReinforcement = (label: string) => {
  return fetchJson<Reinforcement, ReinforcementUpdateDto>('POST', '/management/transverse-reinforcements', { label })
}

/**
 *
 * @param index
 * @param label
 * @returns
 */
const putManagementReinforcement = (index: number, label: string) => {
  return fetchJson<Reinforcement, ReinforcementUpdateDto>('PUT', `/management/transverse-reinforcements/${index}`, {
    label
  })
}

/**
 *
 * @param index
 * @returns
 */
const deleteManagementReinforcement = (index: number) => {
  return fetchJson('DELETE', `/management/transverse-reinforcements/${index}`)
}

export {
  deleteManagementReinforcement,
  getManagementReinforcements,
  postManagementReinforcement,
  putManagementReinforcement
}
