import { FileStatus } from 'core/constants/file'
import { Extensions, LiteMedia } from 'interfaces'
import { FC, memo, useContext, useEffect } from 'react'
import { v4 as uuidv4 } from 'uuid'
import { uploadFile, uploadWithPreupload } from '../services'
import { FileManagementChildProps, FileManagementProps, LiteMediaWithStatus } from '../types'
import { fileToLiteMedia, getAcceptedExtensions, getExtension, spatieToLiteMedia } from '../utils'
import { FileWithStatus } from './FileWithStatus'
import { FilesProvider } from '../context'
import { FilesContext } from '../context/files.context'
import { FileInput } from 'ui/molecules'
import { UiContext } from 'contexts/ui.context'
import { ToastType } from 'core/constants'

const FileManagementChild: FC<FileManagementChildProps> = memo(
  ({
    readonly = false,
    className = '',
    value = [],
    accept = [Extensions.ALL],
    multiple = false,
    onChange,
    onReadyToSubmitChange,
    error,
    hasTask
  }) => {
    const { files, updateFiles, updateFile, deleteFile, addFile, replaceFile } = useContext(FilesContext)
    const { updateToast } = useContext(UiContext)

    const onDeleteFile = (file: LiteMedia) => {
      deleteFile(file.uuid)
    }

    /**
     * Appelé à chaque event onChange de l'input file
     * @param event
     */
    const handleOnChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
      const idleFiles = event.target.files ? Array.from(event.target.files) : []
      idleFiles.forEach(async (idleFile) => {
        const uuid = uuidv4()
        try {
          const preUploadLiteMedia = fileToLiteMedia(idleFile, uuid, FileStatus.UPLOADING)
          multiple ? addFile(preUploadLiteMedia) : replaceFile(preUploadLiteMedia)
          const preuploadedFile = await uploadWithPreupload(idleFile, uuid)

          if (!uploadFile) {
            throw new Error('Unable to parse server response')
          }
          const postUploadLiteMedia = spatieToLiteMedia(
            preuploadedFile,
            hasTask ? FileStatus.PROCESSING : FileStatus.AVAILABLE
          )
          postUploadLiteMedia.uuid = uuid
          multiple ? updateFile(postUploadLiteMedia) : replaceFile(postUploadLiteMedia)
        } catch (e) {
          const postUploadFailLiteMedia = fileToLiteMedia(idleFile, uuid, FileStatus.ERROR)
          multiple ? updateFile(postUploadFailLiteMedia) : replaceFile(postUploadFailLiteMedia)
          updateToast({
            content: (e as { message: string }).message ?? "Une erreur est survenue lors de l'envoi du fichier",
            displayed: true,
            type: ToastType.ERROR
          })
        }
      })
    }

    /**
     * Compare deux tableaux de LiteMedia pour voir les différences (basé sur l'UUID)
     * @param a
     * @param b
     * @returns
     */
    const liteMediasEquals = (a: LiteMedia[], b: LiteMedia[]): boolean => {
      const arr1 = a.map(({ uuid }) => uuid)
      const arr2 = b.map(({ uuid }) => uuid)
      const containsAll = (arr1: string[], arr2: string[]) => arr2.every((arr2Item) => arr1.includes(arr2Item))
      return containsAll(arr1, arr2) && containsAll(arr2, arr1)
    }

    /**
     * Mise à jour des fichiers une fois uploadés
     */
    useEffect(() => {
      if (value.length === 0) {
        return
      }
      const newFiles: LiteMediaWithStatus[] = value.map((f) => ({ ...f, status: FileStatus.IDLE }))
      const reducer = (p: LiteMediaWithStatus[], c: LiteMediaWithStatus) =>
        !p.map(({ uuid }) => uuid).includes(c.uuid) ? [...p, c] : p
      const filesUniqueByUuid = (f: LiteMediaWithStatus[]): LiteMediaWithStatus[] => f.reduce(reducer, [])
      updateFiles(filesUniqueByUuid([...files, ...newFiles]))
    }, [value])

    /**
     * Callback si les fichiers sont mis à jour
     */
    useEffect(() => {
      const hasUploadInProgress = files.some((f) => f.status === FileStatus.UPLOADING)
      const hasUploadInProcess = files.some((f) => f.status === FileStatus.PROCESSING)
      if (onReadyToSubmitChange) {
        onReadyToSubmitChange(!hasUploadInProgress && !hasUploadInProcess)
      }
      if (liteMediasEquals(value, files)) {
        return
      }
      onChange(files)
    }, [files])

    return (
      <div className={`${className} w-[360px]`}>
        {!readonly && <FileInput accept={accept} multiple={multiple} onChange={handleOnChange} />}
        {!readonly && accept.length && (
          <span className='inline-block mt-2 mb-7 text-xs text-greyish-brown'>
            formats acceptés : {getAcceptedExtensions(accept)}
          </span>
        )}
        <div className='flex flex-col'>
          {files.map((file) => (
            <FileWithStatus
              className='mb-4'
              extension={getExtension(file)}
              fileName={file.file_name}
              fileUrl={file.file_url}
              key={file.uuid}
              onDelete={() => onDeleteFile(file)}
              readonly={readonly}
              status={file.status}
              statusText={error ?? ''}
              uuid={file.uuid}
            />
          ))}
        </div>
      </div>
    )
  }
)
const FileManagement: FC<FileManagementProps> = memo(({ ...props }) => (
  <FilesProvider>
    <FileManagementChild {...props} />
  </FilesProvider>
))

export { FileManagement, FileManagementChild }
