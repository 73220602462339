interface Theme {
  id: number
  label: string
  deletable: boolean
}

interface Reinforcement {
  id: number
  label: string
  deletable: boolean
}

interface GenericConstituent {
  id: number
  label: string
  type: 'accelerator' | 'resin' | 'filler' | 'release-agent' | 'peroxyde' | 'additive'
  deletable: boolean
}

enum Fiber {
  CARBON = 'carbon',
  GLASS = 'glass',
  MIX = 'mix',
  NATURAL_FIBER = 'natural-fiber'
}

interface PultrusionDie {
  id: number
  label: string
  deletable: boolean
}

interface Roving {
  id: number
  label: string
  deletable: boolean
  fiber: Fiber
}

interface UserDTO {
  first_name: string
  last_name: string
  email: string
}

interface User {
  id: number
  first_name: string
  last_name: string
  email: string
  deletable: boolean
}
export { Fiber }
export type { Theme, Reinforcement, Roving, PultrusionDie, GenericConstituent, UserDTO, User }
