import { residual_monomer } from 'modules/lab/schemas'
import { FC } from 'react'
import { z } from 'zod'
import { TableTd } from './TableTd'

const ResidualMonomerTd: FC<{
  matrix: Record<string, boolean>
  data: z.infer<typeof residual_monomer>
}> = ({ matrix, data }) => {
  return <>{matrix.average && <TableTd className='text-left'>{data?.average}</TableTd>}</>
}

export { ResidualMonomerTd }
