import { SvgCreate, SvgTrash } from 'assets/svg'
import { UiContext } from 'contexts/ui.context'
import { ToastType } from 'core/constants'
import { i18n } from 'i18n'
import { ErrorObj, Extensions, Nullable } from 'interfaces'
import { ExperimentResidualMonomer, ExperimentResidualMonomerDto } from 'interfaces/api/lab'
import { FileManagement } from 'modules/files'
import {
  getExperimentResidualMonomer,
  postExperimentResidualMonomer,
  putExperimentResidualMonomer
} from 'modules/lab/services'
import { forwardRef, memo, useContext, useState } from 'react'
import { Controller, ControllerRenderProps, SubmitHandler, useForm } from 'react-hook-form'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { useParams } from 'react-router-dom'

import { ButtonPrimary, ButtonSecondary, InputError, Panel } from 'ui/atoms'
import { Field } from 'ui/atoms/Field'
import { SvgInjector } from 'ui/atoms/SvgInjector'
import { UnderlinedNumberInput } from 'ui/atoms/UnderLinedInput'
import { SpinnerLoaderMask } from 'ui/molecules'

import { ResidualMonomerFormValues } from './ProductionDataTab.type'

const ResidualMonomerPanel = memo(
  forwardRef<HTMLFormElement>((_, ref) => {
    const { experimentId } = useParams()

    if (!experimentId) {
      return null
    }

    const [canSubmit, setCanSubmit] = useState<boolean>(false)
    const [residualMonomerError, setResidualMonomerError] =
      useState<Nullable<ErrorObj<ExperimentResidualMonomerDto>>>(null)
    const { updateToast } = useContext(UiContext)
    const queryClient = useQueryClient()
    const { control, setValue, handleSubmit, watch, reset } = useForm<ResidualMonomerFormValues>()

    const get = useQuery(
      ['lab.experiment.residualMonomer', experimentId],
      async () => await getExperimentResidualMonomer(experimentId),
      {
        onSuccess: ({ data }) => {
          setCanSubmit(true)
          resetForm(data)
        }
      }
    )
    const resetForm = (data?: Nullable<ExperimentResidualMonomer>) => {
      data ? reset(data) : reset({ lines: [] })
    }

    const post = useMutation(
      (data: Omit<ExperimentResidualMonomer, 'id'>) => postExperimentResidualMonomer(experimentId, data),
      {
        onMutate: () => {
          setResidualMonomerError(null)
        },
        onSuccess: (data) => {
          queryClient.setQueryData(['lab.experiment.residualMonomer', experimentId], data)
          updateToast({
            content: 'Monomère résiduels créée avec succès',
            displayed: true,
            type: ToastType.SUCCESS
          })
        },
        onError: (e: Error & { errors?: ErrorObj<ExperimentResidualMonomerDto> }) => {
          setResidualMonomerError(e.errors ?? null)
          updateToast({
            content: e.message ?? 'Une erreur est survenue',
            displayed: true,
            type: ToastType.ERROR
          })
        }
      }
    )
    const put = useMutation(
      (data: Omit<ExperimentResidualMonomer, 'id'>) => putExperimentResidualMonomer(experimentId, data),
      {
        onMutate: () => {
          setResidualMonomerError(null)
        },
        onSuccess: (data) => {
          queryClient.setQueryData(['lab.experiment.residualMonomer', experimentId], data)
          updateToast({
            content: 'Monomère résiduels mis à jour avec succès',
            displayed: true,
            type: ToastType.SUCCESS
          })
        },
        onError: (e: Error & { errors?: ErrorObj<ExperimentResidualMonomerDto> }) => {
          setResidualMonomerError(e.errors ?? null)
          updateToast({
            content: e.message ?? 'Une erreur est survenue',
            displayed: true,
            type: ToastType.ERROR
          })
        }
      }
    )

    const handleOnSubmit: SubmitHandler<ResidualMonomerFormValues> = (payload) => {
      get.data?.data ? put.mutate(payload) : post.mutate(payload)
    }
    const handleCreateNewLine = () => {
      const lastId = watch('lines')?.length ?? 0
      setValue(`lines.${lastId}.order`, lastId)
      setValue(`lines.${lastId}.mam_content`, 0)
      updateOrder()
    }
    const handleDeleteLine = (id: number) => {
      const lines = watch('lines').filter((_, i) => i !== id)
      setValue(`lines`, lines)
      updateOrder()
    }
    const updateOrder = () => {
      const lines = watch(`lines`)
      for (let i = 0; i < lines.length; i++) {
        setValue(`lines.${i}.order`, i)
      }
    }

    const handleMamContentChange = (
      value: Nullable<number>,
      field: ControllerRenderProps<ResidualMonomerFormValues, `lines.${number}.mam_content`>
    ) => {
      field.onChange(value)
      const lines = watch('lines')
      if (lines.length === 0) {
        return
      }
      const sum = lines.reduce((p, c) => p + c.mam_content, 0)
      const average = sum / lines.length
      const averageWithTwoDigits = Math.round(average * 100) / 100
      const variance = lines.reduce((p, c) => p + Math.pow(c.mam_content - average, 2), 0) / lines.length
      const std = Math.sqrt(variance)
      const stdWithTwoDigits = Math.round(std * 100) / 100
      setValue(`average`, averageWithTwoDigits)
      setValue(`std`, stdWithTwoDigits)
    }

    return (
      <form onSubmit={handleSubmit(handleOnSubmit)} ref={ref}>
        <Panel openable={true} title='Monomère résiduels'>
          <SpinnerLoaderMask
            message={get.isFetching ? i18n.isLoading : i18n.isSaving}
            spinning={get.isFetching || post.isLoading || put.isLoading}
          >
            <div className='flex flex-col flex-1'>
              <div className='flex flex-1 justify-center items-center'>
                <Controller
                  control={control}
                  defaultValue={[]}
                  name='files'
                  render={({ field }) => (
                    <div className='flex flex-col'>
                      <Field className='' label='Importer un fichier'>
                        <FileManagement
                          accept={[Extensions.ALL]}
                          multiple={true}
                          onChange={field.onChange}
                          onReadyToSubmitChange={setCanSubmit}
                          value={field.value}
                        />
                        <InputError errors={residualMonomerError?.files ?? null} />
                      </Field>
                    </div>
                  )}
                />
              </div>
              <div className='flex flex-col'>
                <div className='flex flex-row'>
                  <Controller
                    control={control}
                    name={`average`}
                    render={({ field }) => (
                      <Field hasError={false} label='Moyenne'>
                        <UnderlinedNumberInput
                          className='mr-20 w-40'
                          errors={residualMonomerError?.average ?? null}
                          onChange={field.onChange}
                          placeholder=''
                          value={field.value}
                        />
                      </Field>
                    )}
                  />

                  <Controller
                    control={control}
                    name={`std`}
                    render={({ field }) => (
                      <Field hasError={false} label='Écart type'>
                        <UnderlinedNumberInput
                          className='w-40'
                          errors={residualMonomerError?.std ?? null}
                          onChange={field.onChange}
                          placeholder=''
                          value={field.value}
                        />
                      </Field>
                    )}
                  />
                </div>
                {watch('lines')?.length > 0 ? (
                  <div className='flex flex-row mt-12'>
                    <label className='mr-20 w-40 font-semibold text-black'>Numéro</label>
                    <label className='w-40 font-semibold text-black'>Teneur MAM (%wt)</label>
                  </div>
                ) : (
                  <p className='mt-12 italic text-pinkish-grey'>Aucune analyse enregistrée</p>
                )}
                {watch('lines')?.map((l, i) => (
                  <div className='flex flex-row mt-5' key={i}>
                    <Controller
                      control={control}
                      name={`lines.${i}.order`}
                      render={({ field }) => (
                        <UnderlinedNumberInput
                          className='mr-20 w-40'
                          errors={null}
                          placeholder=''
                          readOnly={true}
                          value={field.value + 1}
                        />
                      )}
                    />

                    <Controller
                      control={control}
                      name={`lines.${i}.mam_content`}
                      render={({ field }) => (
                        <UnderlinedNumberInput
                          className='w-40'
                          errors={null}
                          onChange={(value) => handleMamContentChange(value, field)}
                          placeholder=''
                          value={field.value}
                        />
                      )}
                    />
                    <button className='ml-8' onClick={() => handleDeleteLine(i)} type='button'>
                      <SvgInjector className='inline-block w-3.5 h-3.5 text-warm-grey' src={SvgTrash} />
                    </button>
                  </div>
                ))}
              </div>
              <button className='flex items-center mt-4' onClick={handleCreateNewLine} type='button'>
                <img className='mr-4 w-7 h-7' src={SvgCreate} />
                <span className='font-medium whitespace-nowrap'>Ajouter une analyse</span>
              </button>
              <div className='flex flex-row justify-center mt-10'>
                <ButtonSecondary className='px-14 mr-40' onClick={() => resetForm(get.data?.data)} type='button'>
                  Annuler
                </ButtonSecondary>
                <ButtonPrimary className='px-14' disabled={!canSubmit} type='submit'>
                  Valider
                </ButtonPrimary>
              </div>
            </div>
          </SpinnerLoaderMask>
        </Panel>
      </form>
    )
  })
)

export { ResidualMonomerPanel }
