import { ChangeEvent, FC, useContext, useState } from 'react'
import { FileWithStatus } from 'modules/files'
import { FileStatus } from 'core/constants/file'
import { FileInput } from 'ui/molecules'
import { Extensions, State } from 'interfaces'
import { Task, TaskPayload } from 'interfaces/api/task'
import { v4 as uuidv4 } from 'uuid'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { fileToLiteMedia, spatieToLiteMedia } from 'modules/files/utils'
import { uploadWithPreupload } from 'modules/files/services'
import { FilesContext } from 'modules/files/context/files.context'
import { Modal } from 'ui/atoms/Modal'
import { ButtonPrimary, ButtonSecondary } from 'ui/atoms'
import { SvgHint } from 'assets/svg'
import { UnderlinedInput } from 'ui/atoms/UnderLinedInput'
import { getTasks, postTasks, putTask } from '../services'
import { InputType } from 'ui/molecules/TextInput'

const dataLabels = new Map()
dataLabels.set('study_temperature', { label: "Température d'étude (°C): ", type: InputType.NUMBER })

const MissingDataModal: FC<{ onAccept: () => void; onClose: () => void; isOpen: boolean; task: Task }> = ({
  onAccept,
  task,
  onClose,
  isOpen
}) => {
  const [missingData, setMissingData] = useState<Map<string, string>>(new Map(task.missing_data?.map((m) => [m, ''])))
  const [errors, setErrors] = useState<Map<string, string[]>>(new Map())
  const put = useMutation(() => putTask(task.id.toString(), Object.fromEntries(missingData)), {
    onError: (e: { status: number; message: string; errors: Record<string, string[]> }) => {
      setErrors(new Map(Object.entries(e.errors)))
    },
    onSuccess: onAccept
  })

  const handleOnChange = (key: string, value: string) => {
    setMissingData(new Map(missingData.set(key, value)))
  }

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose}>
        <div className='flex flex-col justify-center w-96'>
          <img className='mx-auto mb-4' height={28} src={SvgHint} width={28} />
          <div className='mx-auto mb-6 font-medium'>
            <div>Veuillez renseigner les données manquantes pour le fichier: </div>
            <div className='w-full max-w-full font-semibold truncate' title={task.file}>
              {task.file}
            </div>
          </div>
          {task.missing_data?.map((missingDataKey) => (
            <div className='flex justify-between' key={missingDataKey}>
              <span className='font-normal whitespace-nowrap'>
                {dataLabels.has(missingDataKey) ? dataLabels.get(missingDataKey).label : missingDataKey}
              </span>
              <UnderlinedInput
                className='ml-12 w-32'
                errors={errors.get(missingDataKey) ?? null}
                onChange={(value) => handleOnChange(missingDataKey, value)}
                type={dataLabels.get(missingDataKey)?.type ?? InputType.TEXT}
                value={missingData.get(missingDataKey)}
              />
            </div>
          ))}
          <div className='flex justify-between mt-6'>
            <ButtonSecondary className='px-6' onClick={onClose} type='button'>
              Annuler
            </ButtonSecondary>
            <ButtonPrimary className='px-6' disabled={put.isLoading} onClick={put.mutate} type='submit'>
              Valider
            </ButtonPrimary>
          </div>
        </div>
      </Modal>
    </>
  )
}

const FileOverrideModal: FC<{
  isFetching: boolean
  fileName: string
  onAccept: () => void
  onClose: () => void
  isOpen: boolean
}> = ({ isFetching, fileName, onClose, onAccept, isOpen }) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <div className='flex flex-col justify-center w-96'>
        <img className='mx-auto mb-4' height={28} src={SvgHint} width={28} />
        <div className='mx-auto mb-6 font-semibold'>
          Ce fichier <span className='whitespace-nowrap'>{fileName}</span> existe déjà, souhaitez vous le remplacer ?
        </div>
        <div className='flex justify-between'>
          <ButtonSecondary className='px-6' onClick={onClose} type='button'>
            Annuler
          </ButtonSecondary>
          <ButtonPrimary className='px-6' disabled={isFetching} onClick={onAccept} type='submit'>
            Valider
          </ButtonPrimary>
        </div>
      </div>
    </Modal>
  )
}

const TaskFile: FC<{ task: Task; errors: Map<string, string> }> = ({ task, errors }) => (
  <FileWithStatus
    deletable={false}
    downloadable={false}
    extension={task.file.split('.').pop() ?? '*'}
    fileName={task.file}
    fileUrl='ipsum'
    onDelete={() => null}
    status={
      errors.get(task.media_uuid)
        ? FileStatus.ERROR
        : {
            running: FileStatus.PROCESSING,
            'data-required': FileStatus.WARNING,
            finished: FileStatus.AVAILABLE,
            failed: FileStatus.WARNING
          }[task.state]
    }
    statusText={errors.get(task.media_uuid)}
    uuid={task.media_uuid}
  />
)

const taskPayloadToTask = ({ file }: TaskPayload): Task => ({
  id: 0,
  error: null,
  results: null,
  analysis: '',
  missing_data: null,
  media_uuid: file[0].uuid,
  state: {
    processing: State.RUNNING,
    available: State.FINISHED,
    error: State.FAILED,
    idle: State.RUNNING,
    uploading: State.RUNNING,
    none: State.RUNNING,
    warning: State.DATA_REQUIRED
  }[file[0].status],
  file: file[0].file_name
})

const BatchImport: FC = () => {
  const { addFile } = useContext(FilesContext)
  const [taskPayloads, setTaskPayloads] = useState<Map<string, TaskPayload>>(new Map())
  const [overrideRequests, setOverrideRequest] = useState<Map<string, TaskPayload>>(new Map())
  const [additionalDataRequests, setAdditionalDataRequest] = useState<Map<string, TaskPayload>>(new Map())
  const [errors, setErrors] = useState<Map<string, string>>(new Map())
  const [openedOverrideModal, setOpenOverrideModal] = useState<string | null>(null)
  const [missingDataModal, setMissingDataModal] = useState<string | null>(null)

  const post = useMutation((data: TaskPayload) => postTasks(data), {
    onError: (e: { status: number; message: string }, data) => {
      const payload = data
      const file = payload.file[0]
      if (e.status === 409) {
        file.status = FileStatus.WARNING
        setOverrideRequest(overrideRequests.set(file.uuid, payload))
      } else {
        file.status = FileStatus.ERROR
        setErrors(errors.set(payload.file[0].uuid, e.message))
      }
      setTaskPayloads(taskPayloads.set(payload.file[0].uuid, payload))
    },
    onSuccess: (data) => {
      handleTimeout(data?.data ? [data?.data] : [])
    }
  })

  const queryClient = useQueryClient()

  const handleTimeout = (data: Task[]) => {
    const timeOut = setTimeout(() => {
      const hasPendingFile = data?.some((t) => t.state === 'running')
      if (!hasPendingFile) {
        return
      }
      queryClient.invalidateQueries('batch-imports')
      clearTimeout(timeOut)
    }, 1000)
  }

  const query = useQuery('batch-imports', getTasks, {
    onSuccess: (data) => {
      if (!data.data) {
        return
      }
      data.data.forEach((t) => {
        if (t.state === 'data-required') {
          setAdditionalDataRequest(
            new Map(additionalDataRequests.set(t.media_uuid, taskPayloads.get(t.media_uuid) as TaskPayload))
          )
        }
        if (t.state === 'failed') {
          setErrors(errors.set(t.media_uuid, t.error ?? 'Erreur'))
        }
      })
      ;[...taskPayloads.keys()].forEach((k: string) => {
        if (data.data?.map((t: Task) => t.media_uuid).includes(k)) {
          taskPayloads.delete(k)
          setTaskPayloads(taskPayloads)
        }
      })
      handleTimeout(data.data)
    }
  })

  const handleOnChange = async (event: ChangeEvent<HTMLInputElement>) => {
    setTaskPayloads(new Map())
    const idleFiles: File[] = Array.from(event.target.files ?? [])
    for (const idleFile of idleFiles) {
      const uuid = uuidv4()
      const prePayload = {
        file: [{ uuid, file_name: idleFile.name, status: FileStatus.UPLOADING, file_url: '', created_at: '' }],
        override: false
      } as TaskPayload
      setTaskPayloads(new Map(taskPayloads.set(uuid, prePayload as TaskPayload)))
      try {
        const preUploadLiteMedia = fileToLiteMedia(idleFile, uuid, FileStatus.UPLOADING)
        addFile(preUploadLiteMedia)
        const uploadedFile = await uploadWithPreupload(idleFile, uuid)
        const postUploadLiteMedia = spatieToLiteMedia(uploadedFile, FileStatus.PROCESSING)
        const payload = { file: [postUploadLiteMedia], override: false }
        setTaskPayloads(new Map(taskPayloads.set(payload.file[0].uuid, payload)))
        post.mutate(payload)

        queryClient.invalidateQueries('batch-imports')
      } catch (e) {
        setErrors(new Map(errors.set(uuid, (e as { message: string }).message)))
      }
    }
  }

  const handleDataMissingAction = (uuid: string) => {
    setMissingDataModal(null)
    additionalDataRequests.delete(uuid)
    queryClient.invalidateQueries('batch-imports')
  }

  const handleOnAcceptOverride = (taskPayload: TaskPayload) => {
    taskPayload.file[0].status = FileStatus.IDLE
    taskPayloads.set(taskPayload.file[0].uuid, taskPayload)
    overrideRequests.delete(taskPayload.file[0].uuid)
    setOverrideRequest(new Map(overrideRequests))
    const payload = taskPayload
    payload.override = true
    post.mutate(taskPayload)
  }

  const sortImports = (a: TaskPayload, b: TaskPayload) => {
    return a.file[0].created_at > b.file[0].created_at ? 1 : -1
  }

  const runningImports = [
    ...[...taskPayloads.values()]
      .sort(sortImports)
      .filter((t) => t.file[0].status !== FileStatus.ERROR)
      .map(taskPayloadToTask),
    ...(query.data?.data ?? []).filter((task) => !['finished', 'failed'].includes(task.state))
  ]

  const finishedImports = [
    ...[...taskPayloads.values()]
      .sort(sortImports)
      .filter((t) => t.file[0].status === FileStatus.ERROR)
      .map(taskPayloadToTask),
    ...(query.data?.data?.filter((task) => ['finished', 'failed'].includes(task.state)) ?? [])
  ]

  return (
    <div className='flex flex-col py-6 px-9 mb-16 w-full bg-white rounded-sm shadow-card'>
      <div className='flex flex-col py-6 px-12'>
        <div className='flex flex-col mx-auto w-[400px]'>
          <span className='mb-5 font-semibold'>Importer un ou plusieurs fichiers</span>
          <FileInput accept={[Extensions.ALL]} multiple={true} onChange={handleOnChange} />
          <span className='inline-block overflow-visible mt-2 mb-7 text-xs text-greyish-brown whitespace-nowrap'>
            format *
          </span>
        </div>
        <div className='flex flex-col w-full'>
          {runningImports.length > 0 && (
            <>
              <div className='flex flex-row flex-1 mb-4'>
                <p>Imports en cours</p>
              </div>
              <div className='grid grid-cols-testCampaign auto-rows-auto gap-x-6 gap-y-4'>
                {runningImports.map((task, index) => (
                  <div key={'a' + index}>
                    <TaskFile errors={errors} task={task} />
                    {additionalDataRequests.has(task.media_uuid) && (
                      <>
                        <MissingDataModal
                          isOpen={task.media_uuid === missingDataModal}
                          onAccept={() => handleDataMissingAction(task.media_uuid)}
                          onClose={() => setMissingDataModal(null)}
                          task={task}
                        />
                        <button
                          className='p-1 h-5 text-[9px] text-amber underline truncate cursor-pointer'
                          onClick={() => setMissingDataModal(task.media_uuid)}
                        >
                          Action requise
                        </button>
                      </>
                    )}
                    {overrideRequests.has(task.media_uuid) && (
                      <>
                        <FileOverrideModal
                          fileName={task.file}
                          isFetching={post.isLoading}
                          isOpen={task.media_uuid === openedOverrideModal}
                          onAccept={() => handleOnAcceptOverride(overrideRequests.get(task.media_uuid) as TaskPayload)}
                          onClose={() => setOpenOverrideModal(null)}
                        />
                        <button
                          className='p-1 h-5 text-[9px] text-amber underline truncate cursor-pointer'
                          onClick={() => setOpenOverrideModal(task.media_uuid)}
                        >
                          Action requise
                        </button>
                      </>
                    )}
                  </div>
                ))}
              </div>
            </>
          )}
          {finishedImports.length > 0 && (
            <>
              <div className='my-7 w-full h-px bg-pale-grey'></div>
              <div className='flex flex-row flex-1 mb-4'>
                <p>Imports terminés</p>
              </div>
              <div className='grid grid-cols-testCampaign auto-rows-auto gap-x-6 gap-y-4'>
                {finishedImports.map((t, index) => (
                  <TaskFile errors={errors} key={`d${index}`} task={t} />
                ))}
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  )
}

export { BatchImport }
