import { LiteMediaWithStatus } from '../types'

interface State {
  files: LiteMediaWithStatus[]
  replaceFile: (file: LiteMediaWithStatus) => void
  addFile: (file: LiteMediaWithStatus) => void
  updateFile: (file: LiteMediaWithStatus) => void
  deleteFile: (uuid: string) => void
  updateFiles: (files: LiteMediaWithStatus[]) => void
}

enum Types {
  REPLACE_FILE = 'REPLACE_FILE',
  ADD_FILE = 'ADD_FILE',
  UPDATE_FILE = 'UPDATE_FILE',
  DELETE_FILE = 'DELETE_FILE',
  UPDATE_FILES = 'UPDATE_FILES'
}

type Actions =
  | { type: Types.REPLACE_FILE; payload: LiteMediaWithStatus }
  | { type: Types.ADD_FILE; payload: LiteMediaWithStatus }
  | { type: Types.UPDATE_FILE; payload: LiteMediaWithStatus }
  | { type: Types.DELETE_FILE; payload: string }
  | { type: Types.UPDATE_FILES; payload: LiteMediaWithStatus[] }

export { Types }
export type { Actions, State }
