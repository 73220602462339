import { LiteMedia, Nullable } from 'interfaces'
import { ExperimentTransverseReinforcement, FormulaConstituentTypes } from 'interfaces/api/lab'

// INFORMATIONS
interface InformationsPanelFormValues {
  configuration_id?: number
  pultrusion_die_id: Nullable<number>
  speed: Nullable<number>
  reference: string
  user_id: number
  theme_id: Nullable<number>
  realized_at: Nullable<Date>
  start_time: Nullable<Date>
  finish_time: Nullable<Date>
  details: Nullable<string>
}

// FORMULATION
interface FormulationIngredient {
  id?: number
  order: number
  constituent_id?: number
  constituent_type?: FormulaConstituentTypes
  part_per_hundred_of_resin?: number
  quantity_to_introduce?: number
  introduced_quantity?: number
}

interface FormulationPanelFormValues {
  ingredients: FormulationIngredient[]
  part_per_hundred_of_resin: number
  quantity_to_introduce: number
  introduced_quantity: number
  degassing: boolean
  pressure: Nullable<number>
  pressure_time: Nullable<number>
}

// VISCOSITY
type ViscosityMobileType = '61' | '62' | '63' | '64' | 'Non' | null
const ViscosityMobileList = ['61', '62', '63', '64', 'Non']

interface ViscosityRow {
  mobile: ViscosityMobileType
  minute_per_tour?: number
  torsion_torque?: number
  temperature?: number
  viscosity?: number
}

interface ViscosityPanelFormValues {
  starting_drain: ViscosityRow
  drain: ViscosityRow
  ending_drain: ViscosityRow
}
// REINFORCEMENT
interface ReinforcementFormValues {
  roving_id?: number
  fiber: string
  transverse_reinforcements: ExperimentTransverseReinforcement[]
  files: LiteMedia[]
}

export { ViscosityMobileList }
export type {
  FormulationIngredient,
  FormulationPanelFormValues,
  InformationsPanelFormValues,
  ViscosityPanelFormValues,
  ViscosityMobileType,
  ViscosityRow,
  ReinforcementFormValues
}
