import { SvgClose } from 'assets/svg'
import { UiContext } from 'contexts/ui.context'
import { ModalProps } from 'interfaces'
import { FC, ReactNode, useContext, useEffect, useState } from 'react'
import { createPortal } from 'react-dom'
import { SvgInjector } from 'ui/atoms/SvgInjector'

const TAILWIND_ANIMATION_DURATION = 300
const ModalContainer: FC<{ children: ReactNode; onClose: () => void; displayed: boolean }> = ({
  children,
  displayed = false,
  onClose
}) => {
  const { updateModal } = useContext(UiContext)
  const [displayModal, setDisplayModal] = useState<boolean>(false)

  const handleOnClose = () => {
    setDisplayModal(false)
    setTimeout(() => {
      onClose()
      updateModal({
        displayed: false,
        content: null
      })
    }, TAILWIND_ANIMATION_DURATION)
  }
  useEffect(() => {
    const duration = displayed ? 50 : TAILWIND_ANIMATION_DURATION
    setTimeout(() => {
      setDisplayModal(displayed)
    }, duration)
  }, [displayed])

  return displayed ? (
    <div className='flex fixed inset-0 z-50 flex-row justify-center items-center'>
      <div
        className={`fixed inset-0 bg-black transition-opacity duration-300 ${
          displayModal ? 'opacity-80' : 'opacity-0'
        }`}
        onClick={handleOnClose}
      ></div>
      <div
        className={`relative mx-20 max-w-modal-w max-h-hscreen bg-white shadow-card transition-toast duration-300 ${
          displayModal ? 'translate-y-0 opacity-100' : 'translate-y-2 opacity-0'
        }`}
      >
        <button className='absolute top-6 right-6' onClick={handleOnClose}>
          <SvgInjector className='w-6 h-6 text-pinkish-grey' src={SvgClose} />
        </button>
        <div className='overflow-auto w-full h-full'>{children}</div>
      </div>
    </div>
  ) : null
}

const MainModal: FC<ModalProps> = ({ content = null, displayed, hideCallback = () => null }) => {
  const node = document.createElement('div')
  const $modal = document.querySelector('#modal')

  const removeNode = () => {
    if (!$modal || $modal.children.length === 0) {
      return
    }
    $modal.childNodes[0].remove()
  }
  const handleHideCallback = () => {
    hideCallback()
  }

  useEffect(() => {
    if (!$modal) {
      return removeNode()
    }
    $modal.appendChild(node)
    return () => {
      removeNode()
    }
  }, [node, displayed, hideCallback])

  return createPortal(
    <ModalContainer displayed={displayed} onClose={handleHideCallback}>
      {content}
    </ModalContainer>,
    node
  )
}
export { MainModal }
