import { Roving } from 'interfaces/api/management'
import { fetchJson } from 'utils/fetch'
import { Dto, RovingCreateDto, RovingUpdateDto } from './list-parameters.dto'

/**
 *
 * @param page: string
 * @param perPage: string
 * @returns
 */
const getManagementRovings = (page: string, perPage: string) => {
  return fetchJson<Roving[], undefined>('GET', `/management/rovings?page=${page}&per_page=${perPage}`)
}

/**
 *
 * @param payload: Dto<RovingCreateDto>
 * @returns
 */
const postManagementRoving = (payload: Dto<RovingCreateDto>) => {
  return fetchJson<Roving, Dto<RovingCreateDto>>('POST', '/management/rovings', payload)
}

/**
 *
 * @param index
 * @param payload: RovingUpdateDto
 * @returns
 */
const putManagementRoving = (index: number, payload: RovingUpdateDto) => {
  return fetchJson<Roving, RovingUpdateDto>('PUT', `/management/rovings/${index}`, payload)
}

/**
 *
 * @param index: number
 * @return
 *
 */
const deleteManagementRoving = (index: number) => {
  return fetchJson('DELETE', `/management/rovings/${index}`)
}

export { getManagementRovings, putManagementRoving, postManagementRoving, deleteManagementRoving }
