import { SvgAvatar, SvgCalendar, SvgCopy, SvgEdit, SvgEye, SvgHint, SvgTrash } from 'assets/svg'
import { FC, FormEvent, useState } from 'react'
import { useMutation, useQueryClient } from 'react-query'
import { Link, useNavigate } from 'react-router-dom'
import { ButtonPrimary, ButtonSecondary } from 'ui/atoms'
import { Modal } from 'ui/atoms/Modal'
import { RoundedCheckbox } from 'ui/atoms/RoundedCheckbox'
import { deleteManagementConfiguration } from '../services'
import { ConfigurationPreview } from '../services/configuration.dto'

export const ConfigurationCard: FC<{
  configuration: ConfigurationPreview
  onSelectDefault: (id: number) => void
}> = ({ configuration, onSelectDefault }) => {
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)
  const navigate = useNavigate()
  const queryClient = useQueryClient()
  const configurationDelete = useMutation(deleteManagementConfiguration, {
    onSuccess: () => {
      queryClient.invalidateQueries('lab.configurations')
    }
  })

  const onCopyConfiguration = (e: FormEvent<HTMLButtonElement>, path: string) => {
    e.preventDefault()
    e.stopPropagation()
    navigate(path)
  }

  const onDeleteConfiguration = () => {
    configurationDelete.mutate(configuration.id)
    setIsDeleteModalOpen(false)
  }
  const onSelectDefaultConfiguration = () => {
    onSelectDefault(configuration.id)
  }
  return (
    <div>
      <div className={`${configuration.is_active ? 'bg-white' : 'bg-white/50'} py-5 px-7 w-60 shadow-card`}>
        <div className='flex py-2 align-top border-b-2 border-b-pinkish-grey'>
          <RoundedCheckbox
            className='mr-auto w-4 h-4'
            isChecked={configuration.is_active}
            onInput={onSelectDefaultConfiguration}
          />
          <Link to={`/configuration/${configuration.id}`}>
            {configuration.deletable ? (
              <img className='ml-4 w-4 h-4' src={SvgEdit} />
            ) : (
              <img className='ml-4 w-5 h-5' src={SvgEye} />
            )}
          </Link>
          <button
            data-testid='copy-button'
            onClick={(e) => onCopyConfiguration(e, `/configuration/create/${configuration.id}`)}
          >
            <img className='ml-4 w-4 h-4' src={SvgCopy} />
          </button>
          {configuration.deletable && (
            <button data-testid='delete-button' onClick={() => setIsDeleteModalOpen(true)}>
              <img className='ml-4 w-4 h-4' src={SvgTrash} />
            </button>
          )}
        </div>
        <div className='text-warm-grey whitespace-nowrap'>
          <div className='my-6 text-lg font-normal text-[black]'>{configuration.name}</div>
          <div className='flex items-center mb-3 text-sm'>
            <img className='mr-4 w-4 h-4' src={SvgCalendar} />
            <span>{configuration.configured_at}</span>
          </div>
          <div className='flex items-center'>
            <img className='mr-4 w-4 h-4' src={SvgAvatar} />
            <span>{configuration.created_by}</span>
          </div>
        </div>
      </div>
      <DeleteConfigurationModal
        isOpen={isDeleteModalOpen}
        name={configuration.name}
        onClose={() => setIsDeleteModalOpen(false)}
        onSuccess={() => onDeleteConfiguration()}
      />
    </div>
  )
}

export const DeleteConfigurationModal: FC<{
  name: string
  isOpen: boolean
  onClose: () => void
  onSuccess: () => void
}> = ({ isOpen, onClose, onSuccess, name }) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <img className='mx-auto' src={SvgHint} />
      <div className='my-10 w-96 text-center' data-testid='confirm-modal'>
        Souhaitez-vous supprimer la configuration {name}
      </div>
      <div className='flex justify-between'>
        <ButtonSecondary onClick={onClose} type='button'>
          Annuler
        </ButtonSecondary>
        <ButtonPrimary onClick={onSuccess} type='button'>
          Supprimer
        </ButtonPrimary>
      </div>
    </Modal>
  )
}
