import { SvgArrowDown } from 'assets/svg'
import { Angle } from 'interfaces/api/lab'
import { i18n } from 'i18n'
import { i18n as productionDatai18n } from 'modules/lab/i18n'
import { FC } from 'react'
import { Spinner } from 'ui/atoms/Spinner'
import { SvgInjector } from 'ui/atoms/SvgInjector'

const localeI18n = productionDatai18n.productionDataTab.compressionPanel

const CompressionAngleLoading: FC<{ angle: Angle }> = ({ angle }) => (
  <div className={`flex flex-col py-6 px-9 bg-white rounded-sm shadow-card`}>
    <div className='flex flex-row justify-between items-center cursor-pointer'>
      <h3 className='text-xl text-black'>{`${localeI18n.title} ${angle}°`}</h3>
      <div className='flex flex-row justify-center items-center'>
        <Spinner className='w-6 text-dusty-teal' stroke='#eee' />
        <p className='ml-4 italic text-pinkish-grey'>{i18n.isLoading}</p>
      </div>
      <SvgInjector className='ml-8 w-4 text-pinkish-grey' src={SvgArrowDown} />
    </div>
  </div>
)

export { CompressionAngleLoading }
