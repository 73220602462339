import { EosinClassification, ExperimentIndexDto } from 'interfaces/api/lab'
import { FC } from 'react'
import { Control, Controller } from 'react-hook-form'
import { MultiSelectDropdown } from 'ui/molecules'

const EosinFilter: FC<{
  control: Control<ExperimentIndexDto>
}> = ({ control }) => {
  return (
    <div className='flex flex-col mt-5'>
      <details className='' open={true}>
        <summary className='py-1 px-2 text-xl font-semibold list-none text-black bg-ice-blue cursor-pointer'>
          Eosine
        </summary>
        <div className='grid grid-cols-3 gap-8 py-5'>
          <Controller
            control={control}
            name='eosin.classifications'
            render={({ field }) => (
              <MultiSelectDropdown
                label='Classification'
                onChange={(values) => field.onChange(values.map((value) => Object.values(EosinClassification)[value]))}
                options={Object.values(EosinClassification).map((c, index) => ({ label: c, value: index }))}
                value={(field.value ?? []).map((v) => Object.values(EosinClassification).findIndex((l) => l === v))}
              />
            )}
          />
        </div>
      </details>
    </div>
  )
}

export { EosinFilter }
