import { eosin } from 'modules/lab/schemas'
import { FC } from 'react'
import { z } from 'zod'
import { TableTd } from './TableTd'

const EosinTd: FC<{ matrix: Record<string, boolean>; data: z.infer<typeof eosin> }> = ({ matrix, data }) => {
  return <>{matrix.classification && <TableTd className='text-left'>{data?.classification}</TableTd>}</>
}

export { EosinTd }
