import { FC, FormEvent, ReactNode } from 'react'
import CheckboxUnchecked from 'assets/svg/checkbox-unchecked.svg'
import CheckboxChecked from 'assets/svg/checkbox-checked.svg'

export const RoundedCheckbox: FC<{
  className?: string
  isChecked: boolean
  onInput: (value: boolean) => void
  children?: ReactNode
}> = ({ children = '', className, isChecked, onInput }) => {
  const onClick = (e: FormEvent<HTMLButtonElement>) => {
    e.stopPropagation()
    e.preventDefault()
    onInput(!isChecked)
  }
  return (
    <button className={'flex items-center cursor-pointer ' + className} data-testid='checkbox' onClick={onClick}>
      <span>
        <img className='mr-2 w-6 h-6' src={isChecked ? CheckboxChecked : CheckboxUnchecked} />
      </span>
      <span className='text-sm text-greyish-brown'>{children}</span>
    </button>
  )
}
