import { Experiment, ExperimentDto, ExperimentIndexDto } from 'interfaces/api/lab'
import { Dto } from 'modules/list-parameters/services/list-parameters.dto'
import { fetchAndValidateSchema, fetchJson, FetchResult } from 'utils/fetch'
import { getExperiments as getExperimentsSchema } from '../schemas'

/**
 *
 * @param page
 * @param perPage
 * @param filters
 * @returns
 */
const getExperiments = async (page: string, perPage: string, filters?: Omit<ExperimentIndexDto, '_method'>) => {
  const filtersWithMethod: ExperimentIndexDto = {
    _method: 'GET',
    ...(filters ? filters : {})
  }
  return fetchAndValidateSchema<ExperimentIndexDto, typeof getExperimentsSchema>(
    'POST',
    `/lab/experiments?page=${page}&per_page=${perPage}`,
    getExperimentsSchema,
    filtersWithMethod
  )
}

/**
 *
 * @param experimentId
 * @returns
 */
const getExperiment = (experimentId?: string): Promise<FetchResult<Experiment>> => {
  if (!experimentId) {
    throw new Error('experimentId no defined')
  }
  return fetchJson<Experiment>('GET', `/lab/experiments/${experimentId}`)
}

/**
 *
 * @param payload
 * @returns
 */
const postExperiment = (payload: Dto<ExperimentDto>) => {
  return fetchJson<Experiment, Dto<ExperimentDto>>('POST', `/lab/experiments`, payload)
}

/**
 *
 * @param experimentId
 * @param payload
 * @returns
 */
const putExperiment = (experimentId: string, payload: Dto<ExperimentDto>) => {
  return fetchJson<Experiment, Dto<ExperimentDto>>('PUT', `/lab/experiments/${experimentId}`, payload)
}

/**
 *
 * @param experimentId
 * @param reference
 * @returns
 */
const deleteExperiment = (experimentId: string) => {
  return fetchJson<void>('DELETE', `/lab/experiments/${experimentId}`)
}

/**
 *
 * @param experimentId
 * @returns
 */
const duplicateExperiment = (experimentId: string, reference: string) => {
  return fetchJson<{ id: number; reference: string }, { reference: string }>(
    'POST',
    `/lab/experiments/${experimentId}/duplicate`,
    { reference }
  )
}

export { getExperiments, getExperiment, postExperiment, putExperiment, deleteExperiment, duplicateExperiment }
