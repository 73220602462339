import { Nullable } from 'interfaces'
import { FC, FormEvent, useState } from 'react'
import { ArkemaLogo, ButtonPrimary, InputLabel } from 'ui/atoms'
import { TextInput } from 'ui/molecules'
import { postForgotPassword } from '../services/auth'
import { InputType } from 'ui/molecules/TextInput'

export const ForgotPassword: FC = () => {
  const [email, setEmail] = useState<Nullable<string>>('')
  const [isFetching, setisFetching] = useState(false)
  const [isSuccess, setIsSuccess] = useState(false)
  const [error, setError] = useState(null as Nullable<string[]>)

  const onSubmitForm = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    setisFetching(true)
    setError(null)
    setIsSuccess(false)
    if (email) {
      try {
        await postForgotPassword(email)
        setIsSuccess(true)
      } catch (e) {
        setError([(e as { message: string }).message])
      }
      setisFetching(false)
    }
  }

  return (
    <div className='flex flex-col'>
      <div className='m-4 font-semibold text-dusty-teal'>
        <span className='text-sm'>&#10092; &nbsp;</span>
        <a href='/auth/login'>Retour</a>
      </div>
      <div className='my-16 mx-20 w-[350px]'>
        <div className='flex justify-center mb-16'>
          <ArkemaLogo />
        </div>
        <InputLabel label='Mot de passe oublié ?' />
        <p className='mt-5 mb-8 text-sm text-greyish-brown'>
          Entrez le mail associé à votre compte Arkema Pultrusion studio, nous vous enverrons un mail avec les
          instructions pour changer votre mot de passe.
        </p>
        <form className='flex relative flex-col justify-center mx-auto w-[350px]' onSubmit={(e) => onSubmitForm(e)}>
          <TextInput
            className='mb-4'
            errors={error ? [] : null}
            label='Addresse Email'
            name='username'
            onChange={(value) => setEmail(value)}
            placeholder='Saisir ici votre addresse email'
            required={true}
            type={InputType.EMAIL}
            value={email}
          />
          {isSuccess && (
            <div className='mb-4 text-sm font-semibold text-dusty-teal'>
              Un email contenant les instructions de réinitialisation de mot de passe à été envoyé à l'adresse {email}
            </div>
          )}
          <ButtonPrimary className='mx-auto mb-3' disabled={!email} isFetching={isFetching} type='submit'>
            Envoyer le lien de réinitialisation
          </ButtonPrimary>
        </form>
      </div>
    </div>
  )
}
