import { FC, ReactNode } from 'react'
import { Spinner } from 'ui/atoms'

export const SpinnerLoaderMask: FC<{
  spinning: boolean
  message?: string
  className?: string
  children: ReactNode
}> = ({ spinning, message = 'Chargement...', children, className }) => (
  <div className={`relative flex flex-col ${className}`}>
    {spinning && (
      <div className='flex absolute z-10 flex-row flex-1 justify-center items-center w-full h-full bg-white75 backdrop-blur-xs'>
        <Spinner className='w-6 text-dusty-teal' stroke='#eee' />
        <p className='ml-4 italic text-pinkish-grey'>{message}</p>
      </div>
    )}
    <div>{children}</div>
  </div>
)
