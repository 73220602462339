import { SvgCreate, SvgDisk } from 'assets/svg'
import { Nullable } from 'interfaces'
import { PultrusionDie } from 'interfaces/api/management'
import {
  getManagementPultrusionDies,
  postManagementPultrusionDie
} from 'modules/list-parameters/services/pultrusionDies'
import { FC, FormEvent, useState } from 'react'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { useSearchParams } from 'react-router-dom'
import { UnderlinedInput } from 'ui/atoms/UnderLinedInput'
import { PaginationMenu } from 'ui/molecules/PaginationMenu'
import { PultrusionDieItem } from './PultrusionDieItem'

export const PultrusionDies: FC = () => {
  const [searchParams] = useSearchParams()

  const [pultrusionDieCreationError, setPultrusionDieCreationError] = useState<Nullable<string[]>>(null)
  const [isInputVisible, setIsInputVisible] = useState(false)
  const [newPultrusionDieLabel, setNewpultrusionDieLabel] = useState<Nullable<string>>(null)
  const [page, setPage] = useState<string>(searchParams.get('page') ?? '1')
  const [perPage, setPerPage] = useState<string>(searchParams.get('perPage') ?? '10')

  const fetchPultrusionDies = (page: string, perPage: string) => getManagementPultrusionDies(page, perPage)

  const queryClient = useQueryClient()
  const query = useQuery(['pultrusionDies', page, perPage], async () => await fetchPultrusionDies(page, perPage), {
    keepPreviousData: true
  })

  const pultrusionDieCreation = useMutation(postManagementPultrusionDie, {
    onMutate: () => setPultrusionDieCreationError(null),
    onSuccess: () => {
      queryClient.invalidateQueries('pultrusionDies')
      setIsInputVisible(false)
      setNewpultrusionDieLabel(null)
    },
    onError: (e: { message: string }) => setPultrusionDieCreationError([e.message])
  })

  const onCreate = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    if (!newPultrusionDieLabel) {
      return
    }
    pultrusionDieCreation.mutate(newPultrusionDieLabel)
  }

  const onInput = (value: string) => {
    setNewpultrusionDieLabel(value)
  }

  const onPaginationChange = (newPage: string, newPerPage: string) => {
    setPage(newPage)
    setPerPage(newPerPage)
  }

  return (
    <div className='flex flex-col w-full h-full'>
      <div className='py-10 px-16 w-[310px] bg-white rounded shadow-card'>
        <h2 className='mb-2.5 text-sm font-medium'>Filière</h2>
        {query.data?.data &&
          query.data.data
            .sort((a, b) => (a.label > b.label ? 1 : -1))
            .map((pultrusionDie: PultrusionDie) => (
              <div className='mt-5' key={pultrusionDie.id}>
                <PultrusionDieItem pultrusionDie={pultrusionDie} />
              </div>
            ))}
        <div className='flex justify-between items-center mt-5'>
          {!isInputVisible && (
            <button className='flex items-center' onClick={() => setIsInputVisible(true)}>
              <img className='mr-4 w-7 h-7' src={SvgCreate} />
              <span className='font-medium whitespace-nowrap'>Ajouter une filière</span>
            </button>
          )}
          {isInputVisible && (
            <form className='flex flex-row flex-auto justify-between' name='form' onSubmit={onCreate}>
              <div className='overflow-hidden flex-initial w-28'>
                <UnderlinedInput
                  errors={pultrusionDieCreationError}
                  onChange={onInput}
                  value={newPultrusionDieLabel ?? ''}
                />
              </div>
              <button className='flex-initial' data-testid='submit-button' type='submit'>
                <img className='w-4 h-4' src={SvgDisk} />
              </button>
            </form>
          )}
        </div>
      </div>
      <div className='mt-4 ml-auto'>
        <PaginationMenu meta={query.data?.meta ?? null} onChange={onPaginationChange} />
      </div>
    </div>
  )
}
