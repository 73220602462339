import { GenericConstituent } from 'interfaces/api/management'
import { fetchJson } from 'utils/fetch'
import { GenericConstituentDto } from './list-parameters.dto'

/**
 *
 * @param page
 * @param perPage
 * @returns
 */
/** */
const getManagementConstituents = () => {
  return fetchJson<GenericConstituent[], undefined>('GET', `/management/constituents`)
}

/**
 * @param data
 * @returns
 */
const postManagementConstituent = (data: GenericConstituentDto) => {
  return fetchJson<GenericConstituent, GenericConstituentDto>('POST', '/management/constituents', data)
}

/**
 *
 * @param index
 * @param data
 * @returns
 */
const putManagementConstituent = (index: number, data: GenericConstituentDto) => {
  return fetchJson<GenericConstituent, GenericConstituentDto>('PUT', `/management/constituents/${index}`, data)
}

/**
 *
 * @param index
 * @returns
 */
const deleteManagementConstituent = (index: number) => {
  return fetchJson('DELETE', `/management/constituents/${index}`)
}

export { getManagementConstituents, putManagementConstituent, postManagementConstituent, deleteManagementConstituent }
