import { FC, ReactElement, useState } from 'react'

const Tab: FC<{ title: string; onItemClicked: () => void; isActive: boolean }> = ({
  title,
  onItemClicked = () => null,
  isActive
}) => {
  return (
    <div
      className={`flex py-1.5 px-6 shadow-tab rounded-tr-2xl cursor-pointer ${
        isActive ? 'opacity-100 bg-white shadow-tab' : 'opacity-50 bg-ice-blue shadow-tab-inactive'
      }`}
      onClick={onItemClicked}
    >
      <h2 className={`text-lg 2xl:text-xl ${isActive ? 'text-dusk-blue  font-semibold' : 'text'}`}>{title}</h2>
    </div>
  )
}
const Tabs: FC<{ items: { title: string; component: ReactElement }[]; className?: string }> = ({
  items,
  className = ''
}) => {
  const [active, setActive] = useState(0)

  return (
    <div className={`${className} flex flex-col w-full h-full`}>
      <div className='flex relative z-10 flex-row'>
        {items.map(({ title }, k) => (
          <Tab isActive={active === k} key={`tab-${k}`} onItemClicked={() => setActive(k)} title={title} />
        ))}
      </div>
      {items.map(({ component }, k) =>
        active === k ? (
          <div className='flex w-full' key={`panel-${k}`}>
            {component}
          </div>
        ) : null
      )}
    </div>
  )
}

export { Tabs }
