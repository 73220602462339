import { ExperimentIndexDto, FireTestClassification } from 'interfaces/api/lab'
import { FC } from 'react'
import { Control, Controller } from 'react-hook-form'
import { MultiSelectDropdown } from 'ui/molecules'

const FireTestFilters: FC<{
  control: Control<ExperimentIndexDto>
}> = ({ control }) => {
  return (
    <div className='flex flex-col mt-5'>
      <details className='' open={true}>
        <summary className='py-1 px-2 text-xl font-semibold list-none text-black bg-ice-blue cursor-pointer'>
          Test au feu
        </summary>
        <div className='grid grid-cols-3 gap-8 py-5'>
          <Controller
            control={control}
            name='fire_test.classifications'
            render={({ field }) => (
              <MultiSelectDropdown
                label='Classification'
                onChange={(values) =>
                  field.onChange(values.map((value) => Object.values(FireTestClassification)[value]))
                }
                options={Object.values(FireTestClassification).map((c, index) => ({ label: c, value: index }))}
                value={(field.value ?? []).map((v) => Object.values(FireTestClassification).findIndex((l) => l === v))}
              />
            )}
          />
        </div>
      </details>
    </div>
  )
}

export { FireTestFilters }
