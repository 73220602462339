import { SvgDownload, SvgEye, SvgTrash } from 'assets/svg'
import { FileStatus } from 'core/constants/file'
import { format } from 'date-fns'
import { fr } from 'date-fns/locale'
import { FC, useCallback, useState } from 'react'
import { fetchFile } from 'utils/fetch'
import { FilePreviewIconProps, FilePreviewProps } from './FilePreview.type'

const FilePreviewIcon: FC<FilePreviewIconProps> = ({ extension }) => {
  return (
    <svg
      className='w-full h-full'
      height='47.827'
      viewBox='0 0 45.431 47.827'
      width='45.431'
      xmlns='http://www.w3.org/2000/svg'
    >
      <defs>
        <linearGradient gradientUnits='objectBoundingBox' id='4cz4wtxwwa' x1='.5' x2='.5' y2='1'>
          <stop offset='0' stopColor='#2d4691' />
          <stop offset='1' stopColor='#55be9b' />
        </linearGradient>
      </defs>
      <path
        d='M-2608 1773.366h-30a4 4 0 0 1-4-4v-38a4 4 0 0 1 4-4h22.433l11.564 11.975v-.013 30.038a4 4 0 0 1-3.997 4z'
        data-name='Soustraction 5'
        fill='#FFFFFF'
        transform='translate(2647 -1725.54)'
      />
      <path
        d='M-2608 1771.366a2 2 0 0 0 2-2v-29.214l-10.416-10.786H-2638a2 2 0 0 0-2 2v38a2 2 0 0 0 2 2h30m0 2h-30a4 4 0 0 1-4-4v-38a4 4 0 0 1 4-4h22.433l11.564 11.975v-.013 30.038a4 4 0 0 1-3.997 4z'
        data-name='Soustraction 5 - Contour'
        fill='#707070'
        transform='translate(2647 -1725.54)'
      />
      <path
        d='M10.087 1.327a2 2 0 0 1 2.769 0l6.5 6.229A2 2 0 0 1 17.967 11H4.975a2 2 0 0 1-1.384-3.444z'
        data-name='Polygone 8'
        fill='#FFFFFF'
        transform='rotate(-133 23.972 4.013)'
      />
      <path
        d='M11.471 2.771 4.975 9h12.992l-6.494-6.229m0-2a1.994 1.994 0 0 1 1.384.556l6.5 6.229A2 2 0 0 1 17.967 11H4.975a2 2 0 0 1-1.384-3.444l6.5-6.229a1.994 1.994 0 0 1 1.38-.556z'
        data-name='Polygone 8 - Contour'
        fill='#707070'
        transform='rotate(-133 23.972 4.013)'
      />
      <rect
        data-name='Rectangle 883'
        fill='url(#4cz4wtxwwa)'
        height='14'
        rx='2'
        transform='translate(0 26.827)'
        width='34'
      />
      <text
        fill='#FFFFFF'
        fontFamily='WorkSans-Bold,Work Sans'
        fontSize='11px'
        fontWeight={700}
        transform='translate(15 37)'
      >
        <tspan x='-10.696' y='0'>
          .{extension}
        </tspan>
      </text>
    </svg>
  )
}

const FilePreview: FC<FilePreviewProps> = ({
  downloadable = true,
  deletable = true,
  extension = '',
  name = '',
  url,
  date,
  onDelete,
  readonly,
  status
}) => {
  const formattedDate = useCallback((date: Date) => format(date, `dd MMM yyyy 'à' HH'h'mm`, { locale: fr }), [date])
  const [isDownloading, setIsDownloading] = useState(false)
  const [isOpening, setIsOpening] = useState(false)
  const borderColor = () => {
    switch (status) {
      case FileStatus.IDLE:
      case FileStatus.NONE:
        return 'border-pale-grey'
      case FileStatus.ERROR:
        return 'border-red'
      case FileStatus.WARNING:
        return 'border-amber'
      default:
        return 'border-cool-grey'
    }
  }
  const bgColor = () => {
    switch (status) {
      case FileStatus.IDLE:
      case FileStatus.NONE:
        return 'bg-white'
      default:
        return 'bg-ice'
    }
  }

  return (
    <div
      className={`flex flex-row flex-1 items-center py-1.5 px-2.5 ${bgColor()} rounded-xl border shadow-card ${borderColor()}`}
    >
      <div className='flex-none mr-2 w-9 h-9'>
        <FilePreviewIcon extension={extension} />
      </div>
      <div className='flex flex-col flex-1'>
        <span className='w-28 text-xs font-semibold text-greyish-brown truncate cursor-default' title={name ?? ''}>
          {name}
        </span>
        {date != null && <span className='text-xs font-light text-greyish-brown'>{formattedDate(date)}</span>}
      </div>
      <div className='flex float-none relative flex-row ml-2 w-16'>
        {deletable && !readonly && onDelete && (
          <button
            className='mr-2.5 cursor-pointer'
            onClick={() => onDelete()}
            title='Supprimer le fichier'
            type='button'
          >
            <img className='w-4 h-4' src={SvgTrash} />
          </button>
        )}
        {downloadable && (
          <>
            {url ? (
              <button
                className={`${isDownloading ? 'animate-bounce cursor-wait' : 'animate-none cursor-pointer '}`}
                disabled={isDownloading}
                onClick={() => {
                  setIsDownloading(true)
                  fetchFile(url, name ?? '', false, () => setIsDownloading(false))
                }}
                title='Télécharger le fichier'
              >
                <img className='mr-4 w-4 h-4' src={SvgDownload} />
              </button>
            ) : (
              <span>
                <img className='mr-4 w-4 h-4 opacity-50 cursor-not-allowed' src={SvgDownload} />
              </span>
            )}
            {url && ['jpg', 'jpeg', 'txt', 'pdf', 'png', 'gif'].includes(extension) ? (
              <button
                className={`${isOpening ? 'animate-bounce cursor-wait' : 'animate-none cursor-pointer '}`}
                disabled={isOpening}
                onClick={() => {
                  setIsOpening(true)
                  fetchFile(url, name ?? '', true, () => setIsOpening(false))
                }}
                title='Visualiser le fichier'
              >
                <img className={`w-5 h-4`} src={SvgEye} />
              </button>
            ) : (
              <span>
                <img className='w-5 h-4 opacity-50 cursor-not-allowed' src={SvgEye} />
              </span>
            )}
          </>
        )}
      </div>
    </div>
  )
}

export { FilePreview }
