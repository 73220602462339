import { GenericConstituent } from 'interfaces/api/management'
import { fetchJson } from 'utils/fetch'

/**
 *
 * @returns
 */
const getFormulaConstituents = () => {
  return fetchJson<GenericConstituent[]>('GET', '/management/constituents')
}

export { getFormulaConstituents }
