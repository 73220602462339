import { ExperimentFiberRate, ExperimentFiberRateDto } from 'interfaces/api/lab'
import { Dto } from 'modules/list-parameters/services/list-parameters.dto'
import { fetchJson } from 'utils/fetch'

/**
 *
 * @param experimentId
 * @param payload
 * @returns
 */
const postExperimentFiberRate = (experimentId: string, payload: Dto<ExperimentFiberRateDto>) => {
  return fetchJson<ExperimentFiberRate, Dto<ExperimentFiberRateDto>>(
    'POST',
    `/lab/experiments/${experimentId}/fiber-rates`,
    payload
  )
}
/**
 *
 * @param experimentId
 * @param payload
 * @returns
 */
const putExperimentFiberRate = (experimentId: string, payload: Dto<ExperimentFiberRateDto>) => {
  return fetchJson<ExperimentFiberRate, Dto<ExperimentFiberRateDto>>(
    'PUT',
    `/lab/experiments/${experimentId}/fiber-rates`,
    payload
  )
}
/**
 *
 * @param experimentId
 * @returns
 */
const getExperimentFiberRate = (experimentId: string) => {
  return fetchJson<ExperimentFiberRate>('GET', `/lab/experiments/${experimentId}/fiber-rates`)
}

export { postExperimentFiberRate, putExperimentFiberRate, getExperimentFiberRate }
