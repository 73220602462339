import { ExperimentIndexDto } from 'interfaces/api/lab'

const WATER_UPTAKE_PREFIX = 'Rep_eau_'
const ALKALINE_RESISTANCE_PREFIX = 'Res_alca_'
const RAMP_BASE_TEMP = 25
const emptyMinMax = {
  min: null,
  max: null
}
const mechanicalFilters = {
  chord_modulus_deformation: emptyMinMax,
  chord_modulus_force: emptyMinMax,
  fracture_deformation: emptyMinMax,
  fracture_stress: emptyMinMax,
  max_force: emptyMinMax,
  max_stress: emptyMinMax,
  max_stress_deformation: emptyMinMax,
  max_stress_movement: emptyMinMax
}
const dmaFilters = {
  full_width_at_half_maximum: emptyMinMax,
  g_second_max_temperature: emptyMinMax,
  tan_delta_max_temperature: emptyMinMax
}
const EMPTY_FILTERS: Omit<ExperimentIndexDto, '_method'> = {
  compression_0: mechanicalFilters,
  compression_90: mechanicalFilters,
  flexion_0: mechanicalFilters,
  flexion_90: mechanicalFilters,
  traction_0: mechanicalFilters,
  traction_90: mechanicalFilters,
  dynamic_mechanical_analysis_ramp_1: dmaFilters,
  dynamic_mechanical_analysis_ramp_2: dmaFilters,
  eosin: {
    classifications: []
  },
  experiment: {
    configurations: [],
    pultrusion_dies: [],
    realized_at: emptyMinMax,
    search: null,
    speed: emptyMinMax,
    themes: [],
    users: []
  },
  fire_test: {
    classifications: []
  },
  formula: {
    constituents: []
  },
  process_parameter: {
    has_ovens: null,
    impregnation_modes: [],
    is_stable: null,
    wire_count: emptyMinMax
  },
  reinforcement: {
    fibers: [],
    rovings: [],
    transverse_reinforcements: []
  },
  residual_monomer: {
    average: emptyMinMax
  },
  thermocouple: {
    peak_distance: emptyMinMax,
    peak_temperature: emptyMinMax,
    peak_time: emptyMinMax
  },
  viscosity: {
    drain: emptyMinMax,
    ending_drain: emptyMinMax,
    starting_drain: emptyMinMax
  }
}
export { WATER_UPTAKE_PREFIX, RAMP_BASE_TEMP, ALKALINE_RESISTANCE_PREFIX, EMPTY_FILTERS }
