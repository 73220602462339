import { SvgEye } from 'assets/svg'
import { UiContext } from 'contexts/ui.context'
import { ingredients, formula } from 'modules/lab/schemas'
import { ConstituentType } from 'modules/list-parameters/services/list-parameters.dto'
import { FC, useContext, useEffect, useState } from 'react'
import { SvgInjector } from 'ui/atoms/SvgInjector'
import { z } from 'zod'
import { TableTd } from './TableTd'

const FormulaTable: FC<{ ingredients: z.infer<typeof ingredients>; reference: string }> = ({
  ingredients,
  reference
}) => {
  const constituentsTypesLabels: Record<ConstituentType, string> = {
    [ConstituentType.RESIN]: 'Résine',
    [ConstituentType.RELEASE_AGENT]: 'Démoulant',
    [ConstituentType.FILLER]: 'Charge',
    [ConstituentType.ADDITIVE]: 'Additifs',
    [ConstituentType.PEROXYDE]: 'Péroxyde',
    [ConstituentType.ACCELERATOR]: 'Accelérateur'
  }
  const [total, setTotal] = useState<{
    part_per_hundred_of_resin: number
    quantity_to_introduce: number
    introduced_quantity: number
  }>({
    part_per_hundred_of_resin: 0,
    quantity_to_introduce: 0,
    introduced_quantity: 0
  })
  useEffect(() => {
    setTotal({
      introduced_quantity: ingredients
        .map(({ introduced_quantity }) => introduced_quantity ?? 0)
        .reduce((p, v) => p + v),
      part_per_hundred_of_resin: ingredients
        .map(({ part_per_hundred_of_resin }) => part_per_hundred_of_resin ?? 0)
        .reduce((p, v) => p + v),
      quantity_to_introduce: ingredients
        .map(({ quantity_to_introduce }) => quantity_to_introduce ?? 0)
        .reduce((p, v) => parseFloat((p + v).toFixed(2)))
    })
  }, [ingredients])

  return (
    <div className='flex flex-col p-8 pt-0'>
      <div className='flex flex-row justify-center items-center py-8 border-b border-pinkish-grey'>
        <span className='text-xl font-semibold text-black'>Formulation - </span>
        <span className='text-xl text-black'>Essai {reference}</span>
      </div>
      <div className='p-8'>
        <p>
          <span className='font-medium text-black'>Nom de la résine : </span>
          <span className='text-black'>{ingredients[0].constituent_label}</span>
        </p>
        <table className='mt-8 w-full'>
          <thead>
            <tr>
              <th className='pr-4 text-sm font-medium text-left text-black'>Numéro</th>
              <th className='pr-4 text-sm font-medium text-left text-black'>Type de constituant</th>
              <th className='pr-4 text-sm font-medium text-left text-black'>Constituant</th>
              <th className='pr-4 text-sm font-medium text-left text-black'>Quantité à introduire (phr)</th>
              <th className='pr-4 text-sm font-medium text-left text-black'>Quantité à introduire (kg)</th>
              <th className='text-sm font-medium text-left text-black'>Quantité introduite (kg)</th>
            </tr>
          </thead>
          <tbody>
            {ingredients.map((i) => (
              <tr key={i.order}>
                <td className='py-6 text-sm text-left text-black'>{`${i.order + 1}`.padStart(2, '0')}</td>
                <td className='py-6 text-sm text-left text-black'>{constituentsTypesLabels[i.constituent_type]}</td>
                <td className='py-6 text-sm text-left text-black'>{i.constituent_label}</td>
                <td className='py-6 text-sm text-left text-black'>{i.part_per_hundred_of_resin}</td>
                <td className='py-6 text-sm text-left text-black'>{i.quantity_to_introduce}</td>
                <td className='py-6 text-sm text-left text-black'>{i.introduced_quantity}</td>
              </tr>
            ))}
          </tbody>
          <tfoot className='bg-cement-white'>
            <tr>
              <td className='p-4 text-sm font-medium text-left text-black uppercase' colSpan={3}>
                Quantité totale :
              </td>
              <td className='text-sm text-left text-black'>{total.part_per_hundred_of_resin}</td>
              <td className='text-sm text-left text-black'>{total.quantity_to_introduce}</td>
              <td className='text-sm text-left text-black'>{total.introduced_quantity}</td>
            </tr>
          </tfoot>
        </table>
      </div>
    </div>
  )
}

const FormulaTd: FC<{ matrix: Record<string, boolean>; data: z.infer<typeof formula>; reference: string }> = ({
  matrix,
  data,
  reference
}) => {
  const { updateModal } = useContext(UiContext)
  const handleOnClick = () => {
    updateModal({
      content: <FormulaTable ingredients={data?.ingredients ?? []} reference={reference} />,
      displayed: true
    })
  }
  return (
    <>
      {matrix.ingredients && (
        <TableTd className='text-left'>
          <button className='flex justify-center items-center py-2 px-1 mr-2' onClick={handleOnClick} type='button'>
            <SvgInjector className='inline-block w-4 h-4  text-warm-grey' src={SvgEye} />
          </button>
          {data?.ingredients.find((i) => i.constituent_type === ConstituentType.RESIN)?.constituent_label ?? '-'}
        </TableTd>
      )}
    </>
  )
}

export { FormulaTd }
