import { SvgAdd } from 'assets/svg'
import { Extensions } from 'interfaces'
import { ChangeEventHandler, FC } from 'react'

const FileInput: FC<{
  accept: Extensions[]
  multiple: boolean
  onChange: ChangeEventHandler<HTMLInputElement>
}> = ({ multiple = false, onChange, accept }) => {
  return (
    <div className='flex relative flex-col justify-center items-center w-full h-24 border-2 border-viridian border-dashed cursor-pointer'>
      <input
        accept={accept.join(', ')}
        className='absolute inset-0 opacity-0 cursor-pointer'
        multiple={multiple}
        onChange={onChange}
        type='file'
      />
      <img className='w-4 h-4' src={SvgAdd} />
      <span className='text-sm text-warm-grey'>Glissez & déposez</span>
      <span className='text-sm text-warm-grey'>
        ou <strong className='font-bold text-viridian underline'>importer</strong>
      </span>
    </div>
  )
}

export { FileInput }
