import { Theme } from 'interfaces/api/lab'
import { fetchJson } from 'utils/fetch'

/**
 *
 * @returns
 */
const getThemes = () => {
  return fetchJson<Theme[]>('GET', `/lab/themes`)
}

export { getThemes }
