import { Nullable } from 'interfaces'
import { ChangeEvent, FormEvent, FC, forwardRef } from 'react'
import { InputError } from './InputError'

interface Props {
  className?: string
  options: { value: number | string | null; label: string | null }[]
  value?: Nullable<string | number>
  placeholder?: string
  onInput?: (e: FormEvent<HTMLSelectElement>) => void
  onChange?: (e: ChangeEvent<HTMLSelectElement>) => void
  disabled?: boolean
  errors: Nullable<string[]>
}

const UnderLinedSelect: FC<Props> = forwardRef<HTMLSelectElement, Props>(
  (
    {
      className = '',
      value,
      placeholder = 'Sélectionnez une option',
      options,
      onInput,
      onChange,
      errors,
      disabled = false
    },
    ref
  ) => (
    <div className={`flex flex-col ${className}`}>
      <select
        className={`${
          errors && errors[0] ? 'border-b-[red]' : 'border-b-pinkish-grey'
        } pb-2 text-sm text-warm-grey border-b outline-none h-8 w-inherit bg-white`}
        disabled={disabled}
        onChange={onChange}
        onInput={onInput}
        ref={ref}
        value={value ?? ''}
      >
        <option disabled={true} value=''>
          {placeholder}
        </option>
        {options.map((o, index) => (
          <option key={`index-${index}`} value={o.value ?? undefined}>
            {o.label}
          </option>
        ))}
      </select>
      {errors && <InputError errors={errors} />}
    </div>
  )
)

export { UnderLinedSelect }
