import { FC } from 'react'
import { Link } from 'react-router-dom'
import { ArkemaLogo } from 'ui/atoms'
import { UserDropdown } from './UserDropdown'

export const MainHeader: FC = () => {
  return (
    <div className='flex relative z-10 justify-between py-3 px-10 w-full bg-white shadow-card'>
      <Link to='/'>
        <ArkemaLogo />
      </Link>
      <UserDropdown />
    </div>
  )
}
