import { ExperimentThermocouple, ExperimentThermocoupleDto, ExperimentThermocoupleTaskDto } from 'interfaces/api/lab'
import { ExperimentThermocoupleTask } from 'interfaces/api/task'
import { Dto } from 'modules/list-parameters/services/list-parameters.dto'
import { fetchJson } from 'utils/fetch'

/**
 *
 * @param experimentId
 * @param payload
 * @returns
 */
const postExperimentThermocouple = (experimentId: string, payload: Dto<ExperimentThermocoupleDto>) => {
  return fetchJson<ExperimentThermocouple, Dto<ExperimentThermocoupleDto>>(
    'POST',
    `/lab/experiments/${experimentId}/thermocouple`,
    payload
  )
}
/**
 *
 * @param experimentId
 * @param payload
 * @returns
 */
const putExperimentThermocouple = (experimentId: string, payload: Dto<ExperimentThermocoupleDto>) => {
  return fetchJson<ExperimentThermocouple, Dto<ExperimentThermocoupleDto>>(
    'PUT',
    `/lab/experiments/${experimentId}/thermocouple`,
    payload
  )
}

/**
 *
 * @param experimentId
 * @returns
 */
const getExperimentThermocouple = (experimentId: string) => {
  return fetchJson<ExperimentThermocouple>('GET', `/lab/experiments/${experimentId}/thermocouple`)
}

/**
 *
 * @param experimentId
 * @param payload
 * @returns Promise<FetchResult<ExperimentThermocoupleTask>>
 */
const postExperimentThermocoupleTask = (experimentId: string, payload: Dto<ExperimentThermocoupleTaskDto>) => {
  return fetchJson<ExperimentThermocoupleTask, Dto<ExperimentThermocoupleTaskDto>>(
    'POST',
    `/lab/experiments/${experimentId}/thermocouple/task`,
    payload
  )
}

export {
  getExperimentThermocouple,
  postExperimentThermocouple,
  putExperimentThermocouple,
  postExperimentThermocoupleTask
}
