import { FIBERS } from 'core/constants'
import { ExperimentIndexDto } from 'interfaces/api/lab'
import { getExperimentReinforcements } from 'modules/lab/services/reinforcements'
import { getExperimentRovings } from 'modules/lab/services/rovings'
import { FC } from 'react'
import { Control, Controller } from 'react-hook-form'
import { useQuery } from 'react-query'
import { MultiSelectDropdown } from 'ui/molecules'

const ReinforcementFilter: FC<{
  control: Control<ExperimentIndexDto>
}> = ({ control }) => {
  const reinforcementListQuery = useQuery('filters.reinforcement-list', async () => await getExperimentReinforcements())
  const rovingListQuery = useQuery('filters.roving-list', async () => await getExperimentRovings())
  return (
    <div className='flex flex-col mt-5'>
      <details className='' open={true}>
        <summary className='py-1 px-2 text-xl font-semibold list-none text-black bg-ice-blue cursor-pointer'>
          Renforts
        </summary>
        <div className='grid grid-cols-3 gap-8 py-5'>
          <Controller
            control={control}
            name='reinforcement.rovings'
            render={({ field }) => (
              <MultiSelectDropdown
                label='Roving'
                onChange={field.onChange}
                options={(rovingListQuery?.data?.data ?? []).map((r) => ({ label: r.label, value: r.id }))}
                value={field.value ?? []}
              />
            )}
          />
          <Controller
            control={control}
            name='reinforcement.fibers'
            render={({ field }) => (
              <MultiSelectDropdown
                label='Type de fibre'
                onChange={(values) => field.onChange(values.map((v) => FIBERS[v].value))}
                options={FIBERS.map(({ label }, index) => ({ label, value: index }))}
                value={(field.value ?? []).map((v) => FIBERS.findIndex((f) => v === f.value))}
              />
            )}
          />
          <Controller
            control={control}
            name='reinforcement.transverse_reinforcements'
            render={({ field }) => (
              <MultiSelectDropdown
                label='Renforts transverses'
                onChange={field.onChange}
                options={(reinforcementListQuery?.data?.data ?? []).map(({ id, label }) => ({
                  value: id,
                  label
                }))}
                value={field.value ?? []}
              />
            )}
          />
        </div>
      </details>
    </div>
  )
}

export { ReinforcementFilter }
