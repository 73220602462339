import { tcf } from 'modules/lab/schemas'
import { FC } from 'react'
import { z } from 'zod'
import { TableTd } from './TableTd'

const TractionFlexionCompressionTd: FC<{ matrix: Record<string, boolean>; data: z.infer<typeof tcf> }> = ({
  matrix,
  data
}) => {
  return (
    <>
      {matrix.max_force_average && <TableTd className='text-left'>{data?.max_force_average}</TableTd>}
      {matrix.max_stress_average && <TableTd className='text-left'>{data?.max_stress_average}</TableTd>}
      {matrix.max_stress_movement_average && (
        <TableTd className='text-left'>{data?.max_stress_movement_average}</TableTd>
      )}
      {matrix.max_stress_deformation_average && (
        <TableTd className='text-left'>{data?.max_stress_deformation_average}</TableTd>
      )}
      {matrix.chord_modulus_deformation_average && (
        <TableTd className='text-left'>{data?.chord_modulus_deformation_average}</TableTd>
      )}
      {matrix.chord_modulus_force_average && (
        <TableTd className='text-left'>{data?.chord_modulus_force_average}</TableTd>
      )}
      {matrix.fracture_stress_average && <TableTd className='text-left'>{data?.fracture_stress_average}</TableTd>}
      {matrix.fracture_deformation_average && (
        <TableTd className='text-left'>{data?.fracture_deformation_average}</TableTd>
      )}
    </>
  )
}

export { TractionFlexionCompressionTd }
