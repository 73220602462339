import { apiToken, apiUrl } from 'config'
import { Nullable } from 'interfaces'
import { z, ZodTypeAny } from 'zod'

export type FetchMethod = 'GET' | 'POST' | 'PATCH' | 'PUT' | 'DELETE'

export const getXSRFValueInCookie = (cookie: string) => {
  const match = cookie.match(new RegExp('(^| )XSRF-TOKEN=([^;]+)'))
  if (match) return match[2].replace('%3D', '=')
}

export interface PaginationLink {
  active: boolean
  label: string
  url: Nullable<string>
}

export interface PaginationMeta {
  from: number
  to: number
  total: number
  per_page: 20
  current_page: number
  last_page: number
  path: string
  links: PaginationLink[]
}

export interface FetchResult<T> {
  data: Nullable<T>
  meta: Nullable<PaginationMeta>
}

export const fetchFile = async (url: string, name: string, visualize = false, callBack: () => void) => {
  url = url.replace('nlb.', 'api.')
  const request = new Request(url, {
    credentials: 'include',
    method: 'GET',
    headers: {
      'X-Api-Key': apiToken,
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'X-XSRF-TOKEN': getXSRFValueInCookie(document.cookie) ?? ''
    }
  })
  try {
    const response = await fetch(request)
    const a = document.createElement('a')
    const blob = await response.blob()
    const objectUrl = URL.createObjectURL(blob)
    a.setAttribute('target', '_blank')
    a.setAttribute('href', objectUrl)
    a.setAttribute(visualize ? 'name' : 'download', name)
    a.click()
  } finally {
    URL.revokeObjectURL(url)
    callBack()
  }
}
export const fetchAndValidateSchema = async <B = undefined, T extends ZodTypeAny = ZodTypeAny>(
  method: FetchMethod,
  url: string,
  schema: T,
  body?: Nullable<B>
) => {
  const data = await fetchJson<z.infer<typeof schema>, B>(method, url, body)
  schema.parse(data.data)
  return data
}

export const fetchJson = async <T, B = undefined>(
  method: FetchMethod,
  url: string,
  body?: Nullable<B>
): Promise<FetchResult<T>> => {
  const request = new Request(apiUrl + url, {
    credentials: 'include',
    method,
    body: body ? JSON.stringify(body) : null,
    headers: {
      'X-Api-Key': apiToken,
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'X-XSRF-TOKEN': getXSRFValueInCookie(document.cookie) ?? ''
    }
  })

  const response = await fetch(request)
  if (response.ok) {
    try {
      if (response.status === 204) {
        return { data: null, meta: null }
      }
      const parsed = await response.json()
      return parsed as FetchResult<T>
    } catch (e) {
      throw new Error(`Failed parsing server response: ${e}`)
    }
  } else {
    if (response.status) {
      const err = await response.json()
      throw typeof err === 'object'
        ? {
            ...err,
            status: response.status
          }
        : { err: err, status: response.status }
    } else {
      throw await response.json()
    }
  }
}
