import { Angle, ExperimentTraction, ExperimentTractionDto, ExperimentTractionTaskDto } from 'interfaces/api/lab'
import { ExperimentTractionTask } from 'interfaces/api/task'
import { Dto } from 'modules/list-parameters/services/list-parameters.dto'
import { fetchJson } from 'utils/fetch'

/**
 *
 * @param experimentId
 * @param angle
 * @param payload
 * @returns
 */
const postExperimentTraction = (experimentId: string, angle: Angle, payload: Dto<ExperimentTractionDto>) => {
  return fetchJson<ExperimentTraction, Dto<ExperimentTractionDto>>(
    'POST',
    `/lab/experiments/${experimentId}/tractions/${angle}`,
    payload
  )
}

/**
 *
 * @param experimentId
 * @param angle
 * @param payload
 * @returns
 */
const putExperimentTraction = (experimentId: string, angle: Angle, payload: Dto<ExperimentTractionDto>) => {
  return fetchJson<ExperimentTraction, Dto<ExperimentTractionDto>>(
    'PUT',
    `/lab/experiments/${experimentId}/tractions/${angle}`,
    payload
  )
}

/**
 *
 * @param experimentId
 * @returns
 */
const getExperimentTractions = (experimentId: string) => {
  return fetchJson<ExperimentTraction[]>('GET', `/lab/experiments/${experimentId}/tractions`)
}

/**
 *
 * @param url
 * @param angle
 * @param payload
 * @returns
 */
const postExperimentTractionTask = (experimentId: string, angle: Angle, payload: Dto<ExperimentTractionTaskDto>) => {
  return fetchJson<ExperimentTractionTask, Dto<ExperimentTractionTaskDto>>(
    'POST',
    `/lab/experiments/${experimentId}/tractions/${angle}/task`,
    payload
  )
}

export { postExperimentTraction, putExperimentTraction, getExperimentTractions, postExperimentTractionTask }
