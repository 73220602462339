import { ExperimentAlkalineResistance, ExperimentAlkalineResistanceDto } from 'interfaces/api/lab'
import { fetchJson } from 'utils/fetch'

/**
 *
 * @param experimentId
 * @param payload
 * @returns
 */
const postExperimentAlkalineResistances = (experimentId: string, payload: ExperimentAlkalineResistanceDto) => {
  return fetchJson<ExperimentAlkalineResistance[], ExperimentAlkalineResistanceDto>(
    'POST',
    `/lab/experiments/${experimentId}/alkaline-resistances`,
    payload
  )
}

/**
 *
 * @param experimentId
 * @returns
 */
const getExperimentAlkalineResistances = (experimentId: string) => {
  return fetchJson<ExperimentAlkalineResistance[]>('GET', `/lab/experiments/${experimentId}/alkaline-resistances`)
}

export { postExperimentAlkalineResistances, getExperimentAlkalineResistances }
