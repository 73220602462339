import { Nullable } from 'interfaces'
import { ExperimentDMATaskDto } from 'interfaces/api/lab'
import { postExperimentDMATask } from 'modules/lab/services'
import { Dto } from 'modules/list-parameters/services/list-parameters.dto'
import { FC, useContext, useEffect, useState } from 'react'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { useMutation, useQueryClient } from 'react-query'

import { ButtonPrimary, ButtonSecondary } from 'ui/atoms'

import { UnderlinedNumberInput } from 'ui/atoms/UnderLinedInput'
import { Modal } from 'ui/atoms/Modal'
import { Field } from 'ui/atoms/Field'
import { UiContext } from 'contexts/ui.context'
import { ToastType } from 'core/constants'
import { LiteMediaWithStatus } from 'modules/files/types'

interface TaskFormValues {
  study_temperature: number
  override: boolean
}
const DynamicMechanicalAnalysisRampTaskSubmissionModals: FC<{
  experimentId: string
  ramp: 1 | 2
  open: boolean
  onTaskIdChange: (taskId: Nullable<number>) => void
  file: LiteMediaWithStatus[]
  onClose: (temperature: number) => void
}> = ({ experimentId, ramp, onTaskIdChange, file, onClose, open }) => {
  if (!open) {
    return null
  }
  const { updateToast } = useContext(UiContext)
  const [taskModal, setTaskModal] = useState<boolean>(false)
  const [taskConfirmModal, setTaskConfirmModal] = useState<boolean>(false)

  const queryClient = useQueryClient()

  const { control, setValue, handleSubmit } = useForm<TaskFormValues>()

  const taskMutation = useMutation(
    (payload: Dto<ExperimentDMATaskDto>) => postExperimentDMATask(experimentId, ramp, payload),
    {
      onSuccess: (data, { study_temperature }) => {
        onTaskIdChange(data.data?.id ?? null)
        queryClient.setQueryData(['tasks', data.data?.id ?? null], data)
        onClose(study_temperature ?? 0)
      },
      onError: (e: { status: number; message: string }) => {
        if (e.status === 409) {
          setValue('override', true)
          setTaskModal(false)
          setTaskConfirmModal(true)
        } else {
          updateToast({
            content: e.message ?? 'Une erreur est survenue',
            displayed: true,
            type: ToastType.ERROR
          })
        }
      }
    }
  )

  const handleOnSubmitTask: SubmitHandler<TaskFormValues> = ({ study_temperature, override }) => {
    setTaskModal(false)
    setTaskConfirmModal(false)
    taskMutation.mutate({ file, study_temperature, override })
  }

  useEffect(() => {
    setTaskModal(true)
    setValue('override', false)
    setValue('study_temperature', 0)
  }, [file])

  return (
    <form onSubmit={handleSubmit(handleOnSubmitTask)}>
      <Controller
        control={control}
        defaultValue={false}
        name='override'
        render={({ field }) => <input checked={field.value} hidden={true} onChange={field.onChange} type='checkbox' />}
      />
      <Modal isOpen={taskModal} onClose={() => setTaskModal(false)}>
        <Field className='mb-8 w-20' label='Température' required={true}>
          <Controller
            control={control}
            defaultValue={0}
            name='study_temperature'
            render={({ field }) => (
              <UnderlinedNumberInput errors={null} onChange={field.onChange} value={field.value} />
            )}
          />
        </Field>
        <ButtonSecondary className='px-14 mr-40' onClick={() => setValue('study_temperature', 0)} type='button'>
          Annuler
        </ButtonSecondary>
        <ButtonPrimary className='px-14' type='submit'>
          Valider
        </ButtonPrimary>
      </Modal>
      <Modal isOpen={taskConfirmModal} onClose={() => setTaskConfirmModal(false)}>
        <p className='mb-8 text-center'>Êtes vous sûr de vouloir ajouter ce fichier ?</p>
        <ButtonSecondary className='px-14 mr-40' onClick={() => setTaskConfirmModal(false)} type='button'>
          Annuler
        </ButtonSecondary>
        <ButtonPrimary className='px-14' type='submit'>
          Valider
        </ButtonPrimary>
      </Modal>
    </form>
  )
}

export { DynamicMechanicalAnalysisRampTaskSubmissionModals }
