import { SvgCreate } from 'assets/svg'
import { UiContext } from 'contexts/ui.context'
import { Nullable, ToastProps } from 'interfaces'
import {
  FormulaConstituentsTypesList,
  FormulaConstituent,
  FormulaConstituentTypes,
  FormulaIngredientsDto,
  FormulaIngredient
} from 'interfaces/api/lab'
import { GenericConstituent } from 'interfaces/api/management'
import { getExperimentFormula, postExperimentFormula, putExperimentFormula } from 'modules/lab/services'
import { getFormulaConstituents } from 'modules/lab/services/constituents'
import { forwardRef, useContext, useEffect, useState } from 'react'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { useParams } from 'react-router-dom'
import { ButtonPrimary, ButtonSecondary, Panel } from 'ui/atoms'
import { Field } from 'ui/atoms/Field'
import { SwitchInput } from 'ui/atoms/SwitchInput'
import { UnderlinedInput, UnderlinedNumberInput } from 'ui/atoms/UnderLinedInput'
import { UnderLinedSelect } from 'ui/atoms/UnderLinedSelect'
import { SpinnerLoaderMask } from 'ui/molecules'
import { InputType } from 'ui/molecules/TextInput'
import { FormulationIngredient, FormulationPanelFormValues } from './../DescriptionTab.type'
import { FormulaTableRow } from './FormulationPanelRow'

const FormulationPanel = forwardRef<HTMLFormElement>((_, ref) => {
  const [error, setError] = useState<Nullable<Record<string, string[]>>>(null)
  const { control, handleSubmit, watch, setValue, getValues } = useForm<FormulationPanelFormValues>()
  const { experimentId: id = '' } = useParams()
  const { updateToast } = useContext(UiContext)

  const fetchConstituents = async () =>
    ((await getFormulaConstituents()).data ?? []).map(({ id, label, type }: GenericConstituent) => ({
      id,
      label,
      type
    }))

  const constituentQuery = useQuery(['formula-constituents'], async () => await fetchConstituents())

  const fetchFormula = async (id?: string) => {
    if (!id) {
      throw new Error('😓 une erreur est survenue')
    }
    return await getExperimentFormula(id)
  }
  const query = useQuery(['formulation', id], async () => await fetchFormula(id), {
    onSuccess: (data) => resetForm(data.data as FormulationPanelFormValues),
    cacheTime: 0
  })
  const resetForm = (data: Nullable<FormulationPanelFormValues>) => {
    if (!data) {
      setValue('ingredients', [
        {
          order: 0,
          constituent_id: undefined,
          constituent_type: 'resin',
          introduced_quantity: 0,
          quantity_to_introduce: 0,
          part_per_hundred_of_resin: 100
        }
      ])
      setValue('degassing', false)
      setValue('pressure', null)
      setValue('pressure_time', null)
      return
    }

    const ingredients = (data?.ingredients ?? [])
      .map(
        ({
          constituent_id,
          order,
          id,
          introduced_quantity,
          quantity_to_introduce,
          part_per_hundred_of_resin
        }: FormulaIngredient) =>
          ({
            constituent_id,
            part_per_hundred_of_resin,
            quantity_to_introduce,
            introduced_quantity,
            order,
            id,
            constituent_type: constituentQuery.data?.find((c) => c.id === constituent_id)?.type ?? 'resin'
          } as FormulationIngredient)
      )
      .sort((a, b) => (a.order > b.order ? 1 : -1))
    setValue('ingredients', ingredients)
    setValue('degassing', data?.degassing ?? false)
    setValue('pressure', data?.pressure ?? 0)
    setValue('pressure_time', data?.pressure_time ?? 0)
  }

  const filteredConstituents = (type?: FormulaConstituentTypes) => {
    if (!type) {
      return []
    }
    return (
      (constituentQuery.data ?? [])
        .filter((c: FormulaConstituent) => c.type === type)
        .sort((a, b) => a.label.localeCompare(b.label))
        .map((c: FormulaConstituent) => ({ value: c.id, label: c.label })) ?? []
    )
  }

  const newConstituents = (order: number) =>
    ({
      order,
      constituent_id: undefined,
      constituent_type: undefined,
      introduced_quantity: undefined,
      quantity_to_introduce: undefined,
      part_per_hundred_of_resin: undefined
    } as FormulationIngredient)

  const handleAddConstituent = () => {
    const constituents = getValues('ingredients')
    const lastIndex = constituents[constituents.length - 1].order ?? 0
    setValue('ingredients', [...constituents, newConstituents(lastIndex + 1)])
  }

  const handleResetForm = () => {
    resetForm(query.data?.data as FormulationPanelFormValues)
    updateToast({
      displayed: true,
      type: 'error',
      content: 'Les modifications on été annulées.'
    } as ToastProps)
  }

  const onChangeDegassing = (value: boolean) => {
    if (!value) {
      setValue('pressure', null)
      setValue('pressure_time', null)
    }
    setValue('degassing', value)
  }

  const handleDeleteConstituent = (order: number) => {
    const filtered = getValues('ingredients')
      .filter((c: FormulationIngredient) => c.order !== order)
      .map((c: FormulationIngredient, index: number) => {
        c.order = index
        return c
      })
    setValue('ingredients', filtered)
  }

  const queryClient = useQueryClient()
  const formulationUpdate = useMutation(
    (payload: FormulaIngredientsDto) =>
      query.data?.data ? putExperimentFormula(id, payload) : postExperimentFormula(id, payload),
    {
      onMutate: () => {
        setError(null)
      },
      onError: (e: { errors: Record<keyof FormulaIngredientsDto, string[]> }) => {
        updateToast({
          displayed: true,
          type: 'error',
          content: "Les modifications n'ont pas pu être enregistrées."
        } as ToastProps)
        setError(e.errors ?? null)
      },
      onSuccess: () => {
        queryClient.invalidateQueries('formulation')
        updateToast({
          displayed: true,
          type: 'success',
          content: 'Les modifications ont bien été enregistrées.'
        } as ToastProps)
      }
    }
  )

  const isLoading = [query.isFetching, constituentQuery.isFetching, formulationUpdate.isLoading].includes(true)

  const handleOnSubmit: SubmitHandler<FormulationPanelFormValues> = (payload: FormulationPanelFormValues) => {
    formulationUpdate.mutate(payload as FormulaIngredientsDto)
  }

  useEffect(() => {
    setValue(
      'introduced_quantity',
      getValues('ingredients')
        .map((i: FormulationIngredient) => i.introduced_quantity ?? 0)
        .reduce((p: number, v: number) => p + v)
    )
    setValue(
      'quantity_to_introduce',
      getValues('ingredients')
        .map((i: FormulationIngredient) => i.quantity_to_introduce ?? 0)
        .reduce((p: number, v: number) => parseFloat((p + v).toFixed(2)))
    )
    setValue(
      'part_per_hundred_of_resin',
      getValues('ingredients')
        .map((i: FormulationIngredient) => i.part_per_hundred_of_resin ?? 0)
        .reduce((p: number, v: number) => p + v)
    )
  }, [getValues()])

  useEffect(() => {
    if (query.data?.data) {
      return
    }
  }, [query.data?.data])

  if (!getValues()) {
    return <form />
  }
  return (
    <form onSubmit={handleSubmit(handleOnSubmit)} ref={ref}>
      <Panel title='Formulation'>
        <SpinnerLoaderMask spinning={isLoading}>
          <Field className='justify-self-center' label='Nom de la résine'>
            <Controller
              control={control}
              name='ingredients.0.constituent_id'
              render={({ field }) => (
                <UnderLinedSelect
                  className='w-48'
                  errors={(error ?? {})['ingredients.0.constituent_id'] ?? null}
                  onChange={field.onChange}
                  options={filteredConstituents('resin')}
                  placeholder='Résine #AA'
                  value={field.value}
                />
              )}
            />
          </Field>
          <div className='mt-8'>
            <label className='mb-3 font-semibold text-black'>Formulation</label>
            <div className='flex flex-col'>
              <div className='grid grid-cols-6 justify-between items-end mb-6'>
                <span className='w-28 text-sm font-medium text-left text-black'>Numéro</span>
                <span className='w-28 text-sm font-medium text-left text-black'>Type de constituant</span>
                <span className='w-28 text-sm font-medium text-left text-black'>Constituant</span>
                <span className='w-28 text-sm font-medium text-left text-black'>Quantité à introduire (phr)</span>
                <span className='w-28 text-sm font-medium text-left text-black'>Quantité à introduire (kg)</span>
                <span className='w-28 text-sm font-medium text-left text-black'>Quantité introduite (kg)</span>
              </div>
              <FormulaTableRow
                constituentsTypes={[{ label: 'resin', value: 0 }]}
                control={control}
                errors={error}
                filteredConstituents={filteredConstituents}
                getValues={getValues}
                index={0}
                readOnlyFields={true}
                setValue={setValue}
              />
              {watch('ingredients')?.map((_constituent: FormulationIngredient, index: number) => (
                <div key={`constituent-${index.toString()}`}>
                  {index > 0 && (
                    <FormulaTableRow
                      constituentsTypes={FormulaConstituentsTypesList.map((l: string, index: number) => ({
                        label: l,
                        value: index
                      }))}
                      control={control}
                      errors={error}
                      filteredConstituents={filteredConstituents}
                      getValues={getValues}
                      index={index}
                      onDelete={() => handleDeleteConstituent(index)}
                      setValue={setValue}
                    />
                  )}
                </div>
              ))}

              <div className='grid grid-cols-6 mb-6'>
                <div className='col-span-3'>
                  <span className='text-sm font-medium text-left text-black'>Quantité total de la formulation</span>
                </div>
                <Controller
                  control={control}
                  name={`part_per_hundred_of_resin`}
                  render={({ field }) => (
                    <UnderlinedNumberInput
                      className='w-28'
                      errors={(error ?? {})['part_per_hundred_of_resin'] ?? null}
                      onChange={field.onChange}
                      placeholder='Chiffre'
                      readOnly={true}
                      value={field.value}
                    />
                  )}
                />
                <Controller
                  control={control}
                  name={`quantity_to_introduce`}
                  render={({ field }) => (
                    <UnderlinedNumberInput
                      className='w-28'
                      errors={(error ?? {})['quantity_to_introduce'] ?? null}
                      onChange={field.onChange}
                      placeholder='Chiffre'
                      readOnly={true}
                      value={field.value}
                    />
                  )}
                />
                <Controller
                  control={control}
                  name={`introduced_quantity`}
                  render={({ field }) => (
                    <UnderlinedNumberInput
                      className='w-28'
                      errors={(error ?? {})['introduced_quantity'] ?? null}
                      onChange={field.onChange}
                      placeholder='Chiffre'
                      readOnly={true}
                      value={field.value}
                    />
                  )}
                />
              </div>
            </div>
            <button className='flex items-center' onClick={handleAddConstituent} type='button'>
              <img className='mr-4 w-7 h-7' src={SvgCreate} />
              <span className='font-medium whitespace-nowrap'>Ajouter un constituant</span>
            </button>
            <Field className='mt-8' label='Dégazage'>
              <Controller
                control={control}
                name={`degassing`}
                render={({ field }) => (
                  <div className='flex flex-col'>
                    <SwitchInput onChange={onChangeDegassing} value={field.value} />
                  </div>
                )}
              />
              {watch('degassing') && (
                <div className='flex flex-row mt-8'>
                  <Field label='Pression absolue (bar)'>
                    <Controller
                      control={control}
                      name={'pressure'}
                      render={({ field }) => (
                        <UnderlinedInput
                          className='mr-64 w-48'
                          errors={(error ?? {})['pressure'] ?? null}
                          onChange={field.onChange}
                          placeholder='Chiffre'
                          type={InputType.NUMBER}
                          value={field.value}
                        />
                      )}
                    />
                  </Field>
                  <Field label='Temps de Pression (min)'>
                    <Controller
                      control={control}
                      name={'pressure_time'}
                      render={({ field }) => (
                        <UnderlinedInput
                          className='w-48'
                          errors={(error ?? {})['pressure_time'] ?? null}
                          onChange={field.onChange}
                          placeholder='Chiffre'
                          type={InputType.NUMBER}
                          value={field.value}
                        />
                      )}
                    />
                  </Field>
                </div>
              )}
            </Field>
          </div>
          <div className='flex flex-row justify-center mt-10'>
            <ButtonSecondary className='px-14 mr-40' onClick={handleResetForm} type='button'>
              Annuler
            </ButtonSecondary>
            <ButtonPrimary className='px-14' type='submit'>
              Valider
            </ButtonPrimary>
          </div>
        </SpinnerLoaderMask>
      </Panel>
    </form>
  )
})

export { FormulationPanel }
