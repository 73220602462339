import { SvgCreate, SvgDisk } from 'assets/svg'
import { Nullable } from 'interfaces'
import { Theme } from 'interfaces/api/management'
import { getManagementThemes, postManagementTheme } from 'modules/list-parameters/services/themes'
import { FC, FormEvent, useState } from 'react'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { useSearchParams } from 'react-router-dom'
import { UnderlinedInput } from 'ui/atoms/UnderLinedInput'
import { PaginationMenu } from 'ui/molecules/PaginationMenu'
import { ThemeItem } from './ThemeItem'

export const Themes: FC = () => {
  const [searchParams] = useSearchParams()

  const [themeCreationError, setThemeCreationError] = useState<Nullable<string[]>>(null)
  const [isInputVisible, setIsInputVisible] = useState(false)
  const [newThemeLabel, setNewthemeLabel] = useState<Nullable<string>>(null)
  const [page, setPage] = useState<string>(searchParams.get('page') ?? '1')
  const [perPage, setPerPage] = useState<string>(searchParams.get('perPage') ?? '10')

  const fetchThemes = (page: string, perPage: string) => getManagementThemes(page, perPage)

  const queryClient = useQueryClient()
  const query = useQuery(['themes', page, perPage], async () => await fetchThemes(page, perPage), {
    keepPreviousData: true
  })

  const themeCreation = useMutation(postManagementTheme, {
    onMutate: () => setThemeCreationError(null),
    onSuccess: () => {
      queryClient.invalidateQueries('themes')
      setIsInputVisible(false)
      setNewthemeLabel(null)
    },
    onError: (e: { message: string }) => setThemeCreationError([e.message])
  })

  const onCreate = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    if (!newThemeLabel) {
      return
    }
    themeCreation.mutate(newThemeLabel)
  }

  const onChange = (value: string) => {
    setNewthemeLabel(value)
  }

  const onPaginationChange = (newPage: string, newPerPage: string) => {
    setPage(newPage)
    setPerPage(newPerPage)
  }

  return (
    <div className='flex flex-col w-full h-full'>
      <div className='py-10 px-16 w-[310px] bg-white rounded shadow-card'>
        <h2 className='mb-2.5 text-sm font-medium'>Thèmatique</h2>
        {query.data?.data &&
          query.data.data
            .sort((a, b) => (a.label > b.label ? 1 : -1))
            .map((theme: Theme) => (
              <div className='mt-5' key={theme.id}>
                <ThemeItem theme={theme} />
              </div>
            ))}
        <div className='flex justify-between items-center mt-5'>
          {!isInputVisible && (
            <button className='flex items-center' onClick={() => setIsInputVisible(true)}>
              <img className='mr-4 w-7 h-7' src={SvgCreate} />
              <span className='font-medium whitespace-nowrap'>Ajouter un thème</span>
            </button>
          )}
          {isInputVisible && (
            <>
              <form className='flex overflow-hidden justify-between w-full' name='form' onSubmit={onCreate}>
                <UnderlinedInput
                  className='w-28'
                  disabled={themeCreation.isLoading}
                  errors={themeCreationError}
                  name='theme'
                  onChange={onChange}
                  value={newThemeLabel ?? ''}
                />
                <button className='w-5 h-5' data-testid='submit-button' name='submit' type='submit'>
                  <img className='w-4 h-4' src={SvgDisk} />
                </button>
              </form>
            </>
          )}
        </div>
      </div>
      <div className='mt-4 ml-auto'>
        <PaginationMenu meta={query.data?.meta ?? null} onChange={onPaginationChange} />
      </div>
    </div>
  )
}
