import packageJSON from '../package.json'
import * as Sentry from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing'

Sentry.init({
  enabled: process.env.REACT_APP_ENVIRONMENT !== 'development',
  dsn: 'https://9116836460ec4335ac9d7a7bd98b6dea@o1156270.ingest.sentry.io/6509075',
  integrations: [new BrowserTracing()],
  environment: process.env.REACT_APP_ENVIRONMENT,
  release: packageJSON.version,
  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 0
})
