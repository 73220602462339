import { SvgTrash } from 'assets/svg'
import { Nullable } from 'interfaces'
import { FormulaConstituentTypes } from 'interfaces/api/lab'
import { FC, FormEvent, useEffect } from 'react'
import { Control, Controller, UseFormGetValues, UseFormSetValue } from 'react-hook-form'
import { UnderlinedNumberInput } from 'ui/atoms/UnderLinedInput'
import { UnderLinedSelect } from 'ui/atoms/UnderLinedSelect'
import { FormulationPanelFormValues } from '../DescriptionTab.type'

interface FormulaTableRowProps {
  control: Control<FormulationPanelFormValues>
  index: number
  getValues: UseFormGetValues<FormulationPanelFormValues>
  setValue: UseFormSetValue<FormulationPanelFormValues>
  onDelete?: () => void
  filteredConstituents: (type?: FormulaConstituentTypes) => { value: number; label: string }[]
  constituentsTypes: { value: number; label: string }[]
  readOnlyFields?: boolean
  errors: Nullable<Record<string, string[]>>
}

const FormulaTableRow: FC<FormulaTableRowProps> = ({
  control,
  index,
  getValues,
  filteredConstituents,
  onDelete,
  readOnlyFields = false,
  setValue,
  errors
}) => {
  useEffect(() => {
    if (index === 0) {
      return
    }
    const values =
      (getValues(`ingredients.${index}.part_per_hundred_of_resin`) ?? 0) *
      ((getValues(`ingredients.0.quantity_to_introduce`) ?? 0) /
        (getValues(`ingredients.0.part_per_hundred_of_resin`) ?? 1))
    setValue(`ingredients.${index}.quantity_to_introduce`, values ?? undefined)
  }, [getValues(`ingredients.0.quantity_to_introduce`), getValues(`ingredients.${index}.part_per_hundred_of_resin`)])

  const constituentsTypeLabel = [
    { value: 'release-agent', label: 'Démoulant' },
    { value: 'filler', label: 'Charge' },
    { value: 'additive', label: 'Additifs' },
    { value: 'peroxyde', label: 'Péroxyde' },
    { value: 'accelerator', label: 'Accelérateur' }
  ].sort((a, b) => a.label.localeCompare(b.label)) as { value: FormulaConstituentTypes; label: string }[]

  return (
    <div className='grid relative grid-cols-6 justify-between mb-6'>
      <Controller
        control={control}
        name={`ingredients.${index}.order`}
        render={({ field }) => (
          <div className='h-8 text-sm font-medium text-warm-grey'>{(field.value + 1).toString()}</div>
        )}
      />
      <Controller
        control={control}
        name={`ingredients.${index}.constituent_type`}
        render={({ field }) => (
          <UnderLinedSelect
            className='w-28'
            disabled={readOnlyFields}
            errors={(errors ?? {})[`ingredients.${index}.constituent_type`] ?? null}
            onChange={field.onChange}
            options={index !== 0 ? constituentsTypeLabel : [{ value: 'resin', label: 'Résine' }]}
            value={field.value}
          />
        )}
      />
      <Controller
        control={control}
        name={`ingredients.${index}.constituent_id`}
        render={({ field }) => (
          <UnderLinedSelect
            className='w-28'
            disabled={readOnlyFields}
            errors={(errors ?? {})[`ingredients.${index}.constituent_id`] ?? null}
            onChange={(e: FormEvent<HTMLSelectElement>) => field.onChange(parseInt(e.currentTarget.value))}
            options={filteredConstituents(getValues(`ingredients.${index}.constituent_type`))}
            value={field.value}
          />
        )}
      />
      <Controller
        control={control}
        name={`ingredients.${index}.part_per_hundred_of_resin`}
        render={({ field }) => (
          <UnderlinedNumberInput
            className='w-28'
            errors={(errors ?? {})[`ingredients.${index}.part_per_hundred_of_resin`] ?? null}
            onChange={field.onChange}
            placeholder='Chiffre'
            readOnly={readOnlyFields}
            value={field.value}
          />
        )}
      />
      <Controller
        control={control}
        name={`ingredients.${index}.quantity_to_introduce`}
        render={({ field }) => (
          <UnderlinedNumberInput
            className='w-28'
            errors={(errors ?? {})[`ingredients.${index}.quantity_to_introduce`] ?? null}
            onChange={field.onChange}
            placeholder='Chiffre'
            readOnly={!readOnlyFields}
            value={parseFloat((field.value ?? 0).toFixed(2)) ?? 0}
          />
        )}
      />
      <Controller
        control={control}
        name={`ingredients.${index}.introduced_quantity`}
        render={({ field }) => (
          <div className='relative'>
            <UnderlinedNumberInput
              className='w-28'
              errors={(errors ?? {})[`ingredients.${index}.introduced_quantity`] ?? null}
              onChange={field.onChange}
              placeholder='Chiffre'
              value={field.value}
            />
            {onDelete && (
              <button className='absolute top-0.5 left-32 w-5 h-5' onClick={onDelete} type='button'>
                <img className='w-4 h-4' src={SvgTrash} />
              </button>
            )}
          </div>
        )}
      />
    </div>
  )
}

export { FormulaTableRow }
