import { AuthenticatedUser } from 'interfaces/api/auth'
import { fetchJson } from 'utils/fetch'
import { PasswordUpdateDTO } from './auth.dto'

export const getCsrf = async () => {
  return fetchJson<undefined, undefined>('GET', '/auth/csrf-cookie')
}

export const postForgotPassword = async (email: string) => {
  return fetchJson<undefined, { email: string }>('POST', '/auth/forgot-password', { email })
}

export const postResetPassword = async (email: string, password: string, passwordConfirm: string, token: string) => {
  return fetchJson<undefined, { email: string; password: string; password_confirmation: string; token: string }>(
    'POST',
    '/auth/reset-password',
    {
      email,
      password,
      password_confirmation: passwordConfirm,
      token
    }
  )
}

export const postLogin = async (email: string, password: string) => {
  return fetchJson<undefined, { email: string; password: string }>('POST', '/auth/login', { email, password })
}

export const postLogout = async () => {
  return fetchJson<undefined, undefined>('POST', '/auth/logout')
}

export const getAuthenticatedUser = async () => {
  return fetchJson<AuthenticatedUser, undefined>('GET', '/auth/me')
}

export const postInitPassword = async (email: string, password: string, passwordConfirm: string, token: string) => {
  return fetchJson('POST', '/auth/init-password', {
    email,
    password,
    password_confirmation: passwordConfirm,
    token
  })
}

export const postUpdatePassword = async (payload: PasswordUpdateDTO) => {
  return fetchJson<undefined, PasswordUpdateDTO>('PUT', '/auth/me/password', payload)
}
