import { FC, useCallback } from 'react'

export const SvgInjector: FC<{ src: string; className?: string }> = ({ src, className }) => {
  const svgContainer = useCallback((node: HTMLDivElement) => {
    const fetchSvg = async (src: string) => {
      if (node) {
        const response = await fetch(src)
        const parsed = await response.text()
        const fullSized = `height="100%" width="100%" `
        node.innerHTML = parsed
          .replace(/(height|width)="[^"]*"/g, fullSized)
          .replace(/fill:#[0-9a-fA-F]*/g, 'fill:currentColor')
          .replace(/stroke:#\dw*/g, 'stroke:currentColor')
      }
    }
    fetchSvg(src)
  }, [])
  return <div className={className} ref={svgContainer} />
}
