import { UserDTO } from 'interfaces/api/management'
import { FC } from 'react'
import { ButtonPrimary } from 'ui/atoms'
import { UnderlinedInput } from 'ui/atoms/UnderLinedInput'
import { InputType } from 'ui/molecules/TextInput'

export const UserTableForm: FC<{
  user: UserDTO
  onChange: (user: UserDTO) => void
  onSave: (user: UserDTO) => void
  errors: Record<string, string>
}> = ({ user, onSave, onChange, errors }) => {
  const onLastNameChange = (value: string) => {
    const updatedUser = { ...user, last_name: value }
    onChange(updatedUser)
  }

  const onFirstNameChange = (value: string) => {
    const updatedUser = { ...user, first_name: value }
    onChange(updatedUser)
  }

  const onEmailChange = (value: string) => {
    const updatedUser = { ...user, email: value }
    onChange(updatedUser)
  }

  return (
    <tr>
      <td className='h-12'>
        <UnderlinedInput
          className='w-24'
          errors={[errors['last_name']] ?? null}
          onChange={onLastNameChange}
          value={user.last_name}
        />
      </td>
      <td className='h-12'>
        <UnderlinedInput
          className='w-24'
          errors={[errors['first_name']] ?? null}
          onChange={onFirstNameChange}
          value={user.first_name}
        />
      </td>
      <td className='h-12'>
        <UnderlinedInput
          className='w-56'
          errors={[errors['email']] ?? null}
          onChange={onEmailChange}
          type={InputType.EMAIL}
          value={user.email}
        />
      </td>
      <td className='h-12'>
        <ButtonPrimary
          className='py-1 px-4 mb-3'
          disabled={!user.email || !user.last_name || !user.first_name}
          onClick={() => onSave(user)}
          type='submit'
        >
          Valider
        </ButtonPrimary>
      </td>
    </tr>
  )
}
