import { SvgArrowDown, SvgCheckboxChecked, SvgCheckboxUnchecked, SvgClose } from 'assets/svg'
import { SelectOptions } from 'interfaces'
import { FC, useState } from 'react'
import { SvgInjector } from 'ui/atoms/SvgInjector'

interface MultiSelectDropdownProps {
  className?: string
  label: string
  onChange: (value: number[]) => void
  value: number[]
  options: SelectOptions[]
}

const MultiSelectDropdown: FC<MultiSelectDropdownProps> = ({ className, value, options, onChange, label }) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false)
  return (
    <div className={className}>
      <div className='overflow-visible relative'>
        <button
          className='flex justify-between pb-2 mb-4 w-full h-8 font-normal border-b border-b-pinkish-grey outline-none cursor-pointer'
          onClick={() => setIsDropdownOpen(!isDropdownOpen)}
          type='button'
        >
          <span>{label}</span>
          <img
            className={`w-4 h-4 transform transition-all ${isDropdownOpen ? 'rotate-180' : 'rotate-0'}`}
            src={SvgArrowDown}
          />
        </button>
        {isDropdownOpen && (
          <div className='relative z-50'>
            <div className='fixed inset-0' onClick={() => setIsDropdownOpen(!isDropdownOpen)}></div>
            <div className={`absolute z-100 inset-x-0 top-full`}>
              <div className={`flex flex-col h-auto max-h-96 relative overflow-y-auto bg-[#FBFCFE] shadow-card`}>
                {options.map(({ value: id, label: name }: SelectOptions) => (
                  <button
                    className='flex p-4 align-middle hover:bg-ice-blue'
                    key={'option' + id}
                    onClick={() =>
                      value.includes(id) ? onChange(value.filter((v) => v !== id)) : onChange([...value, id])
                    }
                    type='button'
                  >
                    <img
                      className='mr-4 w-5 h-5'
                      src={value.includes(id) ? SvgCheckboxChecked : SvgCheckboxUnchecked}
                    />
                    <div>{name}</div>
                  </button>
                ))}
              </div>
            </div>
          </div>
        )}
      </div>
      <div className='flex flex-wrap'>
        {value.map((id: number) => (
          <div className='flex items-center px-2 mr-2 mb-2 text-white bg-greeny-blue rounded-full' key={`tag-${id}`}>
            <div className='mr-4 text-xs'>{options.find((o) => o.value === id)?.label}</div>
            <button onClick={() => onChange(value.filter((v) => v !== id))} type='button'>
              <SvgInjector className='p-1 w-4 h-5' src={SvgClose} />
            </button>
          </div>
        ))}
      </div>
    </div>
  )
}

export { MultiSelectDropdown }
export type { MultiSelectDropdownProps }
