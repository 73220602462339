import { FC, ReactNode, useState } from 'react'
import { SvgInjector } from './SvgInjector'
import { SvgArrowDown } from 'assets/svg'

const Panel: FC<{
  children: ReactNode
  title: string
  className?: string
  noPadding?: boolean
  topLink?: ReactNode
  openable?: boolean
}> = ({ children, title, className = '', noPadding = false, topLink, openable = false }) => {
  const [open, setOpen] = useState<boolean>(!openable)

  function handlePanelClick() {
    if (!openable) {
      return
    }
    setOpen(!open)
  }
  return (
    <div className={`${className} flex flex-col py-6 px-9 bg-white rounded-sm shadow-card`}>
      <div
        className={`flex flex-row justify-between items-center border-b ${openable ? 'cursor-pointer' : ''} ${
          open ? ' border-cool-grey' : 'border-white'
        }`}
        onClick={handlePanelClick}
      >
        <h3 className={`${open ? 'pb-2 ' : 'pb-0'} text-xl text-black`}>{title}</h3>
        {topLink != null && <div>{topLink}</div>}
        {openable && <SvgInjector className={`w-4 ${open ? 'rotate-180' : ''}`} src={SvgArrowDown} />}
      </div>
      {open ? <div className={`${noPadding ? 'py-6' : 'p-9'}`}>{children}</div> : null}
    </div>
  )
}

export { Panel }
