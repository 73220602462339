import { FC } from 'react'
import { SvgGears, SvgList, SvgTestCampaign } from 'assets/svg'
import { NavigationLink } from 'ui/atoms/NavigationLink'
import IRTM2PLogo from 'assets/logo-irtm2p.png'
import { powerBiUrl, solverUrl } from 'config'

export const Navigation: FC = () => (
  <div className='flex flex-col pt-5 h-full bg-gradient-to-b from-dark-blue to-greeny-blue shadow-drawer'>
    <div className='flex flex-col flex-1'>
      <NavigationLink href={'/experiments'} icon={SvgList}>
        Liste d'essais
      </NavigationLink>
      <NavigationLink href={'/configuration'} icon={SvgGears}>
        Configuration machine
      </NavigationLink>
      <NavigationLink href={'/list-parameters'} icon={SvgList}>
        Paramètres de liste
      </NavigationLink>
      <NavigationLink href={'/test-campaign'} icon={SvgTestCampaign}>
        Campagne de test
      </NavigationLink>
    </div>
    <div className='flex flex-col flex-none items-center'>
      <a
        className='py-2 my-2.5 w-48 text-center text-white rounded-md border border-white'
        href={powerBiUrl}
        rel='noreferrer'
        target='_blank'
      >
        Power BI
      </a>
      <a
        className='py-2 my-2.5 w-48 text-center text-white rounded-md border border-white'
        href={solverUrl}
        rel='noreferrer'
        target='_blank'
      >
        Solver
      </a>
      <img alt='logo-irtm2p.png' className='my-7 w-28' src={IRTM2PLogo} />
    </div>
  </div>
)
