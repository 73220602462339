import { LiteMedia, Nullable, TemporaryFileDto } from 'interfaces'

enum EosinClassification {
  OK = 'ok',
  NOK = 'nok'
}

interface ExperimentEosin {
  id: number
  pictures: LiteMedia[]
  comment: Nullable<string>
  classification: Nullable<EosinClassification>
}

interface ExperimentEosinDto {
  pictures: TemporaryFileDto[]
  comment: Nullable<string>
  classification: Nullable<EosinClassification>
}

export { EosinClassification }
export type { ExperimentEosin, ExperimentEosinDto }
