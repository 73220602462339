import { User } from 'interfaces/api/lab'
import { fetchJson } from 'utils/fetch'

/**
 *
 * @returns
 */
const getUsers = () => {
  return fetchJson<User[]>('GET', `/lab/users`)
}

export { getUsers }
