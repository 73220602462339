import { FC, useEffect } from 'react'

export const ToastNotification: FC<{ svg?: string; text: string; onClose: () => void }> = ({ svg, text, onClose }) => {
  useEffect(() => {
    const timeout = setTimeout(() => {
      clearTimeout(timeout)
      onClose()
    }, 2000)
  })

  return (
    <div className='flex fixed inset-x-0 top-0 justify-center'>
      <div className='flex items-center p-6 mx-auto mt-10 bg-white rounded shadow-card opacity-0 transition-all animate-[toast_ease-out_2s] transform-gpu'>
        {svg && <img className='mr-6 w-10 h-10' src={svg} />}
        <span className='mr-10 w-72 text-xl font-medium text-warm-grey'>{text}</span>
      </div>
    </div>
  )
}
