import { UiContext } from 'contexts/ui.context'
import { ExperimentIndexDto } from 'interfaces/api/lab'
import { EMPTY_FILTERS } from 'modules/lab/constants'
import { LabContext } from 'modules/lab/contexts'
import { FC, useContext, useEffect } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { ButtonPrimary } from 'ui/atoms'
import {
  DMAFilters,
  FormulationFilters,
  GeneralInformationFilters,
  MechanicalsFilters,
  ProcessParametersFilters,
  ReinforcementFilter,
  ThermocoupleFilters,
  ViscosityFilters
} from './components'
import { EosinFilter } from './components/EosinFilters'
import { FireTestFilters } from './components/FireTestFilters'
import { ResidualMonomerFilters } from './components/ResidualMonomerFilters'
import { FiberRateFilters } from './components/FiberRateFilters'

const ExperimentTableFilterModal: FC = () => {
  const { filters, updateFilters, resetFilters } = useContext(LabContext)
  const { updateModal } = useContext(UiContext)
  const methods = useForm<ExperimentIndexDto>({ defaultValues: EMPTY_FILTERS })
  const { control, handleSubmit, watch, reset } = methods

  const handleFiltersSubmit = (values: typeof filters) => {
    updateFilters(values)
    updateModal({ displayed: false, content: '' })
  }

  useEffect(() => {
    reset(filters)
  }, [filters])

  return (
    <div className='overflow-y-auto px-16 w-[1100px] h-auto max-h-[80vh]'>
      <div className='flex sticky top-0 z-10 flex-col pt-6 bg-white'>
        <div className='flex flex-row justify-between items-center'>
          <h3 className='text-2xl font-semibold text-black bg-white'>Plus de filtres</h3>
        </div>
        <div className='mt-5 w-full h-0.5 bg-pinkish-grey'></div>
      </div>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(handleFiltersSubmit)}>
          <GeneralInformationFilters />
          <FormulationFilters control={control} formValues={watch('formula.constituents')} />
          <ViscosityFilters />
          <ReinforcementFilter control={control} />
          <ProcessParametersFilters control={control} />
          <MechanicalsFilters control={control} />
          <ThermocoupleFilters control={control} />
          <DMAFilters control={control} />
          <EosinFilter control={control} />
          <FiberRateFilters control={control} />
          <FireTestFilters control={control} />
          <ResidualMonomerFilters control={control} />
          <div className='flex sticky inset-x-0 bottom-0 z-10 justify-between items-center py-8 bg-white'>
            <button className='font-bold border-b-2 border-b-greyish-brown' onClick={resetFilters} type='button'>
              Tout effacer
            </button>
            <ButtonPrimary type='submit'>Afficher les resultats</ButtonPrimary>
          </div>
        </form>
      </FormProvider>
    </div>
  )
}

export { ExperimentTableFilterModal }
