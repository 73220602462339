import { ExperimentIndexDto } from 'interfaces/api/lab'
import { Controller, useFormContext } from 'react-hook-form'
import { DualNumberInput } from 'ui/molecules'

const ViscosityFilters = () => {
  const { control } = useFormContext<ExperimentIndexDto>()
  return (
    <div className='flex flex-col mt-5'>
      <details className='' open={true}>
        <summary className='py-1 px-2 text-xl font-semibold list-none text-black bg-ice-blue cursor-pointer'>
          Viscosité
        </summary>
        <div className='grid grid-cols-3 gap-8 py-5'>
          <Controller
            control={control}
            defaultValue={{ min: null, max: null }}
            name='viscosity.starting_drain'
            render={({ field }) => (
              <DualNumberInput label='Viscosité début' onChange={field.onChange} value={field.value} />
            )}
          />
          <Controller
            control={control}
            defaultValue={{ min: null, max: null }}
            name='viscosity.drain'
            render={({ field }) => (
              <DualNumberInput label='Viscosité égouttures' onChange={field.onChange} value={field.value} />
            )}
          />
          <Controller
            control={control}
            defaultValue={{ min: null, max: null }}
            name='viscosity.ending_drain'
            render={({ field }) => (
              <DualNumberInput label='Viscosité fin' onChange={field.onChange} value={field.value} />
            )}
          />
        </div>
      </details>
    </div>
  )
}

export { ViscosityFilters }
