import { DATE_FORMAT } from 'core/constants'
import { format, parse } from 'date-fns'
import { general } from 'modules/lab/schemas'
import { FC } from 'react'
import { z } from 'zod'
import { TableTd } from './TableTd'
import { TableTdLink } from './TableTdLink'

const GeneralTd: FC<{ matrix: Record<string, boolean> | boolean; id: number; data: z.infer<typeof general> }> = ({
  matrix,
  id,
  data
}) => {
  const date = (d: string): string => {
    return format(parse(d, DATE_FORMAT, new Date()), 'dd/MM/yyyy')
  }
  const referenceMatches = data.reference.match(/([P|R])([A-Z])([0-9]{6}_[0-9]{1,2})/)
  const reference = referenceMatches
    ? `${referenceMatches[1]}${referenceMatches[2].toLowerCase()}${referenceMatches[3]}`
    : ''
  return (
    <>
      <TableTdLink
        className="sticky after:absolute after:top-0.5 after:-right-5 left-0 z-10 after:w-5 after:h-12 after:content-[''] after:bg-gradient-to-l after:from-transparent after:to-white"
        id={id}
      >
        <span className='font-bold text-dusk-blue'>{reference}</span>
      </TableTdLink>
      {matrix instanceof Object && (
        <>
          {matrix.realized_at && <TableTd className='text-left'>{date(data.realized_at)}</TableTd>}
          {matrix.theme && <TableTd className='text-left'>{data.theme}</TableTd>}
          {matrix.user && <TableTd className='text-left'>{data.user}</TableTd>}
          {matrix.configuration && <TableTd className='text-left'>{data.configuration}</TableTd>}
          {matrix.pultrusion_die && <TableTd className='text-left'>{data.pultrusion_die}</TableTd>}
          {matrix.speed && <TableTd className='text-left'>{data.speed}</TableTd>}
        </>
      )}
    </>
  )
}

export { GeneralTd }
