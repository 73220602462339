import { SvgArrowDown } from 'assets/svg'
import { Angle } from 'interfaces/api/lab'
import { i18n as productionDatai18n } from 'modules/lab/i18n'
import { FC } from 'react'
import { SvgInjector } from 'ui/atoms/SvgInjector'

import { FlexionAngleForm } from './FlexionPanelForm'

const localeI18n = productionDatai18n.productionDataTab.flexionPanel

const FlexionPanelAngle: FC<{
  angle: Angle
  open: boolean
  onPanelChange: (open: boolean) => void
}> = ({ angle, open, onPanelChange }) =>
  open ? (
    <FlexionAngleForm angle={angle} onClose={() => onPanelChange(false)} />
  ) : (
    <div className={`flex flex-col py-6 px-9 bg-white rounded-sm shadow-card`}>
      <div className='flex flex-row justify-between items-center cursor-pointer' onClick={() => onPanelChange(true)}>
        <h3 className='text-xl text-black'>{`${localeI18n.title} ${angle}°`}</h3>
        <SvgInjector className='w-4' src={SvgArrowDown} />
      </div>
    </div>
  )
export { FlexionPanelAngle }
