import { UiContext } from 'contexts/ui.context'
import { ToastType } from 'core/constants'
import { ErrorObj, Extensions, LiteMedia, Nullable } from 'interfaces'
import {
  FireTestClassification,
  ExperimentFireTest,
  ExperimentFireTestDto,
  ExperimentFireTestTaskDto
} from 'interfaces/api/lab'
import { FileAndTaskManagement } from 'modules/files'
import {
  getExperimentFireTest,
  putExperimentFireTest,
  postExperimentFireTest,
  postExperimentFireTestTask
} from 'modules/lab/services'
import { Dto } from 'modules/list-parameters/services/list-parameters.dto'
import { forwardRef, memo, useContext, useState } from 'react'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { useParams } from 'react-router-dom'

import { ButtonPrimary, ButtonSecondary, InputError, Panel } from 'ui/atoms'
import { Field } from 'ui/atoms/Field'
import { InputRadio } from 'ui/atoms/InputRadio'

import { FireTestFormValues } from '../ProductionDataTab.type'

import { i18n as productionDatai18n } from 'modules/lab/i18n'
import { SpinnerLoaderMask } from 'ui/molecules'
import { i18n } from 'i18n'
import { Task } from 'interfaces/api/task'
import { TaskOverwriteModal } from '../../shared'

const localeI18n = productionDatai18n.productionDataTab.fireTestPanel

const FireTestPanel = memo(
  forwardRef<HTMLFormElement>((_, ref) => {
    const { experimentId } = useParams()

    if (!experimentId) {
      return null
    }

    const [taskId, setTaskId] = useState<Nullable<number>>(null)
    const [canSubmit, setCanSubmit] = useState<boolean>(false)
    const [taskModal, setTaskModal] = useState<boolean>(false)
    const [taskFiles, setTaskFiles] = useState<LiteMedia[]>([])
    const [fireTestError, setFireTestError] = useState<Nullable<Record<keyof ExperimentFireTestDto, string[]>>>(null)
    const { updateToast } = useContext(UiContext)
    const queryClient = useQueryClient()

    const { control, handleSubmit, reset, getValues } = useForm<FireTestFormValues>()

    const get = useQuery(
      ['lab.experiment.fireTest', experimentId],
      async () => await getExperimentFireTest(experimentId),
      {
        onSuccess: ({ data }) => {
          setCanSubmit(true)
          resetForm(data)
        }
      }
    )

    const resetForm = (data?: Nullable<ExperimentFireTest>) => {
      data ? reset({ ...data, file: data.file ? [data.file] : [] }) : reset()
    }

    const post = useMutation((data: Dto<ExperimentFireTestDto>) => postExperimentFireTest(experimentId, data), {
      onMutate: () => {
        setFireTestError(null)
      },
      onSuccess: (data) => {
        queryClient.setQueryData(['lab.experiment.fireTest', experimentId], data)
        updateToast({
          content: 'Test au feu créé avec succès',
          displayed: true,
          type: ToastType.SUCCESS
        })
      },
      onError: (e: Error & { errors?: ErrorObj<ExperimentFireTestDto> }) => {
        setFireTestError(e.errors ?? null)
        updateToast({
          content: e.message ?? 'Une erreur est survenue',
          displayed: true,
          type: ToastType.ERROR
        })
      }
    })
    const put = useMutation((data: Dto<ExperimentFireTestDto>) => putExperimentFireTest(experimentId, data), {
      onMutate: () => {
        setFireTestError(null)
      },
      onSuccess: (data) => {
        queryClient.setQueryData(['lab.experiment.fireTest', experimentId], data)
        updateToast({
          content: 'Test au feu mise à jour avec succès',
          displayed: true,
          type: ToastType.SUCCESS
        })
      },
      onError: (e: Error & { errors?: ErrorObj<ExperimentFireTestDto> }) => {
        setFireTestError(e.errors ?? null)
        updateToast({
          content: e.message ?? 'Une erreur est survenue',
          displayed: true,
          type: ToastType.ERROR
        })
      }
    })

    const handleOnSubmit: SubmitHandler<FireTestFormValues> = (payload) => {
      get.data?.data ? put.mutate(payload) : post.mutate(payload)
    }

    const handleCreateTask = (files: LiteMedia[]) => {
      setTaskModal(true)
      setTaskFiles(files)
    }

    const handleTaskCreationDone = (data: Task) => {
      setTaskModal(false)
      if (!data.results) {
        return
      }
      reset({ ...getValues(), ...data.results })
    }

    return (
      <>
        <form onSubmit={handleSubmit(handleOnSubmit)} ref={ref}>
          <Panel openable={true} title={localeI18n.title}>
            <SpinnerLoaderMask
              message={get.isFetching ? i18n.isLoading : i18n.isSaving}
              spinning={get.isFetching || post.isLoading || put.isLoading}
            >
              <div className='flex flex-col items-center'>
                <Controller
                  control={control}
                  defaultValue={[]}
                  name='file'
                  render={({ field }) => (
                    <div className='flex flex-col'>
                      <Field className='' label='Importer un fichier'>
                        <FileAndTaskManagement
                          accept={[Extensions.XLS, Extensions.XLSX, Extensions.CSV]}
                          onChange={field.onChange}
                          onCreateTask={handleCreateTask}
                          onReadyToSubmitChange={setCanSubmit}
                          onTaskPollingEnd={handleTaskCreationDone}
                          taskId={taskId}
                          value={field.value}
                        />
                        <InputError errors={fireTestError?.file ?? null} />
                      </Field>
                    </div>
                  )}
                />
                <Field
                  className='flex flex-row justify-self-start self-start mb-3'
                  hasError={!!fireTestError?.classification}
                  label='Classement'
                  required={true}
                >
                  <Controller
                    control={control}
                    name='classification'
                    render={({ field }) => (
                      <div className='flex flex-col'>
                        <div className='flex flex-row'>
                          <InputRadio
                            id={FireTestClassification.V0}
                            label='V0'
                            name='linear-type'
                            onChange={field.onChange}
                            value={field.value}
                          />
                          <InputRadio
                            className='ml-10'
                            id={FireTestClassification.V1}
                            label='V1'
                            name='linear-type'
                            onChange={field.onChange}
                            value={field.value}
                          />
                          <InputRadio
                            className='ml-10'
                            id={FireTestClassification.V2}
                            label='V2'
                            name='linear-type'
                            onChange={field.onChange}
                            value={field.value}
                          />
                          <InputRadio
                            className='ml-10'
                            id={FireTestClassification.NC}
                            label='NC'
                            name='linear-type'
                            onChange={field.onChange}
                            value={field.value}
                          />
                        </div>
                        <InputError errors={fireTestError?.classification ?? null} />
                      </div>
                    )}
                  />
                </Field>
              </div>
              <div className='flex flex-row justify-around mx-auto mt-10 w-full max-w-2xl'>
                <ButtonSecondary className='px-14 mr-4' onClick={() => resetForm(get.data?.data)} type='button'>
                  Annuler
                </ButtonSecondary>
                <ButtonPrimary className='px-14' disabled={!canSubmit} type='submit'>
                  Valider
                </ButtonPrimary>
              </div>
            </SpinnerLoaderMask>
          </Panel>
        </form>
        <TaskOverwriteModal
          experimentId={experimentId}
          files={taskFiles}
          mutationFn={(payload: Dto<ExperimentFireTestTaskDto>) => postExperimentFireTestTask(experimentId, payload)}
          onClose={() => setTaskModal(false)}
          onTaskIdChange={setTaskId}
          open={taskModal}
        />
      </>
    )
  })
)

export { FireTestPanel }
