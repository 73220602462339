import { ApiError, Nullable } from 'interfaces'
import { FC, FormEvent, useState } from 'react'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { ArkemaLogo, ButtonPrimary, InputError } from 'ui/atoms'
import { PasswordInput, TextInput } from 'ui/molecules'
import { postInitPassword } from '../services/auth'
import { InputType } from 'ui/molecules/TextInput'

export const InitPassword: FC = () => {
  const [searchParams] = useSearchParams()
  const { token } = useParams()
  const [isSuccess, setIsSuccess] = useState(false)
  const [email, setEmail] = useState<Nullable<string>>(searchParams.get('email'))
  const [password, setPassword] = useState<Nullable<string>>('')
  const [passwordConfirm, setPasswordConfirm] = useState<Nullable<string>>('')
  const [errors, setErrors] = useState<Nullable<ApiError>>(null)
  const navigate = useNavigate()
  const [isFetching, setisFetching] = useState(false)
  // redirect to home page
  const onSubmitForm = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    setisFetching(true)
    setErrors(null)
    setIsSuccess(false)
    if (email && password && passwordConfirm && token) {
      try {
        await postInitPassword(email, password, passwordConfirm, token)
        setIsSuccess(true)
        setTimeout(() => navigate('/'), 3000)
      } catch (e) {
        setErrors(e as ApiError)
      }
      setisFetching(false)
    }
  }
  return (
    <div className='flex flex-col'>
      <div className='my-16 mx-20 w-[350px]'>
        <div className='flex justify-center mb-16'>
          <ArkemaLogo />
        </div>
        <form className='flex relative flex-col justify-center mx-auto w-[350px]' onSubmit={(e) => onSubmitForm(e)}>
          <TextInput
            className='mb-4'
            errors={errors?.errors?.email ?? null}
            label='Addresse Email'
            name='username'
            onChange={(value) => setEmail(value)}
            placeholder='Saisir ici votre adresse email'
            required={true}
            type={InputType.EMAIL}
            value={email ?? ''}
          />
          <PasswordInput
            errors={errors?.errors?.password ?? null}
            label='Créer un nouveau mot de passe'
            min={8}
            name='password'
            onChange={(value) => setPassword(value)}
            required={true}
            value={password}
          />
          <PasswordInput
            errors={errors?.errors?.password_confirmation ?? null}
            label='Confirmer votre mot de passe'
            min={8}
            name='passwordConfirm'
            onChange={(value) => setPasswordConfirm(value)}
            required={true}
            value={passwordConfirm}
          />
          {isSuccess && (
            <div className='mb-4 text-sm font-semibold text-dusty-teal'>
              La création de votre mot de passe a bien été prise en compte. Vous allez être redirigé vers la page
              d'accueil.
            </div>
          )}
          {errors && errors.message && (
            <InputError className='mb-4 text-sm font-semibold text-[red]' errors={[errors.message]} />
          )}
          {errors && !errors.message && (
            <InputError className='mb-4 text-sm font-semibold text-[red]' errors={['Une erreur est survenue.']} />
          )}
          <ButtonPrimary
            className='mx-auto mb-3'
            disabled={isSuccess || isFetching || !(email && password && passwordConfirm && token)}
            isFetching={isFetching}
            type='submit'
          >
            Confirmer
          </ButtonPrimary>
        </form>
      </div>
    </div>
  )
}
