import { SvgTrash } from 'assets/svg'
import { Nullable } from 'interfaces'
import { Theme } from 'interfaces/api/management'
import { deleteManagementTheme, putManagementTheme } from 'modules/list-parameters/services/themes'
import { FC, useState } from 'react'
import { useMutation, useQueryClient } from 'react-query'
import { UnderlinedInput } from 'ui/atoms/UnderLinedInput'
import { DebouncedTextInput } from 'ui/molecules'

export const ThemeItem: FC<{
  theme: Theme
}> = ({ theme }) => {
  const [themeUpdateError, setThemeUpdateError] = useState<Nullable<string[]>>(null)

  const queryClient = useQueryClient()
  const themeUpdate = useMutation(
    (params: { id: number; label: string }) => putManagementTheme(params.id, params.label),
    {
      onMutate: () => setThemeUpdateError(null),
      onSuccess: () => queryClient.invalidateQueries('themes'),
      onError: (e: { message: string }) => setThemeUpdateError([e.message])
    }
  )
  const onChange = (label: string) => {
    themeUpdate.mutate({ id: theme.id, label })
  }

  const themeDelete = useMutation(deleteManagementTheme, {
    onSuccess: () => queryClient.invalidateQueries('themes')
  })

  return (
    <div className='flex justify-between items-center'>
      <div className='overflow-hidden w-28'>
        <DebouncedTextInput
          Component={UnderlinedInput}
          disabled={themeUpdate.isLoading}
          errors={themeUpdateError}
          onChange={onChange}
          value={theme.label}
        />
      </div>
      {theme.deletable && (
        <button className='w-5' data-testid='delete-button' onClick={() => themeDelete.mutate(theme.id)}>
          <img className='w-5 h-5' src={SvgTrash} />
        </button>
      )}
    </div>
  )
}
