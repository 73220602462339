import { FC, useEffect, useState } from 'react'
import { Nullable } from 'interfaces'
import { debounceDelay } from 'config'
import { TextInputProps } from './TextInput'

export const DebouncedTextInput: FC<{
  className?: string
  disabled?: boolean
  Component: FC<TextInputProps>
  value: Nullable<string>
  onChange: (value: string) => void
  errors: Nullable<string[]>
}> = ({ disabled, className, Component, value, onChange, errors }) => {
  const [inputValue, setInputValue] = useState<string>('')

  const [timeOut, setTimeOut] = useState<Nullable<NodeJS.Timeout>>(null)
  const [isTyping, setIsTyping] = useState(false)
  useEffect(() => {
    setInputValue(value ?? '')
  }, [value])

  const onInput = (value: string) => {
    setIsTyping(false)
    setTimeout(() => setIsTyping(true), 10)
    if (timeOut) {
      clearTimeout(timeOut)
      setTimeOut(null)
    }
    setInputValue(value)
    setTimeOut(
      setTimeout(() => {
        onChange(value)
        setIsTyping(false)
      }, debounceDelay)
    )
  }

  return (
    <>
      <Component
        className={className}
        disabled={disabled}
        errors={isTyping ? null : errors}
        onChange={onInput}
        value={inputValue}
      ></Component>
      <div className='w-full h-0.5'>
        {isTyping && <div className={`h-0.5 bg-greeny-blue w-full animate-[slidein_linear_1s]`} />}
      </div>
    </>
  )
}
