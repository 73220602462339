import { FC } from 'react'
import { Outlet } from 'react-router-dom'
import { MainTemplate } from 'ui/templates'
import { ListParametersNavigation } from './components/ListParametersNavigation'

export const ListParameters: FC = () => (
  <MainTemplate
    className='overflow-y-auto w-full'
    title={<h1 className='text-2xl font-bold text-dark-blue'>Paramètres de liste</h1>}
  >
    <section className='flex flex-auto w-full'>
      <div className='pb-10 mr-12 w-[310px]'>
        <ListParametersNavigation />
      </div>
      <div className='flex mt-[68px] h-full'>
        <Outlet />
      </div>
    </section>
  </MainTemplate>
)
