import { UiContext } from 'contexts/ui.context'
import { ToastType } from 'core/constants'
import { i18n } from 'i18n'
import { ErrorObj, Nullable } from 'interfaces'
import { ExperimentLinearDensity, ExperimentLinearDensityDto, LinearDensity } from 'interfaces/api/lab'
import {
  getExperimentLinearDensity,
  postExperimentLinearDensity,
  putExperimentLinearDensity
} from 'modules/lab/services'
import { Dto } from 'modules/list-parameters/services/list-parameters.dto'
import { forwardRef, memo, useContext, useState } from 'react'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { useParams } from 'react-router-dom'

import { ButtonPrimary, ButtonSecondary, Panel } from 'ui/atoms'
import { Field } from 'ui/atoms/Field'
import { InputRadio } from 'ui/atoms/InputRadio'
import { UnderlinedInput, UnderlinedNumberInput } from 'ui/atoms/UnderLinedInput'
import { SpinnerLoaderMask } from 'ui/molecules'
import { LinearDensityFormValues } from './ProductionDataTab.type'

const LinearDensityPanel = memo(
  forwardRef<HTMLFormElement>((_, ref) => {
    const { experimentId } = useParams()

    if (!experimentId) {
      return null
    }

    const { control, handleSubmit, watch, reset } = useForm<LinearDensityFormValues>()
    const [linearDensityError, setLinearDensityError] =
      useState<Nullable<Record<keyof ExperimentLinearDensityDto, string[]>>>(null)
    const { updateToast } = useContext(UiContext)
    const queryClient = useQueryClient()

    const get = useQuery(
      ['lab.experiment.linear-density', experimentId],
      async () => await getExperimentLinearDensity(experimentId),
      {
        onSuccess: ({ data }) => {
          resetForm(data)
        }
      }
    )

    const resetForm = (data?: Nullable<ExperimentLinearDensity>) => {
      data ? reset(data) : reset({ type: LinearDensity.ROD })
    }

    const post = useMutation(
      (data: Dto<ExperimentLinearDensityDto>) => postExperimentLinearDensity(experimentId, data),
      {
        onMutate: () => {
          setLinearDensityError(null)
        },
        onSuccess: (data) => {
          queryClient.setQueryData(['lab.experiment.linear-density', experimentId], data)
          updateToast({
            content: 'Densité linéique crée avec succès',
            displayed: true,
            type: ToastType.SUCCESS
          })
        },
        onError: (e: Error & { errors?: ErrorObj<ExperimentLinearDensityDto> }) => {
          setLinearDensityError(e.errors ?? null)
          updateToast({
            content: e.message ?? 'Une erreur est survenue',
            displayed: true,
            type: ToastType.ERROR
          })
        }
      }
    )
    const put = useMutation((data: Dto<ExperimentLinearDensityDto>) => putExperimentLinearDensity(experimentId, data), {
      onMutate: () => {
        setLinearDensityError(null)
      },
      onSuccess: (data) => {
        queryClient.setQueryData(['lab.experiment.linear-density', experimentId], data)
        updateToast({
          content: 'Densité linéique mise à jour avec succès',
          displayed: true,
          type: ToastType.SUCCESS
        })
      },
      onError: (e: Error & { errors?: ErrorObj<ExperimentLinearDensityDto> }) => {
        setLinearDensityError(e.errors ?? null)
        updateToast({
          content: e.message ?? 'Une erreur est survenue',
          displayed: true,
          type: ToastType.ERROR
        })
      }
    })

    const handleOnSubmit: SubmitHandler<LinearDensityFormValues> = (payload) => {
      get.data?.data ? put.mutate(payload) : post.mutate(payload)
    }

    return (
      <form onSubmit={handleSubmit(handleOnSubmit)} ref={ref}>
        <Panel openable={true} title='Masse linéique et dimension'>
          <SpinnerLoaderMask
            message={get.isFetching ? i18n.isLoading : i18n.isSaving}
            spinning={get.isFetching || post.isLoading || put.isLoading}
          >
            <div className='flex flex-col justify-between items-baseline 2xl:flex-row 2xl:items-center'>
              <Field className='justify-self-start mb-8 2xl:mb-0' label='Masse linéique (g/m)'>
                <Controller
                  control={control}
                  name='density'
                  render={({ field }) => (
                    <UnderlinedNumberInput
                      className='w-48'
                      errors={linearDensityError?.density ?? null}
                      onChange={field.onChange}
                      placeholder=''
                      value={field.value}
                    />
                  )}
                />
              </Field>
              <Field className='flex flex-row justify-self-start mb-8 2xl:mb-0' label='Sélectionner la géométrie'>
                <Controller
                  control={control}
                  name='type'
                  render={({ field }) => (
                    <>
                      <InputRadio
                        id={LinearDensity.ROD}
                        label='Jonc'
                        name='linear-type'
                        onChange={field.onChange}
                        value={field.value}
                      />
                      <InputRadio
                        className='ml-8'
                        id={LinearDensity.PLATE}
                        label='Plaque'
                        name='linear-type'
                        onChange={field.onChange}
                        value={field.value}
                      />
                      <InputRadio
                        className='ml-8'
                        id={LinearDensity.OTHER}
                        label='Autre'
                        name='linear-type'
                        onChange={field.onChange}
                        value={field.value}
                      />
                    </>
                  )}
                />
              </Field>
              {watch('type') === LinearDensity.ROD && (
                <Field className='justify-self-start mb-8 2xl:mb-0' label='Diamètre (mm)'>
                  <Controller
                    control={control}
                    defaultValue={null}
                    name='diameter'
                    render={({ field }) => (
                      <UnderlinedNumberInput
                        className='w-48'
                        errors={linearDensityError?.diameter ?? null}
                        onChange={field.onChange}
                        placeholder=''
                        value={field.value}
                      />
                    )}
                  />
                </Field>
              )}
              {watch('type') === LinearDensity.PLATE && (
                <Field className='justify-self-start mb-8 2xl:mb-0' label='Largeur (mm)'>
                  <Controller
                    control={control}
                    defaultValue={null}
                    name='width'
                    render={({ field }) => (
                      <UnderlinedNumberInput
                        className='w-24'
                        errors={linearDensityError?.width ?? null}
                        onChange={field.onChange}
                        placeholder=''
                        value={field.value}
                      />
                    )}
                  />
                </Field>
              )}
              {watch('type') === LinearDensity.PLATE && (
                <Field className='justify-self-start mb-8 2xl:mb-0' label='Épaisseur (mm)'>
                  <Controller
                    control={control}
                    defaultValue={null}
                    name='thickness'
                    render={({ field }) => (
                      <UnderlinedNumberInput
                        className='w-24'
                        errors={linearDensityError?.thickness ?? null}
                        onChange={field.onChange}
                        placeholder=''
                        value={field.value}
                      />
                    )}
                  />
                </Field>
              )}
              {watch('type') === LinearDensity.OTHER && (
                <Field className='justify-self-start mb-8 2xl:mb-0' label='Autre'>
                  <Controller
                    control={control}
                    defaultValue={null}
                    name='other'
                    render={({ field }) => (
                      <UnderlinedInput
                        className='w-48'
                        errors={linearDensityError?.other ?? null}
                        onChange={field.onChange}
                        placeholder=''
                        value={field.value}
                      />
                    )}
                  />
                </Field>
              )}
            </div>
            <div className='flex flex-row justify-around mx-auto mt-10 max-w-2xl'>
              <ButtonSecondary className='px-14 mr-4' onClick={() => resetForm(get.data?.data)} type='button'>
                Annuler
              </ButtonSecondary>
              <ButtonPrimary className='px-14' type='submit'>
                Valider
              </ButtonPrimary>
            </div>
          </SpinnerLoaderMask>
        </Panel>
      </form>
    )
  })
)

export { LinearDensityPanel }
