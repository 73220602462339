import { DATE_FORMAT } from 'core/constants'
import { format, parse } from 'date-fns'
import { Nullable } from 'interfaces'
import { DateRange, RangeValueError } from 'interfaces/api/lab'
import { FC } from 'react'
import ReactDatePicker from 'react-datepicker'
import { InputError } from 'ui/atoms'
import { Field } from 'ui/atoms/Field'

interface DualDateInputProps {
  label: string
  onChange: (dateRange: DateRange) => void
  value?: Nullable<DateRange>
  errors?: RangeValueError
}

const DualDateInput: FC<DualDateInputProps> = ({ label, value, onChange, errors }) => {
  return (
    <Field className='flex' label={label}>
      <div>
        <div>Min</div>
        <ReactDatePicker
          calendarStartDay={1}
          customInput={
            <input
              className={`${
                errors?.min?.length ? 'border-b-[red]' : 'border-b-pinkish-grey'
              } pb-2 text-sm text-warm-grey border-b outline-none h-8 w-inherit bg-white`}
            />
          }
          dateFormat={'dd/MM/yy'}
          locale='fr'
          onChange={(date) => {
            onChange({ min: format(date ?? new Date(), DATE_FORMAT), max: value?.max })
          }}
          placeholderText='JJ/MM/AA'
          selected={value?.min ? parse(value.min, DATE_FORMAT, new Date()) : null}
          showPopperArrow={false}
          wrapperClassName='w-full'
        />
        <InputError errors={errors?.min ?? null} />
      </div>
      <div className='ml-4'>
        <div>Max</div>
        <ReactDatePicker
          calendarStartDay={1}
          customInput={
            <input
              className={`${
                errors?.min?.length ? 'border-b-[red]' : 'border-b-pinkish-grey'
              } pb-2 text-sm text-warm-grey border-b outline-none h-8 w-inherit bg-white`}
            />
          }
          dateFormat={'dd/MM/yy'}
          locale='fr'
          onChange={(date) => {
            onChange({ max: format(date ?? new Date(), DATE_FORMAT), min: value?.min })
          }}
          placeholderText='JJ/MM/AA'
          selected={value?.max ? parse(value.max, DATE_FORMAT, new Date()) : null}
          showPopperArrow={false}
          wrapperClassName='w-full'
        />
        <InputError errors={errors?.max ?? null} />
      </div>
    </Field>
  )
}

export type { DualDateInputProps }
export { DualDateInput }
