import { ExperimentIndexDto, FormulaConstituentsTypesList, FormulaConstituentTypes } from 'interfaces/api/lab'
import { getFormulaConstituents } from 'modules/lab/services/constituents'
import { FC } from 'react'
import { Control, Controller, ControllerRenderProps } from 'react-hook-form'
import { useQuery } from 'react-query'
import { MultiSelectDropdown } from 'ui/molecules'

const FormulationFilters: FC<{
  control: Control<ExperimentIndexDto>
  formValues: number[]
}> = ({ control, formValues }) => {
  const constituentQuery = useQuery(['filters.formula-constituents'], async () => await getFormulaConstituents())
  const constituentsQueryData = constituentQuery.data?.data ?? []

  const constituentsTypesLabels: Record<FormulaConstituentTypes, string> = {
    resin: 'Résine',
    'release-agent': 'Démoulant',
    filler: 'Charge',
    additive: 'Additifs',
    peroxyde: 'Péroxyde',
    accelerator: 'Accelérateur'
  }
  const onFormulaChange = (
    field: ControllerRenderProps<ExperimentIndexDto, 'formula.constituents'>,
    typeValues: number[],
    type: FormulaConstituentTypes
  ) => {
    const allValues = constituentsQueryData.filter((c) => c.type !== type).map(({ id }) => id)
    const currentFormValues = formValues.filter((v) => allValues.includes(v))
    const unique = [...new Set([...currentFormValues, ...typeValues])]
    field.onChange(unique)
  }

  const constituentsByType = (type: FormulaConstituentTypes) =>
    constituentsQueryData.filter((c) => c.type === type).map(({ id, label }) => ({ value: id, label }))

  const getValueByType = (type: FormulaConstituentTypes) =>
    constituentsQueryData
      .filter((c) => c.type === type)
      .map(({ id }) => id)
      .filter((v) => formValues.includes(v))
  return (
    <div className='flex flex-col mt-5'>
      <details className='' open={true}>
        <summary className='py-1 px-2 text-xl font-semibold list-none text-black bg-ice-blue cursor-pointer'>
          Formulation
        </summary>
        <div className='grid grid-cols-3 gap-8 py-5'>
          <Controller
            control={control}
            name='formula.constituents'
            render={({ field }) => (
              <>
                {FormulaConstituentsTypesList.map((type: FormulaConstituentTypes) => (
                  <MultiSelectDropdown
                    key={'dropdown-' + type}
                    label={constituentsTypesLabels[type]}
                    onChange={(value) => onFormulaChange(field, value, type)}
                    options={constituentsByType(type)}
                    value={getValueByType(type)}
                  />
                ))}
              </>
            )}
          />
        </div>
      </details>
    </div>
  )
}

export { FormulationFilters }
