import { SvgTrash } from 'assets/svg'
import { UiContext } from 'contexts/ui.context'
import { User } from 'interfaces/api/management'
import { putManagementUser } from 'modules/list-parameters/services/users'
import { FC, useContext, useState } from 'react'
import { QueryClient, useMutation } from 'react-query'
import { ButtonPrimary, ButtonSecondary } from 'ui/atoms'
import { UnderlinedInput } from 'ui/atoms/UnderLinedInput'
import { DebouncedTextInput } from 'ui/molecules'

export const UserTableItem: FC<{
  className: string
  user: User
  onUserDelete: (id: number) => void
}> = ({ className, user, onUserDelete }) => {
  const [userUpdateError, setUserUpdateError] = useState<Record<string, string>>({})
  const { updateModal } = useContext(UiContext)

  const queryClient = new QueryClient()

  const userUpdate = useMutation((user: User) => putManagementUser(user), {
    onMutate: () => setUserUpdateError({}),
    onSuccess: () => queryClient.invalidateQueries('themes'),
    onError: (e: { errors?: Record<string, string> }) => setUserUpdateError(e.errors ?? {})
  })

  const onUserUpdate = (user: User) => {
    userUpdate.mutate(user)
  }

  const onLastNameChange = (value: string) => {
    const updatedUser = { ...user, last_name: value }
    onUserUpdate(updatedUser)
  }

  const onFirstNameChange = (value: string) => {
    const updatedUser = { ...user, first_name: value }
    onUserUpdate(updatedUser)
  }

  const onEmailChange = (value: string) => {
    const updatedUser = { ...user, email: value }
    onUserUpdate(updatedUser)
  }

  const DeleteModal: FC<{ id: number }> = ({ id }) => {
    const handleModalDeletion = () => {
      updateModal({
        content: null,
        displayed: false
      })
      onUserDelete(id)
    }
    const handleModalCancellation = () => {
      updateModal({
        content: null,
        displayed: false
      })
    }
    return (
      <div className='px-8 pt-6 pb-8'>
        <p className='mr-12 text-xl font-medium text-black'>Êtes-vous sûr de vouloir supprimer cet utilisateur ?</p>
        <div className='flex flex-row justify-between m-auto w-80'>
          <ButtonSecondary className='mt-8' onClick={handleModalCancellation} type='button'>
            Annuler
          </ButtonSecondary>
          <ButtonPrimary className='mt-8' onClick={handleModalDeletion} type='button'>
            Supprimer
          </ButtonPrimary>
        </div>
      </div>
    )
  }
  const handleDeleteExperiment = (e: User) => {
    updateModal({
      content: <DeleteModal id={e.id} />,
      displayed: true
    })
  }

  return (
    <tr className={className}>
      <td className='h-12'>
        <div className='w-24'>
          <DebouncedTextInput
            Component={UnderlinedInput}
            disabled={userUpdate.isLoading}
            errors={[userUpdateError['last_name']] ?? null}
            onChange={onLastNameChange}
            value={user.last_name}
          />
        </div>
      </td>
      <td className='h-12'>
        <div className='w-24'>
          <DebouncedTextInput
            Component={UnderlinedInput}
            disabled={userUpdate.isLoading}
            errors={[userUpdateError['first_name']] ?? null}
            onChange={onFirstNameChange}
            value={user.first_name}
          />
        </div>
      </td>
      <td className='h-12'>
        <div className='w-56'>
          <DebouncedTextInput
            Component={UnderlinedInput}
            disabled={userUpdate.isLoading}
            errors={[userUpdateError['email']] ?? null}
            onChange={onEmailChange}
            value={user.email}
          />
        </div>
      </td>
      <td className='flex justify-end h-12'>
        {user.deletable && (
          <button className='mt-3 mb-auto w-5' data-testid='delete-button' onClick={() => handleDeleteExperiment(user)}>
            <img className='w-5 h-5' src={SvgTrash}></img>
          </button>
        )}
      </td>
    </tr>
  )
}
