import { viscosity } from 'modules/lab/schemas'
import { FC } from 'react'
import { z } from 'zod'
import { TableTd } from './TableTd'

const ViscosityTd: FC<{ matrix: Record<string, boolean>; data: z.infer<typeof viscosity> }> = ({ matrix, data }) => {
  return (
    <>
      {matrix.starting_drain_viscosity && <TableTd className='text-left'>{data?.starting_drain_viscosity}</TableTd>}
      {matrix.drain_viscosity && <TableTd className='text-left'>{data?.drain_viscosity}</TableTd>}
      {matrix.ending_drain_viscosity && <TableTd className='text-left'>{data?.ending_drain_viscosity}</TableTd>}
    </>
  )
}

export { ViscosityTd }
