import { ExperimentReinforcementDto } from 'interfaces/api/lab'
import { Dto } from 'modules/list-parameters/services/list-parameters.dto'
import { fetchJson } from 'utils/fetch'

/**
 *
 * @param experimentId
 * @returns void
 */
const getExperimentReinforcement = (experimentId: string) => {
  return fetchJson<ExperimentReinforcementDto, Dto<ExperimentReinforcementDto>>(
    'GET',
    `/lab/experiments/${experimentId}/reinforcement`
  )
}
/**
 *
 * @param experimentId
 * @param payload: Dto<FormulaViscosityDto>
 * @returns void
 */
const postExperimentReinforcement = (experimentId: string, payload: Dto<ExperimentReinforcementDto>) => {
  return fetchJson<ExperimentReinforcementDto, Dto<ExperimentReinforcementDto>>(
    'POST',
    `/lab/experiments/${experimentId}/reinforcement`,
    payload
  )
}

/**
 *
 * @param experimentId
 * @param payload: Dto<FormulaViscosityDto>
 * @returns void
 */
const putExperimentReinforcement = (experimentId: string, payload: Dto<ExperimentReinforcementDto>) => {
  return fetchJson<ExperimentReinforcementDto, Dto<ExperimentReinforcementDto>>(
    'PUT',
    `/lab/experiments/${experimentId}/reinforcement`,
    payload
  )
}

export { getExperimentReinforcement, putExperimentReinforcement, postExperimentReinforcement }
