import { Nullable } from 'interfaces'

export * from './alkalineResistance'
export * from './compression'
export * from './dma'
export * from './eosin'
export * from './fireTest'
export * from './flexion'
export * from './linearDensity'
export * from './microscopy'
export * from './residualMonomer'
export * from './thermocouple'
export * from './traction'
export * from './waterUptake'
export * from './fiberRate'

enum Angle {
  NINETY = 90,
  ZERO = 0
}

interface Statistic {
  average: Nullable<number>
  std: Nullable<number>
  variation: Nullable<number>
}

export { Angle }
export type { Statistic }
