import { UiContext } from 'contexts/ui.context'
import { ToastType } from 'core/constants'
import { i18n } from 'i18n'
import { ErrorObj, Nullable } from 'interfaces'
import { ExperimentFiberRate, ExperimentFiberRateDto } from 'interfaces/api/lab'
import { Dto } from 'modules/list-parameters/services/list-parameters.dto'
import { forwardRef, memo, useContext, useState } from 'react'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { useParams } from 'react-router-dom'

import { ButtonPrimary, ButtonSecondary, Panel } from 'ui/atoms'
import { Field } from 'ui/atoms/Field'
import { UnderlinedNumberInput } from 'ui/atoms/UnderLinedInput'
import { SpinnerLoaderMask } from 'ui/molecules'
import { getExperimentFiberRate, postExperimentFiberRate, putExperimentFiberRate } from 'modules/lab/services'
import { FiberRateFormValues } from '../ProductionDataTab.type'

const FiberRatePanel = memo(
  forwardRef<HTMLFormElement>((_, ref) => {
    const { experimentId } = useParams()

    if (!experimentId) {
      return null
    }

    const [fiberRateError, setFiberRateError] = useState<Nullable<ErrorObj<ExperimentFiberRateDto>>>(null)
    const { updateToast } = useContext(UiContext)
    const queryClient = useQueryClient()
    const { control, reset, handleSubmit } = useForm<FiberRateFormValues>()

    const get = useQuery(
      ['lab.experiment.fiberRate', experimentId],
      async () => await getExperimentFiberRate(experimentId),
      {
        onSuccess: ({ data }) => {
          resetForm(data)
        }
      }
    )

    const resetForm = (data?: Nullable<ExperimentFiberRate>) => {
      data ? reset(data) : reset()
    }

    const post = useMutation((data: Dto<ExperimentFiberRateDto>) => postExperimentFiberRate(experimentId, data), {
      onMutate: () => {
        setFiberRateError(null)
      },
      onSuccess: (data) => {
        queryClient.setQueryData(['lab.experiment.fiberRate', experimentId], data)
        updateToast({
          content: 'Essai crée avec succès',
          displayed: true,
          type: ToastType.SUCCESS
        })
      },
      onError: (e: Error & { errors?: ErrorObj<ExperimentFiberRateDto> }) => {
        setFiberRateError(e.errors ?? null)
        updateToast({
          content: e.message ?? 'Une erreur est survenue',
          displayed: true,
          type: ToastType.ERROR
        })
      }
    })
    const put = useMutation((data: Dto<ExperimentFiberRateDto>) => putExperimentFiberRate(experimentId, data), {
      onMutate: () => {
        setFiberRateError(null)
      },
      onSuccess: (data) => {
        queryClient.setQueryData(['lab.experiment.fiberRate', experimentId], data)
        updateToast({
          content: 'Essai crée avec succès',
          displayed: true,
          type: ToastType.SUCCESS
        })
      },
      onError: (e: Error & { errors?: ErrorObj<ExperimentFiberRateDto> }) => {
        setFiberRateError(e.errors ?? null)
        updateToast({
          content: e.message ?? 'Une erreur est survenue',
          displayed: true,
          type: ToastType.ERROR
        })
      }
    })

    const handleOnSubmit: SubmitHandler<FiberRateFormValues> = (payload) => {
      get.data?.data ? put.mutate(payload) : post.mutate(payload)
    }

    return (
      <>
        <form onSubmit={handleSubmit(handleOnSubmit)} ref={ref}>
          <Panel openable={true} title='TVF/TP'>
            <SpinnerLoaderMask
              message={get.isFetching ? i18n.isLoading : i18n.isSaving}
              spinning={get.isFetching || post.isLoading || put.isLoading}
            >
              <div className='flex flex-col items-center'>
                <div className='flex flex-row justify-between my-4 w-full'>
                  <Field className='justify-self-start' label='Taux massique de fibre (%)'>
                    <Controller
                      control={control}
                      name='fiber_mass_rate'
                      render={({ field }) => (
                        <UnderlinedNumberInput
                          className='w-48'
                          errors={fiberRateError?.fiber_mass_rate ?? null}
                          onChange={field.onChange}
                          value={field.value}
                        />
                      )}
                    />
                  </Field>
                  <Field className='justify-self-start' label='Taux volumique de fibre (%)'>
                    <Controller
                      control={control}
                      name='fiber_volume_rate'
                      render={({ field }) => (
                        <UnderlinedNumberInput
                          className='w-48'
                          errors={fiberRateError?.fiber_volume_rate ?? null}
                          onChange={field.onChange}
                          value={field.value}
                        />
                      )}
                    />
                  </Field>
                  <Field className='justify-self-start' label='Taux de porosité (%)'>
                    <Controller
                      control={control}
                      name='porosity_rate'
                      render={({ field }) => (
                        <UnderlinedNumberInput
                          className='w-48'
                          errors={fiberRateError?.porosity_rate ?? null}
                          onChange={field.onChange}
                          value={field.value}
                        />
                      )}
                    />
                  </Field>
                </div>
                <div className='flex flex-row justify-center mt-10'>
                  <ButtonSecondary className='px-14 mr-40' onClick={() => resetForm(get.data?.data)} type='button'>
                    Annuler
                  </ButtonSecondary>
                  <ButtonPrimary className='px-14' type='submit'>
                    Valider
                  </ButtonPrimary>
                </div>
              </div>
            </SpinnerLoaderMask>
          </Panel>
        </form>
      </>
    )
  })
)

export { FiberRatePanel }
