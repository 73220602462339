import { FC, ReactNode } from 'react'

const Field: FC<{
  children: ReactNode
  className?: string
  label: string
  row?: boolean
  required?: boolean
  hasError?: boolean
  labelClassName?: string
}> = ({
  children,
  label,
  className = '',
  row,
  required = false,
  hasError = false,
  labelClassName = 'font-semibold text-black'
}) => {
  return (
    <div className={`flex ${row ? 'flex-row' : 'flex-col'} ${className}`}>
      <label className={`${row ? 'mr-3' : 'mb-3'} ${labelClassName}`}>
        {label}
        {required && <span className={`${hasError ? 'text-[red]' : 'text-black'}`}>*</span>}
      </label>
      <div className='flex flex-row'>{children}</div>
    </div>
  )
}

export { Field }
