import { getExperimentDMA } from 'modules/lab/services'
import { forwardRef, memo } from 'react'
import { useQuery } from 'react-query'
import { useParams } from 'react-router-dom'
import { DynamicMechanicalAnalysisRamp } from './DynamicMechanicalAnalysisRamp'
import { DynamicMechanicalAnalysisRampLoading } from './DynamicMechanicalAnalysisRampLoading'

const DynamicMechanicalAnalysisPanel = memo(
  forwardRef<HTMLDivElement>((_, ref) => {
    const { experimentId } = useParams()

    if (!experimentId) {
      return null
    }

    const get = useQuery(
      ['lab.experiment.dynamicMechanicalAnalyses', experimentId],
      async () => await getExperimentDMA(experimentId)
    )

    return get.isFetched ? (
      <div ref={ref}>
        <DynamicMechanicalAnalysisRamp
          dma={get.data?.data?.find((d) => d.ramp === 1)}
          experimentId={experimentId}
          ramp={1}
        />
        <div className='h-5'></div>
        <DynamicMechanicalAnalysisRamp
          dma={get.data?.data?.find((d) => d.ramp === 2)}
          experimentId={experimentId}
          ramp={2}
        />
      </div>
    ) : (
      <div ref={ref}>
        <DynamicMechanicalAnalysisRampLoading ramp={1} />
        <div className='h-5'></div>
        <DynamicMechanicalAnalysisRampLoading ramp={2} />
      </div>
    )
  })
)

export { DynamicMechanicalAnalysisPanel }
