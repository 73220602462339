export type Nullable<T> = T | null
export type NullableObj<T extends object> = { [P in keyof T]: T[P] | null }

type ApiDate = `${number}-${number}-${number}`
type ApiTime = `${number}:${number}`
type ApiDateTime = `${ApiDate} ${ApiTime}`

// @TODO merger avec Benoit

// Fichier envoyé dans une entité
interface TemporaryFileDto {
  uuid: string
  name?: Nullable<string>
  order?: Nullable<number>
  custom_properties?: Record<string, unknown> | Record<string, unknown>[]
  custom_headers?: Record<string, unknown> | Record<string, unknown>[]
  file_name?: Nullable<string>
}

// Données recu dans une entité
interface LiteMedia {
  uuid: string
  file_name: string
  file_url: string
  created_at: string
}

// Fichier envoyé via spatie pour l'envoi temporaire / transparent, géré par la librairie spatie
interface SpatieFileDto {
  uuid: string
  name?: Nullable<string>
  file: File
}

// Données recu après l'envoi temporaire
interface SpatieFile {
  uuid: string
  name: Nullable<string>
  mime_type?: Nullable<string>
  preview_url: Nullable<string>
  size?: number
  extension?: Nullable<string>
}

enum State {
  RUNNING = 'running',
  DATA_REQUIRED = 'data-required',
  FINISHED = 'finished',
  FAILED = 'failed'
}

export type ApiError = { message: string; errors?: Record<string, string[]> }

type DotPrefix<T extends string> = T extends '' ? '' : `.${T}`

type DotNestedKeys<T> = (
  T extends object
    ? { [K in Exclude<keyof T, symbol>]: `${K}${DotPrefix<DotNestedKeys<T[K]>>}` }[Exclude<keyof T, symbol>]
    : ''
) extends infer D
  ? Extract<D, string>
  : never

type ErrorObj<T extends object> = Record<DotNestedKeys<T> | keyof T, string[]>

enum Extensions {
  PDF = 'application/pdf',
  XLSX = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  XLS = 'application/vnd.ms-excel',
  CSV = 'text/csv',
  PNG = 'image/png',
  JPEG = 'image/jpeg',
  TXT = 'text/plain',
  ZIP = 'application/zip',
  ALL = '*/*'
}

interface FetchError {
  exception: string
  file: string
  line: number
  message: string
  status: number
  trace: object[]
}

export type {
  ErrorObj,
  ApiDate,
  ApiDateTime,
  ApiTime,
  SpatieFile,
  SpatieFileDto,
  TemporaryFileDto,
  LiteMedia,
  DotNestedKeys,
  FetchError
}
export { State, Extensions }
export * from './ui'
