import { Nullable } from 'interfaces'
import { ExperimentIndexDto } from 'interfaces/api/lab'
import { FC } from 'react'
import { Control, Controller } from 'react-hook-form'
import { DualNumberInput } from 'ui/molecules'

const ResidualMonomerFilters: FC<{
  control: Control<ExperimentIndexDto>
}> = ({ control }) => {
  return (
    <div className='flex flex-col mt-5'>
      <details className='' open={true}>
        <summary className='py-1 px-2 text-xl font-semibold list-none text-black bg-ice-blue cursor-pointer'>
          Monomères résiduels
        </summary>
        <div className='grid grid-cols-3 gap-8 py-5'>
          <Controller
            control={control}
            name='residual_monomer.average'
            render={({ field }) => (
              <DualNumberInput
                label='Teneur MAM (%) Moyenne'
                onChange={field.onChange}
                value={(field.value ?? { min: null, max: null }) as Record<string, Nullable<number>>}
              />
            )}
          />
        </div>
      </details>
    </div>
  )
}

export { ResidualMonomerFilters }
