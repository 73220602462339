import { SvgCheck, SvgHint2 } from 'assets/svg'
import { DATE_TIME_FORMAT } from 'core/constants'
import { FileStatus } from 'core/constants/file'
import { parse } from 'date-fns'
import { FC, memo } from 'react'
import 'react-circular-progressbar/dist/styles.css'
import { FilePreview } from 'ui/atoms/FilePreview'
import { Spinner } from 'ui/atoms/Spinner'
import { SvgInjector } from 'ui/atoms/SvgInjector'
import { FileWithStatusProps } from '../types'

const FileWithStatus: FC<FileWithStatusProps> = memo(
  ({
    deletable = true,
    extension,
    fileName,
    fileUrl,
    onDelete,
    onDownload,
    status,
    downloadable = true,
    readonly = false,
    className = '',
    createdAt,
    statusText = ''
  }) => {
    return (
      <div className={`flex flex-row items-center ${className}`}>
        <div className='flex flex-col flex-1'>
          <FilePreview
            date={createdAt ? parse(createdAt, DATE_TIME_FORMAT, new Date()) : createdAt}
            deletable={deletable}
            downloadable={downloadable}
            extension={extension}
            name={fileName}
            onDelete={onDelete}
            onDownload={onDownload}
            readonly={readonly}
            status={status}
            url={fileUrl}
          />
          {statusText !== '' && (
            <span
              className={`${
                status === FileStatus.ERROR ? 'text-red' : 'text-amber'
              } p-1 text-[9px] h-5 truncate w-64 cursor-default`}
              title={statusText}
            >
              {statusText}
            </span>
          )}
        </div>
        <div className={`relative ml-3.5 w-8 ${statusText !== '' ? 'mb-5' : 'mb-0'}`}>
          {status === FileStatus.PROCESSING && <Spinner className='text-dusty-teal' stroke='#bcbccb' />}
          {status === FileStatus.UPLOADING && <Spinner className='text-dusk-blue' stroke='#bcbccb' />}
          {!readonly && [FileStatus.AVAILABLE, FileStatus.IDLE].includes(status) && (
            <>
              <SvgInjector className='absolute top-2 left-2 mr-2 w-4 h-4 text-greeny-blue' src={SvgCheck} />
              <Spinner fixed={true} stroke='#55be9b' />
            </>
          )}
          {status === FileStatus.ERROR && (
            <>
              <SvgInjector className='absolute top-1 left-1 mr-4 w-6 h-6 text-red' src={SvgHint2} />
              <Spinner fixed={true} stroke='#d00b0b' />
            </>
          )}
          {status === FileStatus.WARNING && (
            <>
              <SvgInjector className='absolute top-1 left-1 mr-4 w-6 h-6 text-amber' src={SvgHint2} />
              <Spinner fixed={true} stroke='#DE9F3A' />
            </>
          )}
        </div>
      </div>
    )
  }
)

export { FileWithStatus }
