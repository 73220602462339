import { UiContext } from 'contexts/ui.context'
import { ToastType } from 'core/constants'
import { ErrorObj, Extensions, Nullable } from 'interfaces'
import { ExperimentDMA, ExperimentDMADto } from 'interfaces/api/lab'
import { RAMP_BASE_TEMP } from 'modules/lab/constants'
import { postExperimentDMA, putExperimentDMA } from 'modules/lab/services'
import { Dto } from 'modules/list-parameters/services/list-parameters.dto'
import { FC, useContext, useEffect, useState } from 'react'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { useMutation, useQueryClient } from 'react-query'

import { ButtonPrimary, ButtonSecondary, InputError, Panel } from 'ui/atoms'
import { Field } from 'ui/atoms/Field'
import { SwitchInput } from 'ui/atoms/SwitchInput'

import { UnderlinedNumberInput } from 'ui/atoms/UnderLinedInput'
import { DMARampFormValues } from '../ProductionDataTab.type'
import { i18n as productionDatai18n } from 'modules/lab/i18n'
import { DynamicMechanicalAnalysisRampTaskSubmissionModals } from './DynamicMechanicalAnalysisRampTaskSubmissionModals'
import { Task } from 'interfaces/api/task'
import { FileAndTaskManagement } from 'modules/files'
import { LiteMediaWithStatus } from 'modules/files/types'

const localeI18n = productionDatai18n.productionDataTab.DMAPanel

const DynamicMechanicalAnalysisRamp: FC<{ ramp: 1 | 2; dma?: ExperimentDMA; experimentId: string }> = ({
  ramp,
  dma,
  experimentId
}) => {
  const [taskId, setTaskId] = useState<Nullable<number>>(null)
  const [canSubmit, setCanSubmit] = useState<boolean>(false)
  const [taskModal, setTaskModal] = useState<boolean>(false)
  const [taskFile, setTaskFile] = useState<LiteMediaWithStatus[]>([])
  const [temperature, setTemperature] = useState<Nullable<number>>(null)
  const [DMAError, setDMAError] = useState<Nullable<ErrorObj<ExperimentDMADto>>>(null)
  const { updateToast } = useContext(UiContext)
  const queryClient = useQueryClient()

  const { control, reset, handleSubmit, getValues } = useForm<DMARampFormValues>()

  const post = useMutation((data: Dto<ExperimentDMADto>) => postExperimentDMA(experimentId, ramp, data), {
    onMutate: () => {
      setDMAError(null)
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['lab.experiment.dynamicMechanicalAnalyses', experimentId])
      updateToast({
        content: localeI18n.toasts.create_success,
        displayed: true,
        type: ToastType.SUCCESS
      })
    },
    onError: (e: Error & { errors?: ErrorObj<ExperimentDMADto> }) => {
      setDMAError(e.errors ?? null)
      updateToast({
        content: e.message ?? 'Une erreur est survenue',
        displayed: true,
        type: ToastType.ERROR
      })
    }
  })

  const put = useMutation((data: Dto<ExperimentDMADto>) => putExperimentDMA(experimentId, ramp, data), {
    onMutate: () => {
      setDMAError(null)
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['lab.experiment.dynamicMechanicalAnalyses', experimentId])
      updateToast({
        content: localeI18n.toasts.update_success,
        displayed: true,
        type: ToastType.SUCCESS
      })
    },
    onError: (e: Error & { errors?: ErrorObj<ExperimentDMADto> }) => {
      setDMAError(e.errors ?? null)
      updateToast({
        content: e.message ?? 'Une erreur est survenue',
        displayed: true,
        type: ToastType.ERROR
      })
    }
  })

  const handleOnSubmit: SubmitHandler<DMARampFormValues> = (payload) => {
    dma ? put.mutate(payload) : post.mutate(payload)
  }

  const resetForm = () => {
    reset(dma ? { ...dma, standard_temperature: RAMP_BASE_TEMP, file: dma.file ? [dma.file] : [] } : {})
  }

  const handleCreateTask = (file: LiteMediaWithStatus[]) => {
    setTaskModal(true)
    setTaskFile(file)
  }

  const handleTaskCreationDone = (data: Task) => {
    if (!data.results) {
      return
    }
    reset({ ...getValues(), ...data.results, study_temperature: temperature, standard_temperature: RAMP_BASE_TEMP })
  }

  useEffect(() => {
    resetForm()
  }, [dma])

  return (
    <>
      <form onSubmit={handleSubmit(handleOnSubmit)}>
        <Panel openable={true} title={`DMA - Rampe ${ramp}`}>
          <div className='flex flex-col mt-8'>
            <Controller
              control={control}
              defaultValue={[]}
              name='file'
              render={({ field }) => (
                <div className='flex flex-col self-center'>
                  <Field className='' label='Importer un fichier'>
                    <FileAndTaskManagement
                      accept={[Extensions.TXT]}
                      onChange={field.onChange}
                      onCreateTask={handleCreateTask}
                      onReadyToSubmitChange={setCanSubmit}
                      onTaskPollingEnd={handleTaskCreationDone}
                      taskId={taskId}
                      value={field.value}
                    />
                    <InputError errors={DMAError?.file ?? null} />
                  </Field>
                </div>
              )}
            />
            <Controller
              control={control}
              defaultValue={false}
              name='double_distribution'
              render={({ field }) => (
                <Field className='mt-2 text-sm' label='Double distribution' labelClassName='text-warm-grey' row={true}>
                  <div className='flex justify-between w-64'>
                    <SwitchInput onChange={field.onChange} value={field.value} />
                  </div>
                </Field>
              )}
            />
            <div className='flex flex-col flex-auto 2xl:flex-row'>
              <div className='flex flex-row'>
                <div className='flex flex-col pt-5 pr-6 w-28'>
                  <p className='mb-6 h-8 text-sm text-black'>Température</p>
                  <Controller
                    control={control}
                    name='standard_temperature'
                    render={({ field }) => (
                      <UnderlinedNumberInput
                        errors={DMAError?.standard_temperature ?? null}
                        onChange={field.onChange}
                        readOnly={true}
                        value={field.value}
                      />
                    )}
                  />
                  <Controller
                    control={control}
                    name='study_temperature'
                    render={({ field }) => (
                      <div className='mt-4'>
                        <UnderlinedNumberInput
                          errors={DMAError?.study_temperature ?? null}
                          onChange={field.onChange}
                          readOnly={true}
                          value={field.value}
                        />
                      </div>
                    )}
                  />
                </div>
                <div className='flex flex-col px-6 pt-5'>
                  <p className='mb-6 h-8 text-sm text-black'>Module G' (Pa)</p>
                  <Controller
                    control={control}
                    name='standard_g_prime'
                    render={({ field }) => (
                      <UnderlinedNumberInput
                        errors={DMAError?.standard_g_prime ?? null}
                        onChange={field.onChange}
                        scientist={true}
                        value={field.value}
                      />
                    )}
                  />
                  <Controller
                    control={control}
                    name='study_g_prime'
                    render={({ field }) => (
                      <div className='mt-4'>
                        <UnderlinedNumberInput
                          errors={DMAError?.study_g_prime ?? null}
                          onChange={field.onChange}
                          value={field.value}
                        />
                      </div>
                    )}
                  />
                </div>
                <div className='flex flex-col pt-5 pr-12 pl-4'>
                  <p className='mb-6 h-8 text-sm text-black'>Module G" (Pa)</p>
                  <Controller
                    control={control}
                    name='standard_g_second'
                    render={({ field }) => (
                      <UnderlinedNumberInput
                        errors={DMAError?.standard_g_second ?? null}
                        onChange={field.onChange}
                        value={field.value}
                      />
                    )}
                  />
                  <Controller
                    control={control}
                    name='study_g_second'
                    render={({ field }) => (
                      <div className='mt-4'>
                        <UnderlinedNumberInput
                          errors={DMAError?.study_g_second ?? null}
                          onChange={field.onChange}
                          value={field.value}
                        />
                      </div>
                    )}
                  />
                </div>
              </div>
              <div className='flex flex-row flex-auto mt-12 -ml-12 2xl:mt-0 2xl:ml-0'>
                <div className='flex flex-col pt-5 pr-6 pb-12 pl-12 bg-ice'>
                  <p className='mb-6 h-8 text-sm text-black'>FWHM</p>
                  <Controller
                    control={control}
                    name='full_width_at_half_maximum'
                    render={({ field }) => (
                      <UnderlinedNumberInput
                        errors={DMAError?.full_width_at_half_maximum ?? null}
                        onChange={field.onChange}
                        value={field.value}
                      />
                    )}
                  />
                </div>
                <div className='flex flex-col px-6 pt-5 pb-12 bg-ice'>
                  <p className='mb-6 h-8 text-sm text-black'>Température pour Tan δ max.</p>
                  <Controller
                    control={control}
                    name='tan_delta_max_temperature'
                    render={({ field }) => (
                      <UnderlinedNumberInput
                        errors={DMAError?.tan_delta_max_temperature ?? null}
                        onChange={field.onChange}
                        value={field.value}
                      />
                    )}
                  />
                </div>
                <div className='flex flex-col pt-5 pr-12 pb-12 pl-4 bg-ice'>
                  <p className='mb-6 h-8 text-sm text-black'>Température pour G" max.</p>
                  <Controller
                    control={control}
                    name='g_second_max_temperature'
                    render={({ field }) => (
                      <UnderlinedNumberInput
                        errors={DMAError?.g_second_max_temperature ?? null}
                        onChange={field.onChange}
                        value={field.value}
                      />
                    )}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className='flex flex-row justify-center mt-10'>
            <ButtonSecondary className='px-14 mr-40' onClick={() => resetForm()} type='button'>
              Annuler
            </ButtonSecondary>
            <ButtonPrimary className='px-14' disabled={!canSubmit} type='submit'>
              Valider
            </ButtonPrimary>
          </div>
        </Panel>
      </form>
      <DynamicMechanicalAnalysisRampTaskSubmissionModals
        experimentId={experimentId}
        file={taskFile}
        onClose={(temperature) => {
          setTaskModal(false)
          setTemperature(temperature)
        }}
        onTaskIdChange={setTaskId}
        open={taskModal}
        ramp={ramp}
      />
    </>
  )
}

export { DynamicMechanicalAnalysisRamp }
