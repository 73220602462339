import { SvgArrowDown } from 'assets/svg'
import { UiContext } from 'contexts/ui.context'
import { useErrorScroll } from 'hooks/useScrollToError'
import { ToastProps } from 'interfaces'
import { FC, useContext, useLayoutEffect, useRef, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { ButtonPrimary, ButtonSecondary } from 'ui/atoms'
import { MainTemplate } from 'ui/templates'
import { ConfigurationForm } from './components/ConfigurationForm'
import { createManagementConfiguration, getManagementConfigurationDetail } from './services'
import { ConfigurationDetail, ConfigurationDto } from './services/configuration.dto'

export const ConfigurationCreation: FC = () => {
  const [errors, setErrors] = useState<Record<string, string[]>>({})
  const [configName, setConfigName] = useState('')
  const [canSubmit, setCanSubmit] = useState<boolean>(true)
  const [configurationData, setConfigurationData] = useState<ConfigurationDto>({
    oven_count: 0,
    documents: [],
    conformations: [],
    impregnations: []
  })
  const { id } = useParams()
  const navigate = useNavigate()
  const templateRef = useRef<HTMLDivElement>(null)

  const handleScrollTo = (px: number) => {
    if (templateRef.current) {
      templateRef.current.scrollTo({
        top: px,
        behavior: 'smooth'
      })
    }
  }

  useErrorScroll(errors)

  const { updateToast } = useContext(UiContext)

  const fetchConfiguration = async (id?: string) => {
    if (!id) {
      return
    }
    const { data } = await getManagementConfigurationDetail(id)
    if (data) {
      setConfigName(data.general_information.name ?? '')
      setConfigurationData({
        unwinding_type: data.general_information?.unwinding_type,
        closing_pressure: data.general_information?.closing_pressure,
        configured_at: data.general_information?.configured_at,
        created_by: data.general_information?.user?.name,
        die_length: data.general_information?.die_length,
        machine: data.general_information?.machine,
        name: data.general_information?.name,
        documents: data.documents,
        impregnation_mode: data.impregnation?.mode,
        impregnations: data.impregnation?.files,
        oven_count: data.oven_count,
        user_id: data.general_information?.user?.id
      })
    }
  }

  const onSubmit = async () => {
    try {
      setErrors({})
      await createManagementConfiguration(configurationData)
      updateToast({
        displayed: true,
        type: 'success',
        content: 'Les information ont bien été enregistrées.'
      } as ToastProps)
      navigate('/configuration')
    } catch (e) {
      setErrors((e as { errors: Record<string, string[]> }).errors)
    }
  }

  useLayoutEffect(() => {
    fetchConfiguration(id)
  }, [])

  const onCancel = () => {
    updateToast({
      displayed: true,
      type: 'error',
      content: "Les modification n'ont pas été enregistrées."
    } as ToastProps)
    navigate('/configuration')
  }

  const handleConfigurationChange = (data: Partial<ConfigurationDetail>) => {
    const unwinding_type = data.general_information?.unwinding_type ?? configurationData.unwinding_type
    const conformations = data.conformations ?? configurationData.conformations
    const closing_pressure = data.general_information?.closing_pressure ?? configurationData.closing_pressure
    const configured_at = data.general_information?.configured_at ?? configurationData.configured_at
    const created_by = data.general_information?.user?.name ?? configurationData.created_by
    const die_length = data.general_information?.die_length ?? configurationData.die_length
    const machine = data.general_information?.machine ?? configurationData.machine
    const name = data.general_information?.name ?? configurationData.name
    const documents = data.documents ?? configurationData.documents
    const impregnation_mode = data.impregnation?.mode ?? configurationData.impregnation_mode
    const impregnations = data.impregnation?.files ?? configurationData.impregnations
    const oven_count = data.oven_count ?? configurationData.oven_count
    const user_id = data.general_information?.user?.id ?? configurationData.user_id
    setConfigurationData({
      ...configurationData,
      unwinding_type,
      conformations,
      closing_pressure,
      configured_at,
      created_by,
      die_length,
      machine,
      name,
      documents,
      impregnation_mode,
      impregnations,
      oven_count,
      user_id
    })
  }

  return (
    <MainTemplate
      backButton={
        <Link className='flex items-center font-medium text-dusty-teal' to='/configuration'>
          <img className='mr-2 w-3 h-3 rotate-90' src={SvgArrowDown} />
          <span>Retour</span>
        </Link>
      }
      className='overflow-y-scroll'
      ref={templateRef}
      title={
        <div className='text-2xl font-bold text-dark-blue capitalize'>
          {id ? `Copie de ${configName}` : 'Nouvelle configuration machine'}
        </div>
      }
    >
      <ConfigurationForm
        data={{
          conformations: configurationData.conformations ?? [],
          documents: configurationData.documents ?? [],
          editable: false,
          general_information: {
            closing_pressure: configurationData.closing_pressure,
            configured_at: configurationData.configured_at,
            die_length: configurationData.die_length,
            machine: configurationData.machine,
            name: configurationData.name,
            unwinding_type: configurationData.unwinding_type,
            user:
              configurationData.user_id && configurationData.created_by
                ? {
                    id: configurationData.user_id,
                    name: configurationData.created_by
                  }
                : undefined
          },
          impregnation: {
            files: configurationData.impregnations ?? [],
            mode: configurationData.impregnation_mode
          },
          oven_count: configurationData.oven_count
        }}
        editable={true}
        errors={errors}
        onChange={handleConfigurationChange}
        onReadyToSubmitChange={setCanSubmit}
        scrollTo={handleScrollTo}
      />
      <div className='flex sticky inset-x-0 bottom-0 justify-center p-6 mr-10 bg-white shadow-toast 2xl:mr-62'>
        <ButtonSecondary className='mr-40 w-40' onClick={onCancel} type={'button'}>
          Annuler
        </ButtonSecondary>
        <ButtonPrimary className='w-40' disabled={!canSubmit} onClick={onSubmit} type={'button'}>
          Valider
        </ButtonPrimary>
      </div>
    </MainTemplate>
  )
}
