import { FC, ReactNode } from 'react'

const TableTd: FC<{ className?: string; children: ReactNode }> = ({ className = '', children = '' }) => (
  <td
    className={`bg-white h-14 z-10 text-black font-normal text-sm ${className} first:border-l-2 last:border-r-2 border-ice-blue`}
  >
    <div className='flex flex-1 items-center px-5'>{children}</div>
  </td>
)
export { TableTd }
