import { Angle, ExperimentFlexion, ExperimentFlexionDto, ExperimentFlexionTaskDto } from 'interfaces/api/lab'
import { ExperimentFlexionTask } from 'interfaces/api/task'
import { Dto } from 'modules/list-parameters/services/list-parameters.dto'
import { fetchJson } from 'utils/fetch'

/**
 *
 * @param experimentId
 * @param angle
 * @param payload
 * @returns
 */
const postExperimentFlexion = (experimentId: string, angle: Angle, payload: Dto<ExperimentFlexionDto>) => {
  return fetchJson<ExperimentFlexion, Dto<ExperimentFlexionDto>>(
    'POST',
    `/lab/experiments/${experimentId}/flexions/${angle}`,
    payload
  )
}

/**
 *
 * @param experimentId
 * @param angle
 * @param payload
 * @returns
 */
const putExperimentFlexion = (experimentId: string, angle: Angle, payload: Dto<ExperimentFlexionDto>) => {
  return fetchJson<ExperimentFlexion, Dto<ExperimentFlexionDto>>(
    'PUT',
    `/lab/experiments/${experimentId}/flexions/${angle}`,
    payload
  )
}

/**
 *
 * @param experimentId
 * @returns
 */
const getExperimentFlexions = (experimentId: string) => {
  return fetchJson<ExperimentFlexion[]>('GET', `/lab/experiments/${experimentId}/flexions`)
}

/**
 *
 * @param url
 * @param angle
 * @param payload
 * @returns
 */
const postExperimentFlexionTask = (experimentId: string, angle: Angle, payload: Dto<ExperimentFlexionTaskDto>) => {
  return fetchJson<ExperimentFlexionTask, Dto<ExperimentFlexionTaskDto>>(
    'POST',
    `/lab/experiments/${experimentId}/flexions/${angle}/task`,
    payload
  )
}

export { postExperimentFlexion, putExperimentFlexion, getExperimentFlexions, postExperimentFlexionTask }
