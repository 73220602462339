import { SvgArrowDown } from 'assets/svg'
import { Nullable } from 'interfaces'
import { FC, FormEvent, useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { PaginationMeta } from 'utils/fetch'

export const PaginationMenu: FC<{
  meta: Nullable<PaginationMeta>
  onChange: (page: string, perPage: string) => void
  perPageDefault?: string
}> = ({ meta, onChange, perPageDefault = '10' }) => {
  const [perPageValue, setPerPageValue] = useState(perPageDefault)
  const [pageValue, setPageValue] = useState('1')

  const [searchParams, setSearchParams] = useSearchParams()

  const onPerPageSelectInput = (e: FormEvent<HTMLSelectElement>) => {
    const value = e.currentTarget.value ?? perPageDefault
    setSearchParams({ perPage: value, page: '1' })
    onChange('1', value)
  }
  const onPageInput = (delta: number) => {
    const value = (parseInt(pageValue) + delta).toString()
    setPageValue(value)
    setSearchParams({ perPage: searchParams.get('perPage') ?? perPageDefault, page: value })
    onChange(value, perPageValue)
  }

  useEffect(() => {
    setPageValue(searchParams.get('page') ?? '1')
    setPerPageValue(searchParams.get('perPage') ?? perPageDefault)
  })

  return (
    <>
      {meta?.links && (
        <div className='flex items-center mb-10 text-sm text-gunmetal'>
          <div className='mr-2'>
            <select
              className='py-1 px-4 mx-2 bg-white rounded outline-warm-grey focus:outline-warm-grey'
              onChange={onPerPageSelectInput}
              value={perPageValue}
            >
              <option>10</option>
              <option>20</option>
              <option>50</option>
              <option>100</option>
            </select>
            <span>/ pages</span>
          </div>
          {meta.current_page - 1 >= 1 && (
            <button onClick={() => onPageInput(-1)}>
              <div className='flex'>
                <img className='w-3 h-3 rotate-90' src={SvgArrowDown} />
                <img className='w-3 h-3 rotate-90 -translate-x-1' src={SvgArrowDown} />
              </div>
            </button>
          )}
          <div className='p-1'>
            {meta?.from} - {meta?.to} sur {meta?.total}
          </div>

          {meta.current_page + 1 <= meta.last_page && (
            <button onClick={() => onPageInput(1)}>
              <div className='flex'>
                <img className='w-3 h-3 -rotate-90' src={SvgArrowDown} />
                <img className='w-3 h-3 -rotate-90 -translate-x-1' src={SvgArrowDown} />
              </div>
            </button>
          )}
        </div>
      )}
    </>
  )
}
