import { SvgArrowDown } from 'assets/svg'
import { UiContext } from 'contexts/ui.context'
import { useErrorScroll } from 'hooks/useScrollToError'
import { Nullable, ToastProps } from 'interfaces'
import { FC, useContext, useRef, useState } from 'react'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { ButtonPrimary, ButtonSecondary } from 'ui/atoms'
import { MainTemplate } from 'ui/templates'
import { ConfigurationForm } from './components/ConfigurationForm'
import { getManagementConfigurationDetail, updateManagementConfiguration } from './services'
import { ConfigurationChange, ConfigurationDetail } from './services/configuration.dto'
import { configurationDataToDto } from './utils'

export const ConfigurationEdition: FC = () => {
  const [configurationData, setConfigurationData] = useState<Nullable<ConfigurationDetail>>()
  const [canSubmit, setCanSubmit] = useState<boolean>(true)
  const [errors, setErrors] = useState<Record<string, string[]>>({})
  const { updateToast } = useContext(UiContext)
  const { id } = useParams()
  const navigate = useNavigate()
  const templateRef = useRef<HTMLDivElement>(null)

  useErrorScroll(errors)

  const handleScrollTo = (px: number) => {
    if (templateRef.current) {
      templateRef.current.scrollTo({
        top: px,
        behavior: 'smooth'
      })
    }
  }

  const fetchConfiguration = async (id?: string) => {
    if (!id) {
      throw new Error('😓 une erreur est survenue')
    }
    return await getManagementConfigurationDetail(id)
  }
  const query = useQuery(['configuration-detail', id], async () => await fetchConfiguration(id), {
    onSuccess: (data) => {
      setConfigurationData({ ...data.data, ...{ oven_used: (data.data?.oven_count ?? 0) > 0 } } as ConfigurationDetail)
    },
    keepPreviousData: true
  })

  const queryClient = useQueryClient()
  const configurationUpdate = useMutation(
    (configuration: ConfigurationDetail) =>
      updateManagementConfiguration(configuration.id ?? 0, configurationDataToDto(configuration)),
    {
      onMutate: () => setErrors({}),
      onSuccess: () => {
        queryClient.invalidateQueries('configuration-detail')
        updateToast({
          displayed: true,
          type: 'success',
          content: 'Les modification ont bien été enregistrées.'
        } as ToastProps)
        navigate('/configuration')
      },
      onError: (e: { errors: Record<string, string[]> }) => {
        setErrors(e.errors)
      }
    }
  )

  const onCancel = () => {
    updateToast({
      displayed: true,
      type: 'error',
      content: "Les modification n'ont pas été enregistrées."
    } as ToastProps)
    navigate('/configuration')
  }
  const onSubmit = () => {
    if (!configurationData) {
      return
    }
    configurationUpdate.mutate(configurationData)
  }

  return (
    <MainTemplate
      backButton={
        <Link className='flex items-center font-medium text-dusty-teal' to='/configuration'>
          <img className='mr-2 w-3 h-3 rotate-90' src={SvgArrowDown} />
          <span>Retour</span>
        </Link>
      }
      className='overflow-y-scroll relative'
      ref={templateRef}
      title={
        <div className='text-2xl font-bold text-dark-blue capitalize'>{query.data?.data?.general_information.name}</div>
      }
    >
      {query.isFetching && <div>Chargemenent...</div>}
      {(query.error as Error)?.message && <span>(query.error as Error)?.message</span>}
      {configurationData && (
        <ConfigurationForm
          data={configurationData}
          editable={configurationData.editable}
          errors={errors}
          onChange={(change: ConfigurationChange) => setConfigurationData({ ...configurationData, ...change })}
          onReadyToSubmitChange={setCanSubmit}
          scrollTo={handleScrollTo}
        />
      )}
      {configurationData?.editable && (
        <div className='flex sticky inset-x-0 bottom-0 flex-1 justify-center p-6 bg-white shadow-toast 2xl:mr-62'>
          <ButtonSecondary className='mr-40 w-40' onClick={onCancel} type='button'>
            Annuler
          </ButtonSecondary>
          <ButtonPrimary className='w-40' disabled={!canSubmit} onClick={onSubmit} type='button'>
            Valider
          </ButtonPrimary>
        </div>
      )}
    </MainTemplate>
  )
}
