import { Fiber } from 'interfaces/api/management'

const i18n = {
  isLoading: 'Chargement en cours',
  isSaving: 'Sauvegarde en cours',
  cancel: 'Annuler',
  submit: 'Valider',
  addNewId: 'Ajouter un nouvel ID',
  enums: {
    fiber: {
      [Fiber.CARBON]: 'Carbone',
      [Fiber.MIX]: 'Mixte',
      [Fiber.GLASS]: 'Verre',
      [Fiber.NATURAL_FIBER]: 'Naturelle'
    }
  }
}

export { i18n }
