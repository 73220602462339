import { ModalProps, ToastProps } from 'interfaces'

interface State {
  toast: ToastProps
  modal: ModalProps
  updateToast: (toast: ToastProps) => void
  updateModal: (modal: ModalProps) => void
}

enum Types {
  UPDATE_TOAST = 'UPDATE_TOAST',
  UPDATE_MODAL = 'UPDATE_MODAL'
}

type Actions = { type: Types.UPDATE_TOAST; payload: ToastProps } | { type: Types.UPDATE_MODAL; payload: ModalProps }

export { Types }
export type { Actions, State }
