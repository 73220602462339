import { Nullable } from 'interfaces'
import { FC, useEffect, useState } from 'react'

export interface NavigationRef {
  id: number
  title: string
  ref: Nullable<HTMLElement>
}
const AsideLink: FC<{
  navigationRef: NavigationRef
  active: boolean
  margin: number
  onVisibilityChange: (entry: IntersectionObserverEntry, navigationRef: NavigationRef) => void
  scrollTo: (px: number) => void
}> = ({ navigationRef, onVisibilityChange, active, scrollTo, margin }) => {
  const cb: IntersectionObserverCallback = (entries) => {
    const [entry] = entries
    onVisibilityChange(entry, navigationRef)
  }
  const options: IntersectionObserverInit = {
    root: document.querySelector('#scrollArea'),
    threshold: [0, 0.25, 0.5, 0.75, 1]
  }
  useEffect(() => {
    const element = navigationRef.ref
    if (!element) {
      return
    }
    const observer = new IntersectionObserver(cb, options)
    observer.observe(element)
    return () => {
      observer.unobserve(element)
    }
  }, [navigationRef])

  const handleClick = () => {
    if (navigationRef.ref) {
      scrollTo(navigationRef.ref.offsetTop - margin)
    }
  }
  return (
    <a
      className={`${
        active ? 'text-dusk-blue font-bold' : 'text-greyish-brown font-normal'
      } text-sm h-6 items-center flex mb-6 cursor-pointer transition-text last:mb-0`}
      onClick={handleClick}
    >
      {navigationRef.title}
    </a>
  )
}

const AsideNavigation: FC<{
  refs: NavigationRef[]
  className?: string
  reference?: string
  scrollTo: (px: number) => void
  margin?: number
}> = ({ refs, scrollTo, className = '', margin = 0, reference = '' }) => {
  const [active, setActive] = useState<Nullable<number>>(null)
  const [elements, setElements] = useState<
    { id: number; title: string; isIntersecting: boolean; intersectionRatio: number }[]
  >(refs.map((r) => ({ id: r.id, title: r.title, isIntersecting: false, intersectionRatio: 0 })))

  const handleVisibilityChange = (
    { isIntersecting, intersectionRatio }: IntersectionObserverEntry,
    n: NavigationRef
  ) => {
    setElements((prevElements) =>
      prevElements.map((e) => (e.id === n.id ? { id: n.id, title: n.title, isIntersecting, intersectionRatio } : e))
    )
  }

  useEffect(() => {
    const intersectedElements = elements
      .filter((e) => e.isIntersecting)
      .sort((a, b) =>
        a.intersectionRatio === b.intersectionRatio ? b.id - a.id : a.intersectionRatio - b.intersectionRatio
      )
    if (intersectedElements.length) {
      setActive(intersectedElements[intersectedElements.length - 1].id)
    }
  }, [elements])

  return (
    <div className={`flex sticky top-0 flex-row flex-none w-52 ${className}`}>
      <div className='mr-6 w-px bg-pinkish-grey'></div>
      <div
        className={`absolute -left-0.5 mr-6 w-[5px] h-6 bg-dusk-blue transition-top`}
        style={{ top: `${(active ?? 0) * 48}px` }}
      ></div>
      <div className='flex flex-col'>
        {refs.map((e, k) => (
          <AsideLink
            active={active === e.id}
            key={k}
            margin={margin}
            navigationRef={e}
            onVisibilityChange={(a, b) => setTimeout(() => handleVisibilityChange(a, b), 100)}
            scrollTo={scrollTo}
          />
        ))}
      </div>
      {reference !== '' && (
        <span className='absolute mt-10 -bottom-10 left-0 text-greyish-brown font-normal text-sm'>
          Référence : <span className='text-dusk-blue font-bold'>{reference}</span>
        </span>
      )}
    </div>
  )
}
export { AsideNavigation }
