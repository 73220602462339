import { Nullable } from 'interfaces'
import { FC, FormEvent, useState } from 'react'
import { ButtonPrimary } from 'ui/atoms'
import { Modal } from 'ui/atoms/Modal'
import { PasswordInput } from 'ui/molecules'
import { postUpdatePassword } from '../services/auth'

export const UpdatePasswordForm: FC<{ onSuccess: () => void }> = ({ onSuccess }) => {
  const [currentPassword, setCurrentPassword] = useState<Nullable<string>>(null)
  const [password, setPassword] = useState<Nullable<string>>(null)
  const [passwordConfirmation, setPasswordConfirmation] = useState<Nullable<string>>(null)
  const [errors, setErrors] = useState<Nullable<Record<string, string>>>(null)
  const onFormSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    if (password && currentPassword && passwordConfirmation) {
      try {
        setErrors(null)
        await postUpdatePassword({
          current_password: currentPassword,
          password,
          password_confirmation: passwordConfirmation
        })
        onSuccess()
      } catch (e) {
        setErrors((e as { errors: Record<string, string> }).errors)
      }
    }
  }
  return (
    <form className='flex flex-col justify-center m-4 w-[310px]' onSubmit={onFormSubmit}>
      <h2 className='mb-10 text-2xl font-semibold text-center text-greyish-brown'>Changer de mot de passe</h2>
      <PasswordInput
        className='mb-5'
        errors={errors?.current_password ? [errors.current_password] : null}
        label={'Mot de passe actuel'}
        name='current_password'
        onChange={setCurrentPassword}
        value={currentPassword}
      />
      <PasswordInput
        className='mb-5'
        errors={errors?.password ? [errors.password] : null}
        label={'Nouveau mot de passe'}
        name='password'
        onChange={setPassword}
        value={password}
      />
      <PasswordInput
        className='mb-5'
        errors={errors?.password_confirmation ? [errors.password_confirmation] : null}
        label={'Confirmation de mot de passe'}
        name='password_confirmation'
        onChange={setPasswordConfirmation}
        value={passwordConfirmation}
      />
      <ButtonPrimary className='mt-10' type='submit'>
        Valider
      </ButtonPrimary>
    </form>
  )
}

export const UpdatePasswordModal: FC<{ isOpen: boolean; onClose: () => void; onSuccess: () => void }> = ({
  isOpen,
  onClose,
  onSuccess
}) => {
  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose}>
        <UpdatePasswordForm onSuccess={onSuccess} />
      </Modal>
    </>
  )
}
