import { FC } from 'react'
import { Outlet } from 'react-router-dom'
import { MainHeader } from './components/MainHeader'
import { Navigation } from './components/Navigation'

export const MainLayout: FC = () => {
  return (
    <div className='flex flex-col w-screen h-screen'>
      <MainHeader />
      <div className='flex overflow-hidden flex-1 w-full bg-ice-blue'>
        <div className='w-[250px] min-w-[250px] h-full'>
          <Navigation />
        </div>
        <Outlet />
      </div>
    </div>
  )
}
