import { ExperimentMicroscopy, ExperimentMicroscopyDto } from 'interfaces/api/lab'
import { Dto } from 'modules/list-parameters/services/list-parameters.dto'
import { fetchJson } from 'utils/fetch'

/**
 *
 * @param experimentId
 * @param payload
 * @returns
 */
const postExperimentMicroscopy = (experimentId: string, payload: Dto<ExperimentMicroscopyDto>) => {
  return fetchJson<ExperimentMicroscopy, Dto<ExperimentMicroscopyDto>>(
    'POST',
    `/lab/experiments/${experimentId}/microscopy`,
    payload
  )
}
/**
 *
 * @param experimentId
 * @param payload
 * @returns
 */
const putExperimentMicroscopy = (experimentId: string, payload: Dto<ExperimentMicroscopyDto>) => {
  return fetchJson<ExperimentMicroscopy, Dto<ExperimentMicroscopyDto>>(
    'PUT',
    `/lab/experiments/${experimentId}/microscopy`,
    payload
  )
}
/**
 *
 * @param experimentId
 * @returns
 */
const getExperimentMicroscopy = (experimentId: string) => {
  return fetchJson<ExperimentMicroscopy>('GET', `/lab/experiments/${experimentId}/microscopy`)
}

export { getExperimentMicroscopy, postExperimentMicroscopy, putExperimentMicroscopy }
