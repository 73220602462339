import { FC } from 'react'
import { Route, Routes } from 'react-router-dom'

import { Experiments, ExperimentCreation, ExperimentEdition } from './pages'

const ExperimentsRoutes: FC = () => {
  return (
    <Routes>
      <Route element={<ExperimentCreation />} path='create' />
      <Route element={<ExperimentEdition />} path=':experimentId' />
      <Route element={<Experiments />} path='' />
    </Routes>
  )
}

export { ExperimentsRoutes }
