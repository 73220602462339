import { EosinClassification, FireTestClassification } from 'interfaces/api/lab'
import { Fiber } from 'interfaces/api/management'
import { ImpregnationMode } from 'modules/configuration/services/configuration.dto'
import { ConstituentType } from 'modules/list-parameters/services/list-parameters.dto'
import { z } from 'zod'

const tcf = z
  .object({
    max_force_average: z.number().nullable(),
    max_stress_average: z.number().nullable(),
    max_stress_movement_average: z.number().nullable(),
    max_stress_deformation_average: z.number().nullable(),
    chord_modulus_deformation_average: z.number().nullable(),
    chord_modulus_force_average: z.number().nullable(),
    fracture_stress_average: z.number().nullable(),
    fracture_deformation_average: z.number().nullable()
  })
  .nullable()
const general = z.object({
  reference: z.string(),
  realized_at: z.string(),
  theme: z.string().nullable(),
  user: z.string(),
  configuration: z.string(),
  pultrusion_die: z.string().nullable(),
  speed: z.number().nullable()
})
const ingredients = z.array(
  z.object({
    constituent_label: z.string(),
    constituent_type: z.nativeEnum(ConstituentType),
    introduced_quantity: z.number().nullable(),
    order: z.number(),
    part_per_hundred_of_resin: z.number(),
    quantity_to_introduce: z.number()
  })
)
const formula = z
  .object({
    id: z.number().int(),
    ingredients
  })
  .nullable()
const viscosity = z
  .object({
    starting_drain_viscosity: z.number().nullable(),
    drain_viscosity: z.number().nullable(),
    ending_drain_viscosity: z.number().nullable()
  })
  .nullable()
const transverse_reinforcements = z.array(
  z.object({
    order: z.number().int(),
    id: z.number().int(),
    label: z.string()
  })
)
const reinforcement = z
  .object({
    roving_label: z.string(),
    roving_fiber: z.nativeEnum(Fiber).nullable(),
    transverse_reinforcements
  })
  .nullable()
const process_parameter = z
  .object({
    wire_count: z.number().int().nullable(),
    has_conformation: z.boolean(),
    oven_temperatures: z.array(z.number().nullable()).nullable(),
    impregnation_mode: z.nativeEnum(ImpregnationMode).nullable(),
    is_stable: z.boolean()
  })
  .nullable()
const thermocouple = z
  .object({
    peak_temperature: z.number().nullable(),
    peak_time: z.number().nullable(),
    peak_distance: z.number().nullable()
  })
  .nullable()
const dma = z
  .object({
    full_width_at_half_maximum: z.number().nullable(),
    tan_delta_max_temperature: z.number().nullable(),
    g_second_max_temperature: z.number().nullable()
  })
  .nullable()
const eosin = z
  .object({
    classification: z.nativeEnum(EosinClassification).nullable()
  })
  .nullable()
const fiber_rate = z
  .object({
    fiber_mass_rate: z.number().nullable(),
    fiber_volume_rate: z.number().nullable(),
    porosity_rate: z.number().nullable()
  })
  .nullable()
const fire_test = z
  .object({
    classification: z.nativeEnum(FireTestClassification).nullable()
  })
  .nullable()
const residual_monomer = z
  .object({
    average: z.number().nullable()
  })
  .nullable()
const getExperiment = z.object({
  id: z.number().int(),
  general,
  formula,
  viscosity,
  reinforcement,
  process_parameter,
  traction_0: tcf,
  traction_90: tcf,
  compression_0: tcf,
  compression_90: tcf,
  flexion_0: tcf,
  flexion_90: tcf,
  thermocouple,
  dynamic_mechanical_analyses_ramp_1: dma,
  dynamic_mechanical_analyses_ramp_2: dma,
  eosin,
  fiber_rate,
  fire_test,
  residual_monomer
})

const getExperiments = z.array(getExperiment)
export {
  getExperiment,
  getExperiments,
  dma,
  eosin,
  fire_test,
  formula,
  general,
  process_parameter,
  reinforcement,
  residual_monomer,
  tcf,
  thermocouple,
  viscosity,
  transverse_reinforcements,
  ingredients,
  fiber_rate
}
