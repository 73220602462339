import { SvgCreate, SvgDisk, SvgTrash } from 'assets/svg'
import { Nullable } from 'interfaces'
import { GenericConstituent } from 'interfaces/api/management'
import {
  deleteManagementConstituent,
  postManagementConstituent,
  putManagementConstituent
} from 'modules/list-parameters/services/constituents'
import { GenericConstituentDto } from 'modules/list-parameters/services/list-parameters.dto'
import { FC, FormEvent, useState } from 'react'
import { useMutation, useQueryClient } from 'react-query'
import { UnderlinedInput } from 'ui/atoms/UnderLinedInput'
import { DebouncedTextInput } from 'ui/molecules'

interface GenericStringItem {
  id: number
  label: string
  deletable: boolean
}

const GenericItem: FC<{
  type: 'accelerator' | 'resin' | 'filler' | 'release-agent' | 'peroxyde' | 'additive'
  item: GenericStringItem
}> = ({ type, item }) => {
  const [themeUpdateError, setConstituentUpdateError] = useState<Nullable<string[]>>(null)

  const queryClient = useQueryClient()
  const itemUpdate = useMutation(
    (params: { id: number; data: GenericConstituentDto }) => putManagementConstituent(params.id, params.data),
    {
      onMutate: () => setConstituentUpdateError(null),
      onSuccess: () => queryClient.invalidateQueries('constituents'),
      onError: (e: { message: string }) => setConstituentUpdateError([e.message])
    }
  )
  const onInput = (label: string) => {
    itemUpdate.mutate({ id: item.id, data: { type, label } })
  }

  const themeDelete = useMutation(deleteManagementConstituent, {
    onSuccess: () => queryClient.invalidateQueries('constituents')
  })

  return (
    <div className='flex justify-between items-center'>
      <div className='overflow-hidden w-28'>
        <DebouncedTextInput
          Component={UnderlinedInput}
          disabled={itemUpdate.isLoading}
          errors={themeUpdateError}
          onChange={onInput}
          value={item.label}
        />
      </div>
      {item.deletable && (
        <button className='w-5' data-testid='delete-button' onClick={() => themeDelete.mutate(item.id)}>
          <img className='w-5 h-5' src={SvgTrash} />
        </button>
      )}
    </div>
  )
}

export const GenericList: FC<{
  itemName: string
  type: 'accelerator' | 'resin' | 'filler' | 'release-agent' | 'peroxyde' | 'additive'
  items: GenericConstituent[]
}> = ({ type, items, itemName }) => {
  const [themeCreationError, setConstituentCreationError] = useState<Nullable<string[]>>(null)
  const [isInputVisible, setIsInputVisible] = useState(false)
  const [newItemLabel, setNewItemLabel] = useState<Nullable<string>>(null)

  const queryClient = useQueryClient()
  const themeCreation = useMutation(postManagementConstituent, {
    onMutate: () => setConstituentCreationError(null),
    onSuccess: () => {
      queryClient.invalidateQueries('constituents')
      setIsInputVisible(false)
      setNewItemLabel(null)
    },
    onError: (e: { message: string }) => setConstituentCreationError([e.message])
  })

  const onCreate = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    if (!newItemLabel) {
      return
    }
    themeCreation.mutate({ type, label: newItemLabel })
  }

  const onInput = (value: string) => {
    setNewItemLabel(value)
  }

  return (
    <>
      <div className='px-16 pt-4 pb-10 w-[310px]'>
        {items &&
          items
            .filter((item: GenericConstituent) => item.type === type)
            .sort((a, b) => (a.label > b.label ? 1 : -1))
            .map((item: GenericStringItem, index: number) => (
              <div className='mt-5' key={`item-${index}`}>
                <GenericItem item={item} type={type} />
              </div>
            ))}
        <div className='flex justify-between items-center mt-5'>
          {!isInputVisible && (
            <button className='flex items-center' onClick={() => setIsInputVisible(true)}>
              <img className='mr-4 w-7 h-7' src={SvgCreate} />
              <span className='font-medium whitespace-nowrap'>Ajouter {itemName}</span>
            </button>
          )}
          {isInputVisible && (
            <>
              <form className='flex overflow-hidden justify-between w-full' name='form' onSubmit={onCreate}>
                <UnderlinedInput
                  className='w-28'
                  errors={themeCreationError}
                  onChange={onInput}
                  value={newItemLabel ?? ''}
                />
                <button className='w-5 h-5' data-testid='submit-button' type='submit'>
                  <img className='w-4 h-4' src={SvgDisk} />
                </button>
              </form>
            </>
          )}
        </div>
      </div>
    </>
  )
}
