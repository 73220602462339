import { FC } from 'react'

interface Props {
  value: boolean
  onChange: (val: boolean) => void
  name?: string
  disabled?: boolean
  className?: string
}

const SwitchInput: FC<Props> = ({ className = '', disabled, value, name, onChange }) => {
  const checked = value === true
  const handleClick = () => {
    if (disabled) {
      return onChange(value)
    }
    if (onChange) {
      onChange(checked ? false : true)
    }
  }

  return (
    <label
      className={`${className} ${
        disabled ? 'cursor-not-allowed opacity-70' : ''
      } cursor-pointer align-middle h-5 w-16 inline-block relative select-none`}
      onClick={handleClick}
    >
      <input name={name} type='hidden' value={String(value)} />
      <span
        className={`absolute top-0 bottom-0 left-0 right-0 rounded-full transition-all ${
          checked ? 'bg-greeny-blue' : 'bg-grey'
        }`}
      />
      <span
        className={`absolute top-0.5 bottom-0.5 bg-white rounded-full transition-all w-4 ${
          checked ? 'left-11' : 'left-1'
        }`}
      />
      <span
        className={`absolute left-0 pl-3 text-sm text-white font-medium transition ${
          checked ? 'opacity-100' : 'opacity-0'
        }`}
      >
        Oui
      </span>
      <span
        className={`absolute right-0 pr-2.5 text-sm text-white font-medium transition ${
          checked ? 'opacity-0' : 'opacity-100'
        }`}
      >
        Non
      </span>
    </label>
  )
}

export { SwitchInput }
