import './index.css'

import { ExperimentsRoutes } from 'modules/lab'
import { TestCampaignRoutes } from 'modules/test-campaign'

import { ForgotPassword } from 'modules/auth/forgot-password/ForgotPassword'
import { InitPassword } from 'modules/auth/init-password/InitPassword'
import { Login } from 'modules/auth/login/Login'
import { ResetPassword } from 'modules/auth/reset-password/ResetPassword'
import { ListParameters } from 'modules/list-parameters/ListParameters'
import { PultrusionDies, Rovings, Themes } from 'modules/list-parameters'
import { Users } from 'modules/list-parameters/pages/users/Users'
import { Navigate, Route, Routes } from 'react-router-dom'
import { AuthLayout } from 'ui/layouts/AuthLayout'
import { MainLayout } from 'ui/layouts/MainLayout'
import { Constituents } from 'modules/list-parameters/pages/constituents/Constituents'
import { Reinforcements } from 'modules/list-parameters/pages/reinforcement'
import { Toast } from 'ui/molecules/Toast'
import { FC, useContext } from 'react'
import { UiContext } from 'contexts/ui.context'
import { Configuration, ConfigurationEdition } from 'modules/configuration'
import { ConfigurationCreation } from 'modules/configuration/ConfigurationCreation'
import { MainModal } from 'ui/molecules/Modal'
import { LabProvider } from 'modules/lab/contexts'

const App: FC = () => {
  const { toast, modal } = useContext(UiContext)
  return (
    <div className='overflow-hidden w-screen h-screen font-medium'>
      <LabProvider>
        <Routes>
          <Route element={<MainLayout />} path='/'>
            <Route element={<ExperimentsRoutes />} path='experiments/*' />
            <Route element={<TestCampaignRoutes />} path='test-campaign/*' />
            <Route path='configuration'>
              <Route element={<Configuration />} index={true} />
              <Route element={<ConfigurationEdition />} path=':id' />
              <Route path='create'>
                <Route element={<ConfigurationCreation />} index={true} />
                <Route element={<ConfigurationCreation />} path=':id' />
              </Route>
            </Route>
            <Route element={<ListParameters />} path='list-parameters/'>
              <Route element={<Themes />} path='themes' />
              <Route element={<PultrusionDies />} path='pultrusion-dies' />
              <Route element={<Rovings />} path='rovings' />
              <Route element={<Users />} path='users' />
              <Route element={<Constituents />} path='constituents' />
              <Route element={<Reinforcements />} path='reinforcement' />
            </Route>
          </Route>
          <Route element={<AuthLayout />} path='/auth'>
            <Route element={<Login />} path='login' />
            <Route element={<ForgotPassword />} path='forgot-password' />
            <Route element={<InitPassword />} path='password-init/:token' />
            <Route element={<ResetPassword />} path='password-reset/:token' />
          </Route>
          <Route element={<Navigate replace={true} to='experiments' />} path='*' />
          <Route element={<span>404</span>} />
        </Routes>
        <Toast {...toast} />
        <MainModal {...modal} />
      </LabProvider>
    </div>
  )
}

export default App
