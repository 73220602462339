import { SvgEye } from 'assets/svg'
import { UiContext } from 'contexts/ui.context'
import { i18n } from 'i18n'
import { reinforcement, transverse_reinforcements } from 'modules/lab/schemas'
import { FC, useContext } from 'react'
import { SvgInjector } from 'ui/atoms/SvgInjector'
import { z } from 'zod'
import { TableTd } from './TableTd'

const ReinforcementTable: FC<{
  transverseReinforcements: z.infer<typeof transverse_reinforcements>
  reference: string
}> = ({ transverseReinforcements, reference }) => {
  return (
    <div className='flex flex-col p-8 pt-0'>
      <div className='flex flex-row justify-center items-center py-8 border-b border-pinkish-grey'>
        <span className='text-xl font-semibold text-black'>Renforts transverses - </span>
        <span className='mr-8 text-xl text-black'>Essai {reference}</span>
      </div>
      <div className='p-8'>
        <table className='w-full'>
          <thead>
            <tr>
              <th className='pr-4 text-sm font-medium text-left text-black'>Numéro</th>
              <th className='text-sm font-medium text-left text-black'>Renfort</th>
            </tr>
          </thead>
          <tbody>
            {transverseReinforcements.map((tr) => (
              <tr key={tr.order}>
                <td className='py-4 text-sm text-left text-black'>{`${tr.order + 1}`.padStart(2, '0')}</td>
                <td className='py-4 text-sm text-left text-black'>{tr.label}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  )
}

const ReinforcementTd: FC<{
  matrix: Record<string, boolean>
  reference: string
  data: z.infer<typeof reinforcement>
}> = ({ matrix, data, reference }) => {
  const { updateModal } = useContext(UiContext)
  const handleOnClick = () => {
    updateModal({
      content: (
        <ReinforcementTable reference={reference} transverseReinforcements={data?.transverse_reinforcements ?? []} />
      ),
      displayed: true
    })
  }
  return (
    <>
      {matrix.roving_label && <TableTd className='text-left'>{data?.roving_label}</TableTd>}
      {matrix.roving_fiber && (
        <TableTd className='text-left'>{data && data.roving_fiber ? i18n.enums.fiber[data.roving_fiber] : ''}</TableTd>
      )}
      {matrix.transverse_reinforcements && (
        <TableTd className='text-left'>
          <button className='flex justify-center items-center py-2 px-1 mr-2' onClick={handleOnClick} type='button'>
            <SvgInjector className='inline-block w-4 h-4  text-warm-grey' src={SvgEye} />
          </button>
          {data?.transverse_reinforcements.length}
        </TableTd>
      )}
    </>
  )
}

export { ReinforcementTd }
