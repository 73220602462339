import { SvgAdd } from 'assets/svg'
import { LabContext } from 'modules/lab/contexts'
import { getExperiments } from 'modules/lab/services/experiments'
import { FC, useContext, useState } from 'react'
import { useQuery } from 'react-query'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { ButtonPrimary } from 'ui/atoms'
import { SvgInjector } from 'ui/atoms/SvgInjector'
import { PaginationMenu } from 'ui/molecules/PaginationMenu'
import { MainTemplate } from 'ui/templates'
import { ExperimentsHeader } from './ExperimentsHeader'
import { ExperimentsTable } from './ExperimentsTable/ExperimentsTable'

const Title: FC = () => <h1 className='text-2xl font-bold text-dark-blue'>Liste d'essais</h1>

const Experiments: FC = () => {
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()
  const { filters, resetFilters } = useContext(LabContext)
  const [page, setPage] = useState<string>(searchParams.get('page') ?? '1')
  const [perPage, setPerPage] = useState<string>(searchParams.get('perPage') ?? '100')

  const get = useQuery(['lab.experiments', page, perPage, filters], () => getExperiments(page, perPage, filters))

  const onPaginationChange = (newPage: string, newPerPage: string) => {
    setPage(newPage)
    setPerPage(newPerPage)
  }
  const experiments = get.data?.data ?? []

  return (
    <MainTemplate
      actionButton={
        <ButtonPrimary onClick={() => navigate('/experiments/create')} type={'button'}>
          <div className='flex relative items-center text-white'>
            <SvgInjector className='w-4 h-4 text-white stroke-white' src={SvgAdd} />
            <span className='ml-4'>Créer un nouvel essai</span>
          </div>
        </ButtonPrimary>
      }
      className='overflow-y-auto relative w-full'
      containerClassName='w-full'
      title={<Title />}
    >
      <section className='flex overflow-hidden relative flex-col flex-1'>
        <ExperimentsHeader className='flex-initial mt-8' />
        {filters.experiment?.search != null && (
          <div className='flex flex-row mt-2 text-sm text-black'>
            <p>
              <span>{experiments.length} résulat(s) pour la recherche «&nbsp;</span>
              <span className='font-bold'>{filters.experiment.search}</span>
              <span>&nbsp;»</span>
            </p>
            <button className='mx-1 underline' onClick={resetFilters} type='button'>
              Effacer
            </button>
          </div>
        )}
        <ExperimentsTable className='flex-initial mt-2' experiments={experiments} />
        <div className='flex-1'></div>
        <div className='flex-initial mt-6'>
          <PaginationMenu meta={get.data?.meta ?? null} onChange={onPaginationChange} perPageDefault={perPage} />
        </div>
      </section>
    </MainTemplate>
  )
}

export { Experiments }
