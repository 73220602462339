import { LiteMedia, Nullable, TemporaryFileDto } from 'interfaces'
import { TaskDto } from 'interfaces/api/task'

enum FireTestClassification {
  V0 = 'v0',
  V1 = 'v1',
  V2 = 'v2',
  NC = 'nc'
}

interface ExperimentFireTest {
  id: number
  file: Nullable<LiteMedia>
  classification: FireTestClassification
}

interface ExperimentFireTestDto {
  file: TemporaryFileDto[]
  classification: FireTestClassification
}

interface ExperimentFireTestTaskResults {
  classification: FireTestClassification
}
type ExperimentFireTestTaskDto = TaskDto

export { FireTestClassification }
export type { ExperimentFireTest, ExperimentFireTestDto, ExperimentFireTestTaskResults, ExperimentFireTestTaskDto }
