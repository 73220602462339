import { FC, useContext, useEffect, useRef, useState } from 'react'
import { Nullable } from 'interfaces'
import { AsideNavigation, NavigationRef } from 'ui/atoms/AsideNavigation'
import { Experiment } from 'interfaces/api/lab'
import { i18n } from 'modules/lab/i18n'

import { EosinPanel } from './EosinPanel'
import { LinearDensityPanel } from './LinearDensityPanel'
import { MicroscopyPanel } from './MicroscopyPanel'
import { TractionPanel } from './TractionPanel'
import { CompressionPanel } from './CompressionPanel'
import { FlexionPanel } from './FlexionPanel'
import { ThermocouplePanel } from './ThermocouplePanel'
import { FireTestPanel } from './FireTestPanel'
import { WaterUptakePanel } from './WaterUptakePanel'
import { AlkalineResistancePanel } from './AlkalineResistance'
import { ResidualMonomerPanel } from './ResidualMonomer'
import { DynamicMechanicalAnalysisPanel } from './DynamicMechanicalAnalysis'
import { LabContext } from 'modules/lab/contexts'
import { FiberRatePanel } from './FiberRatePanel'

const ProductionDataTab: FC<{ experiment?: Nullable<Experiment>; scrollTo: (px: number) => void }> = ({ scrollTo }) => {
  const linearDensityPanelRef = useRef<HTMLFormElement>(null)
  const microscopyPanelRef = useRef<HTMLFormElement>(null)
  const tractionPanelRef = useRef<HTMLDivElement>(null)
  const eosinPanelRef = useRef<HTMLFormElement>(null)
  const compressionPanelRef = useRef<HTMLDivElement>(null)
  const flexionPanelRef = useRef<HTMLDivElement>(null)
  const thermocouplePanelRef = useRef<HTMLFormElement>(null)
  const fiberRatePanelRef = useRef<HTMLFormElement>(null)
  const fireTestPanelRef = useRef<HTMLFormElement>(null)
  const waterUptakePanelRef = useRef<HTMLFormElement>(null)
  const alkalineResistanceRef = useRef<HTMLFormElement>(null)
  const residualMonomerPanelRef = useRef<HTMLFormElement>(null)
  const DMAPanelRef = useRef<HTMLDivElement>(null)
  const [refs, setRefs] = useState<NavigationRef[]>([])
  const { reference } = useContext(LabContext)

  useEffect(() => {
    const refArray = [
      {
        title: i18n.productionDataTab.tractionPanel.title,
        ref: tractionPanelRef.current,
        id: 0
      },
      {
        title: i18n.productionDataTab.flexionPanel.title,
        ref: flexionPanelRef.current,
        id: 1
      },
      {
        title: i18n.productionDataTab.compressionPanel.title,
        ref: compressionPanelRef.current,
        id: 2
      },
      {
        title: i18n.productionDataTab.thermocouplePanel.title,
        ref: thermocouplePanelRef.current,
        id: 3
      },
      {
        title: i18n.productionDataTab.DMAPanel.title,
        ref: DMAPanelRef.current,
        id: 4
      },
      {
        title: i18n.productionDataTab.linearDensityPanel.title,
        ref: linearDensityPanelRef.current,
        id: 5
      },
      {
        title: i18n.productionDataTab.microscopyPanel.title,
        ref: microscopyPanelRef.current,
        id: 6
      },
      {
        title: i18n.productionDataTab.eosinPanel.title,
        ref: eosinPanelRef.current,
        id: 7
      },
      {
        title: i18n.productionDataTab.waterUptakePanel.title,
        ref: waterUptakePanelRef.current,
        id: 8
      },
      {
        title: i18n.productionDataTab.alkalineResistancePanel.title,
        ref: alkalineResistanceRef.current,
        id: 9
      },
      {
        title: i18n.productionDataTab.fiberRatePanel.title,
        ref: fiberRatePanelRef.current,
        id: 10
      },
      {
        title: i18n.productionDataTab.fireTestPanel.title,
        ref: fireTestPanelRef.current,
        id: 11
      },
      {
        title: i18n.productionDataTab.residualMonomerPanel.title,
        ref: residualMonomerPanelRef.current,
        id: 12
      }
    ]
    setRefs(refArray)
  }, [])

  return (
    <div className='flex flex-row flex-1 items-start'>
      <div className='flex-1 w-full min-w-[900px] 2xl:mr-10'>
        <TractionPanel ref={tractionPanelRef} />
        <div className='h-10'></div>
        <FlexionPanel ref={flexionPanelRef} />
        <div className='h-10'></div>
        <CompressionPanel ref={compressionPanelRef} />
        <div className='h-10'></div>
        <ThermocouplePanel ref={thermocouplePanelRef} />
        <div className='h-10'></div>
        <DynamicMechanicalAnalysisPanel ref={DMAPanelRef} />
        <div className='h-10'></div>
        <LinearDensityPanel ref={linearDensityPanelRef} />
        <div className='h-10'></div>
        <MicroscopyPanel ref={microscopyPanelRef} />
        <div className='h-10'></div>
        <EosinPanel ref={eosinPanelRef} />
        <div className='h-10'></div>
        <WaterUptakePanel ref={waterUptakePanelRef} />
        <div className='h-10'></div>
        <AlkalineResistancePanel ref={alkalineResistanceRef} />
        <div className='h-10'></div>
        <FiberRatePanel ref={fiberRatePanelRef} />
        <div className='h-10'></div>
        <FireTestPanel ref={fireTestPanelRef} />
        <div className='h-10'></div>
        <ResidualMonomerPanel ref={residualMonomerPanelRef} />
        <div className='h-10'></div>
      </div>
      {refs.length > 0 && (
        <AsideNavigation
          className='hidden top-10 flex-none 2xl:flex'
          margin={20}
          reference={reference}
          refs={refs}
          scrollTo={scrollTo}
        />
      )}
    </div>
  )
}

export { ProductionDataTab }
